import { Trans, useTranslation } from 'react-i18next'
import { generatePath, Outlet, useNavigate } from 'react-router-dom'
import { Add, Download } from '@mui/icons-material'
import { Grid, Link, Typography } from '@mui/material'
import * as Sentry from '@sentry/react'
import { PROSCAN_SUPPORT_MAIL } from 'constants/contacts'
import { ERROR_CODE } from 'constants/errorCode'
import { SUPER_USER_ROLES, USER_ROLES } from 'constants/userRoles'
import { routes } from 'routes/routes'
import { PageHeader } from 'components/PageHeader'
import { ProtectedComponent } from 'components/ProtectedComponent'
import { PSButton } from 'components/ui-components/PSButton'
import { PSConfirmationDialog } from 'components/ui-components/PSConfirmationDialog'
import { PSTab, PSTabs } from 'components/ui-components/PSTabs'
import {
  ALL_ORDERS_TABLE_KEYS_MAP,
  ORDERS_TABS_CONFIG,
} from 'pages/orders/OrdersList/constants'
import { usePSSnackbar } from 'utils/hooks/snackbar/usePSSnackbar'
import { useModal } from 'utils/hooks/useModal'
import { useNavigateToFirstTabOnMounting } from 'utils/hooks/useNavigateToFirstTabOnMounting'
import { useRoles } from 'utils/hooks/useRoles'
import { useRouteMatch } from 'utils/hooks/useRouteMatch'

import { useAllRisOrdersTabTableConfigForSiteUserAndSiteUserAdminRoles } from './components/AllRisOrdersTab/hooks/useAllRisOrdersTabTableConfigForSiteUserAndSiteUserAdminRoles'
import { useAllRisOrdersTabTableConfigForSuperUserAndSysAdminRoles } from './components/AllRisOrdersTab/hooks/useAllRisOrdersTabTableConfigForSuperUserAndSysAdminRoles'
import { useDownloadOrderListCsvReport } from './hooks/useDownloadOrderListCsvReport'

export const OrdersList = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { checkUserRoles } = useRoles()
  const { downloadOrderListCsvReport } = useDownloadOrderListCsvReport()
  const { openErrorSnackbar } = usePSSnackbar()
  const {
    isModalOpened: isReportLimitationModalOpen,
    setIsModalOpened: setIsReportLimitationModalOpen,
    toggleModalVisibility: toggleIsReportLimitationModalOpen,
  } = useModal()
  const useGetAllOrdersTableConfig = checkUserRoles(SUPER_USER_ROLES)
    ? useAllRisOrdersTabTableConfigForSuperUserAndSysAdminRoles
    : useAllRisOrdersTabTableConfigForSiteUserAndSiteUserAdminRoles

  const allOrdersTableConfig = useGetAllOrdersTableConfig()

  const handleAddClick = async () => {
    navigate(
      generatePath(routes.orders.orderEdit.path, { accessionNumber: 'new' })
    )
  }

  const handleDownloadReportClick = () => {
    Sentry.captureMessage('DownloadOrderListCsvReport')
    downloadOrderListCsvReport(
      allOrdersTableConfig,
      ALL_ORDERS_TABLE_KEYS_MAP
    ).catch((errorCode) => {
      if (errorCode !== ERROR_CODE.NUMBER_OF_ORDERS_EXCEEDS_LIMIT) {
        openErrorSnackbar(t('domains.RISOrders.csvReportCouldNotBeGenerated'))
      } else {
        setIsReportLimitationModalOpen(true)
      }
    })
  }

  const availableTabs = ORDERS_TABS_CONFIG.filter(
    (tab) => !tab.allowedRoles || checkUserRoles(tab.allowedRoles)
  )

  const routeMatch = useRouteMatch(availableTabs.map(({ path }) => path))
  const currentTab = routeMatch?.pattern?.path

  useNavigateToFirstTabOnMounting(availableTabs[0].path, currentTab)

  return (
    <>
      <PageHeader
        headerText={t('domains.RISOrders.ordersRISOrders')}
        actions={
          <>
            {currentTab === routes.orders.all.path ? (
              <PSButton
                variant='outlined'
                startIcon={<Download />}
                onClick={handleDownloadReportClick}
              >
                {t('components.buttons.csvReport')}
              </PSButton>
            ) : null}
            <ProtectedComponent
              allowedRoles={[
                USER_ROLES.SITE_USER_ADMIN,
                USER_ROLES.SITE_USER,
                USER_ROLES.SUPER_USER,
                USER_ROLES.SYS_ADMIN,
                USER_ROLES.PROCTOR,
              ]}
            >
              <PSButton
                data-cy='addOrderButton'
                variant='contained'
                onClick={handleAddClick}
                startIcon={<Add />}
              >
                {t('components.buttons.addNew')}
              </PSButton>
            </ProtectedComponent>
          </>
        }
      />
      <Grid>
        <Grid borderBottom={1} borderColor='divider' mb={3}>
          <PSTabs value={currentTab}>
            {availableTabs.map(({ label, key, path }) => (
              <PSTab
                component={Link}
                to={path}
                key={path}
                label={t(label)}
                value={path}
                data-cy={key}
              />
            ))}
          </PSTabs>
        </Grid>
        <Outlet />
      </Grid>
      <PSConfirmationDialog
        agreeButtonText={t('components.buttons.okay')}
        headerText={t('common.warnings.attention')}
        isDialogOpen={isReportLimitationModalOpen}
        onAgree={toggleIsReportLimitationModalOpen}
        onClose={toggleIsReportLimitationModalOpen}
        dialogContent={
          <Trans
            i18nKey='domains.RISOrders.requestedExportExceedLimitations'
            values={{ email: PROSCAN_SUPPORT_MAIL }}
            components={{
              linkText: <Typography component='span' color='primary.light' />,
            }}
          />
        }
      />
    </>
  )
}

import { TCountryKey } from 'i18n/dictionaries/countryKeys'
import { TStateKey } from 'i18n/dictionaries/stateKeys'
import { TPracticesFilters } from 'store/practice/types'
import { TQueryData } from 'types/general'

export type TCompanyFilter = {
  search?: string
  countries: TCountryKey[]
  states: TStateKey[]
  siteIds?: string[]
  isPaged?: boolean
}

export enum PRACTICE_CONFIGURABLE_COLUMNS {
  PRACTICES_STREET_1 = 'PRACTICES_STREET_1',
  PRACTICES_STREET_2 = 'PRACTICES_STREET_2',
  PRACTICES_POSTAL_CODE = 'PRACTICES_POSTAL_CODE',
  PRACTICES_COUNTRY = 'PRACTICES_COUNTRY',
  PRACTICES_NPI = 'PRACTICES_NPI',
}

export interface IState {
  queryData: TQueryData
  filtersData: TPracticesFilters
  columnsConfiguration: {
    [PRACTICE_CONFIGURABLE_COLUMNS.PRACTICES_STREET_1]: boolean
    [PRACTICE_CONFIGURABLE_COLUMNS.PRACTICES_STREET_2]: boolean
    [PRACTICE_CONFIGURABLE_COLUMNS.PRACTICES_POSTAL_CODE]: boolean
    [PRACTICE_CONFIGURABLE_COLUMNS.PRACTICES_COUNTRY]: boolean
    [PRACTICE_CONFIGURABLE_COLUMNS.PRACTICES_NPI]: boolean
  }
}

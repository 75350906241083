import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { DATE_TIME_FORMAT } from 'constants/dates/dateFormats'
import dayjs from 'dayjs'
import { formatDateToPattern } from 'utils/helpers/date/formatDateToPattern'

interface IDateTimeLocaleCellProps {
  data?: string
}

export const DateTimeLocaleCell: FC<IDateTimeLocaleCellProps> = ({ data }) => {
  const { t } = useTranslation()

  return (
    <Typography variant='body2'>
      {data
        ? formatDateToPattern(DATE_TIME_FORMAT, dayjs.utc(data).local())
        : t('components.table.notApplicable')}
    </Typography>
  )
}

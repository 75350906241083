import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  PROGRESS_COMPLETE_VALUE,
  PROGRESS_INITIAL_VALUE,
} from 'constants/files/files'
import {
  IInconsistentData,
  INCONSISTENT_INFO_DISPATCH_SOURCE,
  TCaptureRs,
  TOrderResponse,
  TOrdersCopyCandidatesFilterRq,
  TSearchStudiesTabPrefix,
} from 'store/order/types'
import { COMMENT_TYPE } from 'store/support/types'
import { getIsUndefined } from 'utils/helpers/general'
import { LOADING_STATE, TPageable } from 'types/general'

import { initialState, ORDER_SLICE_NAME } from './constants'
import {
  fetchCopyOrderData,
  fetchCopyOrderInformation,
  fetchGroupedByCompanyStudies,
  fetchOrderByAccessionNumber,
  fetchStudies,
  fetchStudiesByCompanyId,
  getEnhancedViewerLinkRq,
  getManualReportDeliveryData,
  getReportsDeliveryResultsByAccessionNumber,
  getSupportRequestComments,
  getSupportRequestsByAccessionNumber,
  getSupportRequestsCountByAccessionNumber,
  pushStudiesInPACS,
  resendHL7ToDownstreamSystem,
  updateOrderTags,
} from './controllers'

const orderSlice = createSlice({
  name: ORDER_SLICE_NAME,
  initialState,
  reducers: {
    toggleSelectCompanyComparisonSearch: (
      { studiesSearch },
      {
        payload: { companyId, isChecked },
      }: PayloadAction<{
        companyId: number
        isChecked: boolean
      }>
    ) => {
      studiesSearch.comparisonInfoTab.groupedContent =
        studiesSearch.comparisonInfoTab.groupedContent.map((company) => {
          if (company.companyId === companyId) {
            const newStudies = company.studies.map((study) => ({
              ...study,
              isChecked,
            }))

            return {
              ...company,
              isChecked,
              isIndeterminate: false,
              studies: newStudies,
            }
          }

          return company
        })
    },
    toggleSelectStudyComparisonSearch: (
      { studiesSearch },
      {
        payload: { companyId, studyId, isChecked },
      }: PayloadAction<{
        companyId: number
        isChecked: boolean
        studyId: number
      }>
    ) => {
      studiesSearch.comparisonInfoTab.groupedContent =
        studiesSearch.comparisonInfoTab.groupedContent.map((company) => {
          if (company.companyId === companyId) {
            const newStudies = company.studies.map((study) => {
              if (study.id === studyId) {
                return {
                  ...study,
                  isChecked,
                }
              }
              return study
            })

            const isAllStudiesChecked = newStudies.every(
              (study) => study.isChecked
            )
            const isSomeStudyChecked = newStudies.some(
              (study) => study.isChecked
            )
            return {
              ...company,
              isIndeterminate: !isAllStudiesChecked && isSomeStudyChecked,
              isChecked:
                isAllStudiesChecked &&
                newStudies.length === company.totalStudiesInCompany,
              studies: newStudies,
            }
          }

          return company
        })
    },
    toggleSelectAllTableRowsOnPage: (
      state,
      {
        payload: { isSelected },
      }: PayloadAction<{
        isSelected: boolean
      }>
    ) => {
      state.copyData.selectedOrders = {
        ...state.copyData.selectedOrders,
        ...state.copyData.list?.content.reduce<{
          [accessionNumber: string]: boolean
        }>((result, { accessionNumber }) => {
          result[accessionNumber] = isSelected

          return result
        }, {}),
      }
      state.copyData.selectedOrdersEditInfo = {
        ...state.copyData.selectedOrdersEditInfo,
        ...state.copyData.list?.content.reduce<Record<string, TCaptureRs[]>>(
          (result, { accessionNumber, capturedEditInfo }) => {
            result[accessionNumber] = isSelected
              ? capturedEditInfo?.orderEditCaptures ?? []
              : []

            return result
          },
          {}
        ),
      }
    },
    updateAccessionNumberForCopy: (
      state,
      {
        payload,
      }: PayloadAction<{
        accessionNumber: string
        orderEditCaptures?: TCaptureRs[]
      }>
    ) => {
      state.copyData.selectedOrders = {
        ...state.copyData.selectedOrders,
        [payload.accessionNumber]:
          !state.copyData.selectedOrders[payload.accessionNumber],
      }
      if (
        state.copyData.selectedOrders[payload.accessionNumber] &&
        payload.orderEditCaptures
      ) {
        state.copyData.selectedOrdersEditInfo = {
          ...state.copyData.selectedOrdersEditInfo,
          [payload.accessionNumber]: payload.orderEditCaptures,
        }
      } else {
        delete state.copyData.selectedOrdersEditInfo[payload.accessionNumber]
      }
    },
    setCopyOrderDataPageable: (
      state,
      { payload }: PayloadAction<Partial<TPageable>>
    ) => {
      state.copyData.pageable = {
        ...state.copyData.pageable,
        ...payload,
      }
    },
    setCopyOrderDataFilter: (
      state,
      { payload }: PayloadAction<Partial<TOrdersCopyCandidatesFilterRq>>
    ) => {
      state.copyData.filter = {
        ...state.copyData.filter,
        ...payload,
      }
      state.copyData.pageable.page = 0
    },
    resetCopyOrderData: (state) => {
      state.copyData = initialState.copyData
    },
    toggleSearchStudiesTableInComparisonInfoTabShown: ({ studiesSearch }) => {
      studiesSearch.comparisonInfoTab.isTableShown =
        !studiesSearch.comparisonInfoTab.isTableShown
    },

    resetCompanyStudies: (
      state,
      {
        payload: { tabPrefix, companyId },
      }: PayloadAction<{
        tabPrefix: TSearchStudiesTabPrefix
        companyId: number
      }>
    ) => {
      state.studiesSearch[tabPrefix].groupedContent = state.studiesSearch[
        tabPrefix
      ].groupedContent.map((company) => {
        if (company.companyId === companyId) {
          return { ...company, studies: [] }
        }
        return company
      })
    },
    changeSearchStudyResultsTablePaginationData: (
      { studiesSearch },
      {
        payload: { page, size, tabPrefix },
      }: PayloadAction<{
        tabPrefix: TSearchStudiesTabPrefix
        page?: number
        size?: number
      }>
    ) => {
      page && (studiesSearch[tabPrefix].queryData.page = page)
      size && (studiesSearch[tabPrefix].queryData.size = size)
    },
    changeSupportRequestPage: (state, { payload }: PayloadAction<number>) => {
      state.supportRequests.page = payload
      if (payload === 0) {
        state.supportRequests.list = initialState.supportRequests.list
      }
    },
    clearSupportRequestData: (state) => {
      state.supportRequests.loadingState = LOADING_STATE.idle
      state.supportRequests.list = initialState.supportRequests.list
      state.supportRequests.page = initialState.supportRequests.page
      state.supportRequests.count = 0
      state.supportRequests.commentsData =
        initialState.supportRequests.commentsData
    },
    clearSupportRequestCommentsData: (
      state,
      {
        payload: { supportId, commentType },
      }: PayloadAction<{
        supportId: number
        commentType: COMMENT_TYPE
      }>
    ) => {
      if (state.supportRequests.commentsData[supportId][commentType]) {
        delete state.supportRequests.commentsData[supportId][commentType]
      }
    },
    clearOrderInfoData: (state) => {
      state.orderInfo = initialState.orderInfo
    },
    clearStudiesSearchData: (state) => {
      state.studiesSearch = initialState.studiesSearch
    },
    openInconsistentPatientInfoModal: (
      { inconsistentPatient },
      {
        payload: { source },
      }: PayloadAction<{ source: INCONSISTENT_INFO_DISPATCH_SOURCE }>
    ) => {
      inconsistentPatient.isModalShown = true
      inconsistentPatient.source = source
    },
    closeInconsistentPatientInfoModal: ({ inconsistentPatient }) => {
      inconsistentPatient.isModalShown = false
      inconsistentPatient.source = null
    },
    changeInconsistentPatientData: (
      { inconsistentPatient },
      { payload: { data } }: PayloadAction<{ data: IInconsistentData }>
    ) => {
      inconsistentPatient.data = data
    },
    toggleInconsistencyAcknowledgmentCheckbox: ({ inconsistentPatient }) => {
      inconsistentPatient.isInconsistencyAcknowledgmentChecked =
        !inconsistentPatient.isInconsistencyAcknowledgmentChecked
    },
    resetInconsistentPatientData: (state) => {
      state.inconsistentPatient = initialState.inconsistentPatient
    },
    // Push studies to PACS
    setPushStudiesToPACSProgressToFinish: (
      { pushStudiesToPACSProgress },
      {
        payload: { accessionNumber },
      }: PayloadAction<{ accessionNumber: string }>
    ) => {
      if (!getIsUndefined(pushStudiesToPACSProgress[accessionNumber])) {
        pushStudiesToPACSProgress[accessionNumber] = PROGRESS_COMPLETE_VALUE
      }
    },
    removePushStudiesToPACSProgressByAccessionNumber: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      if (
        state.pushStudiesToPACSProgress[payload] === PROGRESS_COMPLETE_VALUE
      ) {
        delete state.pushStudiesToPACSProgress[payload]
      }
    },
    // Resend HL7
    setResendHL7ProgressToFinish: (
      { resendHL7ToDownstreamProgress },
      { payload }: PayloadAction<string>
    ) => {
      if (!getIsUndefined(resendHL7ToDownstreamProgress[payload])) {
        resendHL7ToDownstreamProgress[payload] = PROGRESS_COMPLETE_VALUE
      }
    },
    removeResendHL7ProgressByAccessionNumber: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      if (
        state.resendHL7ToDownstreamProgress[payload] === PROGRESS_COMPLETE_VALUE
      ) {
        delete state.resendHL7ToDownstreamProgress[payload]
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchGroupedByCompanyStudies.pending,
        (
          state,
          {
            meta: {
              arg: { tabPrefix },
            },
          }
        ) => {
          state.studiesSearch[tabPrefix].isLoading = true
        }
      )
      .addCase(
        fetchGroupedByCompanyStudies.rejected,
        (
          state,
          {
            meta: {
              arg: { tabPrefix },
            },
          }
        ) => {
          state.studiesSearch[tabPrefix].isLoading = false
        }
      )
      .addCase(
        fetchGroupedByCompanyStudies.fulfilled,
        (
          state,
          {
            payload: { first, last, content, ...otherData },
            meta: {
              arg: { tabPrefix, query },
            },
          }
        ) => {
          state.studiesSearch[tabPrefix] = {
            ...state.studiesSearch[tabPrefix],
            ...otherData,
            groupedContent: content,
            isFirstPage: first,
            isLastPage: last,
            isTableShown: true,
            isLoading: false,
            queryData: query,
          }
        }
      )

      .addCase(
        fetchStudies.pending,
        (
          state,
          {
            meta: {
              arg: { tabPrefix },
            },
          }
        ) => {
          state.studiesSearch[tabPrefix].isLoading = true
        }
      )
      .addCase(
        fetchStudies.rejected,
        (
          state,
          {
            meta: {
              arg: { tabPrefix },
            },
          }
        ) => {
          state.studiesSearch[tabPrefix].isLoading = false
        }
      )
      .addCase(
        fetchStudies.fulfilled,
        (
          state,
          {
            payload: { first, last, content, ...otherData },
            meta: {
              arg: { tabPrefix, query },
            },
          }
        ) => {
          state.studiesSearch[tabPrefix] = {
            ...state.studiesSearch[tabPrefix],
            ...otherData,
            nonGroupedContent: content,
            isFirstPage: first,
            isLastPage: last,
            isTableShown: true,
            isLoading: false,
            queryData: query,
          }
        }
      )

      .addCase(fetchStudiesByCompanyId.pending, ({ studiesSearch }) => {
        studiesSearch.comparisonInfoTab.isLoadingCompanyStudies = true
      })
      .addCase(fetchStudiesByCompanyId.rejected, ({ studiesSearch }) => {
        studiesSearch.comparisonInfoTab.isLoadingCompanyStudies = false
      })
      .addCase(
        fetchStudiesByCompanyId.fulfilled,
        (
          { studiesSearch },
          {
            payload,
            meta: {
              arg: { companyIds },
            },
          }
        ) => {
          studiesSearch.comparisonInfoTab.isLoadingCompanyStudies = false
          studiesSearch.comparisonInfoTab.groupedContent =
            studiesSearch.comparisonInfoTab.groupedContent.map((company) => {
              if (companyIds.includes(company.companyId)) {
                const newStudies = company.isChecked
                  ? payload.map((study) => ({
                      ...study,
                      isChecked: true,
                    }))
                  : payload

                return {
                  ...company,
                  studies: [...company.studies, ...newStudies],
                }
              }

              return company
            })
        }
      )
      .addCase(fetchOrderByAccessionNumber.pending, ({ orderInfo }) => {
        orderInfo.loadingState = LOADING_STATE.pending
      })
      .addCase(fetchOrderByAccessionNumber.rejected, ({ orderInfo }) => {
        orderInfo.loadingState = LOADING_STATE.rejected
        orderInfo.data = null
      })
      .addCase(
        fetchOrderByAccessionNumber.fulfilled,
        ({ orderInfo }, action) => {
          orderInfo.loadingState = LOADING_STATE.fulfilled
          orderInfo.data = action.payload
        }
      )
      .addCase(getEnhancedViewerLinkRq.fulfilled, ({ orderInfo }) => {
        orderInfo.enhanceLinkLoadingState = LOADING_STATE.fulfilled
      })
      .addCase(getEnhancedViewerLinkRq.pending, ({ orderInfo }) => {
        orderInfo.enhanceLinkLoadingState = LOADING_STATE.pending
      })
      .addCase(getEnhancedViewerLinkRq.rejected, ({ orderInfo }) => {
        orderInfo.enhanceLinkLoadingState = LOADING_STATE.rejected
      })
      .addCase(getSupportRequestsByAccessionNumber.pending, (state) => {
        state.supportRequests.loadingState = LOADING_STATE.pending
      })
      .addCase(getSupportRequestsByAccessionNumber.rejected, (state) => {
        state.supportRequests.loadingState = LOADING_STATE.rejected
      })
      .addCase(
        getSupportRequestsByAccessionNumber.fulfilled,
        (
          state,
          {
            payload: { first, last, content, ...rest },
            meta: {
              arg: {
                pageable: { page },
              },
            },
          }
        ) => {
          state.supportRequests.loadingState = LOADING_STATE.fulfilled
          state.supportRequests.list = {
            ...rest,
            content:
              page === 0
                ? content
                : state.supportRequests.list.content.concat(content),
            isFirstPage: first,
            isLastPage: last,
          }
        }
      )
      .addCase(
        getSupportRequestComments.pending,
        (
          state,
          {
            meta: {
              arg: {
                supportId,
                params: { commentType },
              },
            },
          }
        ) => {
          state.supportRequests.commentsData[supportId] = {
            ...state.supportRequests.commentsData[supportId],
            [commentType]: {
              loadingState: LOADING_STATE.pending,
            },
          }
        }
      )
      .addCase(
        getSupportRequestComments.fulfilled,
        (
          state,
          {
            meta: {
              arg: {
                supportId,
                params: { commentType },
              },
            },
            payload,
          }
        ) => {
          state.supportRequests.commentsData[supportId] = {
            ...state.supportRequests.commentsData[supportId],
            [commentType]: {
              list: payload.content,
              loadingState: LOADING_STATE.fulfilled,
            },
          }
        }
      )
      .addCase(
        getSupportRequestComments.rejected,
        (
          state,
          {
            meta: {
              arg: {
                supportId,
                params: { commentType },
              },
            },
          }
        ) => {
          state.supportRequests.commentsData[supportId] = {
            ...state.supportRequests.commentsData[supportId],
            [commentType]: {
              loadingState: LOADING_STATE.rejected,
            },
          }
        }
      )
      .addCase(getReportsDeliveryResultsByAccessionNumber.pending, (state) => {
        state.reportDeliveryResults.loadingState = LOADING_STATE.pending
      })
      .addCase(getReportsDeliveryResultsByAccessionNumber.rejected, (state) => {
        state.reportDeliveryResults.loadingState = LOADING_STATE.rejected
      })
      .addCase(
        getReportsDeliveryResultsByAccessionNumber.fulfilled,
        (state, { payload }) => {
          state.reportDeliveryResults.content = payload
          state.reportDeliveryResults.loadingState = LOADING_STATE.fulfilled
        }
      )
      .addCase(
        getSupportRequestsCountByAccessionNumber.fulfilled,
        (state, { payload }) => {
          state.supportRequests.count = payload.openSupportRequestsCount ?? 0
        }
      )
      .addCase(
        pushStudiesInPACS.fulfilled,
        (state, { meta: { arg: accessionNumber } }) => {
          state.pushStudiesToPACSProgress[accessionNumber] =
            PROGRESS_INITIAL_VALUE
        }
      )
      .addCase(
        resendHL7ToDownstreamSystem.fulfilled,
        (state, { meta: { arg: accessionNumber } }) => {
          state.resendHL7ToDownstreamProgress[accessionNumber] =
            PROGRESS_INITIAL_VALUE
        }
      )
      .addCase(getManualReportDeliveryData.pending, (state) => {
        state.manualReportDelivery.loadingState = LOADING_STATE.pending
      })
      .addCase(getManualReportDeliveryData.rejected, (state) => {
        state.manualReportDelivery.loadingState = LOADING_STATE.rejected
      })
      .addCase(getManualReportDeliveryData.fulfilled, (state, { payload }) => {
        state.manualReportDelivery.data = payload
        state.manualReportDelivery.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(
        updateOrderTags.fulfilled,
        (state, { payload: { orderTags } }) => {
          if (state.orderInfo?.data) {
            state.orderInfo.data.tags = orderTags
          }
        }
      )
      .addCase(fetchCopyOrderData.pending, (state) => {
        state.copyData.loadingState = LOADING_STATE.pending
      })
      .addCase(fetchCopyOrderData.rejected, (state) => {
        state.copyData.loadingState = LOADING_STATE.rejected
      })
      .addCase(fetchCopyOrderData.fulfilled, (state, { payload }) => {
        state.copyData.list = payload
        state.copyData.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(fetchCopyOrderInformation.fulfilled, (state, { payload }) => {
        state.copyData.selectedOrdersInfo = payload.reduce(
          (acc, obj) => {
            if (obj.accessionNumber) {
              acc[obj.accessionNumber] = obj
            }
            return acc
          },
          {} as Record<string, TOrderResponse>
        )
      })
  },
})

export const {
  toggleSearchStudiesTableInComparisonInfoTabShown,
  changeSearchStudyResultsTablePaginationData,
  toggleInconsistencyAcknowledgmentCheckbox,
  closeInconsistentPatientInfoModal,
  openInconsistentPatientInfoModal,
  changeInconsistentPatientData,
  resetInconsistentPatientData,
  clearOrderInfoData,
  clearStudiesSearchData,
  changeSupportRequestPage,
  clearSupportRequestData,
  clearSupportRequestCommentsData,
  setPushStudiesToPACSProgressToFinish,
  removePushStudiesToPACSProgressByAccessionNumber,
  removeResendHL7ProgressByAccessionNumber,
  setResendHL7ProgressToFinish,
  setCopyOrderDataPageable,
  setCopyOrderDataFilter,
  resetCopyOrderData,
  updateAccessionNumberForCopy,
  toggleSelectAllTableRowsOnPage,
  resetCompanyStudies,
  toggleSelectCompanyComparisonSearch,
  toggleSelectStudyComparisonSearch,
} = orderSlice.actions
export default orderSlice

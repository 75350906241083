import { createAsyncThunk } from '@reduxjs/toolkit'
import { SHARED_STUDIES_SLICE_NAME } from 'store/sharedStudies/constants'
import {
  TSharedOrderStudiesRs,
  TSharedStudiesRs,
} from 'store/sharedStudies/types'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'
import { TPageable } from 'types/general'

export const fetchSharedStudies = createAsyncThunk<
  TSharedStudiesRs,
  { pageable: TPageable },
  { rejectValue: TServiceError }
>(
  `${SHARED_STUDIES_SLICE_NAME}/fetchSharedStudies`,
  async ({ pageable }, { rejectWithValue }) => {
    const result = await request<TSharedStudiesRs>({
      url: '/orders/shared',
      params: pageable,
      method: 'GET',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const getShredStudiesByAccessionNumberRq = createAsyncThunk<
  TSharedOrderStudiesRs,
  {
    accessionNumber: string
  },
  { rejectValue: TServiceError }
>(
  `${SHARED_STUDIES_SLICE_NAME}/getShredStudiesByAccessionNumberRq`,
  async ({ accessionNumber }, { rejectWithValue }) => {
    const result = await request<TSharedOrderStudiesRs>({
      url: `/orders/shared/${accessionNumber}/studies`,
      method: 'GET',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

import { SORT_DIRECTIONS } from 'constants/tables'
import { IState } from 'store/filters/patients/types'

export const PATIENTS_FILTERS_SLICE_NAME = 'patientsFilters'

export const initialState: IState = {
  filtersData: {
    search: undefined,
    proscanMrn: undefined,
    sourceMrn: undefined,
    dateOfBirth: undefined,
    companyIds: [],
    genders: [],
    dateOfServiceFrom: null,
    dateOfServiceTo: null,
    showDuplicates: false,
  },
  queryData: {
    sort: `name,${SORT_DIRECTIONS.ASC}`,
    page: 0,
    size: 100,
  },
}

import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from 'services/axiosBaseQuery'
import {
  ORDER_REPORT_VERSIONS_LIST_ID,
  ORDER_REPORT_VERSIONS_TAG,
} from 'services/orderReports/constants'

import { TReportOrderVersion } from './types'

export const orderReportVersionsApi = createApi({
  reducerPath: 'orderReportVersionsApi',
  tagTypes: [ORDER_REPORT_VERSIONS_TAG],
  baseQuery: axiosBaseQuery({ baseUrl: '/report' }),
  endpoints: (builder) => ({
    getOrderReportVersions: builder.query<TReportOrderVersion[], string>({
      query: (accessionNumber) => ({
        url: `order/${accessionNumber}/version`,
        headers: {
          AccessionNumber: accessionNumber,
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...(result?.map(({ id }) => ({
                type: ORDER_REPORT_VERSIONS_TAG,
                id: String(id),
              })) ?? []),
              {
                type: ORDER_REPORT_VERSIONS_TAG,
                id: ORDER_REPORT_VERSIONS_LIST_ID,
              },
            ]
          : [
              {
                type: ORDER_REPORT_VERSIONS_TAG,
                id: ORDER_REPORT_VERSIONS_LIST_ID,
              },
            ],
    }),
  }),
})

export const { useGetOrderReportVersionsQuery } = orderReportVersionsApi

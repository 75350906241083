import { IState } from 'store/sites/types'
import { LOADING_STATE } from 'types/general'

export const SERIES_LEVEL_FILTERS = ['SERIES_DESCRIPTION', 'MODALITY']

export const initialState: IState = {
  siteInfo: null,
  loadingState: LOADING_STATE.idle,
  error: null,
  autoForwardRulesDictionary: {
    data: null,
    loadingState: LOADING_STATE.idle,
  },
}

export const SITES_SLICE_NAME = 'sites'

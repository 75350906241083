import { TAG_STATUS } from 'store/tags/types'

import { IState } from './types'

export const initialState: IState = {
  pageable: {
    size: 10,
    page: 0,
    sort: '',
  },
  filter: {
    statuses: [TAG_STATUS.ACTIVE],
  },
}

export const TAGS_FILTERS_SLICE_NAME = 'tagsFilters'

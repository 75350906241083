import { USER_ROLES } from 'constants/userRoles'
import { routes } from 'routes/routes'
import en from 'i18n/en'
import { DeepPathsToLiteralsOf, ExtractPaths } from 'utils/types/typeUtils'

export type TTranslationKey = DeepPathsToLiteralsOf<typeof en>

/**
 * Sorting direction, Ascending or Descending.
 */
export type SortDirection = 'asc' | 'desc'

export type TEmptyFunction = () => void

export enum CONTACT_PREFERENCE_TYPES {
  Email = 'EMAIL',
  Phone = 'PHONE',
  Chat = 'CHAT/MESSAGE',
}

export type TPath = ExtractPaths<typeof routes>

export type TPageable = {
  page: number
  size: number
  sort?: string
}

export type TQueryData = TPageable & {
  search?: string
}

export type TTablePageableQueryData = {
  isPaged?: boolean
} & Required<TQueryData>

export type ArrayFieldWithOptionalStringId<T> = T & { id?: string }

export type ArrayFieldWithStringId<T> = T & { id: string }

export interface ITableData<T> {
  content: T[]
  isFirstPage: boolean
  isLastPage: boolean
  totalElements?: number
  totalPages?: number
}

type TTabBasic = {
  allowedRoles?: USER_ROLES[]
  label: TTranslationKey
}

export type TTab<T = undefined> = T extends undefined
  ? TTabBasic
  : TTabBasic & { key: T }

export type TTabWithRoute<T = undefined> = {
  path: TPath
} & TTab<T>

export enum LOADING_STATE {
  idle = 'idle',
  pending = 'pending',
  fulfilled = 'fulfilled',
  rejected = 'rejected',
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Tooltip } from '@mui/material'
import { TOrder } from 'store/ordersList/types'

interface IRISOrderStatusCellProps {
  data: TOrder['risOrderStatus']
}

export const RISOrderStatusCell: FC<IRISOrderStatusCellProps> = ({ data }) => {
  const { t } = useTranslation()

  const status = data?.status
    ? t(`proScanStatuses.${data.status}`)
    : t('components.table.notApplicable')

  return data?.reasons?.length ? (
    <Tooltip
      title={data.reasons.map((reason) => (
        <Box key={reason}>{reason}</Box>
      ))}
    >
      <Box>{status}</Box>
    </Tooltip>
  ) : (
    <>{status}</>
  )
}

import { TStudiesQueryParams } from 'store/order/types'

export const prepareStudyParams = (query: TStudiesQueryParams) => ({
  size: query.size,
  page: query.page,
  ...(query.creationDateFrom && { creationDateFrom: query.creationDateFrom }),
  ...(query.creationDateTo && { creationDateTo: query.creationDateTo }),
  ...(query.patientName && { patientName: query.patientName }),
  ...(query.sourceMrn && { sourceMrn: query.sourceMrn }),
  ...(query.modality && { modality: query.modality }),
  ...(query.studyDescription && {
    studyDescription: query.studyDescription,
  }),
  ...(query.studyDateFrom && { studyDateFrom: query.studyDateFrom }),
  ...(query.studyDateTo && { studyDateTo: query.studyDateTo }),
  ...(query.byCompany && { byCompany: query.byCompany }),
})

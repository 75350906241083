import { Box, styled } from '@mui/material'

export const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'overflowWrap',
})<{
  overflowWrap?: 'anywhere' | 'break-word'
}>(({ overflowWrap }) => ({
  ...(overflowWrap && {
    overflowWrap,
  }),
}))

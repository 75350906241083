import { createAsyncThunk } from '@reduxjs/toolkit'
import { prepareFilterParams } from 'store/audit/utils/prepareFilterParams'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'

import { AUDIT_SLICE_NAME } from './constants'
import {
  HL7_COMMUNICATION_TYPE_ENUM,
  IAuditTableParams,
  THl7MessageResponse,
  TOrderAuditTableResponse,
  TPatientAuditTableResponse,
} from './types'

export const fetchOrderAudit = createAsyncThunk<
  TOrderAuditTableResponse,
  {
    accessionNumber: string
    params: IAuditTableParams
    abortSignal?: AbortSignal
  },
  { rejectValue: TServiceError }
>(
  `${AUDIT_SLICE_NAME}/fetchOrderAudit`,
  async ({ accessionNumber, params, abortSignal }, { rejectWithValue }) => {
    const result = await request<TOrderAuditTableResponse>({
      url: `/audit/${accessionNumber}/order`,
      params: prepareFilterParams(params),
      signal: abortSignal,
      headers: {
        AccessionNumber: accessionNumber,
      },
      method: 'GET',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const fetchPatientAudit = createAsyncThunk<
  TPatientAuditTableResponse,
  {
    proscanMrn: string
    params: IAuditTableParams
    abortSignal?: AbortSignal
  },
  { rejectValue: TServiceError }
>(
  `${AUDIT_SLICE_NAME}/fetchPatientAudit`,
  async ({ proscanMrn, params, abortSignal }, { rejectWithValue }) => {
    const result = await request<TPatientAuditTableResponse>({
      url: `/audit/${proscanMrn}/patient`,
      params: prepareFilterParams(params),
      signal: abortSignal,
      method: 'GET',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const fetchHl7Message = createAsyncThunk<
  THl7MessageResponse,
  {
    messageControlId: string
    communicationType: HL7_COMMUNICATION_TYPE_ENUM
  },
  { rejectValue: TServiceError }
>(
  `${AUDIT_SLICE_NAME}/fetchHl7Message`,
  async ({ messageControlId, communicationType }, { rejectWithValue }) => {
    const result = await request<THl7MessageResponse>({
      url: `/hl7-processor/${messageControlId}/message`,
      method: 'GET',
      params: {
        hl7Type: communicationType,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

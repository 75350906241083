import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SITE_AND_SUPER_USER_ROLES } from 'constants/userRoles'
import { ProviderDisplayName } from 'components/ProviderDisplayName'
import { TProviderDisplayName } from 'store/providers/types'
import { getPersonFullNameString } from 'utils/helpers/strings/getPersonFullNameString'
import { useRoles } from 'utils/hooks/useRoles'

interface IPhysicianCellProps {
  data: TProviderDisplayName | null
}

// Ordering provider also
export const PhysicianCell: FC<IPhysicianCellProps> = ({ data }) => {
  const { checkUserRoles } = useRoles()
  const { t } = useTranslation()

  const isSiteAndSuperUserRoles = checkUserRoles(SITE_AND_SUPER_USER_ROLES)

  const fullName = getPersonFullNameString(data)

  return data ? (
    <ProviderDisplayName
      providerId={data.providerId}
      fullName={fullName}
      degrees={data.degrees}
      disableLink={!isSiteAndSuperUserRoles}
    />
  ) : (
    <>{t('components.table.notApplicable')}</>
  )
}

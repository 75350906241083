import { createContext, ReactNode, useMemo, useState } from 'react'

import { EnableNotificationsModal } from './components'
import { useHandleCamundaFailureMessage } from './hooks/useHandleCamundaFailureMessage'
import { useHandleCSVReportDownloadMessage } from './hooks/useHandleCSVReportDownloadMessage'
import { useHandleDICOMArchiveMessage } from './hooks/useHandleDICOMArchiveMessage'
import { useHandleISOGenerateMessage } from './hooks/useHandleISOGenerateMessage'
import { useHandleManualPushStudiesToPACSMessage } from './hooks/useHandleManualPushStudiesToPACSMessage'
import { useHandleManualReportVersionMessage } from './hooks/useHandleManualReportVersionMessage'
import { useHandleResendHL7Message } from './hooks/useHandleResendHL7Message'
import { useHandleTransferViaTaMessage } from './hooks/useHandleTransferViaTaMessage'
import { useNotificationListener } from './hooks/useNotificationListener'

export const FireBaseNotificationContext = createContext({
  showEnableNotificationModal: () => {},
})

export const FireBaseNotificationProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [isEnableNotificationModalOpen, setIsEnableNotificationModalOpen] =
    useState(false)

  const { handleDICOMArchiveMessage } = useHandleDICOMArchiveMessage()
  const { handleTransferViaTaMessage } = useHandleTransferViaTaMessage()
  const { handleISOGenerateMessage } = useHandleISOGenerateMessage()
  const { handleCSVReportDownloadMessage } = useHandleCSVReportDownloadMessage()
  const { handleManualPushStudiesToPACSMessage } =
    useHandleManualPushStudiesToPACSMessage()
  const { handleManualReportVersionMessage } =
    useHandleManualReportVersionMessage()
  const { handleResendHL7Message } = useHandleResendHL7Message()
  const { handleCamundaFailureMessage } = useHandleCamundaFailureMessage()

  useNotificationListener(handleDICOMArchiveMessage)
  useNotificationListener(handleTransferViaTaMessage)
  useNotificationListener(handleISOGenerateMessage)
  useNotificationListener(handleCSVReportDownloadMessage)
  useNotificationListener(handleManualPushStudiesToPACSMessage)
  useNotificationListener(handleManualReportVersionMessage)
  useNotificationListener(handleResendHL7Message)
  useNotificationListener(handleCamundaFailureMessage)

  const handleShowEnableNotificationModal = () => {
    setIsEnableNotificationModalOpen(true)
  }
  const handleCloseEnableNotificationModal = () => {
    setIsEnableNotificationModalOpen(false)
  }

  const contextValue = useMemo(
    () => ({ showEnableNotificationModal: handleShowEnableNotificationModal }),
    []
  )

  return (
    <FireBaseNotificationContext.Provider value={contextValue}>
      {children}
      {isEnableNotificationModalOpen ? (
        <EnableNotificationsModal
          handleClose={handleCloseEnableNotificationModal}
        />
      ) : null}
    </FireBaseNotificationContext.Provider>
  )
}

import { TAG_TYPE, TGroupedTagsByType, TTagRs } from 'store/tags/types'

export const groupTagByType = (tags: TTagRs[]) =>
  tags.reduce<TGroupedTagsByType>(
    (acc, item) => {
      acc[item.type].push(item)
      return acc
    },
    { [TAG_TYPE.ADMIN]: [], [TAG_TYPE.CUSTOMER]: [] }
  )

import { LOADING_STATE } from 'types/general'

import { INSURANCE_TYPES_ENUM, IState } from './types'

export const ORDER_BILLING_INFO_SLICE_NAME = 'orderBillingInformation'

export const ORDER_BILLING_INFO_INITIAL_STATE: IState = {
  billingTypes: {
    [INSURANCE_TYPES_ENUM.PRIMARY]: [],
    [INSURANCE_TYPES_ENUM.SECONDARY]: [],
  },
  billingInformation: undefined,
  loadingState: LOADING_STATE.idle,
  primaryInsurancesTable: {
    list: {
      content: [],
      totalElements: 0,
      totalPages: 0,
      first: true,
      last: false,
    },
    pageable: {
      size: 10,
      page: 0,
    },
    filter: {
      insuranceName: '',
      address: '',
      city: '',
      postalCode: '',
      payerId: '',
    },
    loadingState: LOADING_STATE.idle,
    error: null,
  },
  insuranceHistoryTable: {
    list: {
      content: [],
      totalElements: 0,
      totalPages: 0,
      first: true,
      last: false,
    },
    pageable: {
      size: 10,
      page: 0,
    },
    loadingState: LOADING_STATE.idle,
  },
}

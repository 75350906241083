import { IPersonContained } from './types'

export const getPersonFullNameString = (
  personInfo: IPersonContained | null = {}
) => {
  const fullName = [
    personInfo?.lastName,
    personInfo?.firstName,
    personInfo?.middleName,
  ]
    .filter(Boolean)
    .join(', ')

  return fullName
}

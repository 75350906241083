import { IAuditTableParams } from 'store/audit/types'
import { prepareArrayForSending } from 'utils/helpers/arrays/prepareArrayForSending'

export const prepareFilterParams = (params: IAuditTableParams) => ({
  page: params.page,
  size: params.size,
  ...(params.search && {
    search: params.search,
  }),
  ...(params.categories && {
    categories: prepareArrayForSending(
      params.categories.map((option) => option.category)
    ),
  }),
  ...(params.triggers && {
    triggers: prepareArrayForSending(
      params.triggers.map(({ trigger }) => trigger)
    ),
  }),
  ...(params.events && {
    events: prepareArrayForSending(params.events.map((option) => option.event)),
  }),
  ...(params.dateTime.from && {
    from: params.dateTime.from,
  }),
  ...(params.dateTime.to && {
    to: params.dateTime.to,
  }),
})

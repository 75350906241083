import { RouterProvider } from 'react-router-dom'
import {
  CssBaseline,
  experimental_extendTheme,
  GlobalStyles,
  ThemeProvider,
} from '@mui/material'
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles'
import { themeConstants } from 'constants/theme.constants'
import { SnackbarProvider } from 'notistack'
import { routers } from 'routes/index'
import { ColorModeProvider } from 'components/ColorModeProvider'
import { ProtectedRoute } from 'components/ProtectedRoute'
import { FireBaseNotificationProvider } from 'utils/FireBaseNotifications'

import { globalStyles } from './globalStyles'

export const App = () => {
  const theme = experimental_extendTheme(themeConstants)

  return (
    <CssVarsProvider>
      <ProtectedRoute>
        <ColorModeProvider>
          <SnackbarProvider>
            <ThemeProvider theme={theme}>
              <FireBaseNotificationProvider>
                <CssBaseline />
                <GlobalStyles styles={globalStyles} />
                <RouterProvider router={routers} />
              </FireBaseNotificationProvider>
            </ThemeProvider>
          </SnackbarProvider>
        </ColorModeProvider>
      </ProtectedRoute>
    </CssVarsProvider>
  )
}

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { AxiosProgressEvent } from 'axios'
import { PROGRESS_COMPLETE_VALUE, S3_FILE_GROUPS } from 'constants/files/files'
import { getTabId } from 'store/filesDownloading/selectors'
import { setDICOMDownloadingProgress } from 'store/filesDownloading/slice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { files } from 'utils/api/files/Files'
import { TGetFileLinkResponse } from 'utils/api/files/types'
import { downloadBlobFile } from 'utils/helpers/files/downloadBlobFile'
import { getDownloadProgress } from 'utils/helpers/files/getDownloadProgress'
import { getFileNameFromS3URL } from 'utils/helpers/files/getFileNameFromS3URL'
import { useGetLinkForDownload } from 'utils/hooks/files/useGetLinkForDownload'
import { usePSSnackbar } from 'utils/hooks/snackbar/usePSSnackbar'

import {
  FIREBASE_NOTIFICATION_TYPE_ENUM,
  TFirebaseMessage,
  TMessageHandler,
} from '../types'

export const useHandleDICOMArchiveMessage = () => {
  const { getLinkForDownloadRequest } = useGetLinkForDownload()
  const { openErrorSnackbar } = usePSSnackbar()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const tabId = useAppSelector(getTabId, shallowEqual)

  const handleDICOMDownloadProgress =
    (accessionNumber: string, studyId: number) =>
    (download: AxiosProgressEvent) => {
      const downloadProgress = getDownloadProgress(download)

      dispatch(
        setDICOMDownloadingProgress({
          progress: downloadProgress,
          accessionNumber,
          studyId,
        })
      )
    }

  const handleDICOMArchiveMessage: TMessageHandler = useCallback(
    (event) => {
      try {
        const notificationBody: TFirebaseMessage = JSON.parse(
          event.data.notification.body
        )

        if (
          notificationBody.type ===
          FIREBASE_NOTIFICATION_TYPE_ENUM.SUCCESS_STUDY_ARCHIVE
        ) {
          if (tabId !== notificationBody.metaInfo.tabId) return

          getLinkForDownloadRequest(
            notificationBody.metaInfo.objKey,
            S3_FILE_GROUPS.DICOM
          )
            .then(async (archiveLink: TGetFileLinkResponse | null) => {
              if (archiveLink) {
                const dicomFile = await files.downloadBlobFile(
                  archiveLink.url,
                  {
                    onDownloadProgress: handleDICOMDownloadProgress(
                      notificationBody.metaInfo.accessionNumber,
                      notificationBody.metaInfo.studyId
                    ),
                  }
                )

                return {
                  fileName: getFileNameFromS3URL(archiveLink.url),
                  dicomFile,
                }
              }

              return Promise.reject(
                new Error('DICOM archivingd archiveLink is not provided')
              )
            })
            .then(({ fileName, dicomFile }) => {
              if (dicomFile.data) {
                downloadBlobFile(dicomFile.data, fileName)
              } else {
                return Promise.reject(
                  new Error('DICOM archiving data is not provided')
                )
              }
            })
            .catch(() => {
              dispatch(
                setDICOMDownloadingProgress({
                  progress: PROGRESS_COMPLETE_VALUE,
                  accessionNumber: notificationBody.metaInfo.accessionNumber,
                  studyId: notificationBody.metaInfo.studyId,
                })
              )
              openErrorSnackbar(
                t('common.notifications.generalErrorNotificationMessage')
              )
            })
        }
        if (
          notificationBody.type ===
          FIREBASE_NOTIFICATION_TYPE_ENUM.FAILURE_STUDY_ARCHIVE
        ) {
          if (tabId !== notificationBody.metaInfo.tabId) return

          dispatch(
            setDICOMDownloadingProgress({
              progress: PROGRESS_COMPLETE_VALUE,
              accessionNumber: notificationBody.metaInfo.accessionNumber,
              studyId: notificationBody.metaInfo.studyId,
            })
          )
          openErrorSnackbar(notificationBody.message)
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('JSON Parse error: ', e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return { handleDICOMArchiveMessage }
}

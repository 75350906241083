import { TServiceError } from 'utils/api/types'
import { LOADING_STATE } from 'types/general'
import { components } from 'types/schemas'

export type FilterWithId<T> = T & { id: string | number }

export type TFlagId = components['schemas']['FlagCreationRs']
export type TFlag = components['schemas']['FlagCreationRq']
export type TFlagRelation = components['schemas']['FlagRelation']
export type TFlagType = components['schemas']['FlagType']
export type TOrderStatus = components['schemas']['OrderStatus']
export type TFlagsPageResponse = components['schemas']['BffFlagPageRs']
export type TFlagGridResponse = components['schemas']['BffFlagGridRs']
export type TFlagRequestFilter = components['schemas']['FlagFilterRq']

export type TProcedureCell = Required<components['schemas']['ProcedureNameRs']>
export type TFlagInfo = components['schemas']['BffFlagRs']

export interface IState {
  list: TFlagsPageResponse
  loadingState: LOADING_STATE
  error?: TServiceError | null
  flag?: TFlagInfo
}

export enum FLAG_ARCHIVE_STATUS {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

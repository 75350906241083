import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { AxiosProgressEvent } from 'axios'
import { PROGRESS_COMPLETE_VALUE, S3_FILE_GROUPS } from 'constants/files/files'
import { getTabId } from 'store/filesDownloading/selectors'
import { setCSVReportDownloadProgress } from 'store/filesDownloading/slice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { files } from 'utils/api/files/Files'
import { TGetFileLinkResponse } from 'utils/api/files/types'
import { downloadBlobFile } from 'utils/helpers/files/downloadBlobFile'
import { getDownloadProgress } from 'utils/helpers/files/getDownloadProgress'
import { useGetLinkForDownload } from 'utils/hooks/files/useGetLinkForDownload'
import { usePSSnackbar } from 'utils/hooks/snackbar/usePSSnackbar'

import { getCSVReportFileName } from '../helpers/getCSVReportFileName'
import {
  FIREBASE_NOTIFICATION_TYPE_ENUM,
  TFirebaseMessage,
  TMessageHandler,
} from '../types'

export const useHandleCSVReportDownloadMessage = () => {
  const { getLinkForDownloadRequest } = useGetLinkForDownload()
  const { openErrorSnackbar } = usePSSnackbar()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const tabId = useAppSelector(getTabId, shallowEqual)

  const handleCSVReportDownloadProgress =
    (eventId: string) => (download: AxiosProgressEvent) => {
      const downloadProgress = getDownloadProgress(download)
      dispatch(
        setCSVReportDownloadProgress({
          progress: downloadProgress,
          eventId,
        })
      )
    }

  const handleCSVReportDownloadMessage: TMessageHandler = useCallback(
    (event) => {
      try {
        const notificationBody: TFirebaseMessage = JSON.parse(
          event.data.notification.body
        )

        if (
          notificationBody.type ===
          FIREBASE_NOTIFICATION_TYPE_ENUM.SUCCESS_STORE_CSV_REPORT
        ) {
          if (tabId !== notificationBody.metaInfo.tabId) return

          getLinkForDownloadRequest(
            notificationBody.metaInfo.objectKey,
            S3_FILE_GROUPS.CSV_REPORT
          )
            .then(async (archiveLink: TGetFileLinkResponse | null) => {
              if (archiveLink) {
                const fileName = getCSVReportFileName()
                const csvReportFile = await files.downloadBlobFile(
                  archiveLink.url,
                  {
                    onDownloadProgress: handleCSVReportDownloadProgress(
                      notificationBody.metaInfo.eventId
                    ),
                  }
                )

                return {
                  csvReportFile,
                  fileName,
                }
              }

              return Promise.reject(
                new Error('ISO downloading archiveLink is not provided')
              )
            })
            .then(async ({ csvReportFile, fileName }) => {
              if (csvReportFile.data) {
                downloadBlobFile(csvReportFile.data, fileName)
              } else {
                return Promise.reject(
                  new Error('ISO downloading data is not provided')
                )
              }
            })
            .catch(() => {
              dispatch(
                setCSVReportDownloadProgress({
                  eventId: notificationBody.metaInfo.eventId,
                  progress: PROGRESS_COMPLETE_VALUE,
                })
              )
              openErrorSnackbar(
                t('common.notifications.generalErrorNotificationMessage')
              )
            })
        }

        if (
          notificationBody.type ===
          FIREBASE_NOTIFICATION_TYPE_ENUM.FAILURE_STORE_CSV_REPORT
        ) {
          if (tabId !== notificationBody.metaInfo.tabId) return

          dispatch(
            setCSVReportDownloadProgress({
              eventId: notificationBody.metaInfo.eventId,
              progress: PROGRESS_COMPLETE_VALUE,
            })
          )
          openErrorSnackbar(notificationBody.message)
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('JSON Parse error: ', e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return { handleCSVReportDownloadMessage }
}

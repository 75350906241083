import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _merge from 'lodash/merge'
import { REHYDRATE } from 'redux-persist'
import {
  initialState,
  PATIENTS_FILTERS_SLICE_NAME,
} from 'store/filters/patients/constants'
import { TFilterData } from 'store/filters/patients/types'

const patientsFiltersSlice = createSlice({
  name: PATIENTS_FILTERS_SLICE_NAME,
  initialState,
  reducers: {
    changeFilterData: (state, { payload }) => {
      state.filtersData = {
        ...state.filtersData,
        ...payload,
      }
      state.queryData.page = 0
    },
    changeTableQueryData: (state, { payload }) => {
      state.queryData = {
        ...state.queryData,
        ...payload,
        page: payload.page ?? 0,
      }
    },
    clearFiltersDataAndQueryDataPage: (state) => {
      state.filtersData = initialState.filtersData
      state.queryData.page = 0
    },
    clearFilterByName: (
      state,
      { payload }: PayloadAction<keyof TFilterData>
    ) => {
      state.filtersData = {
        ...state.filtersData,
        [payload]: initialState.filtersData[payload],
      }
      state.queryData.page = 0
    },
  },
  extraReducers: (builder) => {
    // @ts-expect-error action has a payload with rehydrated state
    builder.addCase(REHYDRATE, (state, { payload }) => {
      if (payload) {
        const filters = payload.filters[PATIENTS_FILTERS_SLICE_NAME]
        return _merge(state, filters ?? initialState)
      }
      return state
    })
  },
})

export const {
  changeFilterData,
  changeTableQueryData,
  clearFiltersDataAndQueryDataPage,
  clearFilterByName,
} = patientsFiltersSlice.actions
export default patientsFiltersSlice

import { LOADING_STATE } from 'types/general'

import { ASSIGNMENT_RADIOLOGIST_TAB, IState } from './types'

export const sliceName = 'assignProvidersTable'

export const initialState: IState = {
  flags: {
    list: [],
    loadingState: LOADING_STATE.idle,
  },
  pseudoFlags: {
    list: [],
    loadingState: LOADING_STATE.idle,
  },
  lockInformation: {
    isLockInProgress: false,
    readyForAssignment: [],
    alreadyLocked: [],
    linkedOrders: [],
    patients: {},
  },
  assignQuery: {
    assignmentTab: ASSIGNMENT_RADIOLOGIST_TAB.RADIOLOGISTS,
    accessionNumbers: [],
    includeDoNotAssignmentPanel: false,
  },
  previouslyAssignedRadiologists: {
    loadingState: LOADING_STATE.idle,
    pageable: {
      size: 20,
      page: 0,
      sort: '',
    },
  },
  radiologists: {
    loadingState: LOADING_STATE.idle,
    tableInfo: {
      isSingleOrder: true,
      totalPages: 0,
      totalElements: 0,
      first: false,
      last: false,
    },
    queryData: {
      page: 0,
      size: 10,
    },
    singleAssignmentList: [],
    multiAssignmentList: [],
  },
  groups: {
    loadingState: LOADING_STATE.idle,
    tableInfo: {
      totalPages: 0,
      totalElements: 0,
      first: false,
      last: false,
    },
    queryData: {
      page: 0,
      size: 10,
    },
    radiologists: [],
  },
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TOrder } from 'store/ordersList/types'

interface ISourceMrnCellProps {
  data: TOrder
}

export const SourceMrnCell: FC<ISourceMrnCellProps> = ({ data }) => {
  const { t } = useTranslation()

  return <>{data.patient?.sourceMrn ?? t('components.table.notApplicable')}</>
}

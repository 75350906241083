import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { Box, Link, useTheme } from '@mui/material'
import { PSPageTitle } from 'components/ui-components/PSPageTitle'
import { PSTab, PSTabs } from 'components/ui-components/PSTabs'
import { useNavigateToFirstTabOnMounting } from 'utils/hooks/useNavigateToFirstTabOnMounting'
import { useRoles } from 'utils/hooks/useRoles'
import { useRouteMatch } from 'utils/hooks/useRouteMatch'

import { DICTIONARIES_TABS } from './constants'

export const DictionariesList = () => {
  const { checkUserRoles } = useRoles()
  const { t } = useTranslation()
  const theme = useTheme()

  const availableTabs = DICTIONARIES_TABS.filter(
    (tab) => !tab.allowedRoles || checkUserRoles(tab.allowedRoles)
  )

  const routeMatch = useRouteMatch(availableTabs.map(({ path }) => path))
  const currentTab = routeMatch?.pattern?.path

  useNavigateToFirstTabOnMounting(availableTabs[0].path, currentTab)

  return (
    <>
      <PSPageTitle>
        {t('components.sidebar.sidebarItemDictionaries')}
      </PSPageTitle>
      <Box borderBottom={1} borderColor={theme.palette.divider}>
        <PSTabs value={currentTab}>
          {availableTabs.map(({ key, label, path }) => (
            <PSTab
              component={Link}
              to={path}
              key={path}
              value={path}
              label={t(label)}
              data-cy={key}
            />
          ))}
        </PSTabs>
      </Box>
      <Outlet />
    </>
  )
}

import { Button, styled } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'isLabelTransformDisabled' &&
    prop !== 'isPaddingDisabled' &&
    prop !== 'textAlign',
})<{
  isLabelTransformDisabled: boolean
  isPaddingDisabled?: boolean
  textAlign?: 'center' | 'right' | 'left'
}>(({ isLabelTransformDisabled, isPaddingDisabled, textAlign = 'center' }) => ({
  textAlign,
  ...(isLabelTransformDisabled && {
    fontWeight: 400,
    textTransform: 'none',
  }),
  ...(isPaddingDisabled && {
    padding: 0,
  }),
}))

export const StyledProgressIcon = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: theme.spacing(-1.5),
  marginLeft: theme.spacing(-1.5),
}))

import { Snackbar, styled } from '@mui/material'

export const StyledSnackbar = styled(Snackbar)(({ theme: { palette } }) => ({
  position: 'static',
  borderRadius: '4px',
  '.MuiSnackbarContent-root': {
    minWidth: '350px',
    backgroundColor: palette.text.primary,
    color: palette.background.paper,
    boxShadow:
      '0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)',
  },
}))

import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATE } from 'types/general'

import * as controllers from './controllers'
import { IState } from './types'

const initialState: IState = {
  orderPopoverFlags: [],
  loadingState: LOADING_STATE.idle,
}

export const name = 'orderFlags'

const orderFlagsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(controllers.getOrderFlagsRq.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.getOrderFlagsRq.fulfilled, (state, action) => {
        state.orderPopoverFlags = action.payload
        state.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(controllers.getOrderFlagsRq.rejected, (state) => {
        state.loadingState = LOADING_STATE.rejected
      })
    builder
      .addCase(controllers.updateCheckOrderFlag.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.updateCheckOrderFlag.fulfilled, (state) => {
        state.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(controllers.updateCheckOrderFlag.rejected, (state) => {
        state.loadingState = LOADING_STATE.rejected
      })
    builder
      .addCase(controllers.updateAllCheckOrderFlags.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.updateAllCheckOrderFlags.fulfilled, (state) => {
        state.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(controllers.updateAllCheckOrderFlags.rejected, (state) => {
        state.loadingState = LOADING_STATE.rejected
      })
  },
})

export default orderFlagsSlice

export const DATE_FORMAT = 'YYYY-MM-DD'

export const TIME_FORMAT = 'hh:mm A'

export const DATE_FORMAT_FOR_UI = 'MM/DD/YYYY'

export const DATE_TIME_FORMAT = 'MM/DD/YYYY, hh:mm a'

export const REVERT_DATE_FORMAT = 'ddd M/D/YYYY'

export const DATE_FORMAT_REPORT_NAME = 'MMDDYYYY'

export const EUROPE_TIME_FORMAT = 'HH:mm:ss'

export const DATE_TIME_FORMAT_WITH_WEEKDAY_AND_TIMEZONE =
  'ddd MM/DD/YYYY, hh:mm:ss A z'

export const DATE_TIME_FORMAT_WITH_TIMEZONE = 'MM/DD/YYYY, hh:mm A z'
export const TIME_FORMAT_WITH_TIMEZONE = 'hh:mm A z'

export enum DATE_RANGE_KEYS {
  From = 'from',
  To = 'to',
}

import { TCountryKey } from 'i18n/dictionaries/countryKeys'
import { TStateKey } from 'i18n/dictionaries/stateKeys'
import { TServiceError } from 'utils/api/types'
import { LOADING_STATE } from 'types/general'
import { components } from 'types/schemas'

export type TDegreeRs = Required<components['schemas']['DegreeRs']>
export type TRadiologist = Omit<
  components['schemas']['RadiologistRs'],
  'middleName' | 'degrees'
> & {
  middleName?: string | null
  degrees?: TDegreeRs[]
}

export type TRadiologistData = Omit<
  components['schemas']['RadiologistData'],
  'medicalLicenses' | 'type' | 'notes'
> & {
  medicalLicenses: TMedicalLicense[]
  notes?: string | null
}
export type TPhysicianData = components['schemas']['PhysicianData']

export type TSitePreference = components['schemas']['SitePreference']

export type TProviderCreationRq = Omit<
  components['schemas']['ProviderCreationRq'],
  | 'initials'
  | 'isDistributed'
  | 'country'
  | 'physicianData'
  | 'radiologistData'
  | 'phoneNumber'
  | 'npi'
  | 'middleName'
> & {
  phoneNumber?: string
  initials?: string | null
  middleName?: string | null
  country?: string | null
  npi?: string | null
  physicianData?: TPhysicianData | null
  radiologistData?: TRadiologistData | null
}
export type TProviderCreationRs = components['schemas']['ProviderCreationRs']

export type TProviderResponse = components['schemas']['BffProviderRs']
export type TProviderUpdateResponse = components['schemas']['ProviderUpdateRs']
export type TBffSitePreference = components['schemas']['BffSitePreference']
export type TShortPhysicianTab = components['schemas']['ShortPhysicianTab']
export type TShortPhysicianCreateRq = Omit<
  components['schemas']['ShortPhysicianCreateRq'],
  'middleName' | 'initials'
> & {
  middleName?: string | null
  initials?: string | null
}

export type TShortPhysicianUpdateRq = Omit<
  components['schemas']['ShortPhysicianUpdateRq'],
  'middleName' | 'initials'
> & {
  middleName?: string | null
  initials?: string | null
}
export type TRadiologistFilterRs = Omit<
  components['schemas']['RadiologistFilterRs'],
  'countries' | 'states'
> & {
  countries: TCountryKey[]
  states: TStateKey[]
}

export type TPhysician = Omit<
  components['schemas']['BffPhysicianRs'],
  'degrees' | 'middleName' | 'initials'
> & {
  degrees?: TDegreeRs[]
  middleName?: string | null
  initials?: string | null
}

export type TProviderSearchRs = components['schemas']['ProviderSearchRs']

export type TNPIWillBeGenerated = { npiWillBeGenerated: boolean }

export enum PROVIDER_TYPE {
  Physician = 'REFERENT_PHYSICIAN',
  Radiologist = 'RADIOLOGIST',
}

export const radiologistType = [
  'Attending',
  'Initial Reader',
  'Resident / Fellow',
  'Physician Assistant',
] as const

export type RadiologistType = (typeof radiologistType)[number]

export type TMedicalLicense = Omit<
  components['schemas']['MedicalLicense'],
  'state'
> & { state?: string | null }

export type TProviderDisplayName =
  components['schemas']['ProviderDisplayNameRs']

export type TOnCallAssignmentItemRequest =
  components['schemas']['OnCallAssignmentRq']
export type TOnCallAssignmentTableRequest = components['schemas']['Pageable']

export type TOnCallAssignmentItem = Omit<
  components['schemas']['OncallRadiologistRs'],
  'dateFrom' | 'dateTo'
> & {
  dateFrom: string
  dateTo: string
}

export type TOnCallAssignmentList = {
  content: Array<TOnCallAssignmentItem>
  first: boolean
  last: boolean
  totalElements: number
  totalPages: number
}

export enum PROVIDERS_TABLE_NAMES_ENUM {
  PHYSICIANS = 'PHYSICIANS',
  RADIOLOGISTS = 'RADIOLOGISTS',
}

export enum RADIOLOGIST_CONFIGURABLE_COLUMNS {
  INITIALS = 'INITIALS',
  NPI = 'NPI',
}

export enum PHYSICIAN_CONFIGURABLE_COLUMNS {
  PHYSICIANS_NPI = 'PHYSICIANS_NPI',
  PHYSICIANS_TAXONOMIES = 'PHYSICIANS_TAXONOMIES',
}

export interface IState {
  providerInfo?: TProviderResponse
  error?: null | TServiceError
  radiologistsFilterData: TRadiologistFilterRs
  loadingState: LOADING_STATE
  checkProvider: {
    loadingState: LOADING_STATE
    error?: TServiceError | null
    data?: TProviderSearchRs
  }
  onCallAssignment: {
    table: TOnCallAssignmentList
    query: {
      page: number
      size: number
    }
    editAssignmentId: number | null
    loadingState: LOADING_STATE
    isFormLoading: boolean
  }
  providerFaxes: {
    orderFaxes: TProviderFaxesRs
    loadingState: LOADING_STATE
    isSubmitting: boolean
  }
}

export enum NOTIFICATION_TYPE {
  ALL_RIS_ORDERS = 'ALL_RIS_ORDERS',
  URGENT_RIS_ORDERS = 'URGENT_RIS_ORDERS',
  ON_CALL_ONLY = 'ON_CALL_ONLY',
}

export type TFaxNumbers =
  components['schemas']['ProviderCreationRq']['faxNumbers']

export type TProviderFaxesRs = components['schemas']['BffProvidersFaxesRs']

export type TDeliveryFaxRs = components['schemas']['DeliveryFaxRs']

export type TBffCompanyFaxRs = components['schemas']['BffCompanyFaxRs']

export type TDeliveryFaxFilter = components['schemas']['DeliveryFaxFilter']

export enum DELIVERY_FAX_DATA {
  ORDER_EDIT = 'ORDER_EDIT',
  ORDER_VIEW = 'ORDER_VIEW',
  MANUAL_PUSH = 'MANUAL_PUSH',
}

export type TSendFaxesRq = components['schemas']['SendFaxesRq']

export type TProviderSearch =
  | TProviderSearchRs
  | TNPIWillBeGenerated
  | undefined

export enum SHORT_PHYSICIAN_TAB {
  ORDER = 'ORDER',
  USER = 'USER',
  PRACTICE = 'PRACTICE',
}

import { LOCALHOST_REGEXP } from '../src/constants/regex'

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  window.location.hostname === '[::1]' ||
  new RegExp(LOCALHOST_REGEXP).exec(window.location.hostname)
)

export function register() {
  const isServiceWorkerEnabled = 'serviceWorker' in navigator
  const isProd = import.meta.env.NODE_ENV === 'production'

  if (isProd && isServiceWorkerEnabled) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(import.meta.env.PUBLIC_URL, window.location.href)
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return Promise.resolve()
    }

    return new Promise((resolve) => {
      window.addEventListener('load', () => {
        const firebaseConfig = new URLSearchParams({
          apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
          authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
          projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
          storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
          messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
          appId: import.meta.env.VITE_FIREBASE_APP_ID,
          measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
        }).toString()
        const swUrl = `${import.meta.env.PUBLIC_URL}/firebase-messaging-sw.js?${firebaseConfig}`

        if (isLocalhost) {
          // This is running on localhost. Let's check if a service worker still exists or not.
          checkValidServiceWorker(swUrl).then(resolve)

          // Add some additional logging to localhost, pointing developers to the
          // service worker/PWA documentation.
          navigator.serviceWorker.ready.then(() => {
            // eslint-disable-next-line no-console
            console.log(
              'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://bit.ly/CRA-PWA'
            )
          })
        } else {
          // Is not localhost. Just register service worker
          registerValidSW(swUrl).then(resolve)
        }
      })
    })
  }

  return Promise.resolve()
}

function registerValidSW(swUrl) {
  return navigator.serviceWorker
    .register(swUrl, { scope: '/firebase-cloud-messaging-push-scope' })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error during service worker registration:', error)

      return Promise.reject(new Error(`Error during service worker registration: ${error}`))
    })
}

function checkValidServiceWorker(swUrl) {
  // Check if the service worker can be found. If it can't reload the page.
  return fetch(`${window.origin}${swUrl}`, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type')

      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // eslint-disable-next-line no-console
        console.log('NO SERVICE WORKER FOUND')
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload()
          })
        })
      } else {
        return registerValidSW(swUrl)
      }
    })
    .catch(() => {
      // eslint-disable-next-line no-console
      console.log(
        'No internet connection found. App is running in offline mode.'
      )
    })
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister()
    })
  }
}

export const registeredFirebaseServiceWorker = register()

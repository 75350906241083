import AutoAwesomeMotion from '@mui/icons-material/AutoAwesomeMotion'
import { styled } from '@mui/material'

export const AutoAwesomeMotionStyled = styled(AutoAwesomeMotion)(
  ({ theme: { palette } }) => ({
    '&&': {
      color: palette.primary.main,
    },
  })
)

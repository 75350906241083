export const Support = {
  // table
  supportRequestWorkListTitle: 'Support Request Worklist',
  requestIdColumn: 'Request ID',
  urgencyColumn: 'Urgency',
  accessionNumberColumn: 'Accession Number',
  lastActivityColumn: 'Last Activity',
  resolvedBy: 'Resolved by',
  dateOfServiceColumn: 'Date of Service',
  siteColumn: 'Site',
  typeColumn: 'Type',
  RISOrderStatusColumn: 'RIS-Order Status',
  targetGroupColumn: 'Target Group',
  failedToAddComment: 'Your comment has not been added. Please try again later',
  successCreateMessage: 'Your Support Request has been submitted.',
  errorCreateMessage:
    'Your Support Request has not been submitted. Please try again later',

  //entity page
  patient: 'Patient',
  creator: 'Creator',

  // filters
  searchPlaceholder: 'Search by Request ID',
  targetGroupTitle: 'Target Group',
  typeTitle: 'Type',
  urgencyTitle: 'Urgency',
  SRStatusTitle: 'SR Status',
  SRStatusLabel: 'Include Resolved',
  dateOfServiceTitle: 'Date of service',
  followUpDateTitle: 'Follow up date',
  siteNameTitle: 'Site',
  RISOrderTitle: 'RIS-Order ',
  orderStatuses: 'Order Status',

  // status
  RESOLVED: 'Resolved',
  OPEN: 'Open',

  // Support Request Types
  ProScan: 'ProScan',
  Internal: 'Internal',
  Customer: 'Customer',
  Automatic: 'Automatic',

  // Submit Support Request Modal
  newSupportRequestModalHeader: 'Submit Support Request',
  targetGroup: 'Target Group',
  requestType: 'Request Type',
  comment: 'Comment',
  supportRequestType: 'Support Request type',
  requestTypeNote:
    'Please note that this request type will trigger external communication message',
  requestTypeNoneNote:
    'Please note that this request type won\'t trigger external communication message',

  addComment: 'Add Comment',
  //tabs
  publicComments: 'Public Comments',
  internalComments: 'Internal Comments',
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _merge from 'lodash/merge'
import { REHYDRATE } from 'redux-persist'
import { ORDER_STATUS_ENUM } from 'store/order/types'

import { FLAGS_FILTERS_SLICE_NAME, initialState } from './constants'

const flagsFilterSlice = createSlice({
  name: FLAGS_FILTERS_SLICE_NAME,
  initialState,
  reducers: {
    setTablePageable: (state, { payload }) => {
      state.pageable = {
        ...state.pageable,
        ...payload,
      }
    },
    setTableFilter: (state, { payload }) => {
      state.filter = {
        ...state.filter,
        ...payload,
      }
      state.pageable.page = 0
    },
    removeSiteIdFilterOptionById: (state, { payload }) => {
      state.filter = {
        ...state.filter,
        siteIds: state.filter.siteIds.filter(
          (option) => option.siteId !== payload
        ),
      }
      state.pageable.page = 0
    },
    removePhysicianFilterOptionById: (state, { payload }) => {
      state.filter = {
        ...state.filter,
        physicianIds: state.filter.physicianIds.filter(
          (option) => option.providerId !== payload
        ),
      }
      state.pageable.page = 0
    },
    removeProcedureFilterOptionById: (state, { payload }) => {
      state.filter = {
        ...state.filter,
        procedureIds: state.filter.procedureIds.filter(
          (option) => option.id !== payload
        ),
      }
      state.pageable.page = 0
    },
    removeBillingTypeFilterOptionById: (state, { payload }) => {
      state.filter = {
        ...state.filter,
        billingTypeIds: state.filter.billingTypeIds.filter(
          (option) => option !== payload
        ),
      }
      state.pageable.page = 0
    },
    removeStatusFilterOptionById: (state, { payload }) => {
      state.filter = {
        ...state.filter,
        statuses: state.filter.statuses.filter((option) => option !== payload),
      }
      state.pageable.page = 0
    },
    removeTypeFilterOptionById: (state, { payload }) => {
      state.filter = {
        ...state.filter,
        types: state.filter.types.filter((option) => option !== payload),
      }
      state.pageable.page = 0
    },
    removeTransitionStatusFilterOptionByStatus: (
      state,
      {
        payload: { status },
      }: PayloadAction<{ status: `${ORDER_STATUS_ENUM}` | null }>
    ) => {
      state.filter = {
        ...state.filter,
        transitionStatuses: state.filter.transitionStatuses.filter(
          (option) => option.value !== status
        ),
      }
      state.pageable.page = 0
    },
    resetFlagTableFilters: (state) => {
      state.filter = initialState.filter
      state.pageable.page = 0
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action) => {
      // @ts-expect-error action has a payload with rehydrated state
      if (action.payload) {
        const {
          // @ts-expect-error action has a payload with rehydrated state
          payload: { filters },
        } = action
        return _merge(state, filters?.flagsFilters ?? {})
      }

      return state
    })
  },
})

export const {
  setTablePageable,
  setTableFilter,
  resetFlagTableFilters,
  removeTransitionStatusFilterOptionByStatus,
  removeSiteIdFilterOptionById,
  removePhysicianFilterOptionById,
  removeTypeFilterOptionById,
  removeBillingTypeFilterOptionById,
  removeProcedureFilterOptionById,
  removeStatusFilterOptionById,
} = flagsFilterSlice.actions

export default flagsFilterSlice

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LOADING_STATE } from 'types/general'

import { AUTH_SLICE_NAME, INITIAL_STATE } from './constants'
import * as controllers from './controllers'

const authSlice = createSlice({
  name: AUTH_SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    updateUserInfo: (state, { payload }) => {
      state.currentUser = { ...state.currentUser, ...payload }
    },
    setFireBaseToken: (state, { payload }: PayloadAction<string>) => {
      state.firebaseToken = payload
    },
    setIsNotificationGranted: (state, { payload }: PayloadAction<boolean>) => {
      state.isNotificationsGranted = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(controllers.fetchUserInfo.pending, (state) => {
      state.loadingState = LOADING_STATE.pending
    })
    builder.addCase(controllers.fetchUserInfo.fulfilled, (state, action) => {
      state.currentUser = action.payload.userInfo
      state.associatedCompanies = action.payload.associatedCompanies
      state.loadingState = LOADING_STATE.fulfilled
    })
  },
})

export const { updateUserInfo, setFireBaseToken, setIsNotificationGranted } =
  authSlice.actions

export default authSlice

import { forwardRef } from 'react'
import { AlertColor, Typography } from '@mui/material'
import { topRightSnackbarAnchor } from 'constants/uiConstants'
import { SnackbarKey, useSnackbar } from 'notistack'

import { StyledAlert, StyledSnackbarContainer } from './PSSnackbar.styled'

export type PSSnackbarProps = {
  id: SnackbarKey
  message: string
  header?: string
  status: AlertColor
}

export const PSSnackbar = forwardRef<HTMLDivElement, PSSnackbarProps>(
  ({ id, message, status, header }, ref) => {
    const { closeSnackbar } = useSnackbar()
    const handleClose = () => closeSnackbar(id)

    return (
      <StyledSnackbarContainer
        anchorOrigin={topRightSnackbarAnchor}
        open
        ref={ref}
      >
        <StyledAlert onClose={handleClose} severity={status}>
          {header ? (
            <Typography variant='subtitle2'>{header}</Typography>
          ) : null}
          <Typography variant='body2'>{message}</Typography>
        </StyledAlert>
      </StyledSnackbarContainer>
    )
  }
)

import { configs } from 'constants/configs'

export const firebaseConfig = {
  apiKey: configs.FIREBASE_API_KEY,
  authDomain: configs.FIREBASE_AUTH_DOMAIN,
  projectId: configs.FIREBASE_PROJECT_ID,
  storageBucket: configs.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: configs.FIREBASE_MESSAGING_SENDER_ID,
  appId: configs.FIREBASE_APP_ID,
}

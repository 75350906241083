import { FC, forwardRef } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const SuccessfulDicomTransferIcon: FC<
  SvgIconProps & { className?: string }
> = forwardRef(({ className, fontSize = 'small', ...restProps }, ref) => (
  <SvgIcon
    viewBox='0 0 18 18'
    fontSize={fontSize}
    className={className}
    {...restProps}
    ref={ref}
  >
    <path
      d='M2.75 6.3H1.5V9.4875H1.50625L1.5 15.225C1.5 15.9262 2.0625 16.5 2.75 16.5H14V15.225H2.75V6.3ZM15.25 5.025H10.25L9 3.75H5.25C4.5625 3.75 4.00625 4.32375 4.00625 5.025L4 12.675C4 13.3763 4.5625 13.95 5.25 13.95H15.25C15.9375 13.95 16.5 13.3763 16.5 12.675V6.3C16.5 5.59875 15.9375 5.025 15.25 5.025ZM5.875 12.0375L8.6875 8.2125L10.875 11.0876L12.4375 9.16875L14.625 12.0375H5.875Z'
      fill='#898989'
    />
    <path
      d='M18 4.5C18 6.98528 15.9853 9 13.5 9C11.0147 9 9 6.98528 9 4.5C9 2.01472 11.0147 0 13.5 0C15.9853 0 18 2.01472 18 4.5Z'
      fill='white'
    />
    <path
      d='M13.5 0C11.016 0 9 2.016 9 4.5C9 6.984 11.016 9 13.5 9C15.984 9 18 6.984 18 4.5C18 2.016 15.984 0 13.5 0ZM12.6 6.75L10.35 4.5L10.9845 3.8655L12.6 5.4765L16.0155 2.061L16.65 2.7L12.6 6.75Z'
      fill='#4CAF50'
    />
  </SvgIcon>
))

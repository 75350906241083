import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  TDeliveryFaxFilter,
  TOnCallAssignmentItem,
  TOnCallAssignmentItemRequest,
  TOnCallAssignmentList,
  TOnCallAssignmentTableRequest,
  TProviderCreationRq,
  TProviderCreationRs,
  TProviderFaxesRs,
  TProviderResponse,
  TProviderSearchRs,
  TProviderUpdateResponse,
  TRadiologistFilterRs,
  TSendFaxesRq,
  TShortPhysicianCreateRq,
  TShortPhysicianTab,
  TShortPhysicianUpdateRq,
} from 'store/providers/types'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'
import { prepareArrayForSending } from 'utils/helpers/arrays/prepareArrayForSending'

import { PROVIDERS_SLICE_NAME } from './constants'

export const fetchProvider = createAsyncThunk<
  TProviderResponse,
  string,
  {
    rejectValue: TServiceError | null
  }
>(
  `${PROVIDERS_SLICE_NAME}/fetchProvider`,
  async (providerId, { rejectWithValue }) => {
    const result = await request<TProviderResponse>({
      url: `/bff/providers/${providerId}`,
      method: 'GET',
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return result.data
  }
)

export const editProvider = createAsyncThunk<
  TProviderUpdateResponse,
  { id: string; provider: TProviderCreationRq },
  {
    rejectValue: any
  }
>(
  `${PROVIDERS_SLICE_NAME}/editProvider`,
  async (payload, { rejectWithValue }) => {
    const result = await request<TProviderUpdateResponse>({
      url: `/providers/${payload.id}`,
      data: payload.provider,
      method: 'PUT',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const createPhysician = createAsyncThunk<
  TProviderCreationRs,
  TShortPhysicianCreateRq & { tab: TShortPhysicianTab },
  {
    rejectValue: TServiceError
  }
>(
  `${PROVIDERS_SLICE_NAME}/createPhysician`,
  async ({ tab, ...data }, { rejectWithValue }) => {
    const result = await request<TProviderCreationRs>({
      url: '/providers/physicians/short',
      data,
      params: { tab },
      method: 'POST',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const updatePhysician = createAsyncThunk<
  TProviderCreationRs,
  TShortPhysicianUpdateRq & { providerId: string; tab: TShortPhysicianTab },
  {
    rejectValue: TServiceError
  }
>(
  `${PROVIDERS_SLICE_NAME}/updatePhysician`,
  async ({ providerId, tab, ...data }, { rejectWithValue }) => {
    const result = await request<TProviderCreationRs>({
      url: `/providers/physicians/${providerId}/short`,
      data,
      method: 'PUT',
      params: { tab },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const fetchRadiologistsFilterData =
  createAsyncThunk<TRadiologistFilterRs>(
    `${PROVIDERS_SLICE_NAME}/fetchRadiologistsFilterData`,
    async (_, { rejectWithValue }) => {
      const result = await request<TRadiologistFilterRs>({
        url: '/providers/radiologists/filters',
        method: 'GET',
      })

      if (result.error) {
        return rejectWithValue(result.error)
      }

      return result.data
    }
  )

export const checkProviderNpi = createAsyncThunk<
  TProviderSearchRs,
  string,
  {
    rejectValue: TServiceError
  }
>(
  `${PROVIDERS_SLICE_NAME}/checkProviderNpi`,
  async (npi, { rejectWithValue }) => {
    const result = await request<TProviderSearchRs>({
      url: '/bff/providers/search',
      method: 'GET',
      params: {
        npi,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const checkProviderNpiForSite = createAsyncThunk<
  TProviderSearchRs,
  { npi: string; siteId: number },
  {
    rejectValue: TServiceError
  }
>(
  `${PROVIDERS_SLICE_NAME}/checkProviderNpiForSite`,
  async ({ npi, siteId }, { rejectWithValue }) => {
    const result = await request<TProviderSearchRs>({
      url: 'bff/providers/order/search',
      method: 'GET',
      params: {
        npi,
        siteId,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const createProvider = createAsyncThunk<
  TProviderCreationRs,
  TProviderCreationRq,
  {
    rejectValue: TServiceError
  }
>(
  `${PROVIDERS_SLICE_NAME}/createProvider`,
  async (data, { rejectWithValue }) => {
    const result = await request<TProviderCreationRs>({
      url: '/providers',
      data,
      method: 'POST',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const getOnCallAssignmentList = createAsyncThunk<
  TOnCallAssignmentList,
  { query: TOnCallAssignmentTableRequest },
  { rejectValue: TServiceError }
>(
  `${PROVIDERS_SLICE_NAME}/getOnCallAssignmentList`,
  async ({ query }, { rejectWithValue }) => {
    const result = await request<TOnCallAssignmentList>({
      url: '/providers/on-call-assignment',
      method: 'GET',
      params: {
        ...query,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const createOnCallAssignment = createAsyncThunk<
  TOnCallAssignmentItem,
  { data: TOnCallAssignmentItemRequest },
  {
    rejectValue: TServiceError
  }
>(
  `${PROVIDERS_SLICE_NAME}/createOnCallAssignmentById`,
  async ({ data }, { rejectWithValue }) => {
    const result = await request<TOnCallAssignmentItem>({
      url: '/providers/on-call-assignment',
      data,
      method: 'POST',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const editOnCallAssignmentById = createAsyncThunk<
  TOnCallAssignmentItem,
  { id: number; data: TOnCallAssignmentItemRequest },
  {
    rejectValue: TServiceError
  }
>(
  `${PROVIDERS_SLICE_NAME}/editOnCallAssignmentById`,
  async ({ id, data }, { rejectWithValue }) => {
    const result = await request<TOnCallAssignmentItem>({
      url: `/providers/on-call-assignment/${id}`,
      data,
      method: 'PUT',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const deleteOnCallAssignmentById = createAsyncThunk<
  void,
  { id: number },
  {
    rejectValue: TServiceError
  }
>(
  `${PROVIDERS_SLICE_NAME}/editOnCallAssignmentById`,
  async ({ id }, { rejectWithValue }) => {
    const result = await request<void>({
      url: `/providers/on-call-assignment/${id}`,
      method: 'DELETE',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const getProviderFaxesRq = createAsyncThunk<
  TProviderFaxesRs,
  Partial<TDeliveryFaxFilter>,
  { rejectValue: TServiceError }
>(
  `${PROVIDERS_SLICE_NAME}/getProviderFaxesRq`,
  async (params, { rejectWithValue }) => {
    const result = await request<TProviderFaxesRs>({
      url: '/bff/order/delivery-fax-data',
      method: 'GET',
      params: {
        ...params,
        providerIds: params.providerIds
          ? prepareArrayForSending(params.providerIds)
          : undefined,
      },
      headers: params.accessionNumber
        ? {
            AccessionNumber: params.accessionNumber,
          }
        : undefined,
    })

    if (result.data) {
      return result.data
    }

    return rejectWithValue(result.error)
  }
)

export const sendProviderFaxesRq = createAsyncThunk<
  void,
  TSendFaxesRq,
  { rejectValue: TServiceError }
>(
  `${PROVIDERS_SLICE_NAME}/sendProviderFaxesRq`,
  async (data, { rejectWithValue }) => {
    const result = await request<void>({
      url: '/biscom-integration/faxes',
      data,
      method: 'POST',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

import { getToken, isSupported } from '@firebase/messaging'
import { configs } from 'constants/configs'

import { registeredFirebaseServiceWorker } from '../../../serviceWorker'
import { firebaseMessaging } from '../firebaseMessaging'

const NOT_AVAILABLE_TOKENS_ERROR = 'No registration token available.'

export const requestFireBaseToken = async () => {
  const serviceWorker = await registeredFirebaseServiceWorker

  return isSupported().then((isSupported) => {
    if (isSupported) {
      return getToken(firebaseMessaging, {
        vapidKey: configs.FIREBASE_VAPID,
        ...(serviceWorker && {
          serviceWorkerRegistration: serviceWorker,
        }),
      })
        .then((currentToken) => {
          if (currentToken) {
            return Promise.resolve(currentToken)
          }

          return Promise.reject(new Error(NOT_AVAILABLE_TOKENS_ERROR))
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    }

    return Promise.reject(new Error('Firebase messaging is not supporting'))
  })
}

import { FC, forwardRef } from 'react'
import { ButtonProps } from '@mui/material'

import { StyledButton, StyledProgressIcon } from './PSButton.styled'

interface IPSButtonProps extends ButtonProps {
  isLoading?: boolean
  isPaddingDisabled?: boolean
  isLabelTransformDisabled?: boolean
  target?: string
  textAlign?: 'center' | 'right' | 'left'
}

export const PSButton: FC<IPSButtonProps> = forwardRef(
  (
    {
      variant = 'outlined',
      isLabelTransformDisabled = false,
      isLoading = false,
      ...props
    },
    ref
  ) => (
    <StyledButton
      {...props}
      ref={ref}
      disabled={props.disabled || isLoading}
      variant={variant}
      isLabelTransformDisabled={isLabelTransformDisabled}
    >
      {props.children}
      {isLoading && <StyledProgressIcon size={24} />}
    </StyledButton>
  )
)

import { SORT_DIRECTIONS } from 'constants/tables'

export const generateTableColumnSortConfig = (
  columnKey: string,
  handleSortColumn: (columnKey: string) => void,
  sort: string = ''
) => {
  const [currentColumnKey, currentSortDirection] = sort.split(',')

  const isActive = currentColumnKey === columnKey

  return {
    changeDirectionHandler: handleSortColumn,
    order: isActive
      ? (currentSortDirection as SORT_DIRECTIONS)
      : SORT_DIRECTIONS.ASC,
    orderBy: columnKey,
    isActive,
  }
}

import { FLAG_ARCHIVE_STATUS } from 'store/flags/types'

import { IState } from './types'

export const initialState: IState = {
  pageable: {
    size: 10,
    page: 0,
    sort: '',
  },
  filter: {
    search: '',
    statuses: [FLAG_ARCHIVE_STATUS.ACTIVE],
    siteIds: [],
    physicianIds: [],
    procedureIds: [],
    types: [],
    transitionStatuses: [],
    billingTypeIds: [],
  },
}

export const FLAGS_FILTERS_SLICE_NAME = 'flagsFilters'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { FILES_DOWNLOADING_SLICE_NAME } from 'store/filesDownloading/constants'
import {
  setLatestVersionOrderLegalCopyReportDownloadProgress,
  setOrderPDFReportDownloadProgress,
} from 'store/filesDownloading/slice'
import {
  STUDY_DOWNLOAD_TYPE_ENUM,
  TArchivingInitiateRequest,
} from 'store/filesDownloading/types'
import {
  TAllOrderColumns,
  TOrdersTableParams,
} from 'store/filters/orders/types'
import { DOWNLOAD_REPORT_TYPES } from 'store/orderReportInformation/types'
import { prepareTableQueryParamsForSend } from 'store/ordersList/helpers/prepareTableQueryParamsForSend'
import { ORDERS_TABLE_TAB } from 'store/ordersList/types'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'
import { prepareArrayForSending } from 'utils/helpers/arrays/prepareArrayForSending'
import { getDownloadProgress } from 'utils/helpers/files/getDownloadProgress'

export const initiateStudyDownload = createAsyncThunk<
  unknown,
  {
    data: TArchivingInitiateRequest
    downloadType: STUDY_DOWNLOAD_TYPE_ENUM
  },
  { rejectValue: TServiceError }
>(
  `${FILES_DOWNLOADING_SLICE_NAME}/initiateStudyDownload`,
  async ({ data }, { rejectWithValue }) => {
    const result = await request<unknown>({
      url: '/studies/download',
      data,
      method: 'POST',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const initiateDownloadOrderListCSVReport = createAsyncThunk<
  unknown,
  {
    filterParams: TOrdersTableParams
    tableLabels: TAllOrderColumns[]
    tabName: ORDERS_TABLE_TAB
    eventId: string
    tabId: string
  },
  {
    rejectValue: TServiceError
  }
>(
  `${FILES_DOWNLOADING_SLICE_NAME}/initiateDownloadOrderListCSVReport`,
  async (
    { tabName, eventId, tabId, tableLabels, filterParams },
    { rejectWithValue }
  ) => {
    const result = await request<unknown>({
      url: '/bff/orders/bystatuses/csv',
      method: 'GET',
      params: {
        ...prepareTableQueryParamsForSend(filterParams),
        tabName,
        tableLabes: prepareArrayForSending(tableLabels),
        eventId,
        tabId,
      },
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result
  }
)

export const downloadOrderReport = createAsyncThunk<
  { data: Blob },
  { accessionNumber: string; type: `${DOWNLOAD_REPORT_TYPES}` },
  {
    rejectValue: TServiceError
  }
>(
  `${FILES_DOWNLOADING_SLICE_NAME}/downloadOrderReport`,
  async ({ accessionNumber, type }, { rejectWithValue, dispatch }) => {
    const result = await request<Blob>({
      url: `/report/order/${accessionNumber}/download${
        type === DOWNLOAD_REPORT_TYPES.HTML ? '/html' : ''
      }`,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress: (download) => {
        const downloadProgress = getDownloadProgress(download)
        dispatch(setOrderPDFReportDownloadProgress(downloadProgress))
      },

      headers: {
        AccessionNumber: accessionNumber,
      },
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result
  }
)

export const downloadLatestVersionOrderLegalCopyReport = createAsyncThunk<
  { data: Blob },
  string,
  {
    rejectValue: TServiceError
  }
>(
  `${FILES_DOWNLOADING_SLICE_NAME}/downloadLatestVersionOrderLegalCopyReport`,
  async (accessionNumber, { rejectWithValue, dispatch }) => {
    const result = await request<Blob>({
      url: `/report/order/${accessionNumber}/legalcopy/download`,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress: (download) => {
        const downloadProgress = getDownloadProgress(download)
        dispatch(
          setLatestVersionOrderLegalCopyReportDownloadProgress({
            downloadProgress,
            accessionNumber,
          })
        )
      },

      headers: {
        AccessionNumber: accessionNumber,
      },
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result
  }
)

import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import * as Sentry from '@sentry/react'
import { Login } from 'components/Login'
import { StyledBackdrop } from 'components/ProtectedRoute/ProtectedRoute.styled'
import { PSLoader } from 'components/ui-components/PSLoader'
import { fetchUserInfo, updateLastLogin } from 'store/auth/controllers'
import { getAuthLoadingState } from 'store/auth/selectors'
import { setFireBaseToken, setIsNotificationGranted } from 'store/auth/slice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { requestFireBaseToken } from 'utils/FireBaseNotifications/helpers/requestFireBaseToken'
import { LOADING_STATE } from 'types/general'

export const ProtectedRoute: FC<PropsWithChildren> = ({ children }) => {
  const auth = useAuth()
  const dispatch = useAppDispatch()
  const authLoadingState = useAppSelector(getAuthLoadingState)
  const isAuthLoading = authLoadingState === LOADING_STATE.pending
  const [isTokenReady, setIsTokenReady] = useState(false)

  useEffect(() => {
    auth.events.addAccessTokenExpired(async () => {
      await auth.signoutRedirect()
    })
  }, [auth])

  useEffect(() => {
    if (auth.user?.access_token && auth.isAuthenticated) {
      setIsTokenReady(true)
      Sentry.setUser({ username: auth.user?.profile?.sub })
    }
  }, [auth])

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(fetchUserInfo())

      dispatch(updateLastLogin())

      requestFireBaseToken()
        .then((fireBaseToken) => {
          dispatch(setFireBaseToken(fireBaseToken))
          dispatch(setIsNotificationGranted(true))
          return fireBaseToken
        })
        .catch((err) => {
          setIsNotificationGranted(false)
          // eslint-disable-next-line no-console
          console.log('Request firebase token error', err)
        })
    }
  }, [dispatch, auth.isAuthenticated])

  if (
    auth.isAuthenticated &&
    !isAuthLoading &&
    auth.user?.access_token &&
    isTokenReady
  ) {
    return <>{children}</>
  }

  if (!auth.isAuthenticated && !auth.isLoading) {
    return <Login />
  }

  return (
    <StyledBackdrop open>
      <PSLoader />
    </StyledBackdrop>
  )
}

import { LOADING_STATE } from 'types/general'

import { AUDIT_SLICE_KEY, IState } from './types'

export const AUDIT_SLICE_NAME = 'audit'

const auditTableData = {
  tableData: {
    content: [],
    totalElements: 0,
    totalPages: 0,
    isFirstPage: true,
    isLastPage: false,
  },
  filtersData: {
    categories: [],
    triggers: [],
    events: [],
    dateTime: {
      from: null,
      to: null,
    },
  },
  queryData: {
    search: '',
    page: 0,
    size: 10,
  },
  isFilterPanelOpen: false,
  loadingState: LOADING_STATE.idle,
}

export const initialState: IState = {
  [AUDIT_SLICE_KEY.order]: auditTableData,
  [AUDIT_SLICE_KEY.patient]: auditTableData,
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { hasCamelCaseProperties } from 'components/cells/PatientCell/helpers'
import { getPatientOptionLabel } from 'pages/orders/OrdersList/helpers/getPatientOptionLabel'

interface IPatientCellProps {
  data:
    | { lastName?: string; firstName?: string }
    | { lastname?: string; firstname?: string }
    | null
}

export const PatientCell: FC<IPatientCellProps> = ({ data }) => {
  const { t } = useTranslation()

  const areKeysCamelCase = hasCamelCaseProperties(data)
  const firstname = areKeysCamelCase ? data?.firstName : data?.firstname
  const lastname = areKeysCamelCase ? data.lastName : data?.lastname

  return (
    <>
      {firstname || lastname
        ? getPatientOptionLabel(firstname, lastname)
        : t('components.table.notApplicable')}
    </>
  )
}

import { styled, Typography } from '@mui/material'

export const StyledOrderProcedureCell = styled(Typography)({
  maxWidth: '200px',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '3',
  whiteSpace: 'pre-wrap',
})

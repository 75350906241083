import { FC, forwardRef } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const FailedDicomTransferIcon: FC<
  SvgIconProps & { className?: string }
> = forwardRef(({ className, fontSize = 'small', ...restProps }, ref) => (
  <SvgIcon
    viewBox='0 0 18 18'
    fontSize={fontSize}
    className={className}
    {...restProps}
    ref={ref}
  >
    <path
      d='M2.75 6.3H1.5V9.4875H1.50625L1.5 15.225C1.5 15.9262 2.0625 16.5 2.75 16.5H14V15.225H2.75V6.3ZM15.25 5.025H10.25L9 3.75H5.25C4.5625 3.75 4.00625 4.32375 4.00625 5.025L4 12.675C4 13.3763 4.5625 13.95 5.25 13.95H15.25C15.9375 13.95 16.5 13.3763 16.5 12.675V6.3C16.5 5.59875 15.9375 5.025 15.25 5.025ZM5.875 12.0375L8.6875 8.2125L10.875 11.0876L12.4375 9.16875L14.625 12.0375H5.875Z'
      fill='#898989'
    />
    <path
      d='M18 4.5C18 6.98528 15.9853 9 13.5 9C11.0147 9 9 6.98528 9 4.5C9 2.01472 11.0147 0 13.5 0C15.9853 0 18 2.01472 18 4.5Z'
      fill='white'
    />
    <path
      d='M11.1375 4.5C11.1375 5.8923 12.1077 6.8625 13.5 6.8625C14.0827 6.8625 14.4529 6.80265 14.8781 6.46875L11.5313 3.12188C11.1974 3.54713 11.1375 3.91725 11.1375 4.5Z'
      fill='#D32F2F'
    />
    <path
      d='M12.1219 2.53125L15.4688 5.87813C15.8026 5.45288 15.8625 5.08275 15.8625 4.5C15.8625 3.1077 14.8923 2.1375 13.5 2.1375C12.9172 2.1375 12.5471 2.19735 12.1219 2.53125Z'
      fill='#D32F2F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.5 9C15.9853 9 18 6.98528 18 4.5C18 2.01472 15.9853 0 13.5 0C11.0147 0 9 2.01472 9 4.5C9 6.98528 11.0147 9 13.5 9ZM10.35 4.5C10.35 2.7612 11.7612 1.35 13.5 1.35C15.2388 1.35 16.65 2.7612 16.65 4.5C16.65 6.2388 15.2388 7.65 13.5 7.65C11.7612 7.65 10.35 6.2388 10.35 4.5Z'
      fill='#D32F2F'
    />
  </SvgIcon>
))

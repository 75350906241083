import { configs } from 'constants/configs'
import { WebStorageStateStore } from 'oidc-client-ts'

export const oidcConfig = {
  authority: `${configs.KEYCLOAK_URL}realms/proscan-realm`,
  client_id: 'front-client',
  redirect_uri: configs.FRONT_URL as string,
  post_logout_redirect_uri: configs.FRONT_URL,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  monitorSession: true,
}

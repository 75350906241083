import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

import { StyledContainer, StyledDescription } from './NotFoundPage.styled'

export const NotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <StyledContainer container>
      <Typography variant='h5' mb={2}>
        {t('components.titles.notFoundPageTitle')}
      </Typography>
      <Typography mb={2}>{t('common.notFoundPageContent')}</Typography>
      <StyledDescription>
        {t('common.notFoundPageDescription')}
      </StyledDescription>
    </StyledContainer>
  )
}

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'
import { Typography } from '@mui/material'
import { captureException } from '@sentry/react'
import { SomethingWentWrongIcon } from 'assets/icons'
import {
  StyledContainer,
  StyledContent,
} from 'components/SomethingWentWrong/SomethingWentWrong.styled'
import { PSButton } from 'components/ui-components/PSButton'
import {
  getIsDevEnvironment,
  getIsTestEnvironment,
} from 'utils/helpers/general'

export const SomethingWentWrong = () => {
  const { t } = useTranslation()
  const routeError = useRouteError()

  useEffect(() => {
    captureException(routeError, { level: 'fatal' })
  }, [routeError])

  const handleReloadPage = () => {
    window.location.reload()
  }

  const isDevelopmentEnvironment =
    getIsDevEnvironment() || getIsTestEnvironment()

  return (
    <StyledContainer>
      <StyledContent>
        <SomethingWentWrongIcon />

        <Typography variant='h4' mt={4}>
          {t('common.somethingWentWrong')}
        </Typography>
        <Typography variant='body1' mb={3}>
          {t('common.somethingWentWrongDescription')}
        </Typography>
        <PSButton variant='contained' onClick={handleReloadPage}>
          {t('common.tryAgain')}
        </PSButton>

        {isDevelopmentEnvironment ? (
          <>
            {isRouteErrorResponse(routeError) ? (
              <>
                <Typography variant='body1'>
                  {routeError.status} {routeError.statusText}
                </Typography>
                <Typography variant='body2'>{routeError?.data}</Typography>
              </>
            ) : null}
            {routeError instanceof Error ? (
              <>
                <Typography variant='body1'>
                  {`Error: ${routeError.message}`}
                </Typography>
                <Typography variant='body2'>
                  {`The stack trace is: ${routeError.stack}`}
                </Typography>
              </>
            ) : null}
          </>
        ) : null}
      </StyledContent>
    </StyledContainer>
  )
}

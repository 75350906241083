import { useState } from 'react'

export const useModal = (isOpen = false) => {
  const [isModalOpened, setIsModalOpened] = useState(isOpen)

  const toggleModalVisibility = () =>
    setIsModalOpened((prevState) => !prevState)

  return { isModalOpened, setIsModalOpened, toggleModalVisibility }
}

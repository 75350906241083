import { changeTableQueryDataByName } from 'store/filters/orders/ordersSlice'
import { getAllOrderTableConfiguration } from 'store/filters/selectors'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { ORDERS_TABLE_TAB } from 'store/ordersList/types'
import { switchTableColumnSort } from 'utils/helpers/tables/switchTableColumnSort'

export const useSortTableColumns = (): [
  string | undefined,
  (columnKey: string) => void,
] => {
  const dispatch = useAppDispatch()

  const {
    queryData: { sort },
  } = useAppSelector(getAllOrderTableConfiguration)

  const handleSortColumn = (columnKey: string) => {
    dispatch(
      changeTableQueryDataByName({
        tableName: ORDERS_TABLE_TAB.ALL,
        query: {
          sort: switchTableColumnSort(columnKey, sort),
        },
      })
    )
  }

  return [sort, handleSortColumn]
}

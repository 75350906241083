/* eslint-disable */
// eslint wants to use single quotes for strings that contain single-quotes
// but prettier forces double quotes

export const Users = {
  //common
  userStatusActive: 'Active',
  userStatusDeactivated: 'Deactivated',
  emailIsRegistered: 'This email has been already registered',
  addNewUser: 'Add new user',

  //passwordUpdate
  passwordUpdateTitle: 'Change password',
  passwordUpdateDesc:
    'Use a minimum of 8 characters, including uppercase letters, lowercase letters, numbers and symbols.',
  oldPass: 'Old password',
  newPass: 'New password',
  confirmPass: 'Confirm password',
  passwordUpdateSubmit: 'Change Password',
  passwordUpdateMismatch: 'Passwords do not match',
  passwordUpdateIncorrectFormat: 'The field is not in the correct format',
  passwordUpdateSuccess: 'Password updated',
  passwordUpdateError: 'Old password is incorrect',
  passwordUpdateSame: 'New password cannot be the same as the old one',
  passwordUpdateHistoryMatch:
    'New password cannot be set. Found same password within passwords history',

  //userProfile
  breadcrumbsItem: 'Users',
  practice: 'Practice',
  provider: 'Provider',
  searchProviderByNPI: 'Search provider by name or NPI ',
  deactivateUser: 'Deactivate User',
  onlyOneProvider: 'Only one Provider record could be selected.',
  changePassword: "Change User's password",
  registrationDate: 'Registration date',
  role: 'Role',
  status: 'Status',
  lastLogin: 'Last login',
  userFormTitle: 'User Info',
  roleTitle: 'Role',
  companyTitle: 'Company',
  companyIsRequired: 'Company is required',
  addAllCompanies: 'Add all companies',
  allCompanies: 'All Companies',
  disassosiateAll: 'Disassociate all',
  disassosiate: 'Disassociate',
  companySelectPlaceholder: 'Company...',
  sites: 'Sites',
  sitesSelectAll: 'Select all',
  allCompaniesWithSitesAreAdded: 'All companies with sites are added',
  editAllSites: 'Edit',
  userFormEmail: 'Email',
  userFormSalutation: 'Salutation',
  userFormFirstName: 'First Name',
  userFormLastName: 'Last Name',
  userFormSuffix: 'Suffix',
  userFormPhoneNumber: 'Phone Number',
  pacsUserName: 'PACS User Name',
  userFormCellPhoneNumber: 'Cell Phone Number',
  userFormOfficePhoneNumber: 'Office Phone Number',
  userFormPhoneNumberRequired: '${label} must be at least 11 characters',
  userFormTimezone: 'Timezone',
  addCompany: 'Add Company',
  editCompany: 'Edit Company',
  addPractice: 'Add Practice',
  countSites_one: '{{count}} site',
  countSites_other: '{{count}} sites',
  roleIsRequired: 'User role is required',
  lastLoginNever: 'Never',
  lastLoginAgo_zero: 'Today',
  lastLoginAgo_one: 'Yesterday',
  lastLoginAgo_other: '{{count}} days ago',

  //salutations
  MR: 'Mr.',
  MRS: 'Mrs.',
  MS: 'Ms.',
  DR: 'Dr.',
  DRS: 'Drs.',

  //userManagement
  pageTitle: 'Users',
  pageSearchPlaceholder: 'Search...',
  pageSearchLabel: 'Search by name or email',
  viewUser: 'View',
  filtersBtn: 'Filters',
  filtersTitle: 'Filters',
  filtersActivity: 'Activity',
  filtersRole: 'Role',
  filtersPractice: 'Practice',
  filtersStatus: 'Status',
  filtersInactivityTime: 'Inactive last 30 days',
  deactivated: 'Deactivated Only',

  //table
  tableName: 'Name',
  tableEmail: 'Email',
  tableRegistration: 'Registration',
  tableRole: 'Role',
  tableCompany: 'Company',
  tableSite: 'Site',
  tableStatus: 'Status',
  tableConfigureColumns: 'Configure displayed columns',
  tableActive: 'Active',
  tableDeactivated: 'Deactivated',
}

import { US_LOCALE } from 'constants/locales'

export const formatDateToUSLocale = (date: string) => {
  const parsedDate = new Date(date)

  if (!date || isNaN(parsedDate.getTime())) {
    return ''
  }

  return parsedDate.toLocaleDateString(US_LOCALE, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
}

// BE NotificationAction
export enum FIREBASE_NOTIFICATION_TYPE_ENUM {
  ASSIGN_RADIOLOGIST = 'ASSIGN_RADIOLOGIST',
  SHARE_ORDER = 'SHARE_ORDER',
  SUCCESS_SHARE_IMAGES_VIA_TA = 'SUCCESS_SHARE_IMAGES_VIA_TA',
  FAILURE_SHARE_IMAGES_VIA_TA = 'FAILURE_SHARE_IMAGES_VIA_TA',
  SUCCESS_STUDY_ARCHIVE = 'SUCCESS_STUDY_ARCHIVE',
  FAILURE_STUDY_ARCHIVE = 'FAILURE_STUDY_ARCHIVE',
  SUCCESS_STUDY_ISO = 'SUCCESS_STUDY_ISO',
  FAILURE_STUDY_ISO = 'FAILURE_STUDY_ISO',
  SUCCESS_MANUAL_PUSH_DOCUMENTS = 'SUCCESS_MANUAL_PUSH_DOCUMENTS',
  FAILURE_MANUAL_PUSH_DOCUMENTS = 'FAILURE_MANUAL_PUSH_DOCUMENTS',
  REPORT_CREATION = 'REPORT_CREATION',
  SUCCESS_MANUAL_PUSH_HL7 = 'SUCCESS_MANUAL_PUSH_HL7',
  FAILURE_MANUAL_PUSH_HL7 = 'FAILURE_MANUAL_PUSH_HL7',
  FAILURE_CAMUNDA = 'FAILURE_CAMUNDA',
  SUCCESS_STORE_CSV_REPORT = 'SUCCESS_STORE_CSV_REPORT',
  FAILURE_STORE_CSV_REPORT = 'FAILURE_STORE_CSV_REPORT',
}

interface IDownloadStudyMetaInfo {
  accessionNumber: string
  objKey: string
  tabId: string
}

interface IErrorMessageMetaInfo {
  accessionNumber: string
  tabId: string
}

export type TFirebaseMessage = {
  type: FIREBASE_NOTIFICATION_TYPE_ENUM
  message?: string
} & (
  | {
      type: FIREBASE_NOTIFICATION_TYPE_ENUM.SUCCESS_STUDY_ISO
      metaInfo: IDownloadStudyMetaInfo & {
        studyId: null
      }
    }
  | {
      type: FIREBASE_NOTIFICATION_TYPE_ENUM.FAILURE_STUDY_ISO
      metaInfo: IErrorMessageMetaInfo
      message: string
    }
  | {
      type: FIREBASE_NOTIFICATION_TYPE_ENUM.SUCCESS_STUDY_ARCHIVE
      metaInfo: IDownloadStudyMetaInfo & {
        studyId: number
      }
    }
  | {
      type: FIREBASE_NOTIFICATION_TYPE_ENUM.FAILURE_STUDY_ARCHIVE
      metaInfo: IErrorMessageMetaInfo & {
        studyId: number
      }
    }
  | {
      type: FIREBASE_NOTIFICATION_TYPE_ENUM.ASSIGN_RADIOLOGIST
      metaInfo: undefined
    }
  | {
      type: FIREBASE_NOTIFICATION_TYPE_ENUM.SUCCESS_SHARE_IMAGES_VIA_TA
      metaInfo: undefined
    }
  | {
      type: FIREBASE_NOTIFICATION_TYPE_ENUM.FAILURE_SHARE_IMAGES_VIA_TA
      metaInfo: undefined
    }
  | {
      type: FIREBASE_NOTIFICATION_TYPE_ENUM.SHARE_ORDER
      metaInfo: undefined
    }
  | {
      type: FIREBASE_NOTIFICATION_TYPE_ENUM.SUCCESS_MANUAL_PUSH_DOCUMENTS
      metaInfo: { accessionNumber: string }
    }
  | {
      type: FIREBASE_NOTIFICATION_TYPE_ENUM.FAILURE_MANUAL_PUSH_DOCUMENTS
      metaInfo: { accessionNumber: string }
    }
  | {
      type: FIREBASE_NOTIFICATION_TYPE_ENUM.REPORT_CREATION
      metaInfo: { orderAccessionNumber: string }
    }
  | {
      type: FIREBASE_NOTIFICATION_TYPE_ENUM.SUCCESS_MANUAL_PUSH_HL7
      metaInfo: { accessionNumber: string }
    }
  | {
      type: FIREBASE_NOTIFICATION_TYPE_ENUM.FAILURE_MANUAL_PUSH_HL7
      metaInfo: { accessionNumber: string }
    }
  | {
      type: FIREBASE_NOTIFICATION_TYPE_ENUM.FAILURE_CAMUNDA
      message: string
    }
  | {
      type: FIREBASE_NOTIFICATION_TYPE_ENUM.SUCCESS_STORE_CSV_REPORT
      metaInfo: { objectKey: string; eventId: string; tabId: string }
    }
  | {
      type: FIREBASE_NOTIFICATION_TYPE_ENUM.FAILURE_STORE_CSV_REPORT
      message: string
      metaInfo: { eventId: string; tabId: string }
    }
)

export type TMessageHandler = (
  event: MessageEvent<{
    notification: { body: string }
  }>
) => void

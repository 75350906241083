import { FC, ReactNode } from 'react'
import { SystemProps } from '@mui/system'

import { StyledTypography } from './PSPageTitle.styled'

interface IPSPageTitleProps extends SystemProps {
  children?: ReactNode
  className?: string
}

export const PSPageTitle: FC<IPSPageTitleProps> = ({ children, className }) => {
  return (
    <StyledTypography variant='h4' className={className}>
      {children}
    </StyledTypography>
  )
}

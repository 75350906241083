import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Outlet } from 'react-router-dom'
import { routes } from 'routes/routes'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { ORDER_STATUS_ENUM } from 'store/order/types'
import { getOrderBillingInformation } from 'store/orderBillingInformation/selectors'
import {
  clearBillingInformationData,
  resetBillingTypeOptions,
} from 'store/orderBillingInformation/slice'
import { resetValidationProgress } from 'store/validationProgress/slice'

export const BillingContainer = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const billingInformation = useAppSelector(getOrderBillingInformation)

  useEffect(() => {
    if (
      billingInformation &&
      (billingInformation.isImported ||
        (billingInformation.orderStatus &&
          [ORDER_STATUS_ENUM.MIGRATED].includes(
            billingInformation.orderStatus
          )))
    ) {
      navigate(routes[403].path, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingInformation])

  useEffect(() => {
    return () => {
      dispatch(resetBillingTypeOptions())
      dispatch(clearBillingInformationData())
      dispatch(resetValidationProgress())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Outlet />
}

import { TBillingType } from 'services/dictionary/types'
import { RootState } from 'store/store'
import { TPageable } from 'types/general'

import {
  BILLING_STATUS,
  INSURANCE_TYPES_ENUM,
  TOrderBillingInformationRs,
} from './types'

export const getOrderBilling = (state: RootState) =>
  state.orderBillingInformation

export const getOrderBillingTypes = (
  state: RootState,
  insuranceType: INSURANCE_TYPES_ENUM
) => getOrderBilling(state).billingTypes[insuranceType]

export const getOrderBillingType = (
  state: RootState,
  insuranceType: INSURANCE_TYPES_ENUM,
  billingTypeId: number
): TBillingType | undefined =>
  getOrderBilling(state).billingTypes[insuranceType]?.find(
    (billingType) => billingType.id === billingTypeId
  )

export const getOrderBillingInformation = (
  state: RootState
): TOrderBillingInformationRs | undefined =>
  getOrderBilling(state).billingInformation

export const getOrderBillingLoadingState = (state: RootState) =>
  getOrderBilling(state).loadingState

export const getPrimaryInsuranceTableLoadingState = (state: RootState) =>
  getOrderBilling(state).primaryInsurancesTable.loadingState

export const getPrimaryInsurancePageableData = (state: RootState): TPageable =>
  getOrderBilling(state).primaryInsurancesTable.pageable

export const getPrimaryInsuranceTableData = (state: RootState) =>
  getOrderBilling(state).primaryInsurancesTable.list

export const getPrimaryInsuranceFilterData = (state: RootState) =>
  getOrderBilling(state).primaryInsurancesTable.filter

export const getOrderBillingStatus = (
  state: RootState
): BILLING_STATUS | undefined =>
  getOrderBillingInformation(state)?.orderBillingStatus

export const getIsPatientAssociatedWithOrder = (state: RootState) =>
  Boolean(getOrderBillingInformation(state)?.orderProscanMrn)

export const getBillingInsuranceHistory = (state: RootState) =>
  getOrderBilling(state).insuranceHistoryTable

export const getBillingRevertData = (state: RootState) =>
  getOrderBillingInformation(state)?.billingRevertDataRs

export const getHasOrderAssociatedPatient = (state: RootState) =>
  Boolean(getOrderBillingInformation(state)?.patientInformation)

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TOrder } from 'store/ordersList/types'

interface IBillingTypeCellProps {
  data: TOrder['billingType']
}

export const BillingTypeCell: FC<IBillingTypeCellProps> = ({ data }) => {
  const { t } = useTranslation()

  return <>{data?.name ?? t('components.table.notApplicable')}</>
}

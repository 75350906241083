import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'store/hooks'
import { setResendHL7ProgressToFinish } from 'store/order/slice'
import {
  FIREBASE_NOTIFICATION_TYPE_ENUM,
  TFirebaseMessage,
  TMessageHandler,
} from 'utils/FireBaseNotifications/types'
import { usePSSnackbar } from 'utils/hooks/snackbar/usePSSnackbar'

export const useHandleResendHL7Message = () => {
  const { openSuccessSnackbar, openErrorSnackbar } = usePSSnackbar()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const finishProgressHandler = useCallback((accessionNumber: string) => {
    dispatch(setResendHL7ProgressToFinish(accessionNumber))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleResendHL7Message: TMessageHandler = useCallback(
    (event) => {
      try {
        const notificationBody: TFirebaseMessage = JSON.parse(
          event.data.notification.body
        )

        if (
          notificationBody.type ===
          FIREBASE_NOTIFICATION_TYPE_ENUM.SUCCESS_MANUAL_PUSH_HL7
        ) {
          finishProgressHandler(notificationBody.metaInfo.accessionNumber)
          openSuccessSnackbar(t('domains.RISOrders.resendHL7Successful'))
        }

        if (
          notificationBody.type ===
          FIREBASE_NOTIFICATION_TYPE_ENUM.FAILURE_MANUAL_PUSH_HL7
        ) {
          finishProgressHandler(notificationBody.metaInfo.accessionNumber)
          openErrorSnackbar(
            t('common.notifications.generalErrorNotificationMessage')
          )
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('JSON Parse error: ', error)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return { handleResendHL7Message }
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { formatDateToUSLocale } from 'utils/helpers/date/formatDateToUSLocale'

interface IDateLocaleCellProps {
  data?: string
}

export const DateLocaleCell: FC<IDateLocaleCellProps> = ({ data }) => {
  const { t } = useTranslation()

  return (
    <Typography variant='body2'>
      {data ? formatDateToUSLocale(data) : t('components.table.notApplicable')}
    </Typography>
  )
}

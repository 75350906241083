import { RootState } from 'store/store'

export const getValidationProgress = (state: RootState) =>
  state.validationProgress

export const getValidationProgressConfig = (state: RootState) =>
  getValidationProgress(state).configs

export const getIsValidToSubmitValidationProgress = (state: RootState) =>
  getValidationProgress(state).isValidToSubmit

export const getInputToFocusValidationProgress = (state: RootState) =>
  getValidationProgress(state).inputToFocus

import { ReactNode, useContext } from 'react'
import { LinkProps as RouterLinkProps } from 'react-router-dom'
import { LinkProps } from '@mui/material/Link'
import { BreadcrumbsContext } from 'components/BreadcrumbsProvider/BreadcrumbsContext'
import { PSLink } from 'components/ui-components/PSLink'

export const LinkWithBreadcrumbs = ({
  className,
  href,
  target,
  children,
  disabled,
  ...rest
}: {
  className?: string
  disabled?: boolean
  href: string
  children: ReactNode
} & Omit<RouterLinkProps, 'to'> &
  LinkProps) => {
  const breadcrumbs = useContext(BreadcrumbsContext)

  if (breadcrumbs === null) {
    throw new Error(
      'BreadcrumbsContext has to be used within <BreadcrumbsContext.Provider>'
    )
  }

  return (
    <PSLink
      aria-disabled={disabled}
      target={target}
      className={className}
      href={href}
      state={{ breadcrumbs }}
      {...rest}
    >
      {children}
    </PSLink>
  )
}

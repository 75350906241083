import { initReactI18next } from 'react-i18next'
import i18n, { TFunction } from 'i18next'
import { setLocale } from 'yup'

import { en } from './en'

export const resources = {
  en: { translation: en },
} as const

const buildYupLocale = (_: unknown, t: TFunction) => {
  setLocale({
    array: {
      min: ({ label, min }) =>
        t('common.validation.minArray', { label, count: min }),
    },
  })
}

i18n.use(initReactI18next).init(
  {
    resources,
    lng: localStorage.getItem('ProScan-lang') ?? 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  },
  buildYupLocale
)

declare module 'i18next' {
  interface CustomTypeOptions {
    // for type purposes only one resource is enough
    resources: (typeof resources)['en']
  }
}

export default i18n

import { combineSlices } from '@reduxjs/toolkit'

import companyFilterSlice from './company/companySlice'
import destinationsFilterSlice from './destinations/destinationsSlice'
import flagsFilterSlice from './flags/flagsSlice'
import ordersFiltersSlice from './orders/ordersSlice'
import patientsFiltersSlice from './patients/patientsSlice'
import practiceFilterSlice from './practice/practiceSlice'
import proceduresSlice from './procedures/proceduresSlice'
import providersFilterSlice from './providers/providersSlice'
import supportFiltersSlice from './support/supportSlice'
import tagsFilterSlice from './tags/tagsSlice'
import usersFilterSlice from './users/usersSlice'

export const filtersReducer = combineSlices(
  supportFiltersSlice,
  ordersFiltersSlice,
  providersFilterSlice,
  usersFilterSlice,
  companyFilterSlice,
  practiceFilterSlice,
  flagsFilterSlice,
  tagsFilterSlice,
  destinationsFilterSlice,
  proceduresSlice,
  patientsFiltersSlice
)

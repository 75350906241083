import { createSlice } from '@reduxjs/toolkit'
import _merge from 'lodash/merge'
import { REHYDRATE } from 'redux-persist'

import { initialState, PROCEDURES_FILTERS_SLICE_NAME } from './constants'

const proceduresFilterSlice = createSlice({
  name: PROCEDURES_FILTERS_SLICE_NAME,
  initialState,
  reducers: {
    setTablePageable: (state, { payload }) => {
      state.pageable = {
        ...state.pageable,
        ...payload,
      }
    },
    setTableFilter: (state, { payload }) => {
      state.filter = {
        ...state.filter,
        ...payload,
      }
      state.pageable.page = 0
    },
    resetTableFilters: (state) => {
      state.filter = initialState.filter
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action) => {
      // @ts-expect-error action has a payload with rehydrated state
      if (action.payload) {
        const {
          // @ts-expect-error action has a payload with rehydrated state
          payload: { filters },
        } = action
        return _merge(state, filters?.proceduresFilters ?? {})
      }

      return state
    })
  },
})

export const { setTablePageable, setTableFilter, resetTableFilters } =
  proceduresFilterSlice.actions

export default proceduresFilterSlice

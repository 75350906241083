import { routes } from 'routes/routes'
import { TOrdersTab } from 'pages/orders/OrdersList/types'
import {
  ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS,
  ALL_ORDERS_TABLE_DEFAULT_COLUMNS,
  TAllOrderColumns,
} from 'store/filters/orders/types'
import { ORDERS_TABLE_TAB } from 'store/ordersList/types'

export const ORDERS_TABS_CONFIG: TOrdersTab[] = [
  {
    allowedRoles: routes.orders.all.roles,
    key: ORDERS_TABLE_TAB.ALL,
    path: routes.orders.all.path,
    label: 'domains.RISOrders.allTabLabel',
  },
  {
    allowedRoles: routes.orders.preparation.roles,
    key: ORDERS_TABLE_TAB.PREPARATION,
    path: routes.orders.preparation.path,
    label: 'domains.RISOrders.preparationTabLabel',
  },
  {
    allowedRoles: routes.orders.assignments.roles,
    key: ORDERS_TABLE_TAB.ASSIGNMENT,
    path: routes.orders.assignments.path,
    label: 'domains.RISOrders.assignmentTitle',
  },
  {
    allowedRoles: routes.orders.reportQa.roles,
    key: ORDERS_TABLE_TAB.REPORT_QA,
    path: routes.orders.reportQa.path,
    label: 'domains.RISOrders.reportQATabLabel',
  },
  {
    allowedRoles: routes.orders.billing.roles,
    key: ORDERS_TABLE_TAB.BILLING,
    path: routes.orders.billing.path,
    label: 'domains.RISOrders.billingTabLabel',
  },
]

export const ALL_ORDERS_TABLE_KEYS_MAP: Record<string, TAllOrderColumns> = {
  accessionNumber: ALL_ORDERS_TABLE_DEFAULT_COLUMNS.ACCESSION_NUMBER,
  sourceAccessionNumber: ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.SOURCE_ACCESSION,
  urgency: ALL_ORDERS_TABLE_DEFAULT_COLUMNS.URGENCY,
  dateOfService: ALL_ORDERS_TABLE_DEFAULT_COLUMNS.DATE_OF_SERVICE,
  creationDateTime: ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.CREATE_DATE,
  procedureCell: ALL_ORDERS_TABLE_DEFAULT_COLUMNS.PROCEDURE,
  patientCell: ALL_ORDERS_TABLE_DEFAULT_COLUMNS.PATIENT_NAME,
  proscanMrn: ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.PRO_SCAN_MRN,
  sourceMrn: ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.SOURCE_MRN,
  siteName: ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.SITE_NAME,
  companyName: ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.COMPANY,
  physicianName: ALL_ORDERS_TABLE_DEFAULT_COLUMNS.ORDERING_PROVIDER,
  assignedRadiologistName:
    ALL_ORDERS_TABLE_DEFAULT_COLUMNS.ASSIGNED_RADIOLOGIST,
  risOrderStatus: ALL_ORDERS_TABLE_DEFAULT_COLUMNS.RIS_STATUS,
  billingType: ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.BILLING_TYPE,
  billingStatus: ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.BILLING_STATUS,
  tags: ALL_ORDERS_TABLE_DEFAULT_COLUMNS.TAGS,
  systemTags: ALL_ORDERS_TABLE_DEFAULT_COLUMNS.SYSTEM_TAGS,
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { TBffCommentPageRs, TCommentType } from 'store/support/types'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'
import { prepareArrayForSending } from 'utils/helpers/arrays/prepareArrayForSending'
import { TPageable, TQueryData } from 'types/general'

import { ORDER_SLICE_NAME } from './constants'
import {
  CAPTURE_TYPE_ENUM,
  IEnhancedViewerLinkParams,
  IGroupedByCompanyStudiesResponse,
  IStudiesResponse,
  ORDER_STATUS_ENUM,
  TBffCommentRs,
  TBffOrderSupportRequestPageRs,
  TCancelAddendumRq,
  TFaxNumbersConfig,
  TIndividualSharingTriggerRq,
  TManualOnHoldStatusRequestData,
  TOrderAttachmentRq,
  TOrderBatchItem,
  TOrderCreateResponse,
  TOrderDraftRequest,
  TOrderPhysician,
  TOrderRequest,
  TOrderResponse,
  TOrdersCopyCandidatesFilterRq,
  TOrdersCopyCandidatesPageRs,
  TOrderSystemDeliveryTagRq,
  TRedeliveryPayload,
  TReportDeliveryDataRs,
  TReportReDeliveryData,
  TSearchStudiesTabPrefix,
  TStudiesQueryParams,
  TStudyRs,
  TTaSharingTriggerRq,
  TTriggerOrderStatusRq,
  TUpdateOrderTagsResponse,
  TUpdateOrderTagsRq,
} from './types'
import { prepareStudyParams } from './utils/prepareStudyParams'

export const fetchOrderByAccessionNumber = createAsyncThunk<
  TOrderResponse,
  string,
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/fetchOrderByAccessionNumber`,
  async (accessionNumber, { rejectWithValue }) => {
    const result = await request<TOrderResponse>({
      url: `bff/orders/${accessionNumber}`,
      method: 'GET',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })

    if (result.data) {
      return result.data
    }

    return rejectWithValue(result.error)
  }
)

export const fetchGroupedByCompanyStudies = createAsyncThunk<
  IGroupedByCompanyStudiesResponse,
  { tabPrefix: TSearchStudiesTabPrefix; query: TStudiesQueryParams },
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/fetchGroupedByCompanyStudies`,
  async ({ query }, { rejectWithValue }) => {
    const result = await request<IGroupedByCompanyStudiesResponse>({
      url: '/bff/orders/studies',
      method: 'GET',
      params: { ...prepareStudyParams(query) },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const fetchStudies = createAsyncThunk<
  IStudiesResponse,
  { tabPrefix: TSearchStudiesTabPrefix; query: TStudiesQueryParams },
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/fetchStudies`,
  async ({ query }, { rejectWithValue }) => {
    const result = await request<IStudiesResponse>({
      url: '/bff/orders/studies/notgrouped',
      method: 'GET',
      params: { ...prepareStudyParams(query) },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const fetchStudiesByCompanyId = createAsyncThunk<
  Array<TStudyRs>,
  { companyIds: number[]; query: TStudiesQueryParams },
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/fetchStudiesByCompanyId`,
  async ({ companyIds, query }, { rejectWithValue }) => {
    const result = await request<Array<TStudyRs>>({
      url: '/orders/studies',
      method: 'GET',
      params: {
        ...query,
        companyIds: companyIds.join(','),
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const submitUpdateOrder = createAsyncThunk<
  unknown,
  {
    data: TOrderRequest
    accessionNumber: string
  },
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/submitUpdateOrder`,
  async ({ accessionNumber, data }, { rejectWithValue }) => {
    const result = await request<void>({
      url: `/orders/${accessionNumber}/orderInfo`,
      data,
      method: 'PUT',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const submitCreateOrder = createAsyncThunk<
  TOrderCreateResponse,
  TOrderRequest,
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/submitCreateOrder`,
  async (data, { rejectWithValue }) => {
    const result = await request<TOrderCreateResponse>({
      url: '/orders',
      method: 'POST',
      data,
    })

    if (result.data) {
      return result.data
    }

    return rejectWithValue(result.error)
  }
)

export const changeManualOnHoldStatus = createAsyncThunk<
  void,
  { accessionNumber: string } & Omit<TManualOnHoldStatusRequestData, 'status'>,
  {
    rejectValue: TServiceError
  }
>(
  `${ORDER_SLICE_NAME}/changeManualOnHoldStatus`,
  async (
    { accessionNumber, manualHoldReasonIds, manualHoldDetails },
    { rejectWithValue }
  ) => {
    const result = await request<void>({
      url: `orders/${accessionNumber}/hold-status`,
      method: 'PATCH',
      data: {
        manualHoldReasonIds,
        manualHoldDetails,
        status: ORDER_STATUS_ENUM.ON_HOLD,
      },
      headers: {
        AccessionNumber: accessionNumber,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const cancelAddendumStatus = createAsyncThunk<
  void,
  { accessionNumber: string } & TCancelAddendumRq,
  {
    rejectValue: TServiceError
  }
>(
  `${ORDER_SLICE_NAME}/cancelAddendumStatus`,
  async (
    { accessionNumber, addendumCancellationReason },
    { rejectWithValue }
  ) => {
    const result = await request<void>({
      url: `orders/${accessionNumber}/cancel-addendum`,
      method: 'POST',
      data: {
        addendumCancellationReason,
      },
      headers: {
        AccessionNumber: accessionNumber,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const saveCreateOrder = createAsyncThunk<
  TOrderCreateResponse,
  TOrderDraftRequest,
  { rejectValue: TServiceError }
>(`${ORDER_SLICE_NAME}/saveCreateOrder`, async (data, { rejectWithValue }) => {
  const result = await request<TOrderCreateResponse>({
    url: '/orders/draft',
    method: 'POST',
    data,
  })

  if (result.data) {
    return result.data
  }

  return rejectWithValue(result.error)
})

export const saveUpdateOrder = createAsyncThunk<
  unknown,
  {
    data: TOrderDraftRequest
    accessionNumber: string
  },
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/saveUpdateOrder`,
  async ({ accessionNumber, data }, { rejectWithValue }) => {
    const result = await request<unknown>({
      url: `/orders/${accessionNumber}/draft`,
      data,
      method: 'PUT',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const getEnhancedViewerLinkRq = createAsyncThunk<
  string,
  IEnhancedViewerLinkParams,
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/getEnhancedViewerLinkRq`,
  async (params, { rejectWithValue }) => {
    const result = await request<string>({
      url: 'intelerad/inteleconnect/enhancedViewer/link',
      method: 'GET',
      params,
    })

    if (result.data !== null) {
      return result.data
    }

    return rejectWithValue(result.error)
  }
)

export const changeOrderStatusRq = createAsyncThunk<
  void,
  { accessionNumber: string; requestBody: TTriggerOrderStatusRq },
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/changeOrderStatusRq`,
  async ({ accessionNumber, requestBody }, { rejectWithValue }) => {
    const result = await request<void>({
      url: `orders/${accessionNumber}/trigger`,
      data: requestBody,
      method: 'POST',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const changeOrderStatusToCancel = createAsyncThunk<
  void,
  { accessionNumber: string; requestBody: TTriggerOrderStatusRq },
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/changeOrderStatusToCancel`,
  async ({ accessionNumber, requestBody }, { rejectWithValue }) => {
    const result = await request<void>({
      url: `orders/${accessionNumber}/cancelation`,
      data: requestBody,
      method: 'PATCH',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const getSupportRequestsByAccessionNumber = createAsyncThunk<
  TBffOrderSupportRequestPageRs,
  {
    accessionNumber: string
    pageable: TPageable
    abortSignal?: AbortSignal
  },
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/getSupportRequestsByAccessionNumber`,
  async ({ accessionNumber, pageable, abortSignal }, { rejectWithValue }) => {
    const result = await request<TBffOrderSupportRequestPageRs>({
      url: `bff/sr/order/${accessionNumber}`,
      method: 'GET',
      params: {
        ...pageable,
      },
      signal: abortSignal,
      headers: {
        AccessionNumber: accessionNumber,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const getLastSupportRequestCommentByAccessionNumber = createAsyncThunk<
  TBffCommentRs,
  {
    supportRequestId: number
    abortSignal?: AbortSignal
  },
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/getLastSupportRequestCommentByAccessionNumber`,
  async ({ supportRequestId, abortSignal }, { rejectWithValue }) => {
    const result = await request<TBffCommentRs>({
      url: `bff/sr/${supportRequestId}/comments/last`,
      method: 'GET',
      signal: abortSignal,
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const getSupportRequestsCountByAccessionNumber = createAsyncThunk<
  { openSupportRequestsCount?: number },
  string,
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/getSupportRequestsCountByAccessionNumber`,
  async (accessionNumber, { rejectWithValue }) => {
    const result = await request<{
      openSupportRequestsCount?: number
    }>({
      url: `/bff/sr/order/${accessionNumber}/open-count`,
      method: 'GET',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const shareOrderForIndividual = createAsyncThunk<
  unknown,
  TIndividualSharingTriggerRq,
  { rejectValue: TServiceError }
>(`${ORDER_SLICE_NAME}/saveCreateOrder`, async (data, { rejectWithValue }) => {
  const result = await request<unknown>({
    url: '/orders/individual/share',
    data,
    method: 'POST',
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const shareOrderViaTransferAgentRq = createAsyncThunk<
  void,
  TTaSharingTriggerRq,
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/submitCreateOrder`,
  async (data, { rejectWithValue }) => {
    const result = await request<void>({
      url: '/orders/dicom/share',
      data,
      method: 'POST',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const pushStudiesInPACS = createAsyncThunk<
  void,
  string,
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/pushStudiesInPACS`,
  async (accessionNumber, { rejectWithValue }) => {
    const result = await request<void>({
      url: `/orders/${accessionNumber}/dicom`,
      method: 'POST',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const startEditByEntityType = createAsyncThunk<
  void,
  {
    accessionNumber: string
    captureTypes: CAPTURE_TYPE_ENUM | CAPTURE_TYPE_ENUM[]
  },
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/startEditByEntityType`,
  async ({ accessionNumber, captureTypes }, { rejectWithValue }) => {
    const result = await request<void>({
      url: `/orders/capture/${accessionNumber}`,
      params: {
        captureTypes: Array.isArray(captureTypes)
          ? prepareArrayForSending(captureTypes)
          : captureTypes,
      },
      method: 'POST',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const finishEditByEntityType = createAsyncThunk<
  void,
  {
    accessionNumber: string
    captureTypes: CAPTURE_TYPE_ENUM | CAPTURE_TYPE_ENUM[]
  },
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/finishEditByEntityType`,
  async ({ accessionNumber, captureTypes }, { rejectWithValue }) => {
    const result = await request<void>({
      url: `/orders/capture/${accessionNumber}`,
      params: {
        captureTypes: Array.isArray(captureTypes)
          ? prepareArrayForSending(captureTypes)
          : captureTypes,
      },
      method: 'DELETE',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const resendHL7ToDownstreamSystem = createAsyncThunk<
  void,
  string,
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/resendHL7ToDownstreamSystem`,
  async (accessionNumber, { rejectWithValue }) => {
    const result = await request<void>({
      url: `/orders/${accessionNumber}/hl7/resend`,
      method: 'POST',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const addOrderAttachments = createAsyncThunk<
  void,
  { accessionNumber: string; data: TOrderAttachmentRq },
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/addOrderAttachments`,
  async ({ accessionNumber, data }, { rejectWithValue }) => {
    const result = await request<void>({
      url: `/orders/${accessionNumber}/attachments`,
      data,
      method: 'PUT',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const getReportsDeliveryResultsByAccessionNumber = createAsyncThunk<
  TReportDeliveryDataRs[],
  string,
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/getReportsDeliveryResultsByAccessionNumber`,
  async (accessionNumber, { rejectWithValue }) => {
    const result = await request<TReportDeliveryDataRs[]>({
      url: `/report/order/${accessionNumber}/deliveries`,
      method: 'GET',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const getSupportRequestComments = createAsyncThunk<
  TBffCommentPageRs,
  {
    supportId: number
    params: Partial<TQueryData> & {
      commentType: TCommentType
      isPaged?: boolean
    }
    abortSignal?: AbortSignal
  },
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/getSupportRequestComments`,
  async ({ params, supportId, abortSignal }, { rejectWithValue }) => {
    const result = await request<TBffCommentPageRs>({
      url: `/bff/sr/${supportId}/comments`,
      method: 'GET',
      params,
      signal: abortSignal,
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const getManualReportDeliveryData = createAsyncThunk<
  TReportReDeliveryData,
  {
    siteId: number
    accessionNumber?: string
  },
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/getManualReportDeliveryData`,
  async ({ siteId, accessionNumber }, { rejectWithValue }) => {
    const result = await request<TReportReDeliveryData>({
      url: `/org/sites/${siteId}/report-redelivery-data`,
      method: 'GET',
      params: { accessionNumber },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const sendManualReportDeliveryData = createAsyncThunk<
  unknown,
  TRedeliveryPayload & {
    accessionNumber: string
  },
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/sendManualReportDeliveryData`,
  async ({ accessionNumber, ...data }, { rejectWithValue }) => {
    const result = await request<unknown>({
      url: `/report/order/${accessionNumber}/redelivery`,
      data,
      method: 'POST',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const markAsAcknowledge = createAsyncThunk<
  unknown,
  number,
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/sendManualReportDeliveryData`,
  async (reportDeliveryId, { rejectWithValue }) => {
    const result = await request<unknown>({
      url: `/report/delivery/${reportDeliveryId}/ack`,
      method: 'PATCH',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const updateOrderTags = createAsyncThunk<
  TUpdateOrderTagsResponse,
  { accessionNumber: string } & TUpdateOrderTagsRq,
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/updateOrderTags`,
  async ({ accessionNumber, ...data }, { rejectWithValue }) => {
    const result = await request<TUpdateOrderTagsResponse>({
      url: `/orders/${accessionNumber}/tags`,
      method: 'PATCH',
      data,
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const applyDeliverySystemTags = createAsyncThunk<
  void,
  { accessionNumber: string; requestBody: TOrderSystemDeliveryTagRq },
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/applyDeliverySystemTags`,
  async ({ accessionNumber, requestBody }, { rejectWithValue }) => {
    const result = await request<void>({
      url: `orders/${accessionNumber}/system-tags`,
      data: requestBody,
      method: 'PATCH',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const updateOrderPhysicians = createAsyncThunk<
  unknown,
  { accessionNumber: string; physicians: TOrderPhysician[] },
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/updateOrderPhysicians`,
  async ({ accessionNumber, physicians }, { rejectWithValue }) => {
    const result = await request<unknown>({
      url: `/orders/${accessionNumber}/physicians`,
      method: 'PUT',
      data: { physicians },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const fetchCopyOrderData = createAsyncThunk<
  TOrdersCopyCandidatesPageRs,
  {
    filter?: TOrdersCopyCandidatesFilterRq
    pageable?: TPageable
    siteId: number
    abortSignal?: AbortSignal
    sourceOrderAN: string
  },
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/fetchCopyOrderData`,
  async (
    { filter, pageable, siteId, sourceOrderAN, abortSignal },
    { rejectWithValue }
  ) => {
    const result = await request<TOrdersCopyCandidatesPageRs>({
      url: 'orders/copy-candidates',
      method: 'GET',
      params: { ...filter, ...pageable, siteId, sourceOrderAN },
      signal: abortSignal,
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const fetchCopyOrderInformation = createAsyncThunk<
  TOrderResponse[],
  {
    sourceAccessionNumber: string
    accessionNumbers: Array<string>
    abortSignal?: AbortSignal
  },
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/fetchCopyOrderInformation`,
  async (
    { accessionNumbers, abortSignal, sourceAccessionNumber },
    { rejectWithValue }
  ) => {
    const result = await request<TOrderResponse[]>({
      url: `bff/orders/${sourceAccessionNumber}/copy/prefill`,
      method: 'GET',
      params: { copyTo: prepareArrayForSending(accessionNumbers) },
      signal: abortSignal,
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const submitCopyOrders = createAsyncThunk<
  unknown,
  TOrderBatchItem[],
  { rejectValue: TServiceError }
>(`${ORDER_SLICE_NAME}/submitCopyOrders`, async (data, { rejectWithValue }) => {
  const result = await request<void>({
    url: '/orders/batch-update',
    data: {
      orderBatch: data,
    },
    method: 'PUT',
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const saveDraftCopyOrders = createAsyncThunk<
  unknown,
  TOrderBatchItem[],
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/saveDraftCopyOrders`,
  async (data, { rejectWithValue }) => {
    const result = await request<void>({
      url: '/orders/batch-draft-update',
      data: {
        orderBatch: data,
      },
      method: 'PUT',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const updateFaxData = createAsyncThunk<
  unknown,
  { data: TFaxNumbersConfig; accessionNumber: string },
  { rejectValue: TServiceError }
>(
  `${ORDER_SLICE_NAME}/updateFaxData`,
  async ({ data, accessionNumber }, { rejectWithValue }) => {
    const result = await request<void>({
      url: `/orders/${accessionNumber}/fax-data`,
      data,
      method: 'POST',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

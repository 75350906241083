import { USER_ROLES } from 'constants/userRoles'
import { routes } from 'routes/routes'
import { TPath } from 'types/general'

// if a user does not have an allowed role for the Dashboard, then route accordingly
export const dashboardMapRoleToRoute: Partial<Record<USER_ROLES, TPath>> = {
  [USER_ROLES.PRACTICE_USER]: routes.orders.root.path,
  [USER_ROLES.PROVIDER]: routes.orders.root.path,
  [USER_ROLES.INDIVIDUAL]: routes.sharedStudies.root.path,
}

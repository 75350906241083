import { createAsyncThunk } from '@reduxjs/toolkit'
import { TProcedure } from 'services/dictionary/types'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'

import {
  PROCEDURE_STATUS,
  TProcedureCreate,
  TProcedureID,
  TProcedureUpdate,
} from './types'

export const postProcedure = createAsyncThunk<
  TProcedureID,
  TProcedureCreate,
  {
    rejectValue: TServiceError
  }
>('procedures/createProcedure', async (data, { rejectWithValue }) => {
  const result = await request<TProcedureID>({
    url: '/orders/procedures',
    data,
    method: 'POST',
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const editProcedure = createAsyncThunk<
  unknown,
  { fields: TProcedureUpdate; id: number },
  {
    rejectValue: TServiceError
  }
>('procedures/editProcedure', async (payload, { rejectWithValue }) => {
  const result = await request<TProcedureID>({
    url: `/orders/procedures/${payload.id}`,
    data: payload.fields,
    method: 'PUT',
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const fetchArchive = createAsyncThunk<
  unknown,
  { status: PROCEDURE_STATUS; id: string | number },
  {
    rejectValue: TServiceError
  }
>('procedures/archiveProcedure', async (payload, { rejectWithValue }) => {
  const result = await request<unknown>({
    url: `/orders/procedures/${payload.id}`,
    data: { status: payload.status },
    method: 'PATCH',
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const fetchProcedureInfo = createAsyncThunk<
  TProcedure,
  TProcedureID,
  { rejectValue: TServiceError }
>('procedures/fetchProcedureInfo', async (payload, { rejectWithValue }) => {
  const result = await request<TProcedure>({
    url: `bff/orders/procedures/${payload.id}`,
    method: 'GET',
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

import { TOrder } from 'store/ordersList/types'

export const getPatientNameData = (order: TOrder) => {
  switch (true) {
    case Boolean(order.patient?.firstname ?? order.patient?.lastname):
      return {
        ...order.patient,
        firstname: order.patient?.firstname ?? '',
        lastname: order.patient?.lastname ?? '',
        isDicomFullName: false,
      }
    case Boolean(order.studyInfo?.firstName ?? order.studyInfo?.lastName):
      return {
        ...order.patient,
        firstname: order.studyInfo?.firstName ?? '',
        lastname: order.studyInfo?.lastName ?? '',
        isDicomFullName: true,
      }
    default:
      return {
        ...order.patient,
        firstname: undefined,
        lastname: undefined,
        isDicomFullName: false,
      }
  }
}

import { TServiceError } from 'utils/api/types'
import { LOADING_STATE } from 'types/general'
import { components } from 'types/schemas'

export type TTagsPageRs = Omit<
  Required<components['schemas']['TagPageRs']>,
  'content'
> & { content: TTagRs[] }

export type TTagRs = Required<
  Omit<Required<components['schemas']['TagGridRs']>, 'type' | 'status'> & {
    type: TAG_TYPE
    status: TAG_STATUS
  }
>

export type TTagsFilter = Omit<
  components['schemas']['TagFilterRq'],
  'statuses' | 'siteIds' | 'profileSiteIds' | 'providerIds'
> & {
  statuses: TAG_STATUS[]
  siteIds?: number[]
  profileSiteIds?: number[]
}

export enum TAG_STATUS {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum TAG_TYPE {
  CUSTOMER = 'CUSTOMER',
  ADMIN = 'ADMIN',
}

export type TGroupedTagsByType = Record<TAG_TYPE, TTagRs[]>

export interface IState {
  tagsTable: {
    list: TTagsPageRs
    loadingState: LOADING_STATE
    error?: TServiceError | null
  }
  createTag: {
    list: TTagRs[]
    loadingState: LOADING_STATE
  }
  allTags: {
    loadingState: LOADING_STATE
    error?: TServiceError | null
    list: TTagRs[]
  }
}

export type TTagCreationRs = components['schemas']['TagCreationRs']
export type TTagCreationRq = Omit<
  components['schemas']['TagCreationRq'],
  'siteIds'
> & { siteIds?: number[] }
export type TTagUpdateRq = Omit<
  components['schemas']['TagUpdateRq'],
  'siteIds'
> & { siteIds?: number[] }

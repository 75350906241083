import { useCallback } from 'react'
import {
  FIREBASE_NOTIFICATION_TYPE_ENUM,
  TFirebaseMessage,
  TMessageHandler,
} from 'utils/FireBaseNotifications/types'
import { usePSSnackbar } from 'utils/hooks/snackbar/usePSSnackbar'

export const useHandleCamundaFailureMessage = () => {
  const { openErrorSnackbar } = usePSSnackbar()

  const handleCamundaFailureMessage: TMessageHandler = useCallback((event) => {
    try {
      const notificationBody: TFirebaseMessage = JSON.parse(
        event.data.notification.body
      )

      if (
        notificationBody.type ===
        FIREBASE_NOTIFICATION_TYPE_ENUM.FAILURE_CAMUNDA
      ) {
        openErrorSnackbar(notificationBody.message)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('JSON Parse error: ', e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { handleCamundaFailureMessage }
}

import { IState } from './types'

export const initialState: IState = {
  filtersData: {
    targetGroups: [],
    supportRequestTypes: [],
    risOrders: [],
    urgencies: [],
    siteNames: [],
    orderStatuses: [],
    isIncludedResolved: false,
    dateOfService: {
      from: null,
      to: null,
    },
    followUpDate: {
      from: null,
      to: null,
    },
  },
  queryData: {
    page: 0,
    size: 10,
    search: '',
  },
}

export const SUPPORT_FILTERS_SLICE_NAME = 'supportFilters'

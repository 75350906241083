import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _merge from 'lodash/merge'
import { REHYDRATE } from 'redux-persist'
import { TPhysiciansTableFilter } from 'services/bff/types'
import { TRadiologistsTableFilter } from 'services/providers/types'
import {
  PHYSICIAN_CONFIGURABLE_COLUMNS,
  PROVIDERS_TABLE_NAMES_ENUM,
  RADIOLOGIST_CONFIGURABLE_COLUMNS,
} from 'store/providers/types'
import { TQueryData } from 'types/general'

import { initialState, PROVIDERS_FILTERS_SLICE_NAME } from './constants'

const providersFilterSlice = createSlice({
  name: PROVIDERS_FILTERS_SLICE_NAME,
  initialState,
  reducers: {
    removeTaxonomyFilterOptionById: (state, { payload }) => {
      state[PROVIDERS_TABLE_NAMES_ENUM.PHYSICIANS].filtersData = {
        ...state[PROVIDERS_TABLE_NAMES_ENUM.PHYSICIANS].filtersData,
        taxonomyIds: state[
          PROVIDERS_TABLE_NAMES_ENUM.PHYSICIANS
        ].filtersData.taxonomyIds.filter((option) => option.id !== payload),
      }
      state[PROVIDERS_TABLE_NAMES_ENUM.PHYSICIANS].queryData.page = 0
    },
    removePracticeFilterOptionById: (state, { payload }) => {
      state[PROVIDERS_TABLE_NAMES_ENUM.PHYSICIANS].filtersData = {
        ...state[PROVIDERS_TABLE_NAMES_ENUM.PHYSICIANS].filtersData,
        practiceIds: state[
          PROVIDERS_TABLE_NAMES_ENUM.PHYSICIANS
        ].filtersData.practiceIds.filter(
          (option) => option.practiceId !== payload
        ),
      }
      state[PROVIDERS_TABLE_NAMES_ENUM.PHYSICIANS].queryData.page = 0
    },
    removeSiteFilterOptionById: (state, { payload }) => {
      state[PROVIDERS_TABLE_NAMES_ENUM.PHYSICIANS].filtersData = {
        ...state[PROVIDERS_TABLE_NAMES_ENUM.PHYSICIANS].filtersData,
        siteIds: state[
          PROVIDERS_TABLE_NAMES_ENUM.PHYSICIANS
        ].filtersData.siteIds.filter((option) => option.siteId !== payload),
      }
      state[PROVIDERS_TABLE_NAMES_ENUM.PHYSICIANS].queryData.page = 0
    },
    removeSubSpecialityFilterOptionById: (state, { payload }) => {
      state[PROVIDERS_TABLE_NAMES_ENUM.RADIOLOGISTS].filtersData = {
        ...state[PROVIDERS_TABLE_NAMES_ENUM.RADIOLOGISTS].filtersData,
        subSpecialtyIds: state[
          PROVIDERS_TABLE_NAMES_ENUM.RADIOLOGISTS
        ].filtersData.subSpecialtyIds.filter((option) => option.id !== payload),
      }
      state[PROVIDERS_TABLE_NAMES_ENUM.RADIOLOGISTS].queryData.page = 0
    },
    removeCountryFilterOptionById: (state, { payload }) => {
      state[PROVIDERS_TABLE_NAMES_ENUM.RADIOLOGISTS].filtersData = {
        ...state[PROVIDERS_TABLE_NAMES_ENUM.RADIOLOGISTS].filtersData,
        countries: state[
          PROVIDERS_TABLE_NAMES_ENUM.RADIOLOGISTS
        ].filtersData.countries.filter((option) => option !== payload),
      }
      state[PROVIDERS_TABLE_NAMES_ENUM.RADIOLOGISTS].queryData.page = 0
    },
    removeStateFilterOptionById: (state, { payload }) => {
      state[PROVIDERS_TABLE_NAMES_ENUM.RADIOLOGISTS].filtersData = {
        ...state[PROVIDERS_TABLE_NAMES_ENUM.RADIOLOGISTS].filtersData,
        states: state[
          PROVIDERS_TABLE_NAMES_ENUM.RADIOLOGISTS
        ].filtersData.states.filter((option) => option !== payload),
      }
      state[PROVIDERS_TABLE_NAMES_ENUM.RADIOLOGISTS].queryData.page = 0
    },
    changeTableQueryData: (
      state,
      {
        payload: { tableName, data },
      }: PayloadAction<{
        tableName: `${PROVIDERS_TABLE_NAMES_ENUM}`
        data: Partial<TQueryData>
      }>
    ) => {
      state[tableName].queryData = {
        ...state[tableName].queryData,
        ...data,
      }
    },
    changeFilterData: (
      state,
      {
        payload: { tableName, data },
      }: PayloadAction<{
        tableName: `${PROVIDERS_TABLE_NAMES_ENUM}`
        data: Partial<TPhysiciansTableFilter | TRadiologistsTableFilter>
      }>
    ) => {
      state[tableName].filtersData = {
        ...state[tableName].filtersData,
        ...data,
      }
      state[tableName].queryData.page = 0
    },
    clearFilterData: (
      state,
      { payload }: PayloadAction<`${PROVIDERS_TABLE_NAMES_ENUM}`>
    ) => {
      state[payload].filtersData = initialState[payload].filtersData
      state[payload].queryData.search = ''
      state[payload].queryData.page = 0
    },
    configureTableColumns: (
      state,
      {
        payload,
      }: PayloadAction<
        | {
            tableName: PROVIDERS_TABLE_NAMES_ENUM.RADIOLOGISTS
            columnName: RADIOLOGIST_CONFIGURABLE_COLUMNS
          }
        | {
            tableName: PROVIDERS_TABLE_NAMES_ENUM.PHYSICIANS
            columnName: PHYSICIAN_CONFIGURABLE_COLUMNS
          }
      >
    ) => {
      const tableName = payload.tableName

      if (tableName === PROVIDERS_TABLE_NAMES_ENUM.RADIOLOGISTS) {
        const tableData = state[tableName]
        const columnName = payload.columnName

        tableData.columnsConfiguration = {
          ...tableData.columnsConfiguration,
          [columnName]: !tableData.columnsConfiguration[columnName],
        }
      }

      if (tableName === PROVIDERS_TABLE_NAMES_ENUM.PHYSICIANS) {
        const tableData = state[tableName]
        const columnName = payload.columnName

        tableData.columnsConfiguration = {
          ...tableData.columnsConfiguration,
          [columnName]: !tableData.columnsConfiguration[columnName],
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action) => {
      // @ts-expect-error action has a payload with rehydrated state
      if (action.payload) {
        const {
          // @ts-expect-error action has a payload with rehydrated state
          payload: { filters },
        } = action
        return _merge(state, filters?.providersFilters ?? {})
      }

      return state
    })
  },
})

export const {
  changeTableQueryData,
  changeFilterData,
  clearFilterData,
  configureTableColumns,
  removeTaxonomyFilterOptionById,
  removePracticeFilterOptionById,
  removeSiteFilterOptionById,
  removeSubSpecialityFilterOptionById,
  removeStateFilterOptionById,
  removeCountryFilterOptionById,
} = providersFilterSlice.actions

export default providersFilterSlice

import { LOADING_STATE } from 'types/general'

import { IState } from './types'

export const INITIAL_STATE: IState = {
  firebaseToken: '',
  associatedCompanies: {
    companies: [],
    totalCompanies: 0,
  },
  currentUser: {},
  isNotificationsGranted: false,
  loadingState: LOADING_STATE.idle,
}

export const AUTH_SLICE_NAME = 'auth'

import { FC } from 'react'
import { LinkProps } from '@mui/material/Link'

import { StyledLink } from './PSLink.styled'

interface IPSLinkProps extends LinkProps {}

export const PSLink: FC<IPSLinkProps> = ({
  color = 'primary.main',
  children,
  ...otherProps
}) => (
  <StyledLink {...otherProps} color={color}>
    {children}
  </StyledLink>
)

import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from 'services/axiosBaseQuery'
import {
  RADIOLOGISTS_LIST_ID,
  RADIOLOGISTS_TAG,
} from 'services/providers/contants'
import { prepareArrayForSending } from 'utils/helpers/arrays/prepareArrayForSending'
import { getValuable } from 'utils/helpers/general'
import { TQueryData } from 'types/general'

import { TRadiologistsPageRs, TRadiologistsTableFilter } from './types'

export const providersApi = createApi({
  reducerPath: 'providersApi',
  tagTypes: [RADIOLOGISTS_TAG],
  baseQuery: axiosBaseQuery({ baseUrl: '/providers' }),
  endpoints: (builder) => ({
    getRadiologists: builder.query<
      TRadiologistsPageRs,
      { pageable: TQueryData; filter: TRadiologistsTableFilter }
    >({
      query: ({ pageable, filter }) => ({
        url: 'radiologists',
        params: {
          ...pageable,
          ...getValuable(filter),
          subSpecialtyIds: prepareArrayForSending(
            filter.subSpecialtyIds?.map(({ id }) => id)
          ),
          countries: prepareArrayForSending(filter.countries),
          states: prepareArrayForSending(filter.states),
          search: prepareArrayForSending(pageable.search?.split(' ')),
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...(result.content?.map(({ providerId }) => ({
                type: RADIOLOGISTS_TAG,
                id: String(providerId),
              })) ?? []),
              { type: RADIOLOGISTS_TAG, id: RADIOLOGISTS_LIST_ID },
            ]
          : [{ type: RADIOLOGISTS_TAG, id: RADIOLOGISTS_LIST_ID }],
    }),
  }),
})

export const { useGetRadiologistsQuery } = providersApi

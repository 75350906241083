import { READINESS_COLOR } from '../constants'

export const getOrderReadinessColor = (readinessPercent: number) => {
  switch (true) {
    case readinessPercent < 20:
      return READINESS_COLOR.FROM_0_TO_19
    case readinessPercent < 50:
      return READINESS_COLOR.FROM_20_TO_49
    case readinessPercent < 80:
      return READINESS_COLOR.FROM_50_TO_79
    case readinessPercent < 100:
      return READINESS_COLOR.FROM_80_TO_99
    case readinessPercent === 100:
      return READINESS_COLOR.READY
    default:
      return READINESS_COLOR.FROM_0_TO_19
  }
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip, Typography } from '@mui/material'
import { TOrder } from 'store/ordersList/types'
import { useGetIsContentBiggerThenBlockHeight } from 'utils/hooks/useGetIsContentBiggerThenBlockHeight'

import { StyledOrderProcedureCell } from './RisOrderProcedureCell.styled'

interface IRisOrderProcedureCellProps {
  data: TOrder
}

export const RisOrderProcedureCell: FC<IRisOrderProcedureCellProps> = ({
  data,
}) => {
  const { t } = useTranslation()

  const [textRef, isTooltipEnabled] = useGetIsContentBiggerThenBlockHeight()

  const isDicomProcedureOnlyToShow =
    !data.procedure && Boolean(data.studyInfo?.procedure)
  const procedureToShow = data.procedure ?? data.studyInfo?.procedure

  return (
    <Tooltip
      title={
        <>
          {isTooltipEnabled && (
            <Typography
              variant='inherit'
              fontWeight={400}
              paragraph={isDicomProcedureOnlyToShow}
            >
              {procedureToShow}
            </Typography>
          )}
          {isDicomProcedureOnlyToShow && (
            <Typography variant='inherit' fontWeight={500}>
              {t('domains.RISOrders.procedureExtractedFromDICOM')}
            </Typography>
          )}
        </>
      }
      disableHoverListener={!isTooltipEnabled && !isDicomProcedureOnlyToShow}
    >
      <StyledOrderProcedureCell
        ref={textRef}
        variant='inherit'
        {...(isDicomProcedureOnlyToShow && { color: 'text.disabled' })}
      >
        {procedureToShow ?? t('components.table.notApplicable')}
      </StyledOrderProcedureCell>
    </Tooltip>
  )
}

import { Chip, styled } from '@mui/material'

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'bgColor' && prop !== 'textColor',
})<{ bgColor?: string; textColor?: string }>(({ bgColor, textColor }) => ({
  backgroundColor: bgColor,
  color: textColor,
  ...(textColor && {
    '& .MuiChip-deleteIcon': {
      color: textColor,
      opacity: '0.8',
    },
  }),
}))

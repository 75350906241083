import { TTabWithRoute } from 'types/general'

export enum DICTIONARIES_TABLE_TAB {
  PROCEDURES = 'PROCEDURES',
  FLAGS = 'FLAGS',
  TAGS = 'TAGS',
  DESTINATIONS = 'DESTINATIONS',
  DESTINATION_GROUPS = 'DESTINATION_GROUPS',
}

export type TDictionariesTab = TTabWithRoute<DICTIONARIES_TABLE_TAB>

import { Box, styled } from '@mui/material'

export const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isOpen' && prop !== 'isSmallScreen',
})<{ isOpen: boolean; isSmallScreen: boolean }>(
  ({ isOpen, isSmallScreen, theme: { spacing } }) => ({
    marginTop: spacing(3),
    marginBottom: isOpen ? spacing(2) : 0,
    paddingLeft: !isOpen || isSmallScreen ? 0 : spacing(3),
    paddingRight: isOpen ? spacing(2) : 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: !isOpen || isSmallScreen ? 'center' : 'space-between',
    ...(!isOpen && { flexDirection: 'column', gap: 0.5 }),
  })
)

export const StyledLogoContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isOpen' && prop !== 'isSmallScreen',
})<{ isOpen: boolean; isSmallScreen: boolean }>(
  ({ isOpen, isSmallScreen }) => ({
    display: 'flex',
    width: !isOpen || isSmallScreen ? '64px' : '100px',
    ...(!isOpen && { justifyContent: 'center' }),
  })
)

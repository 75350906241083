import { ElementType } from 'react'
import { styled, Tab, Tabs, TabsTypeMap, TabTypeMap } from '@mui/material'
import { TPath } from 'types/general'

export const PSTabs = styled(Tabs, {
  shouldForwardProp: (prop) => prop !== 'isTabsWithError',
})<TabsTypeMap['props'] & { isTabsWithError?: boolean }>(
  ({ theme, isTabsWithError = false }) => ({
    ...(isTabsWithError
      ? {
          '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.error.main,
          },
        }
      : {}),
  })
)

export const PSTab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'isErrorTab',
})<
  TabTypeMap['props'] & {
    isErrorTab?: boolean
    component?: ElementType
    to?: TPath
  }
>(({ theme, isErrorTab = false }) => ({
  '&.MuiTab-root': {
    minHeight: '60px',
  },
  ...(isErrorTab
    ? {
        '&.Mui-selected': {
          color: theme.palette.error.main,
        },
        '&.MuiTabs-indicator': {
          backgroundColor: theme.palette.error.main,
        },
        '& .MuiTab-iconWrapper': {
          width: '8px',
          marginBottom: '10px',
          marginLeft: '5px',
          color: theme.palette.error.main,
        },
      }
    : {}),
}))

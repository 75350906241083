import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATE } from 'types/general'

import { DASHBOARD_SLICE_NAME, initialState } from './constants'
import * as controllers from './controllers'

const dashboardSlice = createSlice({
  name: DASHBOARD_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(controllers.fetchDashboardInfo.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.fetchDashboardInfo.fulfilled, (state, action) => {
        state.info = action.payload
        state.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(controllers.fetchDashboardInfo.rejected, (state) => {
        state.loadingState = LOADING_STATE.rejected
        state.info = initialState.info
      })
      .addCase(controllers.fetchSuperUserDashboardInfo.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(
        controllers.fetchSuperUserDashboardInfo.fulfilled,
        (state, action) => {
          state.adminInfo = action.payload
          state.loadingState = LOADING_STATE.fulfilled
        }
      )
      .addCase(controllers.fetchSuperUserDashboardInfo.rejected, (state) => {
        state.loadingState = LOADING_STATE.rejected
        state.adminInfo = initialState.adminInfo
      })
      .addCase(
        controllers.fetchProviderAssistantDashboardInfo.pending,
        (state) => {
          state.loadingState = LOADING_STATE.pending
        }
      )
      .addCase(
        controllers.fetchProviderAssistantDashboardInfo.fulfilled,
        (state, action) => {
          state.providerAssistantInfo = action.payload.providers
          state.loadingState = LOADING_STATE.fulfilled
        }
      )
      .addCase(
        controllers.fetchProviderAssistantDashboardInfo.rejected,
        (state) => {
          state.loadingState = LOADING_STATE.rejected
          state.providerAssistantInfo = initialState.providerAssistantInfo
        }
      )
  },
})

export default dashboardSlice

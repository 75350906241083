import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  TCompanyCreationRequest,
  TCompanyInfo,
  TCompanyNameRs,
  TCompanyParams,
  TCompanyResponse,
  TCompanyUpdateRequest,
  TExistingSiteReportConfiguration,
  TSiteLocationRs,
  TSiteReportConfiguration,
} from 'store/company/types'
import { TCompanyFilter } from 'store/filters/company/types'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'
import { downloadBlobFile } from 'utils/helpers/files/downloadBlobFile'
import { TPageable } from 'types/general'

export const fetchCompanies = createAsyncThunk<
  TCompanyResponse,
  Partial<TCompanyFilter & TPageable>,
  {
    rejectValue: TServiceError
  }
>(
  'company/fetchCompanies',
  async (
    { states, countries, siteIds, ...restParams },
    { rejectWithValue }
  ) => {
    const params: TCompanyParams = {
      states: states?.length ? states.join() : undefined,
      countries: countries?.length ? countries.join() : undefined,
      siteIds: siteIds?.length ? siteIds.join() : undefined,
      ...restParams,
    }
    const result = await request<TCompanyResponse>({
      url: '/bff/org/companies/sites',
      params,
      method: 'GET',
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const fetchCompaniesAvailableFilters = createAsyncThunk<TSiteLocationRs>(
  'company/fetchCompaniesAvailableFilters',
  async (_, { rejectWithValue }) => {
    const result = await request<TSiteLocationRs>({
      url: '/org/sites/locations',
      method: 'GET',
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const addCompany = createAsyncThunk<
  { data: TCompanyNameRs },
  TCompanyCreationRequest,
  { rejectValue: TServiceError }
>('company/addCompany', async (data, { rejectWithValue }) => {
  const result = await request<TCompanyNameRs>({
    url: '/org/companies',
    method: 'POST',
    data,
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result
})

export const editCompany = createAsyncThunk<
  unknown,
  TCompanyUpdateRequest & { companyId: string },
  { rejectValue: TServiceError }
>('company/editCompany', async (payload, { rejectWithValue }) => {
  const { companyId, ...data } = payload
  const result = await request<unknown>({
    url: `/org/companies/${companyId}/info`,
    data,
    method: 'PATCH',
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result
})

export const fetchCompanyInfo = createAsyncThunk<
  TCompanyInfo,
  { companyId: number; params?: { sort: string } },
  { rejectValue: TServiceError }
>('company/fetchCompanyInfo', async (payload, { rejectWithValue }) => {
  const response = await request<TCompanyInfo>({
    url: `/bff/org/companies/${payload.companyId}/info`,
    method: 'GET',
    ...(payload?.params && { params: payload.params }),
  })
  if (response.error) {
    return rejectWithValue(response.error)
  }

  return response.data
})

export const fetchSiteReportConfigurationInfo = createAsyncThunk<
  TExistingSiteReportConfiguration,
  number,
  {
    rejectValue: TServiceError
  }
>(
  'company/fetchSiteReportConfigurationInfo',
  async (siteId, { rejectWithValue }) => {
    const result = await request<TExistingSiteReportConfiguration>({
      url: `/report/template/${siteId}`,
      method: 'GET',
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return result.data
  }
)

export const updateSiteReportConfiguration = createAsyncThunk<
  unknown,
  TSiteReportConfiguration,
  {
    rejectValue: TServiceError
  }
>(
  'company/updateSiteReportConfiguration',
  async (data, { rejectWithValue }) => {
    const result = await request({
      url: '/report/template',
      method: 'PUT',
      data,
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result
  }
)

export const createSiteReportConfiguration = createAsyncThunk<
  unknown,
  TSiteReportConfiguration,
  {
    rejectValue: TServiceError
  }
>(
  'company/createSiteReportConfiguration',
  async (data, { rejectWithValue }) => {
    const result = await request({
      url: '/report/template',
      data,
      method: 'POST',
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result
  }
)

export const exportReportTemplate = createAsyncThunk<
  unknown,
  {
    siteId: number
    siteName: string
  },
  {
    rejectValue: TServiceError
  }
>(
  'company/exportReportTemplate',
  async ({ siteId, siteName }, { rejectWithValue }) => {
    const result = await request<Blob>({
      url: `/report/template/${siteId}/download`,
      method: 'GET',
      responseType: 'blob',
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }

    downloadBlobFile(result.data, `${siteName} Report Template.pdf`)

    return result
  }
)

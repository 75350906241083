import { IState } from 'store/orderReportInformation/types'
import { LOADING_STATE } from 'types/general'

export const ORDER_REPORT_SLICE_NAME = 'reportInformation'

export const initialState: IState = {
  reportInformation: undefined,
  loadingState: LOADING_STATE.idle,
  compareInformation: undefined,
  openedReportModal: null,
  legalCopies: {
    data: [],
    loadingState: LOADING_STATE.idle,
  },
  orderReportVersionModalData: {
    isFinalLegalCopyReleased: false,
    isFinalLegalCopyReleasedLoading: false,
    isOrderReportVersionCreating: false,
  },
}

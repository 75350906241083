import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import {
  PROGRESS_COMPLETE_VALUE,
  PROGRESS_INITIAL_VALUE,
} from 'constants/files/files'
import {
  FILES_DOWNLOADING_SLICE_NAME,
  INITIAL_STATE,
} from 'store/filesDownloading/constants'
import {
  downloadLatestVersionOrderLegalCopyReport,
  downloadOrderReport,
  initiateDownloadOrderListCSVReport,
  initiateStudyDownload,
} from 'store/filesDownloading/controllers'
import { handleCompleteDownloadProgress } from 'store/filesDownloading/helpers/handleCompleteDownloadProgress'
import { STUDY_DOWNLOAD_TYPE_ENUM } from 'store/filesDownloading/types'

const filesDownloadingSlice = createSlice({
  name: FILES_DOWNLOADING_SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setDICOMDownloadingProgress: (
      state,
      {
        payload: { accessionNumber, studyId, progress },
      }: PayloadAction<{
        accessionNumber: string
        studyId: number
        progress: number
      }>
    ) => {
      state.DICOMs[accessionNumber] = {
        ...state.DICOMs[accessionNumber],
        [studyId]: {
          ...state.DICOMs?.[accessionNumber]?.[studyId],
          downloadProgress: progress,
        },
      }
    },
    removeDICOMDownloading: (
      state,
      {
        payload: { accessionNumber, studyId },
      }: PayloadAction<{ accessionNumber: string; studyId: number }>
    ) => {
      delete state.DICOMs[accessionNumber][studyId]

      if (!Object.keys(state.DICOMs[accessionNumber]).length) {
        delete state.DICOMs[accessionNumber]
      }
    },
    setISODownloadProgress: (
      state,
      {
        payload: { accessionNumber, progress },
      }: PayloadAction<{ accessionNumber: string; progress: number }>
    ) => {
      state.ISOs[accessionNumber] = {
        ...state.ISOs[accessionNumber],
        downloadProgress: progress,
      }
    },
    setCSVReportDownloadProgress: (
      state,
      {
        payload: { eventId, progress },
      }: PayloadAction<{ eventId: string; progress: number }>
    ) => {
      state.orderListCSVReport[eventId] = {
        ...state.orderListCSVReport[eventId],
        downloadProgress: progress,
      }
    },
    removeISODownloading: (
      state,
      {
        payload: { accessionNumber },
      }: PayloadAction<{ accessionNumber: string }>
    ) => {
      delete state.ISOs[accessionNumber]
    },
    removeCSVReportDownloading: (state, { payload }: PayloadAction<string>) => {
      delete state.orderListCSVReport[payload]
    },
    setOrderPDFReportDownloadProgress: (state, { payload }) => {
      state.orderPDFReport.downloadProgress = payload
    },
    setLatestVersionOrderLegalCopyReportDownloadProgress: (
      state,
      {
        payload: { downloadProgress, accessionNumber },
      }: PayloadAction<{ downloadProgress: number; accessionNumber: string }>
    ) => {
      state.latestVersionOrderLegalCopyReport[
        accessionNumber
      ].downloadProgress = downloadProgress
    },
    setLegalCopiesDownloadProgressById: (
      state,
      {
        payload: { id, progress },
      }: PayloadAction<{ id: number; progress: number }>
    ) => {
      state.orderLegalCopyReport[id] = progress
    },
    removeLegalCopiesDownloadProgressById: (
      state,
      { payload: { id } }: PayloadAction<{ id: number }>
    ) => {
      delete state.orderLegalCopyReport[id]
    },
    removeLatestVersionOrderLegalCopyReportDownloading: (
      state,
      { payload: accessionNumber }: PayloadAction<string>
    ) => {
      delete state.latestVersionOrderLegalCopyReport[accessionNumber]
    },
    setIsUploading: (state, { payload: fileId }: PayloadAction<string>) => {
      state.isUploading[fileId] = true
    },
    removeIsUploading: (state, { payload: fileId }: PayloadAction<string>) => {
      delete state.isUploading[fileId]
    },
    setIsDownloading: (state, { payload: fileId }: PayloadAction<string>) => {
      state.isDownloading[fileId] = true
    },
    removeIsDownloading: (
      state,
      { payload: fileId }: PayloadAction<string>
    ) => {
      delete state.isDownloading[fileId]
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        initiateStudyDownload.pending,
        (
          state,
          {
            meta: {
              arg: {
                downloadType,
                data: { accessionNumber, studyId },
              },
            },
          }
        ) => {
          if (downloadType === STUDY_DOWNLOAD_TYPE_ENUM.DICOM && studyId) {
            state.DICOMs[accessionNumber] = {
              ...state.DICOMs[accessionNumber],
              [studyId]: {
                downloadProgress: PROGRESS_INITIAL_VALUE,
                isDownloading: true,
              },
            }
          }

          if (downloadType === STUDY_DOWNLOAD_TYPE_ENUM.ISO) {
            state.ISOs[accessionNumber] = {
              downloadProgress: PROGRESS_INITIAL_VALUE,
              isDownloading: true,
            }
          }
        }
      )
      .addCase(
        initiateStudyDownload.rejected,
        (
          state,
          {
            meta: {
              arg: {
                downloadType,
                data: { accessionNumber, studyId },
              },
            },
          }
        ) => {
          if (downloadType === STUDY_DOWNLOAD_TYPE_ENUM.DICOM && studyId) {
            delete state.DICOMs[accessionNumber][studyId]
          }

          if (downloadType === STUDY_DOWNLOAD_TYPE_ENUM.ISO) {
            delete state.ISOs[accessionNumber]
          }
        }
      )
      .addCase(
        initiateDownloadOrderListCSVReport.pending,
        (
          state,
          {
            meta: {
              arg: { eventId },
            },
          }
        ) => {
          state.orderListCSVReport[eventId] = {
            downloadProgress: PROGRESS_INITIAL_VALUE,
          }
        }
      )
      .addCase(
        initiateDownloadOrderListCSVReport.rejected,
        (
          state,
          {
            meta: {
              arg: { eventId },
            },
          }
        ) => {
          delete state.orderListCSVReport[eventId]
        }
      )
      .addCase(downloadOrderReport.pending, (state) => {
        state.orderPDFReport.downloadProgress = PROGRESS_INITIAL_VALUE
        state.orderPDFReport.isDownloading = true
      })
      .addCase(
        downloadLatestVersionOrderLegalCopyReport.pending,
        (state, { meta: { arg: accessionNumber } }) => {
          state.latestVersionOrderLegalCopyReport[accessionNumber] = {
            downloadProgress: PROGRESS_INITIAL_VALUE,
          }
        }
      )
      .addCase(
        downloadLatestVersionOrderLegalCopyReport.rejected,
        (state, { meta: { arg: accessionNumber } }) => {
          delete state.latestVersionOrderLegalCopyReport[accessionNumber]
        }
      )
      .addCase(
        downloadLatestVersionOrderLegalCopyReport.fulfilled,
        (state, { meta: { arg: accessionNumber } }) => {
          if (state.latestVersionOrderLegalCopyReport[accessionNumber]) {
            state.latestVersionOrderLegalCopyReport[
              accessionNumber
            ].downloadProgress = PROGRESS_COMPLETE_VALUE
          }
        }
      )
      .addMatcher(
        isAnyOf(downloadOrderReport.fulfilled, downloadOrderReport.rejected),
        ({ orderPDFReport }) => handleCompleteDownloadProgress(orderPDFReport)
      )
  },
})

export const {
  setDICOMDownloadingProgress,
  setOrderPDFReportDownloadProgress,
  setLatestVersionOrderLegalCopyReportDownloadProgress,
  setLegalCopiesDownloadProgressById,
  setISODownloadProgress,
  setCSVReportDownloadProgress,
  removeLegalCopiesDownloadProgressById,
  removeDICOMDownloading,
  removeISODownloading,
  removeCSVReportDownloading,
  removeLatestVersionOrderLegalCopyReportDownloading,
  setIsUploading,
  removeIsUploading,
  setIsDownloading,
  removeIsDownloading,
} = filesDownloadingSlice.actions

export default filesDownloadingSlice

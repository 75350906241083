import { Grid, styled, Typography } from '@mui/material'

export const StyledSubBlockContainer = styled(Grid)(
  ({ theme: { spacing } }) => ({
    width: 'auto',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginLeft: spacing(5),
    marginBottom: spacing(1),
  })
)

export const StyledFieldLink = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'hasClickHandler',
})<{ hasClickHandler: boolean }>(({ hasClickHandler }) => ({
  ...(hasClickHandler && {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  }),
}))

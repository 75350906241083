import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, generatePath, useLocation } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box } from '@mui/material'
import { routes } from 'routes/routes'
import { LinkWithBreadcrumbs } from 'components/BreadcrumbsProvider'
import {
  OPEN_FROM_REPORT_QA,
  SEARCH_PARAM_OPEN_FROM,
} from 'pages/orders/constants'
import { TOrder } from 'store/ordersList/types'

interface IViewCellProps {
  data: TOrder
}

export const OrderViewCell: FC<IViewCellProps> = ({
  data: { accessionNumber, isReportInView },
}) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const isReportQaListPage = pathname === routes.orders.reportQa.path

  let href = generatePath(routes.orders.orderPreview.path, { accessionNumber })

  if (isReportQaListPage) {
    const searchParams = createSearchParams({
      [SEARCH_PARAM_OPEN_FROM]: OPEN_FROM_REPORT_QA,
    }).toString()
    href = `${href}?${searchParams}`
  }

  return (
    <Box display='flex' justifyContent='flex-end'>
      <LinkWithBreadcrumbs disabled={isReportInView} href={href}>
        {t('components.labels.view')} <ChevronRightIcon />
      </LinkWithBreadcrumbs>
    </Box>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchSharedStudies } from 'store/sharedStudies/controllers'
import { LOADING_STATE, TPageable } from 'types/general'

import {
  SHARED_STUDIES_INITIAL_STATE,
  SHARED_STUDIES_SLICE_NAME,
} from './constants'
import { getShredStudiesByAccessionNumberRq } from './controllers'

const sharedStudiesSlice = createSlice({
  name: SHARED_STUDIES_SLICE_NAME,
  initialState: SHARED_STUDIES_INITIAL_STATE,
  reducers: {
    changePageableData: (
      state,
      { payload }: PayloadAction<Partial<TPageable>>
    ) => {
      state.list.pageable = {
        ...state.list.pageable,
        ...payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSharedStudies.pending, (state) => {
        state.list.loadingState = LOADING_STATE.pending
      })
      .addCase(fetchSharedStudies.rejected, (state) => {
        state.list.loadingState = LOADING_STATE.rejected
      })
      .addCase(fetchSharedStudies.fulfilled, (state, { payload }) => {
        state.list = {
          ...state.list,
          loadingState: LOADING_STATE.fulfilled,
          content: payload.content,
          totalPages: payload.totalPages,
          totalElements: payload.totalElements,
          first: payload.first,
          last: payload.last,
        }
      })
      .addCase(getShredStudiesByAccessionNumberRq.pending, (state) => {
        state.orderSharedStudies.loadingState = LOADING_STATE.pending
      })
      .addCase(getShredStudiesByAccessionNumberRq.rejected, (state) => {
        state.orderSharedStudies.loadingState = LOADING_STATE.rejected
      })
      .addCase(
        getShredStudiesByAccessionNumberRq.fulfilled,
        (state, { payload }) => {
          state.orderSharedStudies = {
            ...payload,
            loadingState: LOADING_STATE.fulfilled,
          }
        }
      )
  },
})

export const { changePageableData } = sharedStudiesSlice.actions

export default sharedStudiesSlice

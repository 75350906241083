import { NOT_APPLICABLE_ABBREVIATION } from 'constants/tables'
import _get from 'lodash/get'
import _pick from 'lodash/pick'

import {
  TMultiAssignmentRadiologist,
  TSingleAssignmentRadiologist,
} from '../types'
import { getRadiologistForTable } from './getRadiologistForTable'

export const mapSingleOrderAssignment = (
  radiologistsList: TMultiAssignmentRadiologist[]
): TSingleAssignmentRadiologist[] => {
  return radiologistsList.map((radiologist) => {
    const firstOrder = _get(radiologist, 'orderData[0]', {
      prefPanel: NOT_APPLICABLE_ABBREVIATION,
      isPriviledge: false,
      isLicenseMatched: false,
      isSubspecialtyMatched: false,
      rvuLeft: {},
      rvuTarget: '',
    })

    return {
      ...getRadiologistForTable(radiologist),
      ..._pick(firstOrder, [
        'unread',
        'prefPanel',
        'isPriviledge',
        'isLicenseMatched',
        'isSubspecialtyMatched',
        'rvuLeft',
        'rvuTarget',
      ]),
    }
  })
}

import { IState } from './types'

export const initialState: IState = {
  destinationsTableData: {
    queryData: {
      page: 0,
      size: 10,
      search: '',
      sort: '',
    },
  },
  destinationGroupsTableData: {
    queryData: {
      page: 0,
      size: 10,
      search: '',
      sort: '',
    },
  },
}

export const DESTINATIONS_FILTERS_SLICE_NAME = 'destinationsFilters'

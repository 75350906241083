import axios, { AxiosProgressEvent } from 'axios'
import { configs } from 'constants/configs'
import { S3_FILE_GROUPS } from 'constants/files/files'

import request, { request as makeRequest } from '../request'
import {
  IMultipleUploadLinksRequest,
  IMultipleUploadLinksResponse,
  TGetFileLinkResponse,
  TUploadResponse,
} from './types'

const baseURL = configs.API_ENDPOINT

const unAuthorizedAxiosInstance = makeRequest(
  axios.create({
    baseURL,
  })
)

export class Files {
  async getAwsLinkForFile(
    fileName: string,
    fileType: S3_FILE_GROUPS,
    abortSignal?: AbortSignal
  ) {
    return await request<TUploadResponse>({
      url: 'files/presigned/upload',
      method: 'POST',
      data: { fileName, fileType },
      ...(abortSignal ? { signal: abortSignal } : {}),
    })
  }

  async uploadFileToAws<TFile = File>(
    url: string,
    { file, contentType }: { file: TFile; contentType: string },
    abortSignal?: AbortSignal
  ) {
    return await unAuthorizedAxiosInstance<void>({
      url,
      data: file,
      method: 'PUT',
      ...(abortSignal && { signal: abortSignal }),
      headers: {
        'content-type': contentType,
        Authorization: undefined,
      },
    })
  }

  async getFileUrl(
    objectKey: string,
    fileType: S3_FILE_GROUPS,
    sectionName?: string,
    accessionNumber?: string
  ) {
    return await request<TGetFileLinkResponse>({
      url: '/files/presigned/download',
      method: 'GET',
      params: {
        objectKey,
        fileType,
        sectionName,
        ...(accessionNumber && {
          accessionNumbers: Number(accessionNumber),
        }),
      },
    })
  }

  async getMultipleUploadLinks(data: IMultipleUploadLinksRequest) {
    return await request<IMultipleUploadLinksResponse>({
      url: '/files/presigned/multiple-upload',
      data,
      method: 'POST',
    })
  }

  async abortFileUploading(uploadId: string, objectKey: string): Promise<void> {
    await request<void>({
      url: '/files/multipart-upload-abort',
      data: { uploadId, objectKey },
      method: 'POST',
    })
  }

  async downloadBlobFile(
    fileLink: string,
    {
      onDownloadProgress,
    }: { onDownloadProgress?: (download: AxiosProgressEvent) => void } = {}
  ) {
    return await unAuthorizedAxiosInstance<Blob>({
      url: fileLink,
      method: 'GET',
      onDownloadProgress,
      responseType: 'blob',
      headers: {
        Authorization: undefined,
      },
    })
  }
}

export const files = new Files()

import { Paper, styled } from '@mui/material'

export const StyledPaperContainer = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isPanelOpen',
})<{ isPanelOpen: boolean }>(({ isPanelOpen, theme: { spacing } }) => ({
  padding: spacing(2),
  bottom: 0,
  position: 'absolute',
  height: isPanelOpen ? '100%' : '138px',
}))

import { createAsyncThunk } from '@reduxjs/toolkit'
import { PRACTICE_SLICE_NAME } from 'store/practice/constants'
import {
  IPracticeTableResponse,
  TBffPracticeResponse,
  TPracticeCreation,
  TPracticeResponse,
  TPracticesAllResponse,
  TPracticesFilters,
  TPracticesParams,
} from 'store/practice/types'
import { TPhysician, TRadiologist } from 'store/providers/types'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'
import { arrayFilterUndefinedCallback } from 'utils/helpers/filters/arrayFilterUndefinedCallback'

export const fetchPractices = createAsyncThunk<
  IPracticeTableResponse,
  { query: TPracticesParams; abortSignal?: AbortSignal },
  {
    rejectValue: TServiceError
  }
>(
  `${PRACTICE_SLICE_NAME}/fetchPractices`,
  async ({ query, abortSignal }, { rejectWithValue }) => {
    const result = await request<IPracticeTableResponse>({
      url: '/bff/org/practices',
      method: 'GET',
      params: {
        ...query,
        countries: query.countries?.length ? query.countries.join() : undefined,
        states: query.states?.length ? query.states.join() : undefined,
      },
      signal: abortSignal,
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const fetchPractice = createAsyncThunk<
  TBffPracticeResponse,
  number,
  {
    rejectValue: TServiceError
  }
>(
  `${PRACTICE_SLICE_NAME}/fetchPractice`,
  async (practiceId, { rejectWithValue }) => {
    const result = await request<TBffPracticeResponse>({
      url: `/bff/org/practices/${practiceId}`,
      method: 'GET',
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return result.data
  }
)

export const bindPhysicianToPractice = createAsyncThunk<
  void,
  {
    practiceId: number
    selectedProviders: Array<TPhysician | TRadiologist>
  },
  {
    rejectValue: TServiceError
  }
>(
  `${PRACTICE_SLICE_NAME}/bindPhysicianToPractice`,
  async ({ practiceId, selectedProviders }, { rejectWithValue }) => {
    const result = await request<void>({
      url: '/providers/physicians/practices',
      method: 'POST',
      data: {
        providerIds: selectedProviders
          .map(({ providerId }) => providerId)
          .filter(arrayFilterUndefinedCallback),
        practiceId,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const createPractice = createAsyncThunk<
  TPracticeResponse,
  TPracticeCreation,
  {
    rejectValue: TServiceError
  }
>(
  `${PRACTICE_SLICE_NAME}/createPractice`,
  async (data, { rejectWithValue }) => {
    const result = await request<TPracticeResponse>({
      url: '/org/practices',
      data,
      method: 'POST',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const updatePractice = createAsyncThunk<
  TPracticeResponse,
  TPracticeCreation & { practiceId: number },
  {
    rejectValue: TServiceError
  }
>(
  `${PRACTICE_SLICE_NAME}/updatePractice`,
  async ({ practiceId, name, ...payload }, { rejectWithValue }) => {
    const result = await request<TPracticeResponse>({
      url: `/org/practices/${practiceId}/info`,
      data: {
        ...payload,
        practiceName: name,
      },
      method: 'PUT',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const fetchPracticesFiltersOptions = createAsyncThunk<TPracticesFilters>(
  `${PRACTICE_SLICE_NAME}/fetchPracticesFiltersOptions`,
  async (_, { rejectWithValue }) => {
    const result = await request<TPracticesFilters>({
      url: '/org/practices/locations',
      method: 'GET',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const fetchAllPractices = createAsyncThunk<TPracticesAllResponse>(
  `${PRACTICE_SLICE_NAME}/fetchAllPractices`,
  async (_, { rejectWithValue }) => {
    const result = await request<TPracticesAllResponse>({
      url: '/bff/org/practices/all',
      method: 'GET',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

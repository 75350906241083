export enum FLAG_STATUS_TYPE {
  TODO = 'TODO',
  PREVIOUS = 'PREVIOUS',
}

export enum FLAG_SEVERITY_TYPE {
  HARD_STOP = 'HARD_STOP',
  REQUIRED = 'REQUIRED',
  INFO = 'INFO',
}

export enum ALERT_COLOR {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}

import { TPatientResponse } from 'store/patient/types'
import { TServiceError } from 'utils/api/types'
import { LOADING_STATE, TQueryData } from 'types/general'
import { components } from 'types/schemas'

export type TBffSupportItem = Required<
  components['schemas']['BffSupportRequestGridRs']
>
export type TBffSupportRequestListResponse = Omit<
  components['schemas']['BffSupportRequestPageRs'],
  'content'
> & { content: TBffSupportItem[] }
type TBffSupportRequestListFilterRequest =
  components['schemas']['BffSupportRequestFilterRq']
export type TBffSupportRequestListRequestParams =
  TBffSupportRequestListFilterRequest & Partial<TQueryData>

export type TBffSupportRequestRs = Omit<
  components['schemas']['BffSupportRequestRs'],
  'patient'
> & {
  patient?: TPatientResponse
}

export type TSupportRequestType = components['schemas']['SupportRequestType']

type TInsurancePlan = Omit<
  components['schemas']['InsurancePlan'],
  'state' | 'payerId'
> & {
  state?: string | null
  payerId?: string | null
}

export type TInsurancePlanCreationRq = Omit<
  components['schemas']['SupportRequestAutoCreationRq'],
  'insurancePlan'
> & {
  insurancePlan: TInsurancePlan
}

export type TSupportRequestCreationRs =
  components['schemas']['SupportRequestCreationRs']
export type TSupportRequestCreationRq = Omit<
  components['schemas']['SupportRequestCreationRq'],
  'targetGroupId' | 'supportRequestType'
> & {
  targetGroupId?: number | null
  supportRequestType?: TSupportRequestType | null
}

export type TCreatedByUser = components['schemas']['UserDisplayNameRs']

export type TBffCommentPageRs = components['schemas']['BffCommentPageRs']
export type TBffCommentGridRs = components['schemas']['BffCommentGridRs']
export type TCommentCreationRq = components['schemas']['CommentCreationRq']
export type TCommentCreationRs = components['schemas']['CommentCreationRs']

export enum SUPPORT_REQUEST_TYPES {
  INTERNAL = 'INTERNAL',
  PROSCAN = 'PROSCAN',
  CUSTOMER = 'CUSTOMER',
  AUTOMATIC = 'AUTOMATIC',
}

export type TSupportRequestEntityType = {
  id: SUPPORT_REQUEST_TYPES
  name: string
}

export enum COMMENT_TYPE {
  PUBLIC = 'PUBLIC',
  INTERNAL = 'INTERNAL',
}

export type TCommentType = components['schemas']['CommentType']

export enum SUPPORT_REQUEST_STATUS {
  Open = 'OPEN',
  Resolved = 'RESOLVED',
}

export interface IState {
  table: {
    content: Array<TBffSupportItem>
    isFirstPage: boolean
    isLastPage: boolean
    totalElements: number
    isFilterPanelOpen: boolean
    loadingState: LOADING_STATE
  }
  entity: {
    data: TBffSupportRequestRs | null
    loadingState: LOADING_STATE
    isTargetGroupUpdating: boolean
    isTypeUpdating: boolean
    isStatusUpdating: boolean
  }
  comments: {
    content: Array<TBffCommentGridRs>
    isFirstPage: boolean
    isLastPage: boolean
    totalElements: number
    queryData: Omit<TQueryData, 'sort' | 'page'> & { page?: number }
    loadingState: LOADING_STATE
    error?: TServiceError | null
  }
}

export enum SUPPORT_REQUEST_EVENT_TYPES {
  AUTO_HL7_ORDER_CANCEL = 'AUTO_HL7_ORDER_CANCEL',
  AUTO_INSURANCE_PLAN_CREATE = 'AUTO_INSURANCE_PLAN_CREATE',
  AUTO_HL7_BILLING_DISCREPANCY = 'AUTO_HL7_BILLING_DISCREPANCY',
  MANUAL_CREATE = 'MANUAL_CREATE',
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { TAG_PAGE, TAG_SIZE } from 'store/tags/constants'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'
import { prepareArrayForSending } from 'utils/helpers/arrays/prepareArrayForSending'
import { TPageable } from 'types/general'

import {
  TAG_STATUS,
  TAG_TYPE,
  TTagCreationRq,
  TTagCreationRs,
  TTagRs,
  TTagsFilter,
  TTagsPageRs,
  TTagUpdateRq,
} from './types'

export const createTag = createAsyncThunk<
  TTagCreationRs,
  TTagCreationRq,
  {
    rejectValue: TServiceError
  }
>('tags/createTag', async (data, { rejectWithValue }) => {
  const result = await request<TTagCreationRs>({
    method: 'POST',
    url: '/orders/tags',
    data,
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const updateTag = createAsyncThunk<
  unknown,
  TTagUpdateRq & { id: number },
  {
    rejectValue: TServiceError
  }
>('tags/createTag', async ({ id, ...data }, { rejectWithValue }) => {
  const result = await request<unknown>({
    method: 'PUT',
    url: `/bff/orders/tags/${id}`,
    data,
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const fetchTags = createAsyncThunk<
  TTagsPageRs,
  { pageable: TPageable; filter?: TTagsFilter },
  {
    rejectValue: TServiceError
  }
>('tags/fetchTags', async ({ pageable, filter }, { rejectWithValue }) => {
  const params = {
    ...pageable,
    search: filter?.search,
    grids: filter?.grids,
    statuses: prepareArrayForSending(filter?.statuses ?? []),
    isPaged: true,
  }
  const result = await request<TTagsPageRs>({
    method: 'GET',
    url: '/bff/orders/tags',
    params,
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const fetchExistingTagsName = createAsyncThunk<
  TTagRs[],
  { status: TAG_STATUS[]; type: TAG_TYPE; search: string; signal: AbortSignal },
  {
    rejectValue: TServiceError
  }
>(
  'tags/fetchExistingTagsName',
  async ({ status, type, search, signal }, { rejectWithValue }) => {
    const params = {
      statuses: prepareArrayForSending(status),
      type,
      isPaged: true,
      search,
      page: TAG_PAGE,
      size: TAG_SIZE,
    }
    const result = await request<TTagsPageRs>({
      url: '/bff/orders/tags',
      method: 'GET',
      params,
      signal,
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data.content
  }
)

export const fetchTagArchive = createAsyncThunk<
  unknown,
  { status: TAG_STATUS; id: string | number },
  {
    rejectValue: TServiceError
  }
>('tags/archiveTag', async (payload, { rejectWithValue }) => {
  const result = await request<unknown>({
    url: `/orders/tags/${payload.id}`,
    data: { status: payload.status },
    method: 'PATCH',
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

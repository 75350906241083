import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATE } from 'types/general'

import { defaultOrderListState, initialState, SLICE_NAME } from './constants'
import * as controllers from './controllers'

const patientSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetPatientInfo: (state) => {
      state.patientInfo = initialState.patientInfo
    },
    resetPatientCompareInfo: (state) => {
      state.compareInfo = initialState.compareInfo
    },
    setTablePageable: (state, { payload }) => {
      if (state.compareInfo.ordersList?.[payload.patientId]) {
        state.compareInfo.ordersList[payload.patientId] = {
          ...state.compareInfo.ordersList[payload.patientId],
          pageable: {
            ...state.compareInfo.ordersList[payload.patientId]?.pageable,
            ...payload.pageable,
          },
        }
      }
    },
    switchCompareRecords: (state) => {
      const targetCompareInfo = state.compareInfo.info?.targetInfo
      state.compareInfo.info = {
        targetInfo: state.compareInfo.info?.sourceInfo,
        sourceInfo: targetCompareInfo,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(controllers.getPatientById.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.getPatientById.fulfilled, (state, action) => {
        state.loadingState = LOADING_STATE.fulfilled
        state.patientInfo = action.payload
      })
      .addCase(controllers.getPatientById.rejected, (state, action) => {
        state.loadingState = LOADING_STATE.rejected
        state.patientInfo = initialState.patientInfo
        state.error = action.payload
      })
      .addCase(controllers.fetchDuplicateCompareInfo.pending, (state) => {
        state.compareInfo.loadingState = LOADING_STATE.pending
      })
      .addCase(
        controllers.fetchDuplicateCompareInfo.fulfilled,
        (state, action) => {
          const {
            arg: { targetResourceId, sourceResourceId },
          } = action.meta
          state.compareInfo.loadingState = LOADING_STATE.fulfilled
          state.compareInfo.info = action.payload
          state.compareInfo.ordersList = {
            [targetResourceId]: defaultOrderListState,
            [sourceResourceId]: defaultOrderListState,
          }
        }
      )
      .addCase(
        controllers.fetchDuplicateCompareInfo.rejected,
        (state, action) => {
          state.compareInfo.loadingState = LOADING_STATE.rejected
          state.compareInfo.info = initialState.compareInfo?.info
          state.error = action.payload
        }
      )
      .addCase(
        controllers.fetchAssociatedOrders.pending,
        (
          state,
          {
            meta: {
              arg: { patientId },
            },
          }
        ) => {
          state.compareInfo.ordersList = {
            ...state.compareInfo.ordersList,
            [patientId]: {
              ...state.compareInfo.ordersList?.[patientId],
              loadingState: LOADING_STATE.pending,
            },
          }
        }
      )
      .addCase(
        controllers.fetchAssociatedOrders.fulfilled,
        (
          state,
          {
            meta: {
              arg: { patientId },
            },
            payload,
          }
        ) => {
          if (state.compareInfo.ordersList?.[patientId]) {
            state.compareInfo.ordersList[patientId] = {
              ...state.compareInfo.ordersList[patientId],
              data: payload,
              loadingState: LOADING_STATE.fulfilled,
            }
          } else {
            state.compareInfo.ordersList = {
              ...state.compareInfo.ordersList,
              [patientId]: {
                data: payload,
                error: null,
                loadingState: LOADING_STATE.fulfilled,
                pageable: defaultOrderListState.pageable,
              },
            }
          }
        }
      )
      .addCase(
        controllers.fetchAssociatedOrders.rejected,
        (
          state,
          {
            meta: {
              arg: { patientId },
            },
            payload,
          }
        ) => {
          state.compareInfo.ordersList = {
            [patientId]: {
              ...state.compareInfo.ordersList?.[patientId],
              data: undefined,
              error: payload,
              loadingState: LOADING_STATE.rejected,
            },
          }
        }
      )
  },
})

export const {
  resetPatientInfo,
  resetPatientCompareInfo,
  setTablePageable,
  switchCompareRecords,
} = patientSlice.actions

export default patientSlice

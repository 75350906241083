export const SharedStudies = {
  // SharedStudyPreview
  primaryStudyPreview: 'Primary study RIS-Order {{accessionNumber}}',
  sharedRisOrders: 'Shared RIS-Orders',
  risOrder: 'RIS-Order {{accessionNumber}}',
  timeLeft_one: '{{count}} day left',
  timeLeft_other: '{{count}} days left',
  sharedStudyCellTooltip:
    'Available until {{expiredAt}}. After this primary studies for RIS-Order {{accessionNumber}} will be removed from the list',
  imagesCurrentlyNotAvailable: 'Images are not currently available',
  pdfExportButtonTooltip: 'Report is not currently available',
  siteName: 'Site name',
  expiration: 'Expiration',
}

import { useCallback } from 'react'
import { S3_FILE_GROUPS } from 'constants/files/files'
import { files } from 'utils/api/files/Files'

export const useGetLinkForDownload = () => {
  const getLinkForDownloadRequest = useCallback(
    async (
      objectKey: string,
      fileType: S3_FILE_GROUPS,
      sectionName?: string,
      accessionNumber?: string
    ) => {
      // TODO: Error handling
      const response = await files.getFileUrl(
        objectKey,
        fileType,
        sectionName,
        accessionNumber
      )

      return response.data
    },
    []
  )

  return { getLinkForDownloadRequest }
}

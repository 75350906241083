import { PropsWithChildren } from 'react'
import { Navigate } from 'react-router-dom'
import { USER_ROLES } from 'constants/userRoles'
import { routes } from 'routes/routes'
import { getRolePriority } from 'utils/helpers/getRolePriority'
import { useRoles } from 'utils/hooks/useRoles'
import { TPath } from 'types/general'

type TProtectedBasicComponentProps = {
  allowedRoles: USER_ROLES[]
}

type TProtectedConditionalComponentProps =
  | {
      redirectTo?: TPath
      mapRoleToRoute?: never
    }
  | {
      redirectTo?: never
      mapRoleToRoute: Partial<Record<USER_ROLES, TPath>>
    }

type TProtectedComponentProps = TProtectedBasicComponentProps &
  TProtectedConditionalComponentProps

export const ProtectedComponent = ({
  allowedRoles,
  children,
  redirectTo,
  mapRoleToRoute,
}: PropsWithChildren<TProtectedComponentProps>) => {
  const { checkUserRoles, roles } = useRoles()

  let redirectRoute: TPath | undefined
  if (mapRoleToRoute && roles) {
    const priorityRole = getRolePriority(roles)
    redirectRoute = priorityRole
      ? mapRoleToRoute[priorityRole]
      : routes['403'].path
  }

  if (redirectTo) {
    redirectRoute = redirectTo
  }

  const isAllowedToView = checkUserRoles(allowedRoles)

  const notAllowedComponent = redirectRoute ? (
    <Navigate to={redirectRoute} replace />
  ) : null

  return isAllowedToView ? <>{children}</> : notAllowedComponent
}

import { Box, styled } from '@mui/material'

export const StyledContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  padding: spacing(3),
}))

export const StyledContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '371px',
  alignItems: 'center',
})

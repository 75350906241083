import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from 'services/axiosBaseQuery'
import { PATIENTS_TAG } from 'services/patients/constants'
import { TPatientRequest, TPatientResponse } from 'store/patient/types'

export const patientsApi = createApi({
  reducerPath: 'patientsApi',
  tagTypes: [PATIENTS_TAG],
  baseQuery: axiosBaseQuery({ baseUrl: '/patients' }),
  endpoints: (builder) => ({
    updatePatient: builder.mutation<
      TPatientResponse,
      { id: string; data: TPatientRequest }
    >({
      query: ({ id, data }) => ({
        method: 'PUT',
        url: id,
        body: data,
      }),
    }),
  }),
})

export const { useUpdatePatientMutation } = patientsApi

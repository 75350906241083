import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LOADING_STATE, TQueryData } from 'types/general'

import { AUDIT_SLICE_NAME, initialState } from './constants'
import { fetchOrderAudit, fetchPatientAudit } from './controllers'
import { AUDIT_SLICE_KEY, IAuditFiltersData } from './types'

const auditSlice = createSlice({
  name: AUDIT_SLICE_NAME,
  initialState,
  reducers: {
    resetOrderAuditData: ({ order }) => {
      order.tableData = initialState.order.tableData
    },
    // Order audit table
    changeAuditQueryData: (
      state,
      {
        payload,
      }: PayloadAction<Partial<TQueryData> & { sliceName: AUDIT_SLICE_KEY }>
    ) => {
      const { sliceName, ...payloadData } = payload
      state[sliceName].queryData = {
        ...state[sliceName].queryData,
        ...payloadData,
      }
    },
    toggleOpenAuditTableFilterPanel: (
      state,
      { payload }: PayloadAction<AUDIT_SLICE_KEY>
    ) => {
      state[payload].isFilterPanelOpen = !state[payload].isFilterPanelOpen
    },
    changeAuditFilterData: (
      state,
      {
        payload,
      }: PayloadAction<{
        data: Partial<IAuditFiltersData>
        sliceName: AUDIT_SLICE_KEY
      }>
    ) => {
      const { sliceName, data } = payload
      state[sliceName].filtersData = {
        ...state[sliceName].filtersData,
        ...data,
      }
      state[sliceName].queryData.page = 0
    },
    clearAuditFiltersData: (
      state,
      { payload }: PayloadAction<AUDIT_SLICE_KEY>
    ) => {
      state[payload].filtersData = initialState[payload].filtersData
      state[payload].queryData.page = 0
    },
    removeAuditTriggerFilterById: (
      state,
      {
        payload: { id, sliceName },
      }: PayloadAction<{ id: string; sliceName: AUDIT_SLICE_KEY }>
    ) => {
      state[sliceName].filtersData = {
        ...state[sliceName].filtersData,
        triggers: state[sliceName].filtersData.triggers.filter(
          ({ trigger }) => trigger !== id
        ),
      }
      state[sliceName].queryData.page = 0
    },
    removeAuditEventFilter: (
      state,
      {
        payload: { eventToRemove, sliceName },
      }: PayloadAction<{
        eventToRemove: string
        sliceName: AUDIT_SLICE_KEY
      }>
    ) => {
      state[sliceName].filtersData = {
        ...state[sliceName].filtersData,
        events: state[sliceName].filtersData.events.filter(
          (option) => option.event !== eventToRemove
        ),
      }
      state[sliceName].queryData.page = 0
    },
    removeAuditCategoryFilter: (
      state,
      {
        payload: { categoryToRemove, sliceName },
      }: PayloadAction<{
        sliceName: AUDIT_SLICE_KEY
        categoryToRemove: string
      }>
    ) => {
      state[sliceName].filtersData = {
        ...state[sliceName].filtersData,
        categories: state[sliceName].filtersData.categories.filter(
          (option) => option.category !== categoryToRemove
        ),
      }
      state[sliceName].queryData.page = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderAudit.pending, ({ order }) => {
        order.loadingState = LOADING_STATE.pending
      })
      .addCase(fetchOrderAudit.rejected, ({ order }) => {
        order.loadingState = LOADING_STATE.rejected
      })
      .addCase(
        fetchOrderAudit.fulfilled,
        (
          { order },
          { payload: { content, totalElements, totalPages, first, last } }
        ) => {
          order.tableData = {
            content,
            totalElements,
            totalPages,
            isFirstPage: Boolean(first),
            isLastPage: Boolean(last),
          }
          order.loadingState = LOADING_STATE.fulfilled
        }
      )
      .addCase(fetchPatientAudit.pending, ({ patient }) => {
        patient.loadingState = LOADING_STATE.pending
      })
      .addCase(fetchPatientAudit.rejected, ({ patient }) => {
        patient.loadingState = LOADING_STATE.rejected
      })
      .addCase(
        fetchPatientAudit.fulfilled,
        (
          { patient },
          { payload: { content, totalElements, totalPages, first, last } }
        ) => {
          patient.tableData = {
            content,
            totalElements,
            totalPages,
            isFirstPage: Boolean(first),
            isLastPage: Boolean(last),
          }
          patient.loadingState = LOADING_STATE.fulfilled
        }
      )
  },
})

export const {
  changeAuditQueryData,
  toggleOpenAuditTableFilterPanel,
  changeAuditFilterData,
  clearAuditFiltersData,
  removeAuditTriggerFilterById,
  removeAuditCategoryFilter,
  removeAuditEventFilter,
  resetOrderAuditData,
} = auditSlice.actions
export default auditSlice

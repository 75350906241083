import { IState } from 'store/validationProgress/types'

export const VALIDATION_PROGRESS_INITIAL_STATE: IState = {
  configs: [],
  readinessPercentage: 0,
  inputToFocus: '',
  IsValidationProgressBarDisplay: false,
  isValidToSubmit: false,
}

export const VALIDATION_PROGRESS_SLICE_NAME = 'validationProgress'

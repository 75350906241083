import { PaletteMode } from '@mui/material'
import { configs } from 'constants/configs'
import { Valuable } from 'utils/types/typeUtils'
import { COLOR_MODE } from 'types/theme'

export const getIsDarkMode = (currentMode?: PaletteMode) =>
  currentMode ? currentMode === COLOR_MODE.Dark : false

export function getValuable<T extends object, V = Valuable<T>>(
  obj?: T | null
): V | null {
  if (obj) {
    return recursivelyFilterEmpty(obj)
  }
  return null
}

function recursivelyFilterEmpty<T extends object, V = Valuable<T>>(obj: T): V {
  const filtered: Partial<T> = {}

  for (const [key, value] of Object.entries(obj)) {
    if (
      value !== null &&
      value !== undefined &&
      !(typeof value === 'string' && value.length === 0) &&
      !(Array.isArray(value) && value.length === 0)
    ) {
      if (
        typeof value === 'object' &&
        !Array.isArray(value) &&
        !(value instanceof Date)
      ) {
        const nestedValue = recursivelyFilterEmpty(value)
        if (Object.keys(nestedValue).length > 0) {
          filtered[key as keyof T] = nestedValue as any
        }
      } else {
        filtered[key as keyof T] = value
      }
    }
  }

  return filtered as V
}

export const getIsBoolean = (variable: unknown): variable is boolean => {
  return typeof variable === 'boolean'
}

export const getIsUndefined = <T>(value?: T): boolean => value === undefined

export const getIsDevEnvironment = () => configs.ENVIRONMENT === 'develop'

export const getIsTestEnvironment = () => configs.ENVIRONMENT === 'test'

export const getIsUatEnvironment = () => configs.ENVIRONMENT === 'uat'

export const getIsProdEnvironment = () => configs.ENVIRONMENT === 'prod'

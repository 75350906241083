import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from 'services/axiosBaseQuery'
import { PRESIGNED_DOWNLOAD_TAG } from 'services/bff/constants'
import { prepareArrayForSending } from 'utils/helpers/arrays/prepareArrayForSending'

import {
  TFileType,
  TObjectKey,
  TPaperworkDownloadRq,
  TPresignedDownloadUrlRs,
} from './types'

export const filesApi = createApi({
  reducerPath: 'filesApi',
  tagTypes: [PRESIGNED_DOWNLOAD_TAG],
  baseQuery: axiosBaseQuery({ baseUrl: '/files' }),
  endpoints: (builder) => ({
    getPresignedDownload: builder.query<
      TPresignedDownloadUrlRs,
      {
        objectKey: TObjectKey
        fileType: TFileType
        accessionNumbers?: string[]
      }
    >({
      query: ({ objectKey, fileType, accessionNumbers }) => {
        return {
          url: 'presigned/download',
          params: {
            objectKey,
            fileType,
            accessionNumbers: prepareArrayForSending(accessionNumbers),
          },
        }
      },
      providesTags: (_result, _error, id) => [
        { type: PRESIGNED_DOWNLOAD_TAG, id: id.objectKey },
      ],
    }),
    triggerPaperworkDownloadAudit: builder.mutation<
      unknown,
      TPaperworkDownloadRq
    >({
      query: (payload) => {
        return {
          url: 'audit/paperwork/download',
          method: 'POST',
          body: payload,
        }
      },
    }),
  }),
})

export const {
  useGetPresignedDownloadQuery,
  useTriggerPaperworkDownloadAuditMutation,
} = filesApi

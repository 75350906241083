import { IState } from './types'

export const initialState: IState = {
  pageable: {
    size: 10,
    page: 0,
    sort: '',
  },
  filter: {
    countries: [],
    states: [],
    search: '',
    siteIds: [],
  },
}

export const COMPANY_FILTERS_SLICE_NAME = 'companyFilters'

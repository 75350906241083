import { setExtensionToLowerCase } from './setExtensionToLowerCase'

export const downloadBlobFile = (blobFile: Blob, fileName: string) => {
  const blobUrl = window.URL.createObjectURL(blobFile)
  const a = document.createElement('a')

  a.download = setExtensionToLowerCase(fileName)
  a.href = blobUrl
  document.body.appendChild(a)
  a.click()
  a.remove()

  window.URL.revokeObjectURL(blobUrl)
}

import { FC, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Badge, Typography } from '@mui/material'
import clsx from 'clsx'
import { PSButton } from 'components/ui-components/PSButton'
import { getIsDarkMode } from 'utils/helpers/general'
import { useColorMode } from 'utils/hooks/useColorMode'

import { StyledBadge } from './SidebarItem.styled'

import styles from './styles.module.css'

type SidebarItemProps = {
  icon: JSX.Element
  text: string
  href: string
  isOpen: boolean
  badgeContent?: number
}

export const SidebarItem: FC<SidebarItemProps> = ({
  icon,
  text,
  href,
  isOpen,
  badgeContent,
}) => {
  const { mode } = useColorMode()
  const location = useLocation()

  const isActive = useMemo(
    () =>
      location.pathname === href || location.pathname.startsWith(href + '/'),
    [location, href]
  )

  const classes = useMemo(() => {
    return clsx(
      styles.item,
      isActive && styles.itemActive,
      getIsDarkMode(mode) && styles.itemDark,
      isOpen ? styles.noMinimizedMenuItem : styles.minimizedMenuItem
    )
  }, [mode, isActive, isOpen])

  return (
    <PSButton href={href} className={classes} variant='text'>
      {badgeContent && !isOpen ? (
        <Badge color='error' badgeContent={badgeContent}>
          {icon}
        </Badge>
      ) : (
        icon
      )}
      <Typography
        className={clsx(
          styles.sidebarItemText,
          isOpen ? styles.noMinimizedMenuItem : styles.minimizedMenuItem
        )}
      >
        {text}
      </Typography>
      {badgeContent && isOpen && (
        <StyledBadge color='error' badgeContent={badgeContent} />
      )}
    </PSButton>
  )
}

import { IState, PRACTICE_CONFIGURABLE_COLUMNS } from './types'

export const initialState: IState = {
  queryData: {
    search: '',
    page: 0,
    size: 10,
    sort: '',
  },
  filtersData: {
    countries: [],
    states: [],
  },
  columnsConfiguration: {
    [PRACTICE_CONFIGURABLE_COLUMNS.PRACTICES_STREET_1]: false,
    [PRACTICE_CONFIGURABLE_COLUMNS.PRACTICES_STREET_2]: false,
    [PRACTICE_CONFIGURABLE_COLUMNS.PRACTICES_POSTAL_CODE]: false,
    [PRACTICE_CONFIGURABLE_COLUMNS.PRACTICES_COUNTRY]: false,
    [PRACTICE_CONFIGURABLE_COLUMNS.PRACTICES_NPI]: false,
  },
}

export const PRACTICE_FILTERS_SLICE_NAME = 'practiceFilters'

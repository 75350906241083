import { PS_COLORS } from 'constants/colors/PSColors'
import { URGENCY_ID_ENUM } from 'store/order/types'

export const URGENCY_COLOR_MAP = {
  [URGENCY_ID_ENUM.MedicalClearance]: {
    backgroundColor: '#DD2C00',
    textColor: '#FFFFFF',
    transformTitle: 'urgencies.medicalClearance',
  },
  [URGENCY_ID_ENUM.Stroke]: {
    backgroundColor: '#FF9E1B',
    textColor: 'rgba(0, 0, 0, 0.87)',
    transformTitle: null,
  },
  [URGENCY_ID_ENUM.ERVisit]: {
    backgroundColor: '#FFF380',
    textColor: 'rgba(0, 0, 0, 0.87)',
    transformTitle: null,
  },
  [URGENCY_ID_ENUM.WETPrelim]: {
    backgroundColor: '#44C3EB',
    textColor: '#FFFFFF',
    transformTitle: null,
  },
  [URGENCY_ID_ENUM.WETCall]: {
    backgroundColor: '#9999FF',
    textColor: '#FFFFFF',
    transformTitle: null,
  },
  [URGENCY_ID_ENUM.STAT]: {
    backgroundColor: '#FF9999',
    textColor: 'rgba(0, 0, 0, 0.87)',
    transformTitle: null,
  },
  [URGENCY_ID_ENUM.Normal]: {
    backgroundColor: PS_COLORS.grey[100],
    textColor: 'rgba(0, 0, 0, 0.87)',
    transformTitle: null,
  },
} as const

export type TUrgencyColorMapKeys = keyof typeof URGENCY_COLOR_MAP

import { FC } from 'react'
import { ExpandLess } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import { getOrderReadinessColor } from 'components/ProgressSideBar/helpers/getOrderReadinessColor'
import { PSPercentageProgress } from 'components/ui-components/PSPercentageProgress'
import { TEmptyFunction } from 'types/general'

interface IMinimizedSizeSideBarPreviewProps {
  onExpandClick: TEmptyFunction
  percentage: number
}

export const MinimizedSizeSideBarPreview: FC<
  IMinimizedSizeSideBarPreviewProps
> = ({ percentage, onExpandClick }) => {
  const color = getOrderReadinessColor(percentage)

  return (
    <Box width='100%' display='flex' flexDirection='column' alignItems='center'>
      <Box mb={1}>
        <IconButton onClick={onExpandClick} size='small'>
          {<ExpandLess />}
        </IconButton>
      </Box>
      <PSPercentageProgress percentage={percentage} color={color} />
    </Box>
  )
}

import { Badge, styled } from '@mui/material'

export const StyledBadge = styled(Badge)(({ theme: { spacing } }) => ({
  marginLeft: spacing(1),
  '& .MuiBadge-badge': {
    zIndex: 'unset',
    position: 'unset',
    transform: 'unset',
  },
}))

/* eslint-disable */
// eslint wants to use single quotes for strings that contain single-quotes
// but prettier forces double quotes
import { common } from './common'
import { components } from './components'
import { dictionaries } from './dictionaries'
import {
  Companies,
  Dictionaries,
  Patients,
  Practices,
  Providers,
  RISOrders,
  Support,
  Users,
  SharedStudies,
  Dashboard,
} from './domains'

export const en = {
  ...dictionaries,
  domains: {
    RISOrders,
    Users,
    Companies,
    Practices,
    Providers,
    Support,
    Dictionaries,
    Patients,
    SharedStudies,
    Dashboard,
  },
  components,
  common,
}

export default en

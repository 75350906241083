import { FC, useState } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { IValidationBlockConfig } from 'components/FormValidationStatusBlock'

import { FullSizeSideBarPreview } from './components/FullSizeSideBarPreview'
import { MinimizedSizeSideBarPreview } from './components/MinimizedSizeSideBarPreview'
import { StyledPaperContainer } from './ProgressSideBar.styled'

interface IProgressSideBarProps {
  onFieldClick: (fieldKey: string) => void
  setOpenSidePanel: (isOpen: boolean) => void
  isSideBarOpen: boolean
  readinessPercentage: number
  configs: IValidationBlockConfig[]
}

// UI-PREFIX - molecule
export const ProgressSideBar: FC<IProgressSideBarProps> = ({
  readinessPercentage,
  configs,
  setOpenSidePanel,
  onFieldClick,
  isSideBarOpen,
}) => {
  const theme = useTheme()
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const togglePanel = () => setIsPanelOpen((prevState) => !prevState)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleOpenMinimizedPanel = () => {
    setOpenSidePanel(true)
    togglePanel()
  }
  const handleToggleFullSizePanel = () => {
    isSmallScreen && setOpenSidePanel(false)
    togglePanel()
  }

  return (
    <StyledPaperContainer elevation={10} isPanelOpen={isPanelOpen}>
      {isSideBarOpen ? (
        <FullSizeSideBarPreview
          configs={configs}
          readinessPercent={readinessPercentage}
          isPanelOpen={isPanelOpen}
          onFieldClick={onFieldClick}
          onExpandClick={handleToggleFullSizePanel}
        />
      ) : (
        <MinimizedSizeSideBarPreview
          percentage={readinessPercentage}
          onExpandClick={handleOpenMinimizedPanel}
        />
      )}
    </StyledPaperContainer>
  )
}

import { useTranslation } from 'react-i18next'
import FlagIcon from '@mui/icons-material/Flag'
import { Tooltip } from '@mui/material'
import { mapSystemTagToIcon } from 'pages/orders/OrdersList/components/SystemTagFilter/constants'
import { TSystemTagColumnData } from 'store/filters/orders/types'

import { StyledTagContainer, StyledTagWrapper } from './TagsCell.styled'

interface ISystemTagCellProps {
  data?: TSystemTagColumnData[]
}

export const SystemTagCell = ({ data }: ISystemTagCellProps) => {
  const { t } = useTranslation()

  if (!data?.length) {
    return <>{t('components.table.notApplicable')}</>
  }

  return (
    <StyledTagWrapper>
      <StyledTagContainer>
        {data.map(({ systemTag }) => {
          const SystemTagIcon = mapSystemTagToIcon[systemTag.value] ?? FlagIcon

          return (
            <Tooltip
              key={systemTag.id}
              placement='top'
              title={systemTag.name}
              arrow
            >
              <SystemTagIcon fontSize='medium' titleAccess={systemTag.name} />
            </Tooltip>
          )
        })}
      </StyledTagContainer>
    </StyledTagWrapper>
  )
}

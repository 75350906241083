import { useCallback } from 'react'
import { usePSSnackbar } from 'utils/hooks/snackbar/usePSSnackbar'

import {
  FIREBASE_NOTIFICATION_TYPE_ENUM,
  TFirebaseMessage,
  TMessageHandler,
} from '../types'

export const useHandleTransferViaTaMessage = () => {
  const { openSuccessSnackbar, openErrorSnackbar } = usePSSnackbar()

  const handleTransferViaTaMessage: TMessageHandler = useCallback((event) => {
    try {
      const notificationBody: TFirebaseMessage = JSON.parse(
        event.data.notification.body
      )

      if (
        notificationBody.type ===
        FIREBASE_NOTIFICATION_TYPE_ENUM.SUCCESS_SHARE_IMAGES_VIA_TA
      ) {
        openSuccessSnackbar(notificationBody.message)
      } else if (
        notificationBody.type ===
        FIREBASE_NOTIFICATION_TYPE_ENUM.FAILURE_SHARE_IMAGES_VIA_TA
      ) {
        openErrorSnackbar(notificationBody.message)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('JSON Parse error: ', e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { handleTransferViaTaMessage }
}

import { FC, forwardRef } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PreliminaryAvailableIcon: FC<
  SvgIconProps & {
    className?: string
  }
> = forwardRef(({ className, fontSize = 'small', ...restProps }, ref) => (
  <SvgIcon
    viewBox='0 0 18 18'
    fontSize={fontSize}
    className={className}
    {...restProps}
    ref={ref}
  >
    <path
      d='M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9.75 12.75H8.25V8.25H9.75V12.75ZM9.75 6.75H8.25V5.25H9.75V6.75Z'
      fill='#1E88E5'
    />
  </SvgIcon>
))

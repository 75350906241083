import { CircularProgress } from '@mui/material'
import { LoaderContainer } from 'components/ui-components/PSLoader/PSLoader.styled'

interface IPSLoaderProps {
  size?: number
  position?: 'fixed' | 'absolute' | 'relative'
}

export const PSLoader = ({ size = 48, position = 'fixed' }: IPSLoaderProps) => {
  return (
    <LoaderContainer position={position}>
      <CircularProgress size={size} />
    </LoaderContainer>
  )
}

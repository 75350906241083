import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import _noop from 'lodash/noop'
import { LoaderWrapper } from 'components/LoaderWrapper'

import { PSButton } from './PSButton'
import { PSDialog } from './PSDialog'

interface IPSConfirmationDialogProps {
  isDialogOpen: boolean
  headerText: string
  dialogText?: string
  isLoading?: boolean
  isAgreeButtonDisabled?: boolean
  cancelButtonText?: string
  agreeButtonText?: string
  dialogContent?: JSX.Element
  onCancel?: () => void
  onAgree: () => void
  onClose?: () => void
}

export const PSConfirmationDialog: FC<IPSConfirmationDialogProps> = ({
  isDialogOpen,
  headerText,
  dialogText = '',
  isLoading = false,
  isAgreeButtonDisabled = false,
  dialogContent,
  cancelButtonText,
  agreeButtonText,
  onClose,
  onCancel,
  onAgree,
}) => {
  const { t } = useTranslation()
  const cancelText = cancelButtonText ?? t('components.buttons.cancel')
  const agreeText = agreeButtonText ?? t('components.buttons.save')

  return (
    <PSDialog
      isOpen={isDialogOpen}
      onClose={onClose || onCancel || _noop}
      heading={headerText}
      actions={
        <Box display='flex' justifyContent='space-between' width='100%'>
          {onCancel ? (
            <PSButton
              variant='outlined'
              onClick={onCancel}
              disabled={isLoading}
              data-cy='cancelConfirmation'
            >
              {cancelText}
            </PSButton>
          ) : null}
          <PSButton
            variant='contained'
            onClick={onAgree}
            data-cy='agreeConfirmation'
            autoFocus
            disabled={isLoading || isAgreeButtonDisabled}
          >
            {agreeText}
          </PSButton>
        </Box>
      }
    >
      <LoaderWrapper isLoading={isLoading}>
        {dialogContent ?? <Typography variant='body1'>{dialogText}</Typography>}
      </LoaderWrapper>
    </PSDialog>
  )
}

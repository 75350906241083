export const enProScanStatuses = {
  SCHEDULED: 'Scheduled',
  PENDING_SUBMISSION: 'Pending Submission',
  ON_HOLD: 'On Hold',
  WAITING_FOR_ASSIGNMENT: 'Waiting for Assignment',
  ASSIGNED_WAITING: 'Assigned-Waiting',
  PENDING_REVIEW: 'Pending Review',
  PENDING_RE_REVIEW: 'Pending Re-review',
  PENDING_ADDENDUM: 'Pending Addendum',
  PENDING_APPROVAL: 'Pending Approval',
  PENDING_TRANSCRIPTION: 'Pending Transcription',
  FINAL_REVIEW: 'Final Review',
  COMPLETED: 'Completed',
  CANCELED: 'Canceled',
  NO_REPORT: 'No Report',
  REPORT_QA: 'Report QA',
  REPORT_DELIVERED: 'Report Delivered',
  IN_REVIEW: 'In Review',
  FINAL_REPORT: 'Final Report',
  FINAL_REPORT_ADDENDUM: 'Final Report Addendum',
  MIGRATED: 'Migrated',
  IMPORTED_REVIEW: 'Imported Review',
  IMPORTED_FINAL: 'Imported Final',
  IMPORTED_CANCELED: 'Imported Canceled',
}

import { FC, forwardRef } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const FailedFaxIcon: FC<SvgIconProps & { className?: string }> =
  forwardRef(({ className, fontSize = 'small', ...restProps }, ref) => (
    <SvgIcon
      viewBox='0 0 18 18'
      fontSize={fontSize}
      className={className}
      {...restProps}
      ref={ref}
    >
      <path
        d='M7.05936 10.9757L4.73266 8.625L3.75 9.61567L7.125 13.0227L13.7727 6.30631L12.7244 5.25L7.05936 10.9757Z'
        fill='#4CAF50'
      />
      <path
        d='M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9 15C5.685 15 3 12.315 3 9C3 7.6125 3.4725 6.3375 4.2675 5.325L12.675 13.7325C11.6625 14.5275 10.3875 15 9 15ZM13.7325 12.675L5.325 4.2675C6.3375 3.4725 7.6125 3 9 3C12.315 3 15 5.685 15 9C15 10.3875 14.5275 11.6625 13.7325 12.675Z'
        fill='#D32F2F'
      />
    </SvgIcon>
  ))

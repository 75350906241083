import {
  PHYSICIAN_CONFIGURABLE_COLUMNS,
  PROVIDERS_TABLE_NAMES_ENUM,
  RADIOLOGIST_CONFIGURABLE_COLUMNS,
} from 'store/providers/types'

import { IState } from './types'

export const initialState: IState = {
  [PROVIDERS_TABLE_NAMES_ENUM.RADIOLOGISTS]: {
    filtersData: {
      subSpecialtyIds: [],
      countries: [],
      states: [],
      isOnCallRadiologistGroup: undefined,
    },
    queryData: {
      search: undefined,
      sort: undefined,
      page: 0,
      size: 10,
    },
    columnsConfiguration: {
      [RADIOLOGIST_CONFIGURABLE_COLUMNS.INITIALS]: false,
      [RADIOLOGIST_CONFIGURABLE_COLUMNS.NPI]: false,
    },
  },
  [PROVIDERS_TABLE_NAMES_ENUM.PHYSICIANS]: {
    filtersData: {
      taxonomyIds: [],
      practiceIds: [],
      siteIds: [],
      notVerifiedOnly: false,
    },
    queryData: {
      search: undefined,
      sort: undefined,
      page: 0,
      size: 10,
    },
    columnsConfiguration: {
      [PHYSICIAN_CONFIGURABLE_COLUMNS.PHYSICIANS_NPI]: false,
      [PHYSICIAN_CONFIGURABLE_COLUMNS.PHYSICIANS_TAXONOMIES]: false,
    },
  },
}

export const PROVIDERS_FILTERS_SLICE_NAME = 'providersFilters'

import { routes } from 'routes/routes'

import { DICTIONARIES_TABLE_TAB, TDictionariesTab } from './types'

export const DICTIONARIES_TABS: TDictionariesTab[] = [
  {
    key: DICTIONARIES_TABLE_TAB.PROCEDURES,
    path: routes.dictionaries.procedures.root.path,
    allowedRoles: routes.dictionaries.procedures.root.roles,
    label: 'domains.Dictionaries.procedures',
  },
  {
    key: DICTIONARIES_TABLE_TAB.FLAGS,
    path: routes.dictionaries.flags.root.path,
    allowedRoles: routes.dictionaries.flags.root.roles,
    label: 'domains.Dictionaries.flagsTabHeader',
  },
  {
    key: DICTIONARIES_TABLE_TAB.TAGS,
    path: routes.dictionaries.tags.root.path,
    allowedRoles: routes.dictionaries.tags.root.roles,
    label: 'domains.Dictionaries.tagsTabHeader',
  },
  {
    key: DICTIONARIES_TABLE_TAB.DESTINATIONS,
    path: routes.dictionaries.dicomDestinations.root.path,
    allowedRoles: routes.dictionaries.dicomDestinations.root.roles,
    label: 'domains.Dictionaries.dicomDestinationsTitle',
  },
  {
    key: DICTIONARIES_TABLE_TAB.DESTINATION_GROUPS,
    path: routes.dictionaries.dicomDestinationGroup.root.path,
    allowedRoles: routes.dictionaries.dicomDestinationGroup.root.roles,
    label: 'domains.Dictionaries.dicomDestinationGroupsTabHeader',
  },
]

import { useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { Outlet } from 'react-router-dom'
import { MILLISECONDS_IN_MINUTE } from 'constants/timings'
import { Header } from 'components/Header'
import { Sidebar } from 'components/Sidebar'
import { usePSIdleSnackbar } from 'utils/hooks/snackbar/usePSIdleSnackbar'

import { AppContainer } from './AppLayout.styled'

export const AppLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const auth = useAuth()

  usePSIdleSnackbar({
    timeout: MILLISECONDS_IN_MINUTE * 35,
    promptBeforeIdle: MILLISECONDS_IN_MINUTE * 5,
    onTimeoutOver: async () => {
      await auth.signoutRedirect()
    },
  })

  return (
    <>
      <Header />
      <Sidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
      <AppContainer isSidebarOpen={isSidebarOpen}>
        <Outlet />
      </AppContainer>
    </>
  )
}

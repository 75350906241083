import { createAsyncThunk } from '@reduxjs/toolkit'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'

import { TOrderFlag, TOrderFlagStatus } from './types'

export const getOrderFlagsRq = createAsyncThunk<
  TOrderFlag[],
  string,
  {
    rejectValue: TServiceError
  }
>(
  'orderFlags/getOrderFlagsRq',
  async (accessionNumber, { rejectWithValue }) => {
    const result = await request<TOrderFlag[]>({
      url: `bff/orders/${accessionNumber}/flags`,
      method: 'GET',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return result.data
  }
)

export const updateCheckOrderFlag = createAsyncThunk<
  unknown,
  { accessionNumber: string; id: number; isCompleted: boolean },
  {
    rejectValue: TServiceError
  }
>(
  'orderFlags/updateCheckOrderFlag',
  async ({ accessionNumber, id, isCompleted }, { rejectWithValue }) => {
    const result = await request({
      url: `orders/${accessionNumber}/flags/${id}`,
      data: { complete: isCompleted },
      method: 'PATCH',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return result.data
  }
)

export const updateAllCheckOrderFlags = createAsyncThunk<
  unknown,
  { accessionNumber: string; status: TOrderFlagStatus },
  {
    rejectValue: TServiceError
  }
>(
  'orderFlags/updateAllCheckOrderFlags',
  async ({ accessionNumber, status }, { rejectWithValue }) => {
    const result = await request({
      url: `orders/${accessionNumber}/flags?status=${status}`,
      data: {
        complete: true,
      },
      method: 'PATCH',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return result.data
  }
)

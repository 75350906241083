import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TOrder } from 'store/ordersList/types'

interface IProscanMrnCellProps {
  data: TOrder
}

export const ProscanMrnCell: FC<IProscanMrnCellProps> = ({ data }) => {
  const { t } = useTranslation()

  return <>{data.patient?.proscanMrn ?? t('components.table.notApplicable')}</>
}

import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATE } from 'types/general'

import { initialState, name } from './constants'
import * as controllers from './controllers'

const proceduresSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearProcedureInfo: (state) => {
      state.procedure = initialState.procedure
      state.loadingState = initialState.loadingState
      state.error = initialState.error
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(controllers.fetchProcedureInfo.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.fetchProcedureInfo.fulfilled, (state, action) => {
        state.procedure = action.payload
        state.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(controllers.fetchProcedureInfo.rejected, (state, action) => {
        state.loadingState = LOADING_STATE.rejected
        state.error = action.payload
      })
    builder
      .addCase(controllers.fetchArchive.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.fetchArchive.fulfilled, (state) => {
        state.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(controllers.fetchArchive.rejected, (state, action) => {
        state.loadingState = LOADING_STATE.rejected
        state.error = action.payload
      })
  },
})

export const { clearProcedureInfo } = proceduresSlice.actions

export default proceduresSlice

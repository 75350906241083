import { FC } from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Box, Grid } from '@mui/material'
import { PS_COLORS } from 'constants/colors'

import {
  StyledFieldLink,
  StyledSubBlockContainer,
} from './FormValidationStatusBlock.styled'
import { IValidationBlockConfig } from './types'

interface IFormValidationStatusBlockProps {
  onFieldClick?: (fieldKey: string) => void
  config: IValidationBlockConfig
}
const SUCCESS_BLOCK_COLOR = PS_COLORS.green[500]
const ERROR_BLOCK_COLOR = PS_COLORS.red[700]

export const FormValidationStatusBlock: FC<IFormValidationStatusBlockProps> = ({
  config: { title, isValid, subBlocks, formKey },
  onFieldClick,
}) => {
  const hasClickHandler = !!onFieldClick

  return (
    <Box>
      <Grid
        mb={1}
        gap={2}
        flexWrap='nowrap'
        alignItems='center'
        onClick={() => onFieldClick?.(formKey)}
        container
      >
        <Grid item>
          {isValid ? (
            <CheckCircleIcon htmlColor={SUCCESS_BLOCK_COLOR} />
          ) : (
            <CancelIcon htmlColor={ERROR_BLOCK_COLOR} />
          )}
        </Grid>
        <Grid item>
          <StyledFieldLink
            hasClickHandler={hasClickHandler}
            variant='button'
            fontSize='16px'
          >
            {title}
          </StyledFieldLink>
        </Grid>
      </Grid>

      {subBlocks.map((subBlockConfig) => (
        <StyledSubBlockContainer
          gap={1}
          container
          key={subBlockConfig.title}
          onClick={() => onFieldClick?.(subBlockConfig.formKey)}
        >
          <Grid item display='flex' alignItems='center'>
            {subBlockConfig.isValid ? (
              <CheckCircleIcon
                fontSize='small'
                htmlColor={SUCCESS_BLOCK_COLOR}
              />
            ) : (
              <CancelIcon fontSize='small' htmlColor={ERROR_BLOCK_COLOR} />
            )}
          </Grid>
          <Grid item>
            <StyledFieldLink hasClickHandler={hasClickHandler}>
              {subBlockConfig.title}
            </StyledFieldLink>
          </Grid>
        </StyledSubBlockContainer>
      ))}
    </Box>
  )
}

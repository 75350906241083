import { Box, styled } from '@mui/material'

export const StyledTagBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'bgColor' && prop !== 'isRound',
})<{ bgColor?: string; isRound?: boolean }>(
  ({ bgColor, isRound, theme: { spacing } }) => ({
    backgroundColor: bgColor,
    width: '24px',
    height: '24px',
    borderRadius: spacing(isRound ? 2 : 0.5),
  })
)

export const StyledTagContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: spacing(1),
  maxWidth: '152px',
}))

export const StyledTagWrapper = styled(Box)(() => ({
  width: 'max-content',
}))

export const globalStyles = {
  /* width */
  '::-webkit-scrollbar': {
    width: '6px',
    border: '1px solid rgba(124, 124, 124, 0.3)',
  },
  /* Handle */
  '::-webkit-scrollbar-thumb': {
    background: 'rgba(165, 165, 165, 0.6)',
    borderRadius: '4px',
  },
  /* Handle on hover */
  '::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(205, 205, 205, 0.6)',
  },
}

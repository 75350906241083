import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _merge from 'lodash/merge'
import { REHYDRATE } from 'redux-persist'
import { ORDER_STATUS_ENUM } from 'store/order/types'
import { SUPPORT_REQUEST_TYPES } from 'store/support/types'
import { TQueryData } from 'types/general'

import { initialState, SUPPORT_FILTERS_SLICE_NAME } from './constants'
import { ITableFilterData } from './types'

const supportFilterSlice = createSlice({
  name: SUPPORT_FILTERS_SLICE_NAME,
  initialState,
  reducers: {
    changeSupportTableQueryData: (
      state,
      { payload }: PayloadAction<Partial<TQueryData>>
    ) => {
      state.queryData = {
        ...state.queryData,
        ...payload,
      }
    },
    changeFilterData: (
      state,
      { payload }: PayloadAction<Partial<ITableFilterData>>
    ) => {
      state.filtersData = {
        ...state.filtersData,
        ...payload,
      }
      state.queryData.page = 0
    },
    clearFilterByName: (
      state,
      { payload }: PayloadAction<keyof ITableFilterData>
    ) => {
      state.filtersData = {
        ...state.filtersData,
        [payload]: initialState.filtersData[payload],
      }
      state.queryData.page = 0
    },
    removeUrgencyFilterOptionById: (
      state,
      { payload: { id } }: PayloadAction<{ id: number }>
    ) => {
      state.filtersData = {
        ...state.filtersData,
        urgencies: state.filtersData.urgencies.filter(
          (urgency) => urgency.id !== id
        ),
      }
      state.queryData.page = 0
    },

    removeStatusFilterOptionByStatus: (
      state,
      {
        payload: { status },
      }: PayloadAction<{ status: `${ORDER_STATUS_ENUM}` | null }>
    ) => {
      state.filtersData = {
        ...state.filtersData,
        orderStatuses: state.filtersData.orderStatuses.filter(
          (option) => option.value !== status
        ),
      }
      state.queryData.page = 0
    },
    removeSiteNameFilterOptionById: (
      state,
      { payload: { id } }: PayloadAction<{ id: number }>
    ) => {
      state.filtersData = {
        ...state.filtersData,
        siteNames: state.filtersData.siteNames.filter(
          ({ siteId }) => siteId !== id
        ),
      }
      state.queryData.page = 0
    },
    removeTargetGroupFilterOptionById: (
      state,
      { payload: { id } }: PayloadAction<{ id: number }>
    ) => {
      state.filtersData = {
        ...state.filtersData,
        targetGroups: state.filtersData.targetGroups.filter(
          (targetGroup) => targetGroup.id !== id
        ),
      }
      state.queryData.page = 0
    },
    removeTypeFilterOption: (
      state,
      { payload }: PayloadAction<SUPPORT_REQUEST_TYPES>
    ) => {
      state.filtersData = {
        ...state.filtersData,
        supportRequestTypes: state.filtersData.supportRequestTypes.filter(
          (type) => type.id !== payload
        ),
      }
      state.queryData.page = 0
    },
    clearAllFilterData: (state) => {
      state.filtersData = initialState.filtersData
      state.queryData.page = 0
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action) => {
      // @ts-expect-error action has a payload with rehydrated state
      if (action.payload) {
        const {
          // @ts-expect-error action has a payload with rehydrated state
          payload: { filters },
        } = action
        return _merge(state, filters?.supportFilters ?? {})
      }

      return state
    })
  },
})

export const {
  changeFilterData,
  clearFilterByName,
  clearAllFilterData,
  removeTypeFilterOption,
  changeSupportTableQueryData,
  removeUrgencyFilterOptionById,
  removeSiteNameFilterOptionById,
  removeTargetGroupFilterOptionById,
  removeStatusFilterOptionByStatus,
} = supportFilterSlice.actions

export default supportFilterSlice

import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import { TDegree } from 'services/dictionary/types'

interface IProviderNameWithDegreesProps {
  fullName: string
  degrees?: TDegree[]
  fontVariant?: Variant
  isInline?: boolean
}

export const ProviderNameWithDegrees: FC<IProviderNameWithDegreesProps> = ({
  fullName,
  degrees = [],
  fontVariant = 'body2',
  isInline = false,
}) => (
  <Box display='flex' flexDirection={isInline ? 'row' : 'column'}>
    <Typography variant={fontVariant} mr={isInline ? 1 : 0}>
      {fullName}
    </Typography>
    {Boolean(degrees?.length) && (
      <Typography color='text.secondary' variant={fontVariant}>
        {degrees.map(({ name }) => name).join(', ')}
      </Typography>
    )}
  </Box>
)

import { IState } from './types'

export const initialState: IState = {
  filtersData: {
    siteIds: [],
    companyIds: [],
    practiceIds: [],
    roles: [],
    isLongInactive: false,
    isActive: true,
  },
  queryData: {
    search: undefined,
    sort: undefined,
    page: 0,
    size: 10,
  },
}

export const USERS_FILTERS_SLICE_NAME = 'usersFilters'

export const LIST_ID = 'LIST'

export const USERS_TAG = 'Users' as const

export const PROCEDURES_TAG = 'Procedures' as const

export const PAPERWORKS_TAG = 'Paperworks' as const

export const PHYSICIANS_TAG = 'Physicians' as const

export const PRESIGNED_DOWNLOAD_TAG = 'PresignedDownload' as const

export const BILLING_FOR_SITE_TAG = 'BillingForSite' as const

export const SITE_TAG = 'Site' as const

export const PATIENTS_TAG = 'Patients' as const

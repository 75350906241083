import { createBrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { dashboardMapRoleToRoute } from 'routes/dashboardMapRoleToRoute'
import { AppLayout } from 'components/AppLayout/AppLayout'
import { ProtectedComponent } from 'components/ProtectedComponent'
import { SomethingWentWrong } from 'components/SomethingWentWrong'
import { DeniedAccessPage } from 'pages/DeniedAccessPage'
import { DictionariesList } from 'pages/dictionaries/DictionariesList'
import { NotFoundPage } from 'pages/NotFoundPage'
import { BillingContainer } from 'pages/orders/Billing/BillingContainer'
import { OrdersList } from 'pages/orders/OrdersList'
import { ProvidersList } from 'pages/providers/ProvidersList/ProvidersList'
import { TestError } from 'pages/test/TestError'

import { routes } from './routes'

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

export const routers = sentryCreateBrowserRouter([
  {
    path: routes.main.path,
    Component: AppLayout,
    errorElement: <SomethingWentWrong />,
    children: [
      {
        index: true,
        async lazy() {
          const { Dashboard } = await import('../pages/dashboard')

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.main.roles}
                mapRoleToRoute={dashboardMapRoleToRoute}
              >
                <Dashboard />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.profile.root.path,
        async lazy() {
          const { UserProfile } = await import('../pages/users/UserProfile')

          return {
            Component: UserProfile,
          }
        },
      },
      {
        path: routes.orders.root.path,
        element: (
          <ProtectedComponent
            allowedRoles={routes.orders.root.roles}
            redirectTo={routes['403'].path}
          >
            <OrdersList />
          </ProtectedComponent>
        ),
        children: [
          {
            path: routes.orders.all.path,
            async lazy() {
              const { AllRisOrdersTab } = await import(
                '../pages/orders/OrdersList/components/AllRisOrdersTab'
              )

              return {
                element: (
                  <ProtectedComponent
                    allowedRoles={routes.orders.all.roles}
                    redirectTo={routes['403'].path}
                  >
                    <AllRisOrdersTab />
                  </ProtectedComponent>
                ),
              }
            },
          },

          {
            path: routes.orders.preparation.path,
            async lazy() {
              const { PreparationTab } = await import(
                '../pages/orders/OrdersList/components/PreparationTab'
              )

              return {
                element: (
                  <ProtectedComponent
                    allowedRoles={routes.orders.preparation.roles}
                    redirectTo={routes['403'].path}
                  >
                    <PreparationTab />
                  </ProtectedComponent>
                ),
              }
            },
          },

          {
            path: routes.orders.assignments.path,
            async lazy() {
              const { AssignmentTab } = await import(
                '../pages/orders/OrdersList/components/AssignmentTab'
              )

              return {
                element: (
                  <ProtectedComponent
                    allowedRoles={routes.orders.assignments.roles}
                    redirectTo={routes['403'].path}
                  >
                    <AssignmentTab />
                  </ProtectedComponent>
                ),
              }
            },
          },

          {
            path: routes.orders.reportQa.path,
            async lazy() {
              const { ReportQATab } = await import(
                '../pages/orders/OrdersList/components/ReportQATab'
              )

              return {
                element: (
                  <ProtectedComponent
                    allowedRoles={routes.orders.reportQa.roles}
                    redirectTo={routes['403'].path}
                  >
                    <ReportQATab />
                  </ProtectedComponent>
                ),
              }
            },
          },

          {
            path: routes.orders.billing.path,
            async lazy() {
              const { BillingTab } = await import(
                '../pages/orders/OrdersList/components/BillingTab'
              )

              return {
                element: (
                  <ProtectedComponent
                    allowedRoles={routes.orders.billing.roles}
                    redirectTo={routes['403'].path}
                  >
                    <BillingTab />
                  </ProtectedComponent>
                ),
              }
            },
          },
        ],
      },
      {
        path: routes.orders.create.path,
        async lazy() {
          const { OrderCreate } = await import(
            '../pages/orders/OrderManagement/OrderCreate'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.orders.create.roles}
                redirectTo={routes['403'].path}
              >
                <OrderCreate />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.orders.orderEdit.path,
        async lazy() {
          const { OrderEdit } = await import(
            '../pages/orders/OrderManagement/OrderEdit'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.orders.orderEdit.roles}
                redirectTo={routes['403'].path}
              >
                <OrderEdit />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.orders.orderAttachment.path,
        async lazy() {
          const { OrderAttachments } = await import(
            '../pages/orders/OrderManagement/OrderAttachments'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.orders.orderAttachment.roles}
                redirectTo={routes['403'].path}
              >
                <OrderAttachments />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.orders.radiologistAssignment.path,
        async lazy() {
          const { RadiologistAssignment } = await import(
            '../pages/orders/RadiologistAssignment'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.orders.radiologistAssignment.roles}
                redirectTo={routes['403'].path}
              >
                <RadiologistAssignment />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.orders.paperwork.path,
        async lazy() {
          const { Paperwork } = await import(
            '../pages/paperwork/Paperwork/Paperwork'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.orders.paperwork.roles}
                redirectTo={routes['403'].path}
              >
                <Paperwork />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.orders.paperworkAttachment.path,
        async lazy() {
          const { PaperworkView } = await import(
            '../pages/paperwork/PaperworkView/PaperworkView'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.orders.paperworkAttachment.roles}
                redirectTo={routes['403'].path}
              >
                <PaperworkView />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.orders.orderPreview.path,
        async lazy() {
          const { OrderPreview } = await import('../pages/orders/OrderPreview')

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.orders.orderPreview.roles}
                redirectTo={routes['403'].path}
              >
                <OrderPreview />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.orders.orderAudit.path,
        async lazy() {
          const { OrderAudit } = await import(
            '../pages/orders/Audit/OrderAudit'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.orders.orderAudit.roles}
                redirectTo={routes['403'].path}
              >
                <OrderAudit />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.orders.patientAudit.path,
        async lazy() {
          const { PatientAudit } = await import(
            '../pages/orders/Audit/PatientAudit'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.orders.patientAudit.roles}
                redirectTo={routes['403'].path}
              >
                <PatientAudit />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.orders.orderBillingInformationPreview.path,
        element: <BillingContainer />,
        children: [
          {
            index: true,
            async lazy() {
              const { BillingPreview } = await import(
                '../pages/orders/Billing/BillingPreview'
              )

              return {
                element: (
                  <ProtectedComponent
                    allowedRoles={
                      routes.orders.orderBillingInformationPreview.roles
                    }
                    redirectTo={routes['403'].path}
                  >
                    <BillingPreview />
                  </ProtectedComponent>
                ),
              }
            },
          },
          {
            path: routes.orders.orderBillingInformationEdit.path,
            async lazy() {
              const { BillingEdit } = await import(
                '../pages/orders/Billing/BillingEdit'
              )

              return {
                element: (
                  <ProtectedComponent
                    allowedRoles={
                      routes.orders.orderBillingInformationEdit.roles
                    }
                    redirectTo={routes['403'].path}
                  >
                    <BillingEdit />
                  </ProtectedComponent>
                ),
              }
            },
          },
        ],
      },
      {
        path: routes.orders.orderReportInformation.path,
        async lazy() {
          const { OrderReportInformation } = await import(
            '../pages/orders/OrderReportInformation'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.orders.orderReportInformation.roles}
                redirectTo={routes['403'].path}
              >
                <OrderReportInformation />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.orders.orderReportInformationCompare.path,
        async lazy() {
          const { ReportComparison } = await import(
            '../pages/orders/ReportComparison'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.orders.orderReportInformationCompare.roles}
                redirectTo={routes['403'].path}
              >
                <ReportComparison />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.users.root.path,
        async lazy() {
          const { UserList } = await import('../pages/users/UserList')

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.users.root.roles}
                redirectTo={routes['403'].path}
              >
                <UserList />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.users.create.path,
        async lazy() {
          const { UserCreate } = await import(
            '../pages/users/UserManagement/UserCreate'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.users.create.roles}
                redirectTo={routes['403'].path}
              >
                <UserCreate />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.users.profile.path,
        async lazy() {
          const { UserView } = await import(
            '../pages/users/UserManagement/UserView'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.users.profile.roles}
                redirectTo={routes['403'].path}
              >
                <UserView />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.companies.root.path,
        async lazy() {
          const { CompaniesList } = await import(
            '../pages/companies/CompaniesList'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.companies.root.roles}
                redirectTo={routes['403'].path}
              >
                <CompaniesList />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.companies.create.path,
        async lazy() {
          const { CompanyCreate } = await import(
            '../pages/companies/CompanyManagement/CompanyCreate'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.companies.create.roles}
                redirectTo={routes['403'].path}
              >
                <CompanyCreate />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.companies.details.path,
        async lazy() {
          const { CompanyEdit } = await import(
            '../pages/companies/CompanyManagement/CompanyEdit'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.companies.details.roles}
                redirectTo={routes['403'].path}
              >
                <CompanyEdit />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.companies.companySiteReportConfiguration.path,
        async lazy() {
          const { SiteReportConfiguration } = await import(
            '../pages/sites/SiteReportConfiguration'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={
                  routes.companies.companySiteReportConfiguration.roles
                }
                redirectTo={routes['403'].path}
              >
                <SiteReportConfiguration />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.companies.siteCreate.path,
        async lazy() {
          const { SiteCreate } = await import(
            '../pages/sites/SiteManagement/SiteCreate'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.companies.siteCreate.roles}
                redirectTo={routes['403'].path}
              >
                <SiteCreate />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.companies.companySite.path,
        async lazy() {
          const { SiteEdit } = await import(
            '../pages/sites/SiteManagement/SiteEdit'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.companies.companySite.roles}
                redirectTo={routes['403'].path}
              >
                <SiteEdit />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.practices.root.path,
        async lazy() {
          const { PracticesList } = await import(
            '../pages/practices/PracticesList/PracticesList'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.practices.root.roles}
                redirectTo={routes['403'].path}
              >
                <PracticesList />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.practices.create.path,
        async lazy() {
          const { PracticeManagement } = await import(
            '../pages/practices/PracticeManagement/PracticeManagement'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.practices.create.roles}
                redirectTo={routes['403'].path}
              >
                <PracticeManagement />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.practices.practiceById.path,
        async lazy() {
          const { PracticeManagement } = await import(
            '../pages/practices/PracticeManagement/PracticeManagement'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.practices.practiceById.roles}
                redirectTo={routes['403'].path}
              >
                <PracticeManagement />
              </ProtectedComponent>
            ),
          }
        },
      },

      {
        path: routes.providers.root.path,
        element: (
          <ProtectedComponent
            allowedRoles={routes.providers.root.roles}
            redirectTo={routes['403'].path}
          >
            <ProvidersList />
          </ProtectedComponent>
        ),
        children: [
          {
            path: routes.providers.radiologists.path,
            async lazy() {
              const { RadiologistsTab } = await import(
                '../pages/providers/ProvidersList/components/RadiologistsTab/RadiologistsTab'
              )

              return {
                element: (
                  <ProtectedComponent
                    allowedRoles={routes.providers.radiologists.roles}
                    redirectTo={routes['403'].path}
                  >
                    <RadiologistsTab />
                  </ProtectedComponent>
                ),
              }
            },
          },
          {
            path: routes.providers.referringProvider.path,
            async lazy() {
              const { PhysiciansTab } = await import(
                '../pages/providers/ProvidersList/components/PhysiciansTab/PhysiciansTab'
              )

              return {
                element: (
                  <ProtectedComponent
                    allowedRoles={routes.providers.referringProvider.roles}
                    redirectTo={routes['403'].path}
                  >
                    <PhysiciansTab />
                  </ProtectedComponent>
                ),
              }
            },
          },
        ],
      },

      {
        path: routes.providers.onCallAssignment.path,
        async lazy() {
          const { OnCallRadiologistAssignmentList } = await import(
            '../pages/providers/OnCallRadiologistAssignmentList'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.providers.onCallAssignment.roles}
                redirectTo={routes['403'].path}
              >
                <OnCallRadiologistAssignmentList />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.providers.create.path,
        async lazy() {
          const { ProviderCreate } = await import(
            '../pages/providers/ProviderManagement/ProviderCreate'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.providers.create.roles}
                redirectTo={routes['403'].path}
              >
                <ProviderCreate />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.providers.providerById.path,
        async lazy() {
          const { ProviderEdit } = await import(
            '../pages/providers/ProviderManagement/ProviderEdit'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.providers.providerById.roles}
                redirectTo={routes['403'].path}
              >
                <ProviderEdit />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.supportRequests.root.path,
        async lazy() {
          const { SupportRequestWorkList } = await import(
            '../pages/support/SupportRequestWorkList'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.supportRequests.root.roles}
                redirectTo={routes['403'].path}
              >
                <SupportRequestWorkList />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.supportRequests.supportRequestById.path,
        async lazy() {
          const { SupportRequestWorkManagement } = await import(
            '../pages/support/SupportRequestWorkManagement'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.supportRequests.supportRequestById.roles}
                redirectTo={routes['403'].path}
              >
                <SupportRequestWorkManagement />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.dictionaries.root.path,
        element: <DictionariesList />,
        children: [
          {
            path: routes.dictionaries.procedures.root.path,
            async lazy() {
              const { ProceduresList } = await import(
                '../pages/dictionaries/procedures/ProceduresList'
              )

              return {
                element: (
                  <ProtectedComponent
                    allowedRoles={routes.dictionaries.procedures.root.roles}
                    redirectTo={routes['403'].path}
                  >
                    <ProceduresList />
                  </ProtectedComponent>
                ),
              }
            },
          },
          {
            path: routes.dictionaries.flags.root.path,
            async lazy() {
              const { FlagList } = await import(
                '../pages/dictionaries/flags/FlagList'
              )

              return {
                element: (
                  <ProtectedComponent
                    allowedRoles={routes.dictionaries.flags.root.roles}
                    redirectTo={routes['403'].path}
                  >
                    <FlagList />
                  </ProtectedComponent>
                ),
              }
            },
          },
          {
            path: routes.dictionaries.tags.root.path,
            async lazy() {
              const { TagList } = await import(
                '../pages/dictionaries/tags/TagList'
              )

              return {
                element: (
                  <ProtectedComponent
                    allowedRoles={routes.dictionaries.tags.root.roles}
                    redirectTo={routes['403'].path}
                  >
                    <TagList />
                  </ProtectedComponent>
                ),
              }
            },
          },
          {
            path: routes.dictionaries.dicomDestinations.root.path,
            async lazy() {
              const { DicomDestinationList } = await import(
                '../pages/dictionaries/destinations/DicomDestinationList'
              )

              return {
                element: (
                  <ProtectedComponent
                    allowedRoles={
                      routes.dictionaries.dicomDestinations.root.roles
                    }
                    redirectTo={routes['403'].path}
                  >
                    <DicomDestinationList />
                  </ProtectedComponent>
                ),
              }
            },
          },
          {
            path: routes.dictionaries.dicomDestinationGroup.root.path,
            async lazy() {
              const { DicomDestinationGroupList } = await import(
                '../pages/dictionaries/destinationGroups/DicomDestinationGroupList'
              )

              return {
                element: (
                  <ProtectedComponent
                    allowedRoles={
                      routes.dictionaries.dicomDestinationGroup.root.roles
                    }
                    redirectTo={routes['403'].path}
                  >
                    <DicomDestinationGroupList />
                  </ProtectedComponent>
                ),
              }
            },
          },
        ],
      },
      {
        path: routes.dictionaries.procedures.create.path,
        async lazy() {
          const { ProcedureCreate } = await import(
            '../pages/dictionaries/procedures/ProcedureManagement/ProcedureCreate'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.dictionaries.procedures.create.roles}
                redirectTo={routes['403'].path}
              >
                <ProcedureCreate />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.dictionaries.procedures.details.path,
        async lazy() {
          const { ProcedureEdit } = await import(
            '../pages/dictionaries/procedures/ProcedureManagement/ProcedureEdit'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.dictionaries.procedures.details.roles}
                redirectTo={routes['403'].path}
              >
                <ProcedureEdit />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.dictionaries.flags.create.path,
        async lazy() {
          const { FlagCreate } = await import(
            '../pages/dictionaries/flags/FlagManagement/FlagCreate'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.dictionaries.flags.create.roles}
                redirectTo={routes['403'].path}
              >
                <FlagCreate />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.dictionaries.flags.details.path,
        async lazy() {
          const { FlagEdit } = await import(
            '../pages/dictionaries/flags/FlagManagement/FlagEdit'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.dictionaries.flags.details.roles}
                redirectTo={routes['403'].path}
              >
                <FlagEdit />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.dictionaries.dicomDestinations.destinationCreate.path,
        async lazy() {
          const { DicomDestinationCreate } = await import(
            '../pages/dictionaries/destinations/DicomDestinationManagement/DicomDestinationCreate'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={
                  routes.dictionaries.dicomDestinations.destinationCreate.roles
                }
                redirectTo={routes['403'].path}
              >
                <DicomDestinationCreate />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.dictionaries.dicomDestinations.destinationEdit.path,
        async lazy() {
          const { DicomDestinationEdit } = await import(
            '../pages/dictionaries/destinations/DicomDestinationManagement/DicomDestinationEdit'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={
                  routes.dictionaries.dicomDestinations.destinationEdit.roles
                }
                redirectTo={routes['403'].path}
              >
                <DicomDestinationEdit />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.dictionaries.dicomDestinationGroup.destinationGroupCreate
          .path,
        async lazy() {
          const { DicomDestinationGroupCreate } = await import(
            '../pages/dictionaries/destinationGroups/DicomDestinationGroupManagement/DicomDestinationGroupCreate'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={
                  routes.dictionaries.dicomDestinationGroup
                    .destinationGroupCreate.roles
                }
                redirectTo={routes['403'].path}
              >
                <DicomDestinationGroupCreate />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.dictionaries.dicomDestinationGroup.destinationGroupEdit
          .path,
        async lazy() {
          const { DicomDestinationGroupEdit } = await import(
            '../pages/dictionaries/destinationGroups/DicomDestinationGroupManagement/DicomDestinationGroupEdit'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={
                  routes.dictionaries.dicomDestinationGroup.destinationGroupEdit
                    .roles
                }
                redirectTo={routes['403'].path}
              >
                <DicomDestinationGroupEdit />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.patients.root.path,
        async lazy() {
          const { PatientsList } = await import(
            'pages/patients/PatientsList/PatientsList'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.patients.root.roles}
                redirectTo={routes['403'].path}
              >
                <PatientsList />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.patients.patientById.path,
        async lazy() {
          const { PatientManagement } = await import(
            '../pages/patients/PatientManagement/PatientManagement'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.patients.patientById.roles}
                redirectTo={routes['403'].path}
              >
                <PatientManagement />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.sharedStudies.root.path,
        async lazy() {
          const { SharedStudyList } = await import(
            '../pages/sharedStudies/SharedStudyList/SharedStudyList'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.sharedStudies.root.roles}
                redirectTo={routes['403'].path}
              >
                <SharedStudyList />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.sharedStudies.sharedStudyPreview.path,
        async lazy() {
          const { SharedStudyPreview } = await import(
            '../pages/sharedStudies/SharedStudyPreview'
          )

          return {
            element: (
              <ProtectedComponent
                allowedRoles={routes.sharedStudies.sharedStudyPreview.roles}
                redirectTo={routes['403'].path}
              >
                <SharedStudyPreview />
              </ProtectedComponent>
            ),
          }
        },
      },
      {
        path: routes.testError.path,
        element: (
          <ProtectedComponent
            allowedRoles={routes.testError.roles}
            redirectTo={routes['403'].path}
          >
            <TestError />
          </ProtectedComponent>
        ),
      },
      {
        path: routes['403'].path,
        Component: DeniedAccessPage,
      },
      {
        path: '*',
        Component: NotFoundPage,
      },
    ],
  },
])

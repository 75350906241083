/* eslint-disable */
// eslint wants to use single quotes for strings that contain single-quotes
// but prettier forces double quotes

export const Practices = {
  //common
  addNewPracticeTitle: 'Add New Practice',
  newPractice: 'New Practice',
  practiceInfoBlockTitle: 'Practice Info',
  addProviderBlockTitle: 'Add Provider',
  npi: 'NPI',
  practiceName: 'Practice Name',
  name: 'Name',
  country: 'Country',
  state: 'State',
  city: 'City',
  streetFirst: 'Street 1',
  streetSecond: 'Street 2',
  postalCode: 'Postal Code',
  timezone: 'Timezone',
  phoneNumber: 'Phone Number',
  faxNumber: 'Fax Number',
  primaryContactName: 'Primary Contact Name',
  primaryContactColumnName: 'Primary Contact',
  notes: 'Notes',
  searchProviderByNPI: 'Search physician by name or NPI',
  view: 'View',
  addNewProvider: 'Add New Provider',
  npiWillBeCreated:
    'Provider doesn’t have NPI number. System will generate an internal ID after saving.',
  clickHereToKnowThePhysicianNPI: "Don't know the Physician NPI? Click here",
  firstName: 'First Name',
  lastName: 'Last Name',
  middleName: 'Middle Name ',
  initials: 'Initials',
  taxonomy: 'Taxonomy',
  locationFilterTitle: 'Location',
  searchPlaceholder: 'Search by name, city or primary contact',

  transferAgentTitle: 'Transfer agents available for sharing',
  transferAgentLabel: 'Transfer Agents',
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { TTargetGroup } from 'services/dictionary/types'
import { TNewSupportRequestFormSchema } from 'pages/orders/OrderPreview/components/NewSupportRequestForm/newSupportRequestFormSchema'
import { TAddCommentFormSchema } from 'pages/support/SupportRequestWorkManagement/components/SupportRequestWorkComments/components/AddCommentForm/addCommentFormSchema'
import { TAvailableForChangeRequestTypes } from 'pages/support/SupportRequestWorkManagement/types'
import { SLICE_NAME } from 'store/support/constants'
import {
  SUPPORT_REQUEST_STATUS,
  TBffCommentPageRs,
  TBffSupportRequestListRequestParams,
  TBffSupportRequestListResponse,
  TBffSupportRequestRs,
  TCommentCreationRs,
  TCommentType,
  TInsurancePlanCreationRq,
  TSupportRequestCreationRs,
} from 'store/support/types'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'
import { prepareArrayForSending } from 'utils/helpers/arrays/prepareArrayForSending'
import { TQueryData } from 'types/general'

export const getSupportRequestList = createAsyncThunk<
  TBffSupportRequestListResponse,
  {
    params: Partial<TBffSupportRequestListRequestParams>
    abortSignal?: AbortSignal
  },
  { rejectValue: TServiceError }
>(
  `${SLICE_NAME}/getSupportRequestList`,
  async (
    {
      params: {
        targetGroups,
        supportRequestTypes,
        accessionNumbers,
        urgencies,
        siteIds,
        ...rest
      },
      abortSignal,
    },
    { rejectWithValue }
  ) => {
    const result = await request<TBffSupportRequestListResponse>({
      method: 'GET',
      url: '/bff/sr',
      params: {
        ...(targetGroups && {
          targetGroups: prepareArrayForSending(targetGroups),
        }),
        ...(supportRequestTypes && {
          supportRequestTypes: prepareArrayForSending(supportRequestTypes),
        }),
        ...(accessionNumbers && {
          accessionNumbers: prepareArrayForSending(accessionNumbers),
        }),
        ...(urgencies && { urgencies: prepareArrayForSending(urgencies) }),
        ...(siteIds && { siteIds: prepareArrayForSending(siteIds) }),
        ...rest,
      },
      signal: abortSignal,
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const getComments = createAsyncThunk<
  TBffCommentPageRs,
  {
    supportId: string
    params: Partial<TQueryData> & { commentType: TCommentType }
    abortSignal?: AbortSignal
  },
  { rejectValue: TServiceError }
>(
  `${SLICE_NAME}/getComments`,
  async ({ params, supportId, abortSignal }, { rejectWithValue }) => {
    const result = await request<TBffCommentPageRs>({
      method: 'GET',
      url: `/bff/sr/${supportId}/comments`,
      params,
      signal: abortSignal,
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const getSupportRequestById = createAsyncThunk<
  TBffSupportRequestRs,
  string,
  { rejectValue: TServiceError }
>(`${SLICE_NAME}/getSupportRequestById`, async (id, { rejectWithValue }) => {
  const result = await request<TBffSupportRequestRs>({
    method: 'GET',
    url: `/bff/sr/${id}`,
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const createSupportRequest = createAsyncThunk<
  TSupportRequestCreationRs,
  TNewSupportRequestFormSchema & { accessionNumber: string; siteId: number },
  {
    rejectValue: TServiceError
  }
>(
  `${SLICE_NAME}/createSupportRequest`,
  async (
    { accessionNumber, targetGroup, requestType, ...payload },
    { rejectWithValue }
  ) => {
    const result = await request<TSupportRequestCreationRs>({
      method: 'POST',
      url: `/sr/order/${accessionNumber}`,
      data: {
        ...payload,
        requestTypeId: requestType?.id ?? null,
        targetGroupId: targetGroup?.id ?? null,
      },
      headers: {
        AccessionNumber: accessionNumber,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const createSupportRequestForNewInsurance = createAsyncThunk<
  unknown,
  TInsurancePlanCreationRq & { accessionNumber: string },
  {
    rejectValue: TServiceError
  }
>(
  `${SLICE_NAME}/createSupportRequestForNewInsurance`,
  async ({ accessionNumber, ...data }, { rejectWithValue }) => {
    const result = await request<unknown>({
      method: 'POST',
      url: `/sr/order/${accessionNumber}/insurances/plan`,
      data,
      headers: {
        AccessionNumber: accessionNumber,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const addSupportRequestComment = createAsyncThunk<
  TCommentCreationRs,
  TAddCommentFormSchema & { supportId: string; type: TCommentType },
  {
    rejectValue: TServiceError
  }
>(
  `${SLICE_NAME}/addSupportRequestComment`,
  async ({ supportId, ...data }, { rejectWithValue }) => {
    const result = await request<TCommentCreationRs>({
      url: `/sr/${supportId}/comments`,
      data,
      method: 'POST',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const deleteSupportRequestComment = createAsyncThunk<
  unknown,
  { supportId: string; commentId: string },
  {
    rejectValue: TServiceError
  }
>(
  `${SLICE_NAME}/deleteSupportRequestComment`,
  async ({ supportId, commentId }, { rejectWithValue }) => {
    const result = await request<unknown>({
      url: `/sr/${supportId}/comments/${commentId}`,
      method: 'DELETE',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const updateSupportRequestTargetGroupById = createAsyncThunk<
  void,
  { supportRequestId: number; targetGroup: TTargetGroup },
  {
    rejectValue: TServiceError
  }
>(
  `${SLICE_NAME}/updateSupportRequestTargetGroupById`,
  async ({ supportRequestId, targetGroup }, { rejectWithValue }) => {
    const result = await request<void>({
      url: `/sr/${supportRequestId}/target-group`,
      data: {
        targetGroupId: targetGroup.id,
      },
      method: 'PATCH',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const updateSupportRequestTypeById = createAsyncThunk<
  void,
  {
    supportRequestId: number
    type: TAvailableForChangeRequestTypes
  },
  {
    rejectValue: TServiceError
  }
>(
  `${SLICE_NAME}/updateSupportRequestTypeById`,
  async ({ supportRequestId, type }, { rejectWithValue }) => {
    const result = await request<void>({
      url: `/sr/${supportRequestId}/type`,
      data: {
        type,
      },
      method: 'PATCH',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const updateSupportRequestStatusById = createAsyncThunk<
  void,
  { supportRequestId: number; status: SUPPORT_REQUEST_STATUS },
  {
    rejectValue: TServiceError
  }
>(
  `${SLICE_NAME}/updateSupportRequestStatusById`,
  async ({ supportRequestId, status }, { rejectWithValue }) => {
    const result = await request<void>({
      url: `/sr/${supportRequestId}/status`,
      data: {
        status,
      },
      method: 'PATCH',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

import { FC } from 'react'
import { generatePath } from 'react-router-dom'
import { Variant } from '@mui/material/styles/createTypography'
import { routes } from 'routes/routes'
import { TDegree } from 'services/dictionary/types'

import { ProviderNameWithDegrees } from './components/ProviderNameWithDegrees'
import { StyledContainer } from './ProviderDisplayName.styled'

interface IProviderDisplayNameProps {
  providerId?: string
  fullName: string
  degrees?: TDegree[]
  fontVariant?: Variant
  disableLink?: boolean
  isInline?: boolean
  className?: string
}

export const ProviderDisplayName: FC<IProviderDisplayNameProps> = ({
  providerId,
  fullName,
  degrees = [],
  fontVariant = 'body2',
  disableLink = false,
  isInline = false,
  className,
}) => {
  const providerLink = providerId
    ? generatePath(routes.providers.providerById.path, { providerId })
    : ''

  return disableLink ? (
    <ProviderNameWithDegrees
      fullName={fullName}
      degrees={degrees}
      fontVariant={fontVariant}
      isInline={isInline}
    />
  ) : (
    <StyledContainer href={providerLink} className={className}>
      <ProviderNameWithDegrees
        fullName={fullName}
        degrees={degrees}
        fontVariant={fontVariant}
        isInline={isInline}
      />
    </StyledContainer>
  )
}

import { FC, PropsWithChildren } from 'react'
import { Box, CircularProgress } from '@mui/material'

interface ILoaderWrapperProps {
  isLoading?: boolean
  size?: number
  mt?: string | number
  mb?: string | number
}

export const LoaderWrapper: FC<PropsWithChildren<ILoaderWrapperProps>> = ({
  mt = 0,
  mb = 0,
  children,
  isLoading,
  size = 24,
}) => {
  if (isLoading) {
    return (
      <Box display='flex' justifyContent='center' mt={mt} mb={mb}>
        <CircularProgress size={size} />
      </Box>
    )
  }

  return <>{children}</>
}

import { Box, styled, Typography } from '@mui/material'
import { HEADER_AND_PAGE_CONTAINER_PADDINGS_HEIGHT_SUM } from 'constants/layoutDimensions'

export const StyledContainer = styled(Box)({
  minHeight: `calc(100vh - ${HEADER_AND_PAGE_CONTAINER_PADDINGS_HEIGHT_SUM}px)`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

export const StyledDescription = styled(Typography)(
  ({ theme: { palette } }) => ({
    width: '377px',
    textAlign: 'center',
    color: palette.text.secondary,
  })
)

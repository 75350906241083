import { styled } from '@mui/material'
import { LinkWithBreadcrumbs } from 'components/BreadcrumbsProvider/LinkWithBreadcrumbs'

export const StyledContainer = styled(LinkWithBreadcrumbs)(({ href }) => ({
  textDecoration: 'none',
  color: 'unset',
  ...(href
    ? {
        '&:hover': {
          textDecoration: 'underline',
          cursor: 'pointer',
        },
      }
    : {
        pointerEvents: 'none',
      }),
}))

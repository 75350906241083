import { FC } from 'react'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { IconButton, useMediaQuery } from '@mui/material'
import LogoLight from 'assets/images/logo.svg'
import LogoDark from 'assets/images/logoDark.svg'
import { TEmptyFunction } from 'types/general'

import { StyledContainer, StyledLogoContainer } from './SideBarHeader.styled'

interface ISideBarHeaderProps {
  isOpen: boolean
  isDarkMode: boolean
  onChevronClick: TEmptyFunction
}

export const SideBarHeader: FC<ISideBarHeaderProps> = ({
  isOpen,
  isDarkMode,
  onChevronClick,
}) => {
  const isSmallScreen = useMediaQuery('(max-width:1024px)')
  const Logo = isDarkMode ? LogoDark : LogoLight

  return (
    <StyledContainer isOpen={isOpen} isSmallScreen={isSmallScreen}>
      <StyledLogoContainer isOpen={isOpen} isSmallScreen={isSmallScreen}>
        <Logo />
      </StyledLogoContainer>
      {isSmallScreen ? null : (
        <IconButton onClick={onChevronClick}>
          {isOpen ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      )}
    </StyledContainer>
  )
}

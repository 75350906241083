import { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { ERROR_CODE } from 'constants/errorCode'
import { IColumnConfigItem } from 'components/ui-components/PSTable/types'
import { getFirebaseToken } from 'store/auth/selectors'
import { initiateDownloadOrderListCSVReport } from 'store/filesDownloading/controllers'
import {
  getOrderListCSVDownloadProgress,
  getOrderListCSVDownloadProgressByEventId,
  getTabId,
} from 'store/filesDownloading/selectors'
import { removeCSVReportDownloading } from 'store/filesDownloading/slice'
import { TAllOrderColumns } from 'store/filters/orders/types'
import { getAllOrderTableConfiguration } from 'store/filters/selectors'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { ORDERS_TABLE_TAB } from 'store/ordersList/types'
import { FireBaseNotificationContext } from 'utils/FireBaseNotifications/FireBaseNotificationProvider'
import { generateIdUUIDv4 } from 'utils/helpers/strings/generateIdUUIDv4'
import { useSetFirebaseNotificationToken } from 'utils/hooks/network/useSetFirebaseNotificationToken'
import { usePSSnackbar } from 'utils/hooks/snackbar/usePSSnackbar'

import { mapSearchFiltersToBackEndParams } from '../helpers/mapSearchFiltersToBackEndParams'

export const useDownloadOrderListCsvReport = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { setFirebaseNotificationToken } = useSetFirebaseNotificationToken()
  const { openProgressSnackbar } = usePSSnackbar()

  const { showEnableNotificationModal } = useContext(
    FireBaseNotificationContext
  )
  const downloadProgressData = useAppSelector(getOrderListCSVDownloadProgress)
  const firebaseToken = useAppSelector(getFirebaseToken)
  const tabId = useAppSelector(getTabId, shallowEqual)
  const { filtersData, queryData } = useAppSelector(
    getAllOrderTableConfiguration
  )

  const downloadPromiseRef = useRef<Promise<unknown> | null>(null)

  const initiateDownloadOrderListCsvReport = (downloadKey: string) => {
    openProgressSnackbar(
      t('domains.RISOrders.csvReportGeneration'),
      getOrderListCSVDownloadProgressByEventId(downloadKey),
      {
        hasCloseButton: true,
        onProgressFinish: () => {
          dispatch(removeCSVReportDownloading(downloadKey))
        },
      }
    )
  }

  const sendTokenAndInitiateDownloadOrderListCsvReport = (
    tableConfig: IColumnConfigItem[],
    columnsMap: Record<string, TAllOrderColumns>
  ) => {
    const downloadKey = generateIdUUIDv4()

    const tableLabels = tableConfig
      .map(({ key }) => (key ? columnsMap[key] : undefined))
      .filter(Boolean) as TAllOrderColumns[]

    downloadPromiseRef.current = setFirebaseNotificationToken(firebaseToken)
      .then(() => {
        return dispatch(
          initiateDownloadOrderListCSVReport({
            filterParams: {
              ...mapSearchFiltersToBackEndParams(filtersData),
              sort: queryData.sort,
            },
            tableLabels,
            tabName: ORDERS_TABLE_TAB.ALL,
            eventId: downloadKey,
            tabId,
          })
        ).then((resultAction) => {
          if (
            initiateDownloadOrderListCSVReport.fulfilled.match(resultAction)
          ) {
            initiateDownloadOrderListCsvReport(downloadKey)
          }

          if (initiateDownloadOrderListCSVReport.rejected.match(resultAction)) {
            const errorCode = resultAction.payload?.errorCodes?.[0]

            return Promise.reject(errorCode)
          }

          return Promise.resolve(resultAction.payload)
        })
      })
      .catch((error) => {
        if (error === ERROR_CODE.BROWSER_API_NOTIFICATION_DISABLED) {
          showEnableNotificationModal()
        } else {
          throw error
        }
      })
      .finally(() => {
        downloadPromiseRef.current = null
      })

    return downloadPromiseRef.current
  }

  const downloadOrderListCsvReport = (
    tableConfig: IColumnConfigItem[],
    columnsMap: Record<string, TAllOrderColumns>
  ) => {
    if (downloadPromiseRef.current) {
      return downloadPromiseRef.current
    }

    if (Object.keys(downloadProgressData).length) {
      return Promise.resolve()
    }

    return sendTokenAndInitiateDownloadOrderListCsvReport(
      tableConfig,
      columnsMap
    )
  }

  return { downloadOrderListCsvReport }
}

import { MouseEvent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Settings } from '@mui/icons-material'
import {
  Checkbox,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material'
import { TTranslationKey } from 'types/general'

import { gearAnchorOrigin } from './constants'

interface IConfigureGearHeaderCellProps<T extends string> {
  options: { [key in T]: TTranslationKey }
  columnsConfig: { [key in T]: boolean }
  onOptionsChange: (columnName: T) => void
}

export const ConfigureGearHeaderCell = <K extends string>({
  options,
  columnsConfig,
  onOptionsChange,
}: IConfigureGearHeaderCellProps<K>) => {
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const hiddenOptionsButtonRef = useRef<null | HTMLButtonElement>(null)

  const toggleOpenMenu = (event?: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl((prevState) =>
      event && !prevState ? event.currentTarget : null
    )
  }

  const handleClose = () => {
    toggleOpenMenu()
  }

  return (
    <>
      <IconButton
        data-cy='configureColumns'
        title={t('domains.Providers.configureColumns')}
        ref={hiddenOptionsButtonRef}
        onClick={toggleOpenMenu}
      >
        <Settings />
      </IconButton>
      <Menu
        anchorEl={hiddenOptionsButtonRef.current}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={gearAnchorOrigin}
        transformOrigin={gearAnchorOrigin}
      >
        {(Object.keys(options) as Array<keyof typeof options>).map(
          (columnName) => (
            <MenuItem
              key={columnName}
              onClick={(_) => onOptionsChange(columnName)}
            >
              <Checkbox checked={columnsConfig[columnName]} />
              <ListItemText primary={t(options[columnName])} />
            </MenuItem>
          )
        )}
      </Menu>
    </>
  )
}

import { routes } from 'routes/routes'

import { TProviderTab } from './types'

export const PROVIDERS_TABS: TProviderTab[] = [
  {
    path: routes.providers.radiologists.path,
    allowedRoles: routes.providers.radiologists.roles,
    label: 'domains.Providers.radiologists',
  },
  {
    path: routes.providers.referringProvider.path,
    allowedRoles: routes.providers.referringProvider.roles,
    label: 'domains.Providers.referringProviders',
  },
]

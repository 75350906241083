import { createTransform } from 'redux-persist'
import { customPeriodOption } from 'components/filters/DateRangeFilter/constants'
import { DEFAULT_BILLING_ORDERS_TABLE_BILLING_STATUS_FILTER } from 'store/filters/orders/constants'
import { filtersReducer } from 'store/filters/slice'
import { ORDERS_TABLE_TAB } from 'store/ordersList/types'
import { addDay } from 'utils/helpers/date/addDay'
import { subDay } from 'utils/helpers/date/subDay'
import { getValuable } from 'utils/helpers/general'

export type TFiltersSliceState = ReturnType<typeof filtersReducer>

export const SetFiltersSliceTransform = createTransform<
  TFiltersSliceState,
  TFiltersSliceState
>(
  (state) => {
    return getValuable(state) as TFiltersSliceState
  },
  ({ ordersFilters, ...rest }) => {
    const allOrdersTableData = ordersFilters?.[ORDERS_TABLE_TAB.ALL]
    const billingOrdersTableData = ordersFilters?.[ORDERS_TABLE_TAB.BILLING]

    return {
      ordersFilters: {
        ...ordersFilters,
        [ORDERS_TABLE_TAB.ALL]: {
          ...allOrdersTableData,
          filtersData: {
            ...allOrdersTableData?.filtersData,
            dateOfService: {
              period:
                allOrdersTableData?.filtersData?.dateOfService?.period ??
                customPeriodOption,
              from: allOrdersTableData?.filtersData?.dateOfService?.from
                ? allOrdersTableData.filtersData.dateOfService.from
                : subDay(new Date(), 30).toISOString(),
              to: allOrdersTableData?.filtersData?.dateOfService?.to
                ? allOrdersTableData.filtersData.dateOfService.to
                : addDay(new Date(), 30).toISOString(),
            },
          },
        },
        [ORDERS_TABLE_TAB.BILLING]: {
          ...billingOrdersTableData,
          filtersData: {
            ...billingOrdersTableData.filtersData,
            billingStatuses: billingOrdersTableData?.filtersData
              ?.billingStatuses?.length
              ? billingOrdersTableData.filtersData.billingStatuses
              : DEFAULT_BILLING_ORDERS_TABLE_BILLING_STATUS_FILTER,
          },
        },
      },
      ...rest,
    }
  },
  { whitelist: ['filters'] }
)

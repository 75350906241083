import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@mui/material'
import { TTagRs } from 'store/tags/types'
import { groupTagByType } from 'utils/helpers/tags/groupTagByType'

import {
  StyledTagBox,
  StyledTagContainer,
  StyledTagWrapper,
} from './TagsCell.styled'

interface ITagsCellProps {
  data: TTagRs[]
}

export const TagsCell: FC<ITagsCellProps> = ({ data }) => {
  const { t } = useTranslation()

  if (!data?.length) return <>{t('components.table.notApplicable')}</>

  const tagsByType = groupTagByType(data)

  const { ADMIN, CUSTOMER } = tagsByType

  return (
    <StyledTagWrapper>
      {ADMIN.length ? (
        <StyledTagContainer>
          {ADMIN.map((tag) => (
            <Tooltip key={tag.id} placement='top' title={tag.name} arrow>
              <StyledTagBox bgColor={tag.color} isRound />
            </Tooltip>
          ))}
        </StyledTagContainer>
      ) : null}
      {CUSTOMER.length ? (
        <StyledTagContainer mt={1}>
          {CUSTOMER.map((tag) => (
            <Tooltip key={tag.id} placement='top' title={tag.name} arrow>
              <StyledTagBox bgColor={tag.color} />
            </Tooltip>
          ))}
        </StyledTagContainer>
      ) : null}
    </StyledTagWrapper>
  )
}

import { useCallback } from 'react'
import { ERROR_CODE } from 'constants/errorCode'
import { setFirebaseNotificationTokenRq } from 'store/auth/controllers'
import { getIsNotificationGranted } from 'store/auth/selectors'
import { useAppDispatch, useAppSelector } from 'store/hooks'

export const useSetFirebaseNotificationToken = () => {
  const dispatch = useAppDispatch()
  const isNotificationGranted = useAppSelector(getIsNotificationGranted)

  const setFirebaseNotificationToken = useCallback(
    (currentToken: string | null) => {
      if (!isNotificationGranted) {
        return Promise.reject(ERROR_CODE.BROWSER_API_NOTIFICATION_DISABLED)
      }

      return currentToken === null
        ? Promise.reject(
            new Error('Set firebase notification error - missed token')
          )
        : dispatch(
            setFirebaseNotificationTokenRq({ firebaseToken: currentToken })
          ).then((response) => {
            if (setFirebaseNotificationTokenRq.rejected.match(response)) {
              return Promise.reject(
                new Error(
                  `Set firebase notification token errorCode - ${response.payload?.errorCodes?.[0]}`
                )
              )
            }

            return Promise.resolve()
          })
    },
    [dispatch, isNotificationGranted]
  )

  return { setFirebaseNotificationToken }
}

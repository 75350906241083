import { ORDER_STATUS_ENUM } from 'store/order/types'

export const MIGRATED_IMPORTED_REVIEW_IMPORTED_CANCELED_ORDER_STATUSES = [
  ORDER_STATUS_ENUM.MIGRATED,
  ORDER_STATUS_ENUM.IMPORTED_REVIEW,
  ORDER_STATUS_ENUM.IMPORTED_CANCELED,
]

export const SCHEDULED_AND_PENDING_SUBMISSION_STATUSES = [
  ORDER_STATUS_ENUM.SCHEDULED,
  ORDER_STATUS_ENUM.PENDING_SUBMISSION,
]

export const SEARCH_PARAM_OPEN_FROM = 'from'

export const OPEN_FROM_REPORT_QA = 'reportQa'

import { Link, styled } from '@mui/material'

export const StyledLink = styled(Link)(({ theme: { palette } }) => ({
  display: 'flex',
  textDecoration: 'none',
  alignItems: 'center',
  fontWeight: 500,
  '&[aria-disabled="true"]': {
    color: palette.action.disabled,
    pointerEvents: 'none',
  },
}))

import axios, { AxiosInstance, AxiosRequestConfig, isAxiosError } from 'axios'
import { configs } from 'constants/configs'
import { ERROR_CODE } from 'constants/errorCode'
import { oidcConfig } from 'keycloak'

import { getAxiosErrors } from './helpers/getAxiosErrors'
import { TServiceError } from './types'

const baseURL = configs.API_ENDPOINT

const axiosInstance = axios.create({
  baseURL,
})

axiosInstance.interceptors.request.use((config) => {
  try {
    const user = JSON.parse(
      localStorage.getItem(
        `oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`
      ) ?? ''
    )
    config.headers['Authorization'] = `Bearer ${user.access_token}`
    return config
  } catch {
    return config
  }
})

export type ResponseData<T, E = TServiceError> =
  | {
      data: T
      error: null
    }
  | {
      error: E
      data: null
    }

export const request =
  (instance: AxiosInstance = axiosInstance) =>
  async <T>(config: AxiosRequestConfig): Promise<ResponseData<T>> => {
    try {
      const response = await instance.request<T>(config)
      return { data: response.data, error: null }
    } catch (error) {
      if (isAxiosError(error)) {
        return {
          data: null,
          error: {
            statusCode: error.response?.status ?? 0,
            errorCodes: getAxiosErrors(
              error.code,
              error.response?.data.errorCodes
            ),
          },
        }
      }
      return {
        data: null,
        error: {
          statusCode: 0,
          errorCodes: [ERROR_CODE.NETWORK_ERROR],
        },
      }
    }
  }

export default request()

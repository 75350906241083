import { useContext } from 'react'
import { ColorModeContext } from 'components/ColorModeProvider'
import { getIsUndefined } from 'utils/helpers/general'

export const useColorMode = () => {
  const colorMode = useContext(ColorModeContext)

  if (getIsUndefined(colorMode)) {
    throw new Error('useColorMode must be used within a ColorModeProvider')
  }

  return colorMode
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ORDERS_TABLE_TAB } from 'store/ordersList/types'
import { LOADING_STATE } from 'types/general'

import { initialState, sliceName } from './constants'
import { getOrders } from './controllers'

const ordersListSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    toggleOpenTableFilter: (state) => {
      state.isFilterPanelOpen = !state.isFilterPanelOpen
    },
    // row selection reducers
    toggleSelectAllAssignmentTableRowsOnPage: (
      state,
      {
        payload: { isSelected },
      }: PayloadAction<{
        isSelected: boolean
      }>
    ) => {
      const assignmentTableData = state[ORDERS_TABLE_TAB.ASSIGNMENT]

      assignmentTableData.selectedRows = {
        ...assignmentTableData.selectedRows,
        ...assignmentTableData.content.reduce<{
          [accessionNumber: string]: boolean
        }>((result, { accessionNumber, isLocked }) => {
          if (!isLocked) {
            result[accessionNumber] = isSelected
          }

          return result
        }, {}),
      }
    },
    toggleSelectAssignmentTableRow: (
      state,
      {
        payload: { accessionNumber },
      }: PayloadAction<{
        accessionNumber: string
      }>
    ) => {
      const assignmentTableData = state[ORDERS_TABLE_TAB.ASSIGNMENT]

      assignmentTableData.selectedRows = {
        ...assignmentTableData.selectedRows,
        [accessionNumber]: !assignmentTableData.selectedRows[accessionNumber],
      }
    },
    resetAssignmentOrdersSelectedRowsData: (state) => {
      state[ORDERS_TABLE_TAB.ASSIGNMENT].selectedRows =
        initialState[ORDERS_TABLE_TAB.ASSIGNMENT].selectedRows
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(getOrders.rejected, (state) => {
        state.loadingState = LOADING_STATE.rejected
      })
      .addCase(getOrders.fulfilled, (state, { payload }) => {
        const { tableName, data } = payload

        state[tableName].content = data.content
        state[tableName].totalElements = data.totalElements
        state[tableName].totalPages = data.totalPages
        state[tableName].isFirstPage = data.first
        state[tableName].isLastPage = data.last
        state.loadingState = LOADING_STATE.fulfilled
      })
  },
})

export const {
  toggleOpenTableFilter,
  toggleSelectAllAssignmentTableRowsOnPage,
  toggleSelectAssignmentTableRow,
  resetAssignmentOrdersSelectedRowsData,
} = ordersListSlice.actions
export default ordersListSlice

export const enOrderBillingStatuses = {
  BILLING_INFO_COMPLETE: 'Billing Info Complete',
  BILLING_NOT_APPLICABLE: 'Billing Not Applicable',
  BILLING_VERIFIED: 'Billing info verified',
  CHARGES_RETRIEVED: 'Charges Retrieved',
  PENDING_BILLING_INFO: 'Pending Billing information',
  PENDING_REVERT: 'Pending Revert',
  REVERT: 'Revert',
  UNDEFINED: 'Undefined',
}

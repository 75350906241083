import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IValidationBlockConfig } from 'components/FormValidationStatusBlock'
import {
  VALIDATION_PROGRESS_INITIAL_STATE,
  VALIDATION_PROGRESS_SLICE_NAME,
} from 'store/validationProgress/constants'

const validationProgressSlice = createSlice({
  name: VALIDATION_PROGRESS_SLICE_NAME,
  initialState: VALIDATION_PROGRESS_INITIAL_STATE,
  reducers: {
    setIsValidationProgressBarDisplay: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.IsValidationProgressBarDisplay = payload
    },
    setValidationProgressStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        percentage: number
        configs: IValidationBlockConfig[]
        isValidToSubmit: boolean
      }>
    ) => {
      state.configs = payload.configs
      state.readinessPercentage = payload.percentage
      state.isValidToSubmit = payload.isValidToSubmit
    },
    setValidationProgressInputToFocus: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.inputToFocus = payload
    },
    resetValidationProgress: () => {
      return VALIDATION_PROGRESS_INITIAL_STATE
    },
  },
})

export const {
  setIsValidationProgressBarDisplay,
  setValidationProgressStatus,
  setValidationProgressInputToFocus,
  resetValidationProgress,
} = validationProgressSlice.actions

export default validationProgressSlice

export const Providers = {
  //common
  orderingProvider: 'Ordering Provider',
  providerInfo: 'Provider Info',
  thirdPartyID: 'Third Party ID',
  addThirdPartyID: 'Add Third Party ID',
  editThirdPartyID: 'Edit Third Party ID',
  thirdParty: 'Third Party',
  id: 'ID',
  phoneNumbers: 'Phone Numbers',
  dragFaxesHint:
    'Drag faxes in priority order where the top one is the primary one',
  phoneNumber: 'Phone Number',
  faxNumber: 'Fax Number',
  addPhoneNumber: 'Add Phone Number',
  editPhoneNumber: 'Edit Phone Number',
  addFax: 'Add Fax',
  editFax: 'Edit Fax',
  allowedToBeDistributedToNonSystemUsers:
    'Allowed to be distributed to non-system users',
  allowFaxAutoDelivery: 'Allow Fax Auto-Delivery',
  dragRadiologistsHint:
    'Drag contact radiologist in preferred assignment panel where the top one is the primary one',
  dragPhoneNumbersHint:
    'Drag numbers in priority order where the top one is the primary one',
  phoneNumberType: 'Phone number type',
  providerCreated: 'New Provider has been successfully created',
  updatePhoneNumber: 'Update Phone Number',
  updateNumber: 'Update Number',
  preferredAssignmentPanel: 'Preferred Assignment Panel',
  preferredAssignmentPanelHint:
    'Companies and sites whose studies this radiologist should be prioritized to read',
  doNotAssignPanel: 'Do Not Assign Panel',
  doNotAssignProceduresHint: 'Select procedures that a radiologist cannot read',
  doNotAssignPanelHint:
    'Companies and sites whose studies this radiologist should not be prioritized to read',
  providersManagement: 'Providers management',
  privilegeCredentialing: 'Privilege Credentialing',
  privilegeCredentialingHint:
    'Companies and sites where this radiologist has privilege credentialing',
  addedSites_one: '{{count}} site',
  addedSites_other: '{{count}} sites',
  contactPreferences: 'Contact Preferences',
  uploadLabel:
    'Upload or drop a document in {{availableHeaderFormats}}. Recommended size is {{recommendedSize}}px. Max size {{maxSize}}MB',
  dragContactPreferencesHint:
    'Drag contact preferences in priority order where the top one is the primary one',
  siteAlreadyUsed: '{{siteNames}} already assigned',
  npi: 'NPI',
  risId: 'RisId',
  npiExistError: 'Provider with the same NPI already registered.',
  nowEditThisNpi: 'You are editing this NPI',
  npiWillBeCreated:
    'Provider doesn’t have NPI number. System will generate an internal ID after saving.',
  npiValidation: 'The NPI field should be a 10-digit numeric input type',
  editNPI: 'Edit NPI',
  creationWarning:
    'Provider’s creation will start from the beginning if you update NPI.',
  found: 'Found',
  npiLinkForSearch: 'Don’t know the Provider NPI? Click here',
  npiNotFound: 'Provider with this NPI is not found.',
  npiSearch: 'Search',
  firstName: 'First name',
  middleName: 'Middle name',
  lastName: 'Last name',
  initials: 'Initials',
  country: 'Country',
  degrees: 'Degrees',
  practice: 'Practice',
  type: 'Provider type',
  nonVisibleForAssignmentLabel: 'Non-visible for assignment',
  isVerified: 'Verified',
  radiologistsGroup: 'Radiologists Group',
  onCallRadiologistsGroup: 'On-Call Radiologists Group',
  allRadiologistsGroups: 'All Radiologists Groups',
  preliminaryStudies: 'Preliminary Studies',
  allowPreliminaryStudies: 'Allow preliminary Studies',
  notes: 'Notes',
  timezone: 'Timezone',
  taxonomy: 'Taxonomy',
  rvuTarget: 'RVU Target',
  doNotAssignProcedure: 'Do Not Assign Procedure',
  excludeProcedures: 'Exclude procedures',
  addProcedure: 'Add Procedure',
  signature: 'Rad. Signature',
  specificDetails: 'Specific details',
  associatedCompanies: 'Associated companies/sites',
  associatedPractices: 'Associated practices',
  subSpecialty: 'Sub-specialty',
  employmentType: 'Type',
  justCapitalLettersError: 'Field must to have juxt capital letters',
  emailError: 'Invalid format email',
  invalidFormat: 'Invalid format',
  phoneError: 'Invalid format phone',
  thirdPartyError: 'Fields must not be empty',
  radiologist: 'Radiologist',
  referringPhysician: 'Referring Physician',
  radiologists: 'Radiologists',
  referringProviders: 'Referring Providers',
  searchLabel: 'Search provider by name or NPI',
  onCallAssignmentHeader: 'On-Call Radiologist Assignment',
  assignmentTitle: 'Assignment',
  onCallDateLabel: 'On-Call date',
  fromLabel: 'From',
  toLabel: 'To',
  radiologistIsOnCallNowTitle: 'Radiologist is On-Call now',
  assignmentSuccessfullyRemoved:
    'On-Call Radiologist Assignment has been successfully removed',
  onCallRadiologistTableHeader: 'On-Call radiologist',

  // faxes
  faxNumberType: 'Fax number type',
  faxNumberLocation: 'Fax number location',
  providerFaxes: 'Provider faxes',
  faxes: 'Faxes',
  disableKeyImagesForFax: 'Disable key images for Fax deliver',
  disableReportAppendixForFax: 'Disable report appendix for Fax delivery',
  addFaxNumber: 'Add Fax Number',

  //phoneTypes & emailTypes
  office: 'Office',
  proScan: 'ProScan',
  personal: 'Personal',
  OFFICE: 'Office',
  MOBILE: 'Mobile',
  OTHER: 'Other',
  PERSONAL_CELL: 'Personal cell',
  COMPANY_CELL: 'Company cell',
  HOME: 'Home',
  PROSCAN: 'ProScan',
  PERSONAL: 'Personal',
  CELL: 'Cell',

  //emails
  emails: 'Emails',
  addNew: 'Add New Email',
  addEmail: 'Add Email',
  editEmail: 'Edit Email',
  dragHint:
    'Drag emails in priority order where the top one is the primary one',
  updateEmail: 'Update Email',
  emailType: 'Email type',
  email: 'Email',

  // notifications
  notificationsHeader: 'Notifications',
  notificationsHeaderDescription:
    'Select parameters for Radiologist’s notifications',
  allRISOrdersOption: 'All RIS-Orders',
  urgentRISOrdersOption: 'Urgent RIS-Orders',
  onCallOnlyOption: 'On-Call only',
  smsHeader: 'SMS',
  notificationRequiredContactError:
    'Select at least one contact method for notifications',
  atLeastOneUrgencyOptionError: 'Select at least one Urgency',
  atLeastOneContactMethodError:
    'Select at least one contact method for notifications',

  //licenses
  medicalLicenses: 'Medical Licenses',
  expiredMedicalLicenses: 'Expired Medical Licenses',
  licenseN: 'License {{n}}',
  licenseCountry: 'Country',
  state: 'State',
  licenseNumber: 'License Number',
  issuedDate: 'Issued date',
  expirationDate: 'Expiration date',
  addMedicalLicense: 'Add Medical License',
  medicalLicenseError: 'Field all must be filled',

  //employmentTypes
  Attending: 'Attending',
  'Initial Reader': 'Initial Reader',
  'Resident / Fellow': 'Resident / Fellow',
  'Physician Assistant': 'Physician Assistant',

  //types
  RADIOLOGIST: 'Radiologist',
  REFERENT_PHYSICIAN: 'Referring Provider',

  //filters
  filters: 'Filters',
  subSpecialties: 'Sub-specialties',
  showMore: 'Show more',
  showLess: 'Show less',
  license: 'License',
  filterCountry: 'Country',
  filterState: 'State',
  clearAll: 'Clear All',
  notVerifiedOnly: 'Not-verified Providers',
  showNotVerified: 'Show not-verified providers',

  //tables
  name: 'Name',
  isPrivileged: 'Privilege Credentialing',
  configureColumns: 'Configure columns',
  andMore: 'and {{n}} more',
  view: 'View',

  //providerPhysicians
  providerPhysiciansName: 'Name',
  providerPhysiciansPhoneNumbers: 'Phone Number',
  providerPhysiciansEmail: 'Email',
  providerPhysiciansFax: 'Fax Number',
  providerPhysiciansCompanies: 'Companies',
  providerPhysiciansSites: 'Sites',
  providerPhysiciansPractice: 'Practice',
  providerPhysiciansNpi: 'NPI',
  providerPhysiciansTaxonomies: 'Taxonomies',
  morePhones: 'More than one phone',
  moreEmails: 'More than one email',
  moreFaxes: 'More than one fax',
  taxonomyFiltersTitle: 'Taxonomies',
  practiceFiltersTitle: 'Practice',
  siteFiltersTitle: 'Associated sites',
  site: 'Site',
}

import { LOADING_STATE } from 'types/general'

import { type IState } from './types'

export const PROVIDERS_SLICE_NAME = 'providers'

export const INITIAL_STATE: IState = {
  providerInfo: undefined,
  error: null,
  loadingState: LOADING_STATE.idle,
  radiologistsFilterData: {
    countries: [],
    states: [],
  },
  checkProvider: {
    loadingState: LOADING_STATE.idle,
    error: undefined,
    data: undefined,
  },
  onCallAssignment: {
    table: {
      content: [],
      first: false,
      last: false,
      totalElements: 0,
      totalPages: 0,
    },
    query: {
      page: 0,
      size: 10,
    },
    editAssignmentId: null,
    loadingState: LOADING_STATE.idle,
    isFormLoading: false,
  },
  providerFaxes: {
    isSubmitting: false,
    loadingState: LOADING_STATE.idle,
    orderFaxes: {
      providers: [],
      siteFaxes: [],
      otherFaxes: [],
      disableKeyImages: false,
      disableReportAppendix: false,
    },
  },
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _merge from 'lodash/merge'
import { REHYDRATE } from 'redux-persist'
import { TPracticesFilters } from 'store/practice/types'
import { TQueryData } from 'types/general'

import { initialState, PRACTICE_FILTERS_SLICE_NAME } from './constants'
import { PRACTICE_CONFIGURABLE_COLUMNS } from './types'

const practiceFilterSlice = createSlice({
  name: PRACTICE_FILTERS_SLICE_NAME,
  initialState,
  reducers: {
    changeQueryTableData: (
      state,
      { payload: query }: PayloadAction<Partial<TQueryData>>
    ) => {
      state.queryData = {
        ...state.queryData,
        ...query,
      }
    },
    removeCountryFilterOptionById: (state, { payload }) => {
      state.filtersData = {
        ...state.filtersData,
        countries: state.filtersData.countries.filter(
          (option) => option !== payload
        ),
      }
      state.queryData.page = 0
    },
    removeStateFilterOptionById: (state, { payload }) => {
      state.filtersData = {
        ...state.filtersData,
        states: state.filtersData.states.filter((option) => option !== payload),
      }
      state.queryData.page = 0
    },
    changeFilterTableData: (
      state,
      { payload: data }: PayloadAction<Partial<TPracticesFilters>>
    ) => {
      state.filtersData = {
        ...state.filtersData,
        ...data,
      }
      state.queryData.page = 0
    },
    configureTableColumns: (
      state,
      {
        payload: { columnName },
      }: PayloadAction<{ columnName: PRACTICE_CONFIGURABLE_COLUMNS }>
    ) => {
      state.columnsConfiguration = {
        ...state.columnsConfiguration,
        [columnName]: !state.columnsConfiguration[columnName],
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action) => {
      // @ts-expect-error action has a payload with rehydrated state
      if (action.payload) {
        const {
          // @ts-expect-error action has a payload with rehydrated state
          payload: { filters },
        } = action
        return _merge(state, filters?.practiceFilters ?? {})
      }

      return state
    })
  },
})

export const {
  changeQueryTableData,
  changeFilterTableData,
  configureTableColumns,
  removeCountryFilterOptionById,
  removeStateFilterOptionById,
} = practiceFilterSlice.actions

export default practiceFilterSlice

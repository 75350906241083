import { FC, useContext } from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Typography,
} from '@mui/material'
import { IBreadcrumbsProps } from 'components/BreadcrumbsProvider/types'

import { BreadcrumbsContext } from './BreadcrumbsContext'

export const Breadcrumbs: FC<IBreadcrumbsProps> = ({ className, onClick }) => {
  const breadcrumbsForRender = useContext(BreadcrumbsContext)

  return breadcrumbsForRender ? (
    <Box mb={2}>
      <MuiBreadcrumbs
        className={className}
        separator={<NavigateNextIcon fontSize='small' />}
      >
        {breadcrumbsForRender.map(({ href, pageLabel }, index) => {
          return breadcrumbsForRender.length - 1 !== index ? (
            <Link
              key={pageLabel}
              underline='hover'
              color='inherit'
              href={href}
              onClick={() => onClick?.(breadcrumbsForRender)}
              {...{
                state: {
                  breadcrumbs: breadcrumbsForRender?.slice(0, index),
                },
              }}
            >
              {pageLabel}
            </Link>
          ) : (
            <Typography key={pageLabel} color='text.primary'>
              {pageLabel}
            </Typography>
          )
        })}
      </MuiBreadcrumbs>
    </Box>
  ) : null
}

import { Box, styled } from '@mui/material'

export const AppContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSidebarOpen',
})<{ isSidebarOpen: boolean }>(({ isSidebarOpen, theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3, 6),
  transitionTimingFunction: isSidebarOpen
    ? 'cubic-bezier(0, 0, 0.2, 1)'
    : 'cubic-bezier(0.4, 0, 0.6, 1)',
  transitionDuration: isSidebarOpen ? '225ms' : '195ms',
  marginLeft: isSidebarOpen ? '240px' : '100px',
  [`@media only screen and (max-width: ${theme.breakpoints.values.md}px)`]: {
    marginLeft: '100px',
  },
}))

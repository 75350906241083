import { createSlice } from '@reduxjs/toolkit'
import _merge from 'lodash/merge'
import { REHYDRATE } from 'redux-persist'

import { initialState, USERS_FILTERS_SLICE_NAME } from './constants'

const usersFilterSlice = createSlice({
  name: USERS_FILTERS_SLICE_NAME,
  initialState,
  reducers: {
    changeTableQueryData: (state, { payload }) => {
      state.queryData = {
        ...state.queryData,
        ...payload,
      }
    },
    changeFilterData: (state, { payload }) => {
      state.filtersData = {
        ...state.filtersData,
        ...payload,
      }
      state.queryData.page = 0
    },
    clearFilterData: (state) => {
      state.filtersData = initialState.filtersData
      state.queryData.search = ''
      state.queryData.page = 0
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action) => {
      // @ts-expect-error action has a payload with rehydrated state
      if (action.payload) {
        const {
          // @ts-expect-error action has a payload with rehydrated state
          payload: { filters },
        } = action
        return _merge(state, filters?.usersFilters ?? {})
      }

      return state
    })
  },
})

export const { changeTableQueryData, changeFilterData, clearFilterData } =
  usersFilterSlice.actions

export default usersFilterSlice

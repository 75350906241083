import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATE } from 'types/general'

import {
  ORDER_BILLING_INFO_INITIAL_STATE,
  ORDER_BILLING_INFO_SLICE_NAME,
} from './constants'
import * as controllers from './controllers'

const orderBillingInformationSlice = createSlice({
  name: ORDER_BILLING_INFO_SLICE_NAME,
  initialState: ORDER_BILLING_INFO_INITIAL_STATE,
  reducers: {
    setPrimaryTablePageable: (state, { payload }) => {
      state.primaryInsurancesTable.pageable = {
        ...state.primaryInsurancesTable.pageable,
        ...payload,
      }
    },
    setPrimaryTableFilter: (state, { payload }) => {
      state.primaryInsurancesTable.filter = {
        ...state.primaryInsurancesTable.filter,
        ...payload,
      }
      state.primaryInsurancesTable.pageable.page =
        ORDER_BILLING_INFO_INITIAL_STATE.primaryInsurancesTable.pageable.page
    },
    resetPrimaryTableFilter: (state) => {
      state.primaryInsurancesTable.filter =
        ORDER_BILLING_INFO_INITIAL_STATE.primaryInsurancesTable.filter
    },
    setInsuranceHistoryTablePageable: (state, { payload }) => {
      state.insuranceHistoryTable.pageable = {
        ...state.insuranceHistoryTable.pageable,
        ...payload,
      }
    },
    clearInsuranceHistoryTable: (state) => {
      state.insuranceHistoryTable =
        ORDER_BILLING_INFO_INITIAL_STATE.insuranceHistoryTable
    },
    resetBillingTypeOptions: (state) => {
      state.billingTypes = ORDER_BILLING_INFO_INITIAL_STATE.billingTypes
    },
    clearBillingInformationData: (state) => {
      state.billingInformation =
        ORDER_BILLING_INFO_INITIAL_STATE.billingInformation
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      controllers.fetchOrderBillingInformationTypes.fulfilled,
      (
        state,
        {
          payload,
          meta: {
            arg: { insuranceType },
          },
        }
      ) => {
        state.billingTypes[insuranceType] = payload
      }
    )
    builder
      .addCase(controllers.fetchOrderBillingInformation.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(
        controllers.fetchOrderBillingInformation.fulfilled,
        (state, action) => {
          state.billingInformation = action.payload
          state.loadingState = LOADING_STATE.fulfilled
        }
      )
      .addCase(controllers.fetchOrderBillingInformation.rejected, (state) => {
        state.loadingState = LOADING_STATE.rejected
      })
      .addCase(controllers.searchInsurance.pending, (state) => {
        state.primaryInsurancesTable.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.searchInsurance.fulfilled, (state, { payload }) => {
        state.primaryInsurancesTable.list = payload
        state.primaryInsurancesTable.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(controllers.searchInsurance.rejected, (state, { payload }) => {
        state.primaryInsurancesTable.loadingState = LOADING_STATE.rejected
        state.primaryInsurancesTable.error = payload
      })
      .addCase(controllers.fetchInsuranceHistory.pending, (state) => {
        state.insuranceHistoryTable.loadingState = LOADING_STATE.pending
        state.primaryInsurancesTable.error = null
      })
      .addCase(
        controllers.fetchInsuranceHistory.fulfilled,
        (state, { payload }) => {
          state.insuranceHistoryTable.loadingState = LOADING_STATE.fulfilled
          state.insuranceHistoryTable.list = payload
          state.primaryInsurancesTable.error = null
        }
      )
      .addCase(
        controllers.fetchInsuranceHistory.rejected,
        (state, { payload }) => {
          state.insuranceHistoryTable.loadingState = LOADING_STATE.rejected
          state.primaryInsurancesTable.error = payload
        }
      )
  },
})

export const {
  setPrimaryTablePageable,
  resetPrimaryTableFilter,
  setPrimaryTableFilter,
  setInsuranceHistoryTablePageable,
  clearInsuranceHistoryTable,
  resetBillingTypeOptions,
  clearBillingInformationData,
} = orderBillingInformationSlice.actions

export default orderBillingInformationSlice

import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { PSDialog } from 'components/ui-components/PSDialog'

export const EnableNotificationsModal = ({
  handleClose,
}: {
  handleClose: () => void
}) => {
  const { t } = useTranslation()

  return (
    <PSDialog
      isOpen
      onClose={handleClose}
      heading={t('common.enableNotifications')}
    >
      <Box mb={3}>
        <Typography variant='body1'>
          {t('common.enableNotificationdsDescription')}
        </Typography>
      </Box>
    </PSDialog>
  )
}

import { useEffect } from 'react'
import { useAuth } from 'react-oidc-context'

export const Login = () => {
  const auth = useAuth()

  useEffect(() => {
    if (!auth.isAuthenticated && !auth.isLoading) auth.signinRedirect()
  }, [auth])

  return null
}

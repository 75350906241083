import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AirlineSeatFlatAngled,
  Apartment,
  AutoAwesomeMosaic,
  LocalHospital,
  MenuBook,
  Message,
  PeopleAlt,
  PermMedia,
  Share,
} from '@mui/icons-material'
import { useMediaQuery, useTheme } from '@mui/material'
import { MILLISECONDS_IN_MINUTE } from 'constants/timings'
import { SITE_USER_ROLES } from 'constants/userRoles'
import { routes } from 'routes/routes'
import { useGetSupportRequestCountQuery } from 'services/support'
import { DoctorIcon } from 'assets/icons/DoctorIcon'
import { ProgressSideBar } from 'components/ProgressSideBar'
import { ProtectedComponent } from 'components/ProtectedComponent'
import { getIsDarkMode } from 'utils/helpers/general'
import { useColorMode } from 'utils/hooks/useColorMode'
import { useRoles } from 'utils/hooks/useRoles'

import { SideBarHeader, SidebarItem } from './components'
import { useGetValidationProgressBarData } from './hooks/useGetValidationProgressBarData'
import { StyledDrawer, StyledSidebarItemsContainer } from './Sidebar.styled'

type SideBarProps = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const Sidebar: FC<SideBarProps> = ({ isOpen, setIsOpen }) => {
  const { checkUserRoles } = useRoles()

  const { mode } = useColorMode()
  const { t } = useTranslation()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  const { isProgressBarDisplay, progressBarProps } =
    useGetValidationProgressBarData()

  const isSiteUserRoles = checkUserRoles(SITE_USER_ROLES)

  const { data: openSupportRequestCountData } = useGetSupportRequestCountQuery(
    undefined,
    {
      pollingInterval: MILLISECONDS_IN_MINUTE,
      skip: !isSiteUserRoles,
    }
  )

  const shouldRenderSupportRequestCounterBadge =
    isSiteUserRoles &&
    Boolean(Number(openSupportRequestCountData?.openSupportRequestsCount))

  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    setIsOpen(!isSmallScreen)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmallScreen])

  const isDarkMode = getIsDarkMode(mode)

  return (
    <StyledDrawer
      open={true}
      variant='persistent'
      anchor='left'
      isOpen={isOpen}
    >
      <SideBarHeader
        isDarkMode={isDarkMode}
        isOpen={isOpen}
        onChevronClick={toggleSidebar}
      />
      <StyledSidebarItemsContainer
        isOpen={isOpen}
        isProgressBarDisplay={isProgressBarDisplay}
      >
        <ProtectedComponent allowedRoles={routes.main.roles}>
          <SidebarItem
            icon={<AutoAwesomeMosaic />}
            text={t('components.sidebar.sidebarItemDashboard')}
            href={routes.main.path}
            isOpen={isOpen}
          />
        </ProtectedComponent>
        <ProtectedComponent allowedRoles={routes.orders.root.roles}>
          <SidebarItem
            icon={<PermMedia />}
            text={t('components.sidebar.sidebarItemOrders')}
            href={routes.orders.root.path}
            isOpen={isOpen}
          />
        </ProtectedComponent>
        <ProtectedComponent allowedRoles={routes.supportRequests.root.roles}>
          <SidebarItem
            icon={<Message />}
            text={t('components.sidebar.sidebarItemSupport')}
            href={routes.supportRequests.root.path}
            {...(shouldRenderSupportRequestCounterBadge && {
              badgeContent:
                openSupportRequestCountData?.openSupportRequestsCount,
            })}
            isOpen={isOpen}
          />
        </ProtectedComponent>
        <ProtectedComponent allowedRoles={routes.dictionaries.root.roles}>
          <SidebarItem
            icon={<MenuBook />}
            text={t('components.sidebar.sidebarItemDictionaries')}
            href={routes.dictionaries.root.path}
            isOpen={isOpen}
          />
        </ProtectedComponent>
        <ProtectedComponent allowedRoles={routes.providers.root.roles}>
          <SidebarItem
            icon={<DoctorIcon />}
            text={t('components.sidebar.sidebarItemProviders')}
            href={routes.providers.root.path}
            isOpen={isOpen}
          />
        </ProtectedComponent>
        <ProtectedComponent allowedRoles={routes.patients.root.roles}>
          <SidebarItem
            icon={<AirlineSeatFlatAngled />}
            text={t('components.sidebar.sidebarItemPatients')}
            href={routes.patients.root.path}
            isOpen={isOpen}
          />
        </ProtectedComponent>
        <ProtectedComponent allowedRoles={routes.practices.root.roles}>
          <SidebarItem
            icon={<LocalHospital />}
            text={t('components.sidebar.sidebarItemPractices')}
            href={routes.practices.root.path}
            isOpen={isOpen}
          />
        </ProtectedComponent>
        <ProtectedComponent allowedRoles={routes.companies.root.roles}>
          <SidebarItem
            icon={<Apartment />}
            text={t('components.sidebar.sidebarItemCompanies')}
            href={routes.companies.root.path}
            isOpen={isOpen}
          />
        </ProtectedComponent>
        <ProtectedComponent allowedRoles={routes.users.root.roles}>
          <SidebarItem
            icon={<PeopleAlt />}
            text={t('components.sidebar.sidebarItemUserManagement')}
            href={routes.users.root.path}
            isOpen={isOpen}
          />
        </ProtectedComponent>
        <ProtectedComponent allowedRoles={routes.sharedStudies.root.roles}>
          <SidebarItem
            icon={<Share />}
            text={t('components.sidebar.sidebarItemSharedStudies')}
            href={routes.sharedStudies.root.path}
            isOpen={isOpen}
          />
        </ProtectedComponent>
      </StyledSidebarItemsContainer>
      {isProgressBarDisplay && (
        <ProgressSideBar
          {...progressBarProps}
          setOpenSidePanel={setIsOpen}
          isSideBarOpen={isOpen}
        />
      )}
    </StyledDrawer>
  )
}

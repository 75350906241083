import { FC, ReactNode } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { Breadcrumbs, IBreadcrumb } from 'components/BreadcrumbsProvider'

import { StyledBox } from './PageHeader.styled'

interface IPageHeaderProps {
  headerText: string | ReactNode
  subHeaderText?: string | ReactNode
  actions?: JSX.Element | null
  mt?: string | number
  mr?: string | number
  mb?: string | number
  ml?: string | number
  overflowWrap?: 'anywhere' | 'break-word'
  onBreadcrumbClick?: (breadcrumbs: IBreadcrumb[]) => void
}

export const PageHeader: FC<IPageHeaderProps> = ({
  headerText,
  subHeaderText,
  actions = null,
  mt,
  mr,
  mb = 3,
  ml,
  overflowWrap,
  onBreadcrumbClick,
}) => {
  return (
    <Box
      mt={mt}
      mr={mr}
      mb={mb}
      ml={ml}
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      flexWrap='nowrap'
      gap={4}
    >
      <StyledBox overflowWrap={overflowWrap}>
        <Breadcrumbs onClick={onBreadcrumbClick} />
        <Typography variant='h4'>{headerText}</Typography>
        {subHeaderText ? (
          <Typography variant='body2' mt={2}>
            {subHeaderText}
          </Typography>
        ) : null}
      </StyledBox>
      <Stack direction='row' gap={2} justifyContent='end' flexWrap='wrap'>
        {actions}
      </Stack>
    </Box>
  )
}

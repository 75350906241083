import { Box, styled } from '@mui/material'

export const LoaderContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'position',
})<{
  position: 'fixed' | 'absolute' | 'relative'
}>(({ position }) => ({
  position,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}))

import { IState } from 'store/practice/types'
import { LOADING_STATE } from 'types/general'

export const PRACTICE_SLICE_NAME = 'practice'

export const PRACTICE_INITIAL_STATE: IState = {
  practicesPage: {
    tableData: {
      practices: [],
      loadingState: LOADING_STATE.idle,
      totalElements: 0,
      totalPages: 0,
      isFirstPage: true,
      isLastPage: false,
    },
    filtersOptions: {
      countries: [],
      states: [],
    },
  },
  practicesCatalog: [],
  practicePage: {
    loadingState: LOADING_STATE.idle,
    error: undefined,
  },
}

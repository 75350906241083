export const Companies = {
  //common
  createCompany: 'Add New Company',
  companies: 'Companies',
  search: 'Search by company or site name',
  companyViewButton: 'View',
  filtersBtn: 'Filters',
  filtersTitle: 'Filters',
  filtersCountry: 'Country',
  filtersState: 'State',
  addNewSite: 'Add new site',
  name: 'Company Name',
  company: 'Company',
  sites: 'Sites',
  billingInfo: 'Billing info',
  inDxLogic: 'InDxLogic',
  inDxLogicDescription:
    'Check if the company requires document management integration',
  inDxLogicLabel: 'Integration with InDxLogic',

  //sites
  originName: 'Name',
  siteInfo: 'Site info',
  siteReportConfiguration: 'Site Report Configuration',
  noSites: 'Company’s sites will appear here',
  tabGeneral: 'General Info',
  tabConfiguration: 'Site Configuration',
  newSite: 'New site',
  createSite: 'Add New Site',
  siteName: 'Site Name',
  organizationalCode: 'Organizational code',
  nPI: 'NPI',
  iNPI: 'INPI',
  country: 'Country',
  street1: 'Street 1',
  street2: 'Street 2',
  orgCode: 'Org code',
  city: 'City',
  timezone: 'Timezone',
  onlyAlphanumbericAndSpacesAllowed:
    'Only numbers, letters and spaces are allowed',
  state: 'State',
  postalCode: 'Postal Code',
  onlyNumericAndDashIsAllowed: 'Only numbers and - are allowed',
  phoneNumber: 'Phone number',
  faxNumber: 'Fax number',
  businessHours: 'Business Hours',
  primaryContactName: 'Primary Contact Name',
  sectionPrivilegeDesc:
    'Radiologists who has privilege credentialing for this site',
  sectionPreferredDesc:
    'Radiologists who should be prioritized to read this site studies',
  sectionDoNotAssignDesc:
    'Radiologists who should not be prioritized to read this site studies',
  searchRadiologistPlaceholder: 'Search radiologist by name and NPI ',
  urgencyTitle: 'Urgency',
  pointsOfServiceTitle: 'Points of service',
  urgencyTitleDescription: 'Select urgencies available for this site',
  availableRecipients: 'Available recipients for HL7 delivery',
  recipientsForHL7Delivery: 'Recipients for HL7 Delivery',
  onCall: 'On-Call',
  enableRoundTheClockWorkflow: 'Enable 24×7 Workflow',
  AETitle: 'AE Title',
  AETitleDescription: 'AE Title to be used when sending data to PACS server',
  DICOMDestinationGroupLabel: 'DICOM Destination Group',
  disableReportAppendixForFaxDeliveryLabel:
    'Disable report appendix for Fax delivery',
  disableKeyImagesForFaxDeliveryLabel: 'Disable key images for Fax delivery',
  reportDelivery: 'Report delivery',
  autoPromoteOrders: 'Auto-promote orders to “Delivered” status',
  autoPromoteOrdersError: 'Minimum 1 tag option should be selected',
  deliveredToSite: 'Delivered to Site',
  deliveredToRefMD: 'Delivered to RefMD',
  faxDelivery: 'Fax Delivery',
  reportDeliveryNoFaxesError: 'Please add fax numbers to General Info',
  reportDeliverySelectFaxNumberError:
    'Select at least one fax number for fax delivery report',
  PDFDeliveryViaFax: 'PDF Delivery via Fax',
  preliminaryReportOptions: 'Preliminary Report Banner Options',
  prelimReportBannerOptionAsterisk: '* Asterisk',
  prelimReportBannerOptionDash: '- Dash',
  prelimReportBannerOptionPound: '# Pound',
  prelimReportBannerOptionNone: 'None',
  reportDeliveryFaxOption: 'Fax',
  reportDeliverySaveToShareOption: 'Save-to-share',
  reportDeliveryHL7Option: 'HL7',
  reportDeliveryHL7Title: 'HL7 Delivery',
  reportDeliverySaveToShareTitle: 'Save-to-share Delivery',
  reportDeliveryNoAutomaticDeliveryOption: 'No Automatic Delivery',
  doNotUpdateOrderAttributesViaHL7: 'Do not update order attributes via HL7',
  fileNamePattern: 'File Name Pattern',
  transferAgent: 'Transfer Agent',
  deliveryFormat: 'Delivery format',
  deliveryDestinationWithPhysicianId:
    'Delivery Destination (with Physician ID)',
  deliveryDestinationWithPhysicianIdHint:
    'Physician ID will be included in HL7 message',
  deliveryDestinationWithoutPhysicianId:
    'Delivery Destination (without Physician ID)',
  deliveryDestinationWithoutPhysicianIdHint:
    'Physician ID will not be included in HL7 message',
  deliveryFormatPDF: 'PDF',
  deliveryFormatHTML: 'HTML',
  patternTitle: 'Add attributes to file name pattern',
  dateFormat: 'Date format',
  autoForwardRules: 'Auto-forward Rules',
  addRule: 'Add Rule',
  rule: 'Rule',
  dicomRule: 'DICOM Transfer via TA',
  shareRule: 'Share',
  studyLevelFilters: 'Study level filters',
  seriesLevelFilters: 'Series level filters',
  dicomTag: 'DICOM Tag',
  condition: 'Condition',
  value: 'Value',
  addFilter: 'Add Filter',
  removeFilter: 'Remove Filter',
  preliminaryReport: 'Preliminary Report',
  orderCreationDisabled: 'Restrict new RIS-Order creation ability to this site',
  orderCreationDisabledModalHeader: 'Restrict new RIS-Order creation ability',
  orderCreationDisabledModalDescription:
    'Are you sure you want to apply restrictions to this site for adding new RIS-Orders?',
  //siteReportConfiguration
  header: 'Report Configuration',
  uploadLogo: 'Upload Logo',
  headerLogo: 'Header Logo',
  smallLogo: 'Small Logo',
  demographicData: 'Demographic Data',
  leftSide: 'Left Side',
  rightSide: 'Right Side',
  addRow: 'Add Row',
  addLine: 'Add Line',
  addEmptyRow: 'Add Empty Row',
  removeRow: 'Remove Row',
  title: 'Title',
  footerSettings: 'Footer Settings',
  footerText: 'Footer Text',
  footerTextHelperText: 'Max 40 characters per line',
  footerCenterText: 'Footer Center Text',
  text: 'Text',
  isUpperCase: 'Display Value in CAPS',
  keyImages: 'Key Images',
  keyImagesLabel_one: '{{count}} Key image per page',
  keyImagesLabel_other: '{{count}} Images per page',
  countColumns: '{{count}} Columns',
  exportTemplateProblem: 'There was a problem during template exporting',
  uploadLabel:
    'Upload or drop a document in {{availableHeaderFormats}}. Recommended size is {{recommendedSize}}px. Max size {{maxSize}}MB',
  //order attributes
  URGENCY: 'Urgency',
  TIME_OF_SERVICE: 'Time of Service',
  POINTS_OF_SERVICE: 'Points of Service',
  ADMISSION_DATE: 'Admission date',
  ICD_10: 'ICD-10',
  CONTRAST_INFO: 'Contrast info',
  FLUORO_TIME: 'Fluoro time',
  RADIATION_DOSE: 'Radiation dose',
  TECH_NOTE: 'Tech note',
  REASON_FOR_EXAM: 'Reason for exam',
  COMPARISON_INFO: 'Comparison reports and images will not be provided',
  PHONE_NUMBER: 'Phone Number (Ref MD)',
  BILLING: 'Billing information',

  //options
  DATE_OF_SERVICE: 'Date of Service',
  GENDER: 'Gender',
  GENDER_AT_BIRTH: 'Gender at Birth',
  PATIENT_AGE: 'Patient Age',
  PATIENT_DATE_OF_BIRTH: 'Patient Date of Birth',
  PATIENT_NAME_FIRST_LAST: 'Patient Name (First Last)',
  PATIENT_NAME_LAST_FIRST: 'Patient Name (Last, First)',
  PATIENT_PHONE: 'Patient Phone',
  PROCEDURE: 'Procedure',
  PROSCAN_MRN: 'ProScan MRN',
  PROSCAN_ACCESSION_NUMBER: 'ProScan Accession Number',
  REFERRING_PHYSICIAN: 'Referring Physician',
  SOURCE_ACCESSION_NUMBER: 'Source Accession Number',
  SOURCE_MRN_NUMBER: 'Source MRN number',
  EMPTY: 'Empty',
}

import { createSlice } from '@reduxjs/toolkit'
import _merge from 'lodash/merge'
import { REHYDRATE } from 'redux-persist'

import { DESTINATIONS_FILTERS_SLICE_NAME, initialState } from './constants'

const destinationsFilterSlice = createSlice({
  name: DESTINATIONS_FILTERS_SLICE_NAME,
  initialState,
  reducers: {
    changeDestinationsTableQueryData: (state, { payload }) => {
      state.destinationsTableData = {
        ...state.destinationsTableData,
        queryData: {
          ...state.destinationsTableData.queryData,
          ...payload.query,
        },
      }
    },
    changeDestinationGroupsTableQueryData: (state, { payload }) => {
      state.destinationGroupsTableData = {
        ...state.destinationGroupsTableData,
        queryData: {
          ...state.destinationGroupsTableData.queryData,
          ...payload.query,
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action) => {
      // @ts-expect-error action has a payload with rehydrated state
      if (action.payload) {
        const {
          // @ts-expect-error action has a payload with rehydrated state
          payload: { filters },
        } = action
        return _merge(state, filters?.destinationsFilterSlice ?? {})
      }

      return state
    })
  },
})

export const {
  changeDestinationsTableQueryData,
  changeDestinationGroupsTableQueryData,
} = destinationsFilterSlice.actions

export default destinationsFilterSlice

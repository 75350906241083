import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { INITIAL_STATE, USER_PREFERENCES_SLICE_NAME } from './constants'

const userPreferences = createSlice({
  name: USER_PREFERENCES_SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    onIsPaperworkPostSaveActionEnabledCheckboxChange: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isPaperworkPostSaveActionEnabled = payload
    },
  },
})

export const { onIsPaperworkPostSaveActionEnabledCheckboxChange } =
  userPreferences.actions

export default userPreferences

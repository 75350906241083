import { FC, forwardRef } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const ReportVersionAvailableIcon: FC<
  SvgIconProps & {
    className?: string
    titleAccess?: string
  }
> = forwardRef(
  ({ className, titleAccess, fontSize = 'small', ...restProps }, ref) => (
    <SvgIcon
      titleAccess={titleAccess}
      viewBox='0 0 18 18'
      fontSize={fontSize}
      className={className}
      {...restProps}
      ref={ref}
    >
      <path
        d='M10.5 1.5H4.5C3.675 1.5 3.0075 2.175 3.0075 3L3 15C3 15.825 3.6675 16.5 4.4925 16.5H13.5C14.325 16.5 15 15.825 15 15V6L10.5 1.5ZM12 13.5H6V12H12V13.5ZM12 10.5H6V9H12V10.5ZM9.75 6.75V2.625L13.875 6.75H9.75Z'
        fill='#FF5722'
      />
    </SvgIcon>
  )
)

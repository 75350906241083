import { createSelector } from '@reduxjs/toolkit'
import { USER_ROLES } from 'constants/userRoles'
import { RootState } from 'store/store'

import { TAssignedSites, TAssociatedSite } from './types'

const getAuth = (state: RootState) => state.auth

export const getUserInfo = (state: RootState) =>
  getAuth(state).currentUser ?? {}

export const getCurrentUserRoles = (state: RootState) =>
  getUserInfo(state).roles ?? ([] as USER_ROLES[])

export const getAuthLoadingState = (state: RootState) =>
  getAuth(state).loadingState

export const getAssociatedSiteIds = (state: RootState): TAssignedSites =>
  getUserInfo(state)?.siteIds ?? undefined

export const getAssociatedCompanies = (state: RootState) =>
  getAuth(state).associatedCompanies

export const getAssociatedCompaniesCount = (state: RootState) =>
  getAssociatedCompanies(state).totalCompanies

export const getAssociatedSites = createSelector(
  (state: RootState) => getAssociatedCompanies(state).companies,
  (companies) =>
    companies.reduce<TAssociatedSite[]>(
      (sitesResult, company) => [
        ...sitesResult,
        ...(company.sites?.map(({ siteId, siteName }) => ({
          siteId,
          siteName,
          company: {
            companyId: company.companyId,
            companyName: company.companyName,
          },
        })) || []),
      ],
      []
    )
)

export const getFirebaseToken = (state: RootState) =>
  getAuth(state).firebaseToken

export const getIsNotificationGranted = (state: RootState) =>
  getAuth(state).isNotificationsGranted

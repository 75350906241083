import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  TRuleFilteringDataRs,
  TSiteCreateUpdateResponse,
  TSiteRequest,
  TSiteResponse,
} from 'store/sites/types'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'

import { SITES_SLICE_NAME } from './constants'

const fetchSiteInfo = createAsyncThunk<
  TSiteResponse,
  number,
  { rejectValue: TServiceError }
>(`${SITES_SLICE_NAME}/fetchSiteInfo`, async (id, { rejectWithValue }) => {
  const result = await request<TSiteResponse>({
    url: `/bff/org/sites/${id}`,
    method: 'GET',
  })
  return result.error ? rejectWithValue(result.error) : result.data
})

const createSite = createAsyncThunk<
  TSiteCreateUpdateResponse,
  TSiteRequest,
  { rejectValue: TServiceError }
>(`${SITES_SLICE_NAME}/createSite`, async (data, { rejectWithValue }) => {
  const result = await request<TSiteCreateUpdateResponse>({
    url: '/org/sites',
    data,
    method: 'POST',
  })

  return result.error ? rejectWithValue(result.error) : result.data
})

const updateSite = createAsyncThunk<
  TSiteCreateUpdateResponse,
  {
    id: number
    data: TSiteRequest
  },
  { rejectValue: TServiceError }
>(
  `${SITES_SLICE_NAME}/updateSite`,
  async ({ id, data }, { rejectWithValue }) => {
    const result = await request<TSiteCreateUpdateResponse>({
      url: `/org/sites/${id}`,
      data,
      method: 'PUT',
    })

    return result.error ? rejectWithValue(result.error) : result.data
  }
)

export const fetchAutoForwardRulesDictionary =
  createAsyncThunk<TRuleFilteringDataRs>(
    `${SITES_SLICE_NAME}/fetchAutoForwardRulesDictionary`,
    async (_, { rejectWithValue }) => {
      const result = await request<TRuleFilteringDataRs>({
        url: '/org/sites/rules/filter',
        method: 'GET',
      })

      if (result.error) {
        return rejectWithValue(result.error)
      }

      return result.data
    }
  )

export { fetchSiteInfo, createSite, updateSite }

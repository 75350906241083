import { FC, forwardRef, useEffect } from 'react'
import { Close } from '@mui/icons-material'
import { Box, CircularProgress, IconButton, Typography } from '@mui/material'
import { PROGRESS_COMPLETE_VALUE } from 'constants/files/files'
import { bottomLeftSnackbarAnchor } from 'constants/uiConstants'
import { SnackbarKey, useSnackbar } from 'notistack'
import { useAppSelector } from 'store/hooks'
import { RootState } from 'store/store'

import { StyledSnackbar } from './PSProgressSnackbar.styled'

interface IPSProgressSnackbarProps {
  id: SnackbarKey
  messageText?: string
  hasCloseButton?: boolean
  selector: (state: RootState) => number | undefined
  onProgressFinish?: (id?: SnackbarKey) => void
}

export const PSProgressSnackbar: FC<IPSProgressSnackbarProps> = forwardRef<
  HTMLDivElement,
  IPSProgressSnackbarProps
>(
  (
    { id, messageText, hasCloseButton = false, selector, onProgressFinish },
    ref
  ) => {
    const { closeSnackbar } = useSnackbar()
    const downloadProgress = useAppSelector(selector)

    const handleClose = () => closeSnackbar(id)

    useEffect(() => {
      if (downloadProgress === PROGRESS_COMPLETE_VALUE) {
        closeSnackbar(id)
        onProgressFinish?.(id)
      }
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [downloadProgress, closeSnackbar, id])

    return (
      <StyledSnackbar
        open
        anchorOrigin={bottomLeftSnackbarAnchor}
        ref={ref}
        action={
          hasCloseButton ? (
            <IconButton
              size='small'
              aria-label='close'
              color='inherit'
              onClick={handleClose}
            >
              <Close fontSize='small' />
            </IconButton>
          ) : null
        }
        message={
          <Box display='flex' gap={2}>
            <CircularProgress
              variant={downloadProgress === 0 ? 'indeterminate' : 'determinate'}
              value={downloadProgress}
              size={24}
            />
            <Typography variant='body2'>{messageText}</Typography>
          </Box>
        }
      />
    )
  }
)

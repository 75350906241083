import { TTranslationKey } from 'types/general'

export enum PERIODS_ENUM {
  TODAY = 'TODAY',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_90_DAYS = 'LAST_90_DAYS',
  THIS_WEEK = 'THIS_WEEK',
  CUSTOM = 'CUSTOM',
}

export interface IDatePeriod {
  label: TTranslationKey
  key: PERIODS_ENUM
}

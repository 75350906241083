import { FC, ForwardedRef, forwardRef, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Close, KeyboardArrowLeft } from '@mui/icons-material'
import { Box, DialogProps } from '@mui/material'
import { LoaderWrapper } from 'components/LoaderWrapper'
import { PSDialogActions } from 'components/ui-components/PSDialogActions'

import {
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
  StyledIconButton,
  StyledSubTitleContainer,
} from './PSDialog.styled'

export interface IPSDialogProps {
  isOpen: boolean
  onClose: (_e: any) => void
  heading: string | ReactNode
  subHeading?: ReactNode
  children?: ReactNode
  actions?: ReactNode
  className?: string
  isLoading?: boolean
  handleBackArrowClick?: () => void
  dataCy?: string
  maxWidth?: DialogProps['maxWidth']
  fullWidth?: DialogProps['fullWidth']
}

export const PSDialog: FC<IPSDialogProps> = forwardRef(
  (
    {
      isOpen,
      onClose,
      heading,
      subHeading,
      children,
      actions,
      className,
      handleBackArrowClick,
      isLoading = false,
      dataCy,
      maxWidth,
      fullWidth,
    },
    ref?: ForwardedRef<HTMLDivElement> | null
  ) => {
    const { t } = useTranslation()

    const handleDialogClose = (
      _e: KeyboardEvent | Event,
      reason?: 'backdropClick' | 'escapeKeyDown'
    ) => {
      if (reason === 'backdropClick') {
        return
      }
      onClose(_e)
    }

    return (
      <StyledDialog
        PaperProps={{ ref }}
        open={isOpen}
        onClose={handleDialogClose}
        className={className}
        data-cy={dataCy}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
      >
        <StyledDialogTitle>
          <Box gap={3} display='flex'>
            {handleBackArrowClick && (
              <StyledIconButton onClick={handleBackArrowClick}>
                <KeyboardArrowLeft />
              </StyledIconButton>
            )}
            {heading}
          </Box>
          <StyledIconButton
            aria-label={t('components.buttons.cancel')}
            onClick={onClose}
          >
            <Close />
          </StyledIconButton>
        </StyledDialogTitle>
        {subHeading ? (
          <StyledSubTitleContainer>{subHeading}</StyledSubTitleContainer>
        ) : null}
        <LoaderWrapper isLoading={isLoading}>
          <StyledDialogContent>{children}</StyledDialogContent>
        </LoaderWrapper>
        {actions && <PSDialogActions>{actions}</PSDialogActions>}
      </StyledDialog>
    )
  }
)

import { createAsyncThunk } from '@reduxjs/toolkit'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'

import { ORDER_REPORT_SLICE_NAME } from './constants'
import {
  AUDIT_TRIGGER_ACTION,
  REPORT_TYPE_ENUM,
  TBffAuditTriggerRq,
  TLegalCopyExistenceRs,
  TOrderReportInformation,
  TReportCreationRq,
  TReportLegalCopyRs,
  TReportTextsRs,
  TReportToggleUpdateRq,
  TUpdateReportRq,
} from './types'

export const fetchOrderReportInformation = createAsyncThunk<
  TOrderReportInformation,
  string,
  {
    rejectValue: TServiceError
  }
>(
  `${ORDER_REPORT_SLICE_NAME}/fetchReportInformation`,
  async (accessionNumber, { rejectWithValue }) => {
    const result = await request<TOrderReportInformation>({
      url: `/report/order/${accessionNumber}`,
      method: 'GET',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return result.data
  }
)

export const updateOrderReportText = createAsyncThunk<
  unknown,
  { accessionNumber: string } & TUpdateReportRq,
  {
    rejectValue: TServiceError
  }
>(
  `${ORDER_REPORT_SLICE_NAME}/updateOrderReportText`,
  async ({ accessionNumber, reportText }, { rejectWithValue }) => {
    const result = await request<TUpdateReportRq>({
      url: `/report/order/${accessionNumber}`,
      data: { reportText },
      method: 'PUT',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return result.data
  }
)

export const fetchOrderReportCompareInformation = createAsyncThunk<
  TReportTextsRs,
  {
    accessionNumber: string
    idOne: string
    idTwo: string
  },
  {
    rejectValue: TServiceError
  }
>(
  `${ORDER_REPORT_SLICE_NAME}/fetchOrderReportCompareInformation`,
  async ({ accessionNumber, idOne, idTwo }, { rejectWithValue }) => {
    const result = await request<TReportTextsRs>({
      url: `report/order/${accessionNumber}/texts?idOne=${idOne}&idTwo=${idTwo}`,
      method: 'GET',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return result.data
  }
)

export const fetchOrderLegalCopies = createAsyncThunk<
  TReportLegalCopyRs[],
  string,
  {
    rejectValue: TServiceError
  }
>(
  `${ORDER_REPORT_SLICE_NAME}/fetchOrderLegalCopies`,
  async (accessionNumber, { rejectWithValue }) => {
    const result = await request<TReportLegalCopyRs[]>({
      url: `bff/report/order/${accessionNumber}/legalcopy`,
      method: 'GET',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return result.data
  }
)

export const togglePreliminaryReport = createAsyncThunk<
  TReportToggleUpdateRq,
  { accessionNumber: string; enable: boolean },
  {
    rejectValue: TServiceError
  }
>(
  `${ORDER_REPORT_SLICE_NAME}/togglePreliminaryReport`,
  async ({ accessionNumber, enable }, { rejectWithValue }) => {
    const result = await request<TReportToggleUpdateRq>({
      url: `report/order/${accessionNumber}/toggle`,
      data: { enable },
      method: 'PUT',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return result.data
  }
)

export const fetchIsFinalLegalCopyReleased = createAsyncThunk<
  TLegalCopyExistenceRs,
  { accessionNumber: string },
  {
    rejectValue: TServiceError
  }
>(
  `${ORDER_REPORT_SLICE_NAME}/fetchIsFinalLegalCopyReleased`,
  async ({ accessionNumber }, { rejectWithValue }) => {
    const result = await request<TLegalCopyExistenceRs>({
      url: `/report/order/${accessionNumber}/legalcopy/existence`,
      method: 'GET',
      params: {
        type: REPORT_TYPE_ENUM.FINAL,
      },
      headers: {
        AccessionNumber: accessionNumber,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }
    return result.data
  }
)

export const createManualReportVersion = createAsyncThunk<
  unknown,
  { accessionNumber: string; data: TReportCreationRq },
  {
    rejectValue: TServiceError
  }
>(
  `${ORDER_REPORT_SLICE_NAME}/createManualReportVersion`,
  async ({ accessionNumber, data }, { rejectWithValue }) => {
    const result = await request<unknown>({
      url: `/report/order/${accessionNumber}`,
      method: 'POST',
      data,
      headers: {
        AccessionNumber: accessionNumber,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }
    return result.data
  }
)

export const trackAuditLegalCopy = createAsyncThunk<
  void,
  { payload: TBffAuditTriggerRq; action: AUDIT_TRIGGER_ACTION },
  { rejectValue: TServiceError }
>(
  `${ORDER_REPORT_SLICE_NAME}/trackAuditLegalCopy`,
  async ({ payload, action }, { rejectWithValue }) => {
    const result = await request<void>({
      url: '/bff/trigger/audit',
      data: payload,
      params: { action },
      method: 'POST',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

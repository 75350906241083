import { Box, Drawer, styled } from '@mui/material'
import {
  MINIMAL_SIDEBAR_WIDTH,
  SIDEBAR_WIDTH,
} from 'constants/layoutDimensions'
import { getIsDarkMode } from 'utils/helpers/general'

import { DRAWER_COLOR_DARK, DRAWER_COLOR_LIGHT } from './constants'

const HEADER_AND_VALIDATION_BAR_HEIGHT = '250px'

export const StyledSidebarItemsContainer = styled(Box, {
  shouldForwardProp: (propName) =>
    propName !== 'isOpen' && propName !== 'isProgressBarDisplay',
})<{ isOpen: boolean; isProgressBarDisplay: boolean }>(
  ({ isOpen, isProgressBarDisplay, theme: { spacing, breakpoints } }) => ({
    padding: spacing(1),
    width: isOpen ? `${SIDEBAR_WIDTH}px` : `${MINIMAL_SIDEBAR_WIDTH}px`,
    height: isProgressBarDisplay
      ? `calc(100vh - ${HEADER_AND_VALIDATION_BAR_HEIGHT})`
      : 'auto',
    overflowY: 'auto',
    overflowX: 'hidden',
    [`@media only screen and ${breakpoints.down('md')}`]: {
      width: `${MINIMAL_SIDEBAR_WIDTH}px`,
      paddingTop: !isOpen && spacing(1),
    },
  })
)

export const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (propName) => propName !== 'isOpen',
})<{ isOpen: boolean }>(({ theme: { palette }, isOpen }) => ({
  '& .MuiPaper-root': {
    width: isOpen ? SIDEBAR_WIDTH : MINIMAL_SIDEBAR_WIDTH,
    backgroundColor: getIsDarkMode(palette.mode)
      ? DRAWER_COLOR_DARK
      : DRAWER_COLOR_LIGHT,
    border: 'none',
    overflow: 'auto',
  },
}))

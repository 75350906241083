import { MutableRefObject, useEffect, useRef, useState } from 'react'

export const useGetIsContentBiggerThenBlockHeight = (): [
  MutableRefObject<HTMLDivElement | null>,
  boolean
] => {
  const [isTooltipEnabled, setIsTooltipEnabled] = useState(false)

  const textRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const compareSize = () => {
      if (textRef.current) {
        const compare =
          textRef.current.scrollHeight > textRef.current.clientHeight

        setIsTooltipEnabled(compare)
      }
    }

    compareSize()
    window.addEventListener('resize', compareSize)

    return () => window.removeEventListener('resize', compareSize)
  }, [])

  return [textRef, isTooltipEnabled]
}

import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import Add from '@mui/icons-material/Add'
import { Box, Link, Tabs } from '@mui/material'
import { routes } from 'routes/routes'
import { PageHeader } from 'components/PageHeader'
import { ProtectedComponent } from 'components/ProtectedComponent'
import { PSButton } from 'components/ui-components/PSButton'
import { PSTab } from 'components/ui-components/PSTabs'
import { useNavigateToFirstTabOnMounting } from 'utils/hooks/useNavigateToFirstTabOnMounting'
import { useRoles } from 'utils/hooks/useRoles'
import { useRouteMatch } from 'utils/hooks/useRouteMatch'

import { PROVIDERS_TABS } from './constants'

export const ProvidersList = () => {
  const { checkUserRoles } = useRoles()
  const { t } = useTranslation()

  const availableTabs = PROVIDERS_TABS.filter(
    (tab) => !tab.allowedRoles || checkUserRoles(tab.allowedRoles)
  )

  const routeMatch = useRouteMatch(availableTabs.map(({ path }) => path))
  const currentTab = routeMatch?.pattern?.path

  useNavigateToFirstTabOnMounting(availableTabs[0].path, currentTab)

  return (
    <>
      <PageHeader
        headerText={t('components.sidebar.sidebarItemProviders')}
        actions={
          <>
            <ProtectedComponent
              allowedRoles={routes.providers.onCallAssignment.roles}
            >
              <PSButton
                href={routes.providers.onCallAssignment.path}
                data-testid='On-call assignment'
              >
                {t('components.buttons.onCallAssignment')}
              </PSButton>
            </ProtectedComponent>
            <ProtectedComponent allowedRoles={routes.providers.create.roles}>
              <PSButton
                variant='contained'
                href={routes.providers.create.path}
                startIcon={<Add />}
                data-testid='Add Provider'
              >
                {t('components.buttons.addNew')}
              </PSButton>
            </ProtectedComponent>
          </>
        }
      />
      <Box borderBottom={1} borderColor='divider'>
        <Tabs value={currentTab}>
          {availableTabs.map(({ path, label }) => (
            <PSTab
              component={Link}
              to={path}
              key={label}
              label={t(label)}
              value={path}
            />
          ))}
        </Tabs>
      </Box>
      <Outlet />
    </>
  )
}

import {
  IAllOrdersFiltersData,
  IAssignmentOrdersFiltersData,
  IBillingOrdersFiltersData,
  IPreparationOrdersFiltersData,
  IReportQAOrdersFiltersData,
  TOrdersTableParams,
} from 'store/filters/orders/types'
import { TAG_TYPE } from 'store/tags/types'
import { arrayFilterUndefinedCallback } from 'utils/helpers/filters/arrayFilterUndefinedCallback'

type TFilterParams = Partial<
  IPreparationOrdersFiltersData &
    IAssignmentOrdersFiltersData &
    IReportQAOrdersFiltersData &
    IBillingOrdersFiltersData &
    IAllOrdersFiltersData
>

type TMappedParams = Omit<
  TOrdersTableParams,
  'isPaged' | 'orderStatus' | 'search' | 'sort' | 'page' | 'size'
>

export const mapSearchFiltersToBackEndParams = (
  filtersData: TFilterParams
): TMappedParams => {
  return {
    ...(filtersData.urgencies && {
      urgencies: filtersData.urgencies.map(({ id }) => id),
    }),
    ...(filtersData.siteNames && {
      siteIds: filtersData.siteNames.map(({ siteId }) => siteId),
    }),
    ...(filtersData.tags && {
      tagIds: [
        ...filtersData.tags[TAG_TYPE.ADMIN],
        ...filtersData.tags[TAG_TYPE.CUSTOMER],
      ].map(({ id }) => id),
    }),
    ...(filtersData.systemTags && {
      systemTagIds: filtersData.systemTags.map(({ id }) => id),
    }),
    ...(filtersData.holdReasons && {
      holdReasons: filtersData.holdReasons.map(({ id }) => id),
    }),
    ...(filtersData?.dateOfService?.from && {
      dateOfServiceFrom: filtersData?.dateOfService?.from,
    }),
    ...(filtersData?.dateOfService?.to && {
      dateOfServiceTo: filtersData.dateOfService.to,
    }),
    ...(filtersData?.followUpDate?.from && {
      followUpFrom: filtersData?.followUpDate?.from,
    }),
    ...(filtersData?.followUpDate?.to && {
      followUpTo: filtersData?.followUpDate?.to,
    }),
    ...(filtersData.states && {
      states: filtersData.states,
    }),
    ...(filtersData.billingTypes && {
      billingTypeIds: filtersData.billingTypes.map(({ id }) => id),
    }),
    ...(filtersData.billingStatuses && {
      billingStatuses: filtersData.billingStatuses.map(({ name }) => name),
    }),
    ...(filtersData.insuranceGroupNames && {
      insuranceGroupNames: filtersData.insuranceGroupNames,
    }),
    ...(filtersData?.patientDayOfBirth && {
      patientDayOfBirth: filtersData.patientDayOfBirth,
    }),
    ...(filtersData.modalities && {
      modalityIds: filtersData.modalities.map(({ id }) => id),
    }),
    ...(filtersData.bodyParts && {
      bodyPartIds: filtersData.bodyParts.map(({ id }) => id),
    }),
    ...(filtersData.procedures && {
      procedureIds: filtersData.procedures.map(({ id }) => id),
    }),
    ...(filtersData.cptCodes && {
      cptCodeIds: filtersData.cptCodes.map(({ code }) => code),
    }),
    ...(filtersData.companies && {
      companyIds: filtersData.companies
        .map(({ companyId }) => companyId)
        .filter(arrayFilterUndefinedCallback),
    }),
    ...(filtersData.radiologists && {
      radiologistIds: filtersData.radiologists.map(
        ({ providerId }) => providerId as string
      ),
    }),
    ...(filtersData.contributingReaders && {
      contributorReaderIds: filtersData.contributingReaders.map(
        ({ providerId }) => providerId as string
      ),
    }),
    ...(filtersData.physicians && {
      physicianIds: filtersData.physicians.map(
        ({ providerId }) => providerId as string
      ),
    }),
    ...(filtersData?.cptCodes && {
      cptCodeIds: filtersData.cptCodes.map(({ code }) => code),
    }),
    ...(filtersData?.reportQAReasons && {
      reportQaReasons: filtersData.reportQAReasons.map(({ value }) => value),
    }),
    ...('hasAssignedRadiologist' in filtersData && {
      hasAssignedRadiologist: filtersData.hasAssignedRadiologist,
    }),
    ...(filtersData?.proscanMrnSearch && {
      proscanMrnSearch: filtersData.proscanMrnSearch,
    }),
    ...(filtersData?.proscanAccessionSearch && {
      proscanAccessionSearch: filtersData.proscanAccessionSearch,
    }),
    ...(filtersData?.patientSearch && {
      patientSearch: filtersData.patientSearch,
    }),
    ...(filtersData?.siteSearch && {
      siteSearch: filtersData.siteSearch,
    }),
    ...(filtersData?.sourceMrnSearch && {
      sourceMrnSearch: filtersData.sourceMrnSearch,
    }),
    ...(filtersData?.sourceAccessionSearch && {
      sourceAccessionSearch: filtersData.sourceAccessionSearch,
    }),
    ...(filtersData?.isInactiveStatuses && {
      isInactiveStatuses: filtersData.isInactiveStatuses,
    }),
    ...(filtersData?.orderStatuses && {
      orderStatuses: filtersData.orderStatuses.map(({ value }) => value),
    }),
    ...(filtersData?.deliveryStatuses && {
      deliveryStatuses: filtersData.deliveryStatuses.map(({ name }) => name),
    }),
    ...(filtersData?.search && {
      search: filtersData.search,
    }),
    ...(filtersData?.hideInReview && {
      hideInReview: filtersData.hideInReview,
    }),
    ...(filtersData?.providerId && {
      providerId: filtersData.providerId,
    }),
  }
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { USERS_SLICE_NAME } from 'store/users/constants'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'

import {
  TUpdateUserProfileRq,
  TUserInfoRq,
  TUserInfoRs,
  TUserRegistrationRq,
  TUserRegistrationRs,
} from './types'

export const createUser = createAsyncThunk<
  TUserRegistrationRs,
  TUserRegistrationRq,
  {
    rejectValue: TServiceError
  }
>(`${USERS_SLICE_NAME}/createUser`, async (payload, { rejectWithValue }) => {
  const result = await request<TUserRegistrationRs>({
    url: '/users/register',
    data: payload,
    method: 'POST',
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const updateUser = createAsyncThunk<
  unknown,
  { fields: TUserInfoRq; userId: string },
  {
    rejectValue: TServiceError
  }
>(`${USERS_SLICE_NAME}/updateUser`, async (payload, { rejectWithValue }) => {
  const result = await request<TUserInfoRs>({
    url: `/users/${payload.userId}/info`,
    data: payload.fields,
    method: 'PATCH',
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const getUser = createAsyncThunk<
  TUserInfoRs,
  string,
  {
    rejectValue: TServiceError
  }
>(`${USERS_SLICE_NAME}/getUser`, async (userId, { rejectWithValue }) => {
  const result = await request<TUserInfoRs>({
    url: `bff/users/${userId}/info`,
    method: 'GET',
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const updateUserOnProfilePage = createAsyncThunk<
  TUserInfoRs,
  TUpdateUserProfileRq,
  { rejectValue: TServiceError }
>(
  `${USERS_SLICE_NAME}/updateUserOnProfilePage`,
  async (data, { rejectWithValue }) => {
    const result = await request<TUserInfoRs>({
      url: 'users/info',
      data,
      method: 'PATCH',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

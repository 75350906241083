import { createAsyncThunk } from '@reduxjs/toolkit'
import { FLAG_STATUS_TYPE } from 'components/flags/constants'
import { ORDER_STATUS_ENUM } from 'store/order/types'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'
import { prepareArrayForSending } from 'utils/helpers/arrays/prepareArrayForSending'
import { TPageable } from 'types/general'

import { sliceName } from './constants'
import {
  IAssignmentOrders,
  IQueryData,
  TAssignmentRadiologistResponse,
  TAssignOrdersRequest,
  TBffCombinedOrderFlagRs,
  TBffHistoricalOrdersPageRs,
  TLockOrdersResponse,
} from './types'

export const getAssignRadiologists = createAsyncThunk<
  TAssignmentRadiologistResponse,
  { query: IQueryData; abortSignal?: AbortSignal },
  { rejectValue: TServiceError }
>(
  `${sliceName}/getAssignRadiologists`,
  async ({ query, abortSignal }, { rejectWithValue }) => {
    const result = await request<TAssignmentRadiologistResponse>({
      url: '/bff/providers/assignment',
      method: 'GET',
      params: {
        ...query,
        accessionNumbers: prepareArrayForSending(query.accessionNumbers),
      },
      signal: abortSignal,
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const fetchOrdersFlags = createAsyncThunk<
  TBffCombinedOrderFlagRs[],
  string[],
  { rejectValue: TServiceError }
>(
  `${sliceName}/fetchOrdersFlags`,
  async (accessionNumbers, { rejectWithValue }) => {
    const result = await request<TBffCombinedOrderFlagRs[]>({
      url: '/bff/orders/orders-flags',
      method: 'GET',
      params: {
        accessionNumbers: prepareArrayForSending(accessionNumbers),
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const fetchOrdersPseudoFlags = createAsyncThunk<
  TBffCombinedOrderFlagRs[],
  string[],
  { rejectValue: TServiceError }
>(
  `${sliceName}/fetchOrdersFlagsfetchOrdersPseudoFlags`,
  async (accessionNumbers, { rejectWithValue }) => {
    const result = await request<TBffCombinedOrderFlagRs[]>({
      url: '/orders/orders-flags/pseudo',
      method: 'GET',
      params: {
        accessionNumbers: prepareArrayForSending(accessionNumbers),
        checkerTypes: 'OPEN_SUPPORT_REQUEST',
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const fetchOrdersReassignFlags = createAsyncThunk<
  TBffCombinedOrderFlagRs[],
  string[],
  { rejectValue: TServiceError }
>(
  `${sliceName}/fetchOrdersReassignFlags`,
  async (accessionNumbers, { rejectWithValue }) => {
    const result = await request<TBffCombinedOrderFlagRs[]>({
      url: '/bff/orders/reassign/orders-flags',
      method: 'GET',
      params: {
        accessionNumbers: prepareArrayForSending(accessionNumbers),
        orderStatus: prepareArrayForSending([
          ORDER_STATUS_ENUM.WAITING_FOR_ASSIGNMENT,
        ]),
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const completeAllOrdersFlags = createAsyncThunk<
  unknown,
  {
    accessionNumbers: string[]
    status: FLAG_STATUS_TYPE
    completedBy: { firstName?: string; lastName?: string }
  },
  { rejectValue: TServiceError }
>(
  `${sliceName}/completeAllOrdersFlags`,
  async ({ accessionNumbers, status }, { rejectWithValue }) => {
    const result = await request<unknown>({
      url: `/orders/orders-flags?accessionNumbers=${prepareArrayForSending(
        accessionNumbers
      )}&status=${status}`,
      data: {
        complete: true,
      },
      method: 'PATCH',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const updateOrderFlagsStatus = createAsyncThunk<
  unknown,
  {
    accessionNumbers: string[]
    flagId: number
    isCompleted: boolean
    completedBy: { firstName?: string; lastName?: string }
  },
  { rejectValue: TServiceError }
>(
  `${sliceName}/updateOrderFlagsStatus`,
  async ({ flagId, accessionNumbers, isCompleted }, { rejectWithValue }) => {
    const result = await request<unknown>({
      url: `/orders/orders-flags/${flagId}?accessionNumbers=${prepareArrayForSending(
        accessionNumbers
      )}`,
      data: {
        complete: isCompleted,
      },
      method: 'PATCH',
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const assignOrdersRequest = createAsyncThunk<
  unknown,
  TAssignOrdersRequest,
  { rejectValue: TServiceError }
>(`${sliceName}/assignOrdersRequest`, async (data, { rejectWithValue }) => {
  const result = await request({
    url: '/orders/assignments',
    data,
    method: 'POST',
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result
})

export const linkAndLockOrdersRequest = createAsyncThunk<
  TLockOrdersResponse,
  IAssignmentOrders,
  { rejectValue: TServiceError }
>(
  `${sliceName}/linkAndLockOrdersRequest`,
  async (params, { rejectWithValue }) => {
    const result = await request<TLockOrdersResponse>({
      url: 'bff/orders/assignments/link-and-lock',
      method: 'PUT',
      params,
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const unlockOrdersRequest = createAsyncThunk<
  void,
  Omit<IAssignmentOrders, 'isReassignmentFlow'>,
  { rejectValue: TServiceError }
>(`${sliceName}/unlockOrdersRequest`, async (params, { rejectWithValue }) => {
  const result = await request<void>({
    url: '/orders/assignments/lock',
    method: 'DELETE',
    params,
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const fetchPreviouslyAssignedRadiologists = createAsyncThunk<
  TBffHistoricalOrdersPageRs,
  { query: TPageable; abortSignal?: AbortSignal; patientId: string },
  { rejectValue: TServiceError }
>(
  `${sliceName}/fetchPreviouslyAssignedRadiologists`,
  async ({ query, patientId, abortSignal }, { rejectWithValue }) => {
    const result = await request<TBffHistoricalOrdersPageRs>({
      url: `bff/orders/patient/${patientId}/history`,
      method: 'GET',
      params: query,
      signal: abortSignal,
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

import _get from 'lodash/get'
import _pick from 'lodash/pick'

import { TGroupAssignment, TMultiAssignmentRadiologist } from '../types'
import { getRadiologistForTable } from './getRadiologistForTable'

export const mapGroupsAssignment = (
  radiologistsList: TMultiAssignmentRadiologist[]
): TGroupAssignment[] => {
  return radiologistsList.map((radiologist) => {
    const firstOrder = _get(radiologist, 'orderData[0]', {
      rvuLeft: {},
      rvuTarget: '',
    })

    return {
      ...getRadiologistForTable(radiologist),
      ..._pick(firstOrder, ['rvuLeft', 'rvuTarget', 'unread']),
    }
  })
}

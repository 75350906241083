import { PROGRESS_INITIAL_VALUE } from 'constants/files/files'
import { RootState } from 'store/store'

const getFilesDownloading = (state: RootState) => state.filesDownloading

export const getTabId = (state: RootState) => getFilesDownloading(state).tabId

export const getAllISOsDownloadingData = (state: RootState) =>
  getFilesDownloading(state).ISOs

export const getISODownloadingData =
  (accessionNumber: string) => (state: RootState) =>
    getAllISOsDownloadingData(state)[accessionNumber]

export const getISODownloadingProgress =
  (accessionNumber: string) => (state: RootState) =>
    getAllISOsDownloadingData(state)[accessionNumber]?.downloadProgress

export const getAllDICOMsDownloadingData = (state: RootState) =>
  getFilesDownloading(state).DICOMs

export const getDICOMDownloadingData =
  (studyId: number, accessionNumber?: string) => (state: RootState) =>
    accessionNumber
      ? getAllDICOMsDownloadingData(state)[accessionNumber]?.[studyId]
      : undefined

export const getDICOMsDownloadProgress =
  (accessionNumber: string, studyId: number) => (state: RootState) =>
    getAllDICOMsDownloadingData(state)[accessionNumber]?.[studyId]
      ?.downloadProgress || PROGRESS_INITIAL_VALUE

export const getOrderListCSVDownloadProgress = (state: RootState) =>
  getFilesDownloading(state).orderListCSVReport

export const getOrderListCSVDownloadProgressByEventId =
  (key: string) => (state: RootState) =>
    getOrderListCSVDownloadProgress(state)[key]?.downloadProgress ||
    PROGRESS_INITIAL_VALUE

export const getOrderPDFReportDownloadProgress = (state: RootState) =>
  getFilesDownloading(state).orderPDFReport.downloadProgress

export const getIsOrderPDFReportDownloading = (state: RootState) =>
  getFilesDownloading(state).orderPDFReport.isDownloading

export const getOrderLegalCopyReportDownload = (state: RootState) =>
  getFilesDownloading(state).latestVersionOrderLegalCopyReport

export const getOrderLegalCopyReportDownloadData =
  (accessionNumber: string) => (state: RootState) =>
    getOrderLegalCopyReportDownload(state)[accessionNumber]

export const getOrderLegalCopyReportDownloadProgress =
  (accessionNumber: string) => (state: RootState) =>
    getOrderLegalCopyReportDownload(state)[accessionNumber]?.downloadProgress ||
    PROGRESS_INITIAL_VALUE

export const getLegalCopiesDownloadProgressData = (state: RootState) =>
  getFilesDownloading(state).orderLegalCopyReport

export const getLegalCopiesDownloadProgressById =
  (id: number) => (state: RootState) =>
    getLegalCopiesDownloadProgressData(state)[id]

export const getIsFilesUploading = (state: RootState) =>
  Object.keys(getFilesDownloading(state).isUploading).length > 0

export const getIsFilesDownloading = (state: RootState) => {
  const isFilesDownloading =
    Object.keys(getFilesDownloading(state).isDownloading).length > 0
  const isISODownloading =
    Object.keys(getAllISOsDownloadingData(state)).length > 0
  const isOrderListCSVDownloading =
    Object.keys(getOrderListCSVDownloadProgress(state)).length > 0
  const isOrderLegalCopyReportDownloading =
    Object.keys(getOrderLegalCopyReportDownload(state)).length > 0
  const isLegalCopiesDownloading =
    Object.keys(getLegalCopiesDownloadProgressData(state)).length > 0
  const isAllDICOMsDownloading =
    Object.keys(getAllDICOMsDownloadingData(state)).length > 0
  const isOrderPDFReportDownloading = getIsOrderPDFReportDownloading(state)

  return (
    isFilesDownloading ||
    isISODownloading ||
    isOrderListCSVDownloading ||
    isOrderPDFReportDownloading ||
    isOrderLegalCopyReportDownloading ||
    isLegalCopiesDownloading ||
    isAllDICOMsDownloading
  )
}

import { KeyboardArrowDown } from '@mui/icons-material'
import { Grid, styled } from '@mui/material'

const StyledEyeBrowContainer = styled(Grid)(({ theme: { spacing } }) => ({
  justifyContent: 'end',
  alignItems: 'center',
  height: '64px',
  paddingRight: spacing(1),
}))

const StyledProfileButton = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}))

const StyledArrowDownIcon = styled(KeyboardArrowDown)(
  ({ theme: { palette } }) => ({
    fill: palette.action.active,
  })
)

export { StyledEyeBrowContainer, StyledProfileButton, StyledArrowDownIcon }

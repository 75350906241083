export const Dictionaries = {
  //common
  procedures: 'Procedures',
  procedureId: 'Procedure ID',
  procedureWithId: 'Procedure {{id}}',
  CPTCodes: 'CPT codes',
  modality: 'Modality',
  bodyPart: 'Body Part',
  subspecialty: 'Sub-speciality',
  description: 'Exam Description',
  contrast: 'Contrast',
  includeArchive: 'Include Archived Procedures',
  flagName: 'Flag Name',
  name: 'Name',
  flagDescription: 'Flag Description',
  addNewProcedure: 'Add new procedure',
  addNewFlag: 'Add new flag',
  searchPlaceholder: 'Search by name',
  searchProcedure: 'Search by Procedure ID or Exam description...',

  //flags
  flagsTabHeader: 'Flags',
  flagsSearch: 'Search Flags by name or description...',
  flagsDescription: 'Description',
  flagsRelatedTo: 'Related to',
  flagsType: 'Type',
  applyOnTransition: 'Apply on Transition',
  flagsBillType: 'Bill Type',
  flagsProcedures: 'Procedures',
  flagsStatus: 'Status',
  flagsIncludeArchivePill: 'Archived Included',
  flagsIncludeArchive: 'Include Archived',

  //proceduresForm
  footNote: 'Foot Note',
  footNotes: 'Foot Notes',
  addNote: 'Add note',
  createProcedure: 'Add New Procedure',
  addMaxNotes: 'Add max {{n}} foot notes',
  procedureInfoTitle: 'Procedure Info',
  maxCharacters: 'Max {{n}} characters',
  CPTCodesTitle: 'CPT codes',
  procedure: 'Procedure',
  laterality: 'Laterality',
  rvu: 'RVU credit',
  technicalFactors: 'Technical Factors',
  fluoroTime: 'Fluoro Time',
  radiationDose: 'Radiation Dose',
  modifier3d: '3D Modifier',
  cadModifier: 'CAD Modifier',
  editProcedureID: 'Procedure {{procedureId}}',
  requiredPaperworksTitle: 'Required Paperworks',
  requiredPaperworksDescription:
    'Select paperworks that will be required for uploading for each RIS-Order that has this procedure',
  documentsForInterpretation: 'Documents for Interpretation',
  techWorksheet: 'Tech worksheet',

  //flagForm
  createFlag: 'Add New Flag',
  relatedTo: 'Related to',
  site: 'Site',
  company: 'Company',
  referringPhysician: 'Referring Physician',
  type: 'Type',
  transitionStatus: 'RIS-Order transitioned to status',
  transition: 'Transition',
  billTypeId: 'Bill type',
  requiredToComplete: 'Required to complete',
  searchSites: 'Search Sites by name',
  searchPhysicians: 'Search Referring Physician by name and NPI ',
  appliedToAllRefMD: 'The Flag will be applied to all Ref MDs',
  appliedToAllSites: 'The Flag will be applied to all Sites',

  //flagProcedures
  flagProceduresTitle: 'Procedures ({{count}}) *',
  allProceduresSelectedMessage: 'The flag will be applied to All Procedures',
  addProcedures: 'Add procedures',
  addProceduresModal: 'Add Procedure',

  //proceduresTable
  placeholder: 'Search CPT codes or Exam description...',
  archive: 'Archive',
  view: 'View',

  //tagsTable
  tagsTabHeader: 'Tags',
  tagsSearchPlaceholder: 'Search by tag name',
  tagsIncludeArchived: 'Include Archived',
  addNewTagTitle: 'Add new tag',
  customer: 'Customer',
  admin: 'Admin',
  tagName: 'Tag',
  color: 'Color',
  tagStatus: 'Status',
  tagType: 'Type',
  //tagsModal
  addTag: 'Add Tag',
  editTag: 'Edit Tag',
  tagNameField: 'Tag Name',
  activeTags: 'Active tags',
  archivedTags: 'Archived tags',
  possibleMatches: 'Possible Matches',
  tagNameHint:
    'Please don’t create new tag if you can use one of following ones',
  types: {
    ADMIN: 'Admin',
    CUSTOMER: 'Customer',
  },
  tagAlreadyExists: 'Tag with such name and type already exists',

  // Dicom destinations
  dicomDestinationGroupsTabHeader: 'Dicom destinations Groups',
  addNewDicomDestination: 'Add new DICOM Destination',
  addDicomDestination: 'Add DICOM Destination',
  addNewDicomDestinationGroup: 'Add new DICOM Destination Group',
  DICOMDestinationsLabel: 'DICOM Destinations',
  dicomDestinationsTitle: 'Dicom destinations',
  dicomDestinationsCount: 'DICOM destinations ({{count}})',
  nameLabel: 'Name',
  calledAELabel: 'Called AE',
  ipLabel: 'IP',
  portLabel: 'Port',
  skipModality: 'Skip modality',
  active: 'Active',
  inactive: 'Inactive',
  status: 'Status',
  allDicomDestinations: 'All DICOM Destinations',
  destinationGroupsWillAppear: 'DICOM Destinations  will appear here',
  atLeastOneDestinationRequired: 'At least one DICOM Destination is required',
}

import { LOADING_STATE } from 'types/general'

import { IState } from './types'

export const sliceName = 'destinations'
export const initialState: IState = {
  destinationLoadingState: LOADING_STATE.idle,
  destinationGroupLoadingState: LOADING_STATE.idle,
  destinationsTableData: {
    content: [],
    isFirstPage: true,
    isLastPage: false,
    totalElements: 0,
  },
  destinationGroupsTableData: {
    content: [],
    isFirstPage: true,
    isLastPage: false,
    totalElements: 0,
  },
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Grid, IconButton, Typography } from '@mui/material'
import {
  FormValidationStatusBlock,
  IValidationBlockConfig,
} from 'components/FormValidationStatusBlock'
import { TEmptyFunction } from 'types/general'

import { getOrderReadinessColor } from '../helpers/getOrderReadinessColor'

interface IFullSizeSideBarPreviewProps {
  configs: IValidationBlockConfig[]
  readinessPercent: number
  onExpandClick: TEmptyFunction
  onFieldClick: (fieldKey: string) => void
  isPanelOpen: boolean
}

export const FullSizeSideBarPreview: FC<IFullSizeSideBarPreviewProps> = ({
  configs,
  readinessPercent,
  onFieldClick,
  onExpandClick,
  isPanelOpen,
}) => {
  const { t } = useTranslation()

  return (
    <Grid container>
      <Grid
        item
        container
        justifyContent='space-between'
        alignItems='flex-start'
        flexWrap='nowrap'
        paddingBottom={1}
      >
        <Grid item>
          <Typography
            color={getOrderReadinessColor(readinessPercent)}
            fontWeight='bold'
          >
            {t('domains.RISOrders.orderValidationCompleted', {
              validationPercentage: `${readinessPercent}%`,
            })}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={onExpandClick}>
            {isPanelOpen ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
        </Grid>
      </Grid>
      <Grid item>
        <Typography>
          {t('domains.RISOrders.orderFillFormForSubmission')}
        </Typography>
      </Grid>
      {isPanelOpen ? (
        <Grid paddingTop={2}>
          {configs.map((blockConfig) => (
            <FormValidationStatusBlock
              onFieldClick={onFieldClick}
              key={blockConfig.title}
              config={blockConfig}
            />
          ))}
        </Grid>
      ) : null}
    </Grid>
  )
}

import { TTagRs } from 'store/tags/types'
import { LOADING_STATE } from 'types/general'
import { components } from 'types/schemas'

export enum ORDERS_TABLE_TAB {
  ALL = 'ALL',
  PREPARATION = 'PREPARATION',
  ASSIGNMENT = 'ASSIGNMENT',
  REPORT_QA = 'REPORT_QA',
  BILLING = 'BILLING',
}

export type TOrder = Omit<
  components['schemas']['BffSearchOrderRs'],
  'assignedRadiologistId' | 'physicianId' | 'tags'
> & {
  assignedRadiologistId?: string
  physicianId?: string
  tags: TTagRs[]
}

interface IOrderTableBasicData {
  content: Array<TOrder>
  totalElements: number
  totalPages: number
  isFirstPage: boolean
  isLastPage: boolean
}

export type TOrdersResponse = Omit<
  components['schemas']['BffOrderPageRs'],
  'content'
> & { content: TOrder[] }

export interface IAllOrdersTableData extends IOrderTableBasicData {}

export interface IPreparationOrdersTableData extends IOrderTableBasicData {}

export interface IAssignmentOrdersTableData extends IOrderTableBasicData {
  selectedRows: { [accessionNumber: string]: boolean }
}

export interface IReportQAOrdersTableData extends IOrderTableBasicData {}

export interface IBillingOrdersTableData extends IOrderTableBasicData {}

export interface IState {
  [ORDERS_TABLE_TAB.ALL]: IAllOrdersTableData
  [ORDERS_TABLE_TAB.PREPARATION]: IPreparationOrdersTableData
  [ORDERS_TABLE_TAB.ASSIGNMENT]: IAssignmentOrdersTableData
  [ORDERS_TABLE_TAB.REPORT_QA]: IReportQAOrdersTableData
  [ORDERS_TABLE_TAB.BILLING]: IBillingOrdersTableData
  isFilterPanelOpen: boolean
  loadingState: LOADING_STATE
}

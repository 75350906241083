import { configureStore } from '@reduxjs/toolkit'
import { createReduxEnhancer } from '@sentry/react'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import { bffApi } from 'services/bff'
import { dictionaryApi } from 'services/dictionary'
import { filesApi } from 'services/files'
import { orderReportVersionsApi } from 'services/orderReports'
import { ordersApi } from 'services/orders'
import { patientsApi } from 'services/patients'
import { providersApi } from 'services/providers'
import { supportApi } from 'services/support'
import { migrate } from 'store/filters/migrations'
import { SetFiltersSliceTransform } from 'store/filters/transforms'

import reducer, { type TAppReducer } from './reducers'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['filters', 'userPreferences'],
  transforms: [SetFiltersSliceTransform],
  stateReconciler: autoMergeLevel2,
  migrate,
}

export const persistedReducer = persistReducer<TAppReducer>(
  persistConfig,
  reducer
)

const sentryReduxEnhancer = createReduxEnhancer({
  attachReduxState: true,
  /*
    TODO: remove stateTransformer after BE and FE implement autocomplete to fetch procedures data (630kb) data
    BE Task: https://jira.andersenlab.com/browse/PSI-13766
    FE task: https://jira.andersenlab.com/browse/PSI-13767
  */
  stateTransformer: (state: RootState) => ({
    ...state,
    dictionaryApi: {
      ...state.dictionaryApi,
      queries: {
        ...state.dictionaryApi.queries,
        'getProceduresDescriptions(undefined)': null,
      },
    },
  }),
  actionTransformer: (action) => {
    if (action.type === 'ordersList/getOrdersByStatus/fulfilled') {
      return {
        ...action,
        payload: {
          ...action.payload,
          data: {
            ...action.payload.data,
            content: action.payload.data.content.map(
              ({ id }: { id: number }) => id
            ),
          },
        },
      }
    }

    return action
  },
})

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      bffApi.middleware,
      dictionaryApi.middleware,
      providersApi.middleware,
      filesApi.middleware,
      ordersApi.middleware,
      orderReportVersionsApi.middleware,
      patientsApi.middleware,
      supportApi.middleware,
    ]),
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat(sentryReduxEnhancer),
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

import { Alert, styled } from '@mui/material'

export const StyledAlert = styled(Alert)(() => ({
  minWidth: '320px',
  display: 'flex',
  height: '48px',
  alignItems: 'center',
  '& .MuiAlert-action': {
    padding: 0,
  },
}))

import { FC, forwardRef } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const PendingFaxDeliveryIcon: FC<SvgIconProps & { className?: string }> =
  forwardRef(({ className, fontSize = 'small', ...restProps }, ref) => (
    <SvgIcon
      viewBox='0 0 18 18'
      fontSize={fontSize}
      className={className}
      {...restProps}
      ref={ref}
    >
      <path
        d='M7.9925 0.5C3.8525 0.5 0.5 3.86 0.5 8C0.5 12.14 3.8525 15.5 7.9925 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 7.9925 0.5ZM10.4675 11.5325L7.25 8.3075V4.25H8.75V7.6925L11.5325 10.475L10.4675 11.5325Z'
        fill='#FFA726'
      />
    </SvgIcon>
  ))

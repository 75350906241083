import { IState } from 'store/procedures/types'
import { LOADING_STATE } from 'types/general'

export const initialState: IState = {
  loadingState: LOADING_STATE.idle,
  error: null,
  procedure: undefined,
}

export const name = 'procedures'

import { IValidationBlockConfig } from 'components/FormValidationStatusBlock'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getValidationProgress } from 'store/validationProgress/selectors'
import { setValidationProgressInputToFocus } from 'store/validationProgress/slice'

type TUseGetValidationProgressBarDataReturn =
  | {
      isProgressBarDisplay: false
      progressBarProps?: unknown
    }
  | {
      isProgressBarDisplay: true
      progressBarProps: {
        configs: IValidationBlockConfig[]
        onFieldClick: (fieldKey: string) => void
        readinessPercentage: number
      }
    }

export const useGetValidationProgressBarData =
  (): TUseGetValidationProgressBarDataReturn => {
    const dispatch = useAppDispatch()

    const { IsValidationProgressBarDisplay, readinessPercentage, configs } =
      useAppSelector(getValidationProgress)

    const handleFieldClick = (fieldKey: string) => {
      dispatch(setValidationProgressInputToFocus(fieldKey))
    }

    if (IsValidationProgressBarDisplay) {
      return {
        isProgressBarDisplay: IsValidationProgressBarDisplay,
        progressBarProps: {
          readinessPercentage,
          configs,
          onFieldClick: handleFieldClick,
        },
      }
    }

    return {
      isProgressBarDisplay: IsValidationProgressBarDisplay,
    }
  }

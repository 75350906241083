import { useAuth } from 'react-oidc-context'
import { ALL_ROLES, USER_ROLES } from 'constants/userRoles'

export const useRoles = () => {
  const { user } = useAuth()

  const checkUserRoles = (requiredRoles: USER_ROLES[]): boolean => {
    return Boolean(
      requiredRoles.find((role) =>
        (user?.profile?.roles as Array<USER_ROLES>)?.includes(role)
      )
    )
  }

  return {
    roles:
      (user?.profile?.roles as Array<USER_ROLES>).filter((role) =>
        ALL_ROLES.includes(role)
      ) ?? [],
    checkUserRoles,
  }
}

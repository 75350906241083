export const PROGRESS_COMPLETE_VALUE = 100
export const PROGRESS_INITIAL_VALUE = 0

export enum FILE_FORMATS {
  EMPTY = '',
  JPEG = 'jpeg',
  JPG = 'jpg',
  PNG = 'png',
  TIFF = 'tiff',
  DOC = 'doc',
  DOCX = 'docx',
  PDF = 'pdf',
  RTF = 'rtf',
  DCM = 'dcm',
  DICM = 'dicm',
}

export enum S3_FILE_GROUPS {
  COMPARISON_REPORT = 'COMPARISON_REPORT',
  DICOM = 'DICOM',
  PRESCRIPTION = 'PRESCRIPTION',
  HISTORY = 'HISTORY',
  BILLING_INFORMATION = 'BILLING_INFORMATION',
  TECH_WORKSHEET = 'TECH_WORKSHEET',
  MEDICAL_CLEARANCE_PROCESS = 'MEDICAL_CLEARANCE_PROCESS',
  SITE_LOGO = 'SITE_LOGO',
  RADIOLOGIST_SIGNATURE = 'RADIOLOGIST_SIGNATURE',
  REPORT_APPENDIX = 'REPORT_APPENDIX',
  FAX = 'FAX',
  OTHER = 'OTHER',
  LEGAL_COPY = 'LEGAL_COPY',
  CSV_REPORT = 'CSV_REPORT',
  DOCUMENT = 'DOCUMENT',
}

export const DICOM_PREVIEW_ID_PREFIX = 'preview-canvas-wrapper-'

export const HEADER_LOGO_MB_SIZE = 5

export const availableHeaderFormats = [FILE_FORMATS.JPEG, FILE_FORMATS.PNG]

import { createContext, FC, ReactNode, useEffect, useMemo } from 'react'
import { useColorScheme, useMediaQuery } from '@mui/material'
import { getIsDarkMode } from 'utils/helpers/general'
import { COLOR_MODE } from 'types/theme'

export const ColorModeContext = createContext({
  mode: COLOR_MODE.Light,
  toggleColorMode: () => {},
})

interface IColorModeProviderProps {
  children: ReactNode
}

export const ColorModeProvider: FC<IColorModeProviderProps> = ({
  children,
}) => {
  const { mode, setMode } = useColorScheme()
  const isDarkModePreferred = useMediaQuery('(prefers-color-scheme: dark)')

  const colorMode = useMemo(() => {
    // @ts-expect-error variable mode has 'dark' | 'light | 'system' literal types
    const isDarkMode = getIsDarkMode(mode)

    return {
      toggleColorMode: () => {
        const newMode = isDarkMode ? COLOR_MODE.Light : COLOR_MODE.Dark

        window.localStorage.setItem('ProScan-mode', newMode)
        setMode(newMode)
      },
      mode: mode as COLOR_MODE,
    }
  }, [mode, setMode])

  useEffect(() => {
    const savedColorMode = localStorage.getItem('ProScan-mode') as COLOR_MODE

    setMode(
      savedColorMode ??
        (isDarkModePreferred ? COLOR_MODE.Dark : COLOR_MODE.Light)
    )
  }, [isDarkModePreferred, setMode])

  return (
    <ColorModeContext.Provider value={colorMode}>
      {children}
    </ColorModeContext.Provider>
  )
}

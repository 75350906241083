import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from '@mui/material'

export const StyledDialog = styled(Dialog)(() => ({
  '& > .MuiDialog-container': {
    '& > .MuiPaper-root': {
      minWidth: '500px',
    },
  },
}))

export const StyledDialogTitle = styled(DialogTitle)(
  ({ theme: { spacing } }) => ({
    marginBottom: spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  })
)

export const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
}))

export const StyledDialogContent = styled(DialogContent)(() => ({
  paddingBottom: 'unset',
}))

export const StyledSubTitleContainer = styled(Box)(
  ({ theme: { spacing } }) => ({
    padding: spacing(0, 3),
  })
)

import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from 'services/axiosBaseQuery'
import { SUPPORT_TAG } from 'services/support/constants'
import { TOpenCountResponse } from 'services/support/types'
import { SUPPORT_REQUEST_TYPES } from 'store/support/types'

export const supportApi = createApi({
  reducerPath: 'supportApi',
  tagTypes: [SUPPORT_TAG],
  baseQuery: axiosBaseQuery({ baseUrl: '/bff/sr' }),
  endpoints: (builder) => ({
    getSupportRequestCount: builder.query<TOpenCountResponse, void>({
      query: () => ({
        method: 'GET',
        url: 'open-count',
        params: {
          supportRequestTypes: SUPPORT_REQUEST_TYPES.CUSTOMER,
        },
      }),
    }),
  }),
})

export const { useGetSupportRequestCountQuery } = supportApi

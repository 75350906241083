import { TBillingType } from 'services/dictionary/types'
import { ORDER_STATUS_ENUM, TCaptureEditMetadata } from 'store/order/types'
import { TServiceError } from 'utils/api/types'
import { LOADING_STATE, TPageable } from 'types/general'
import { components } from 'types/schemas'

type TBffInsuranceInfo = components['schemas']['BffInsuranceInfo'] & {
  injuredAt?: string
}

export type TBffPolicyHolderInfo = components['schemas']['BffPolicyHolderInfo']

export type TBffInsuranceInformation =
  components['schemas']['BffInsuranceInformation'] & {
    insuranceInfo: TBffInsuranceInfo
  }
export type TOrderBillingInformationRs = Omit<
  components['schemas']['BffOrderBillingInfoRs'],
  'billingRevertDataRs' | 'billingEditCaptures' | 'orderStatus'
> & {
  orderStatus: ORDER_STATUS_ENUM
  billingEditCaptures?: Array<TCaptureEditMetadata>
  primaryInsurance: TBffInsuranceInformation
  secondaryInsurance: TBffInsuranceInformation
  orderBillingStatus?: BILLING_STATUS
  billingRevertDataRs: Omit<
    components['schemas']['BillingRevertDataRs'],
    'reasons' | 'revertType'
  > & {
    revertType?: REVERT_TYPE_ENUM
    reasons: Omit<components['schemas']['RevertReasonRs'], 'reasons'> & {
      reasons: Array<TRevertReason>
    }
  }
}

export type TPatientRelationship = components['schemas']['PatientRelationship']
export type TBffBasicPatientInfo = components['schemas']['BffBasicPatientInfo']

export type TInsurancePageRs = Required<
  components['schemas']['InsurancePageRs']
>
export type TInsuranceBillingPageRs = Required<
  components['schemas']['InsuranceBillingPageRs']
>
export type TInsuranceBillingGridRs = Required<
  components['schemas']['InsuranceBillingGridRs']
>

export type TInsuranceType = components['schemas']['InsuranceType']

export type TPhoneNumber = components['schemas']['PhoneNumber']

export type TInsuranceGridRs = components['schemas']['InsuranceGridRs']

export type TOrderBillingInfoUpdateRq =
  components['schemas']['OrderBillingInfoUpdateRq']
export type TDraftOrderBillingInfoUpdateRq =
  components['schemas']['DraftOrderBillingInfoUpdateRq']

export type TBillingStatusRq = components['schemas']['BillingUpdateStatusRq']

export type TInsuranceRs = components['schemas']['InsuranceRs']

export enum INSURANCE_TYPES_ENUM {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export type TInsuranceFilter = Required<
  components['schemas']['InsuranceFilterRq']
>

export interface IState {
  billingTypes: {
    [INSURANCE_TYPES_ENUM.PRIMARY]: TBillingType[]
    [INSURANCE_TYPES_ENUM.SECONDARY]: TBillingType[]
  }
  billingInformation?: TOrderBillingInformationRs
  loadingState: LOADING_STATE
  primaryInsurancesTable: {
    list: TInsurancePageRs
    loadingState: LOADING_STATE
    pageable: TPageable
    error?: TServiceError | null
    filter: TInsuranceFilter
  }
  insuranceHistoryTable: {
    list: TInsuranceBillingPageRs
    loadingState: LOADING_STATE
    pageable: TPageable
  }
}

export enum BILLING_STATUS {
  PENDING_BILLING_INFO = 'PENDING_BILLING_INFO',
  BILLING_INFO_COMPLETE = 'BILLING_INFO_COMPLETE',
  BILLING_VERIFIED = 'BILLING_VERIFIED',
  REVERT = 'REVERT',
  BILLING_NOT_APPLICABLE = 'BILLING_NOT_APPLICABLE',
  CHARGES_RETRIEVED = 'CHARGES_RETRIEVED',
  PENDING_REVERT = 'PENDING_REVERT',
  UNDEFINED = 'UNDEFINED',
}

export enum INSURANCE_OPTIONS_ENUM {
  HISTORY = 'HISTORY',
  OTHER = 'OTHER',
}

export enum BILLING_TYPE_SCOPE {
  PROSCAN = 'PROSCAN',
  COMPANY = 'COMPANY',
  SYSTEM = 'SYSTEM',
}

export type TRevertReason = Required<components['schemas']['RevertReason']>

export type TRevertReasonAndDateRs = Omit<
  components['schemas']['RevertReasonRs'],
  'reasons'
> & {
  reasons: Array<TRevertReason>
}

export enum REVERT_TYPE_ENUM {
  INSTANT = 'INSTANT',
  SET_UP_DATE = 'SET_UP_DATE',
}

import { createSlice } from '@reduxjs/toolkit'
import { INITIAL_STATE, USERS_SLICE_NAME } from 'store/users/constants'
import { LOADING_STATE } from 'types/general'

import * as controllers from './controllers'

const usersSlice = createSlice({
  name: USERS_SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    resetUser: (state) => {
      state.user = INITIAL_STATE.user
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(controllers.getUser.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.getUser.fulfilled, (state, { payload }) => {
        state.loadingState = LOADING_STATE.fulfilled
        state.user = payload
      })
      .addCase(controllers.getUser.rejected, (state, { payload }) => {
        state.loadingState = LOADING_STATE.rejected
        state.error = payload
      })
      .addCase(controllers.createUser.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.createUser.fulfilled, (state) => {
        state.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(controllers.createUser.rejected, (state, action) => {
        state.loadingState = LOADING_STATE.rejected
        state.error = action.payload
      })
      .addCase(controllers.updateUser.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.updateUser.fulfilled, (state) => {
        state.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(controllers.updateUser.rejected, (state, action) => {
        state.loadingState = LOADING_STATE.rejected
        state.error = action.payload
      })
  },
})

export const { resetUser } = usersSlice.actions

export default usersSlice

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { TPath } from 'types/general'

export const useNavigateToFirstTabOnMounting = (
  pathToNavigate: TPath,
  currentTab?: string
) => {
  const navigate = useNavigate()

  useEffect(() => {
    !currentTab && navigate(pathToNavigate, { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab])
}

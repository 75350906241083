import { USER_ROLES } from 'constants/userRoles'
import { ConfigureGearHeaderCell } from 'components/cells/ConfigureGearHeaderCell'
import { DateLocaleCell } from 'components/cells/DateLocaleCell'
import { DateTimeLocaleCell } from 'components/cells/DateTimeLocaleCell'
import { PhysicianCell } from 'components/cells/PhysicianCell'
import { RadiologistCell } from 'components/cells/RadiologistCell'
import { UrgencyCell } from 'components/cells/UrgencyCell'
import { IColumnConfigItem } from 'components/ui-components/PSTable/types'
import { RisOrderPatientCell } from 'pages/orders/components/Cells/RisOrderPatientCell'
import { RisOrderProcedureCell } from 'pages/orders/components/Cells/RisOrderProcedureCell'
import { getGearOptionsConfig } from 'pages/orders/OrdersList/components/AllRisOrdersTab/helpers/getGearOptionsConfig'
import { BillingStatusCell } from 'pages/orders/OrdersList/components/Cells/BillingStatusCell'
import { BillingTypeCell } from 'pages/orders/OrdersList/components/Cells/BillingTypeCell'
import { OrderViewCell } from 'pages/orders/OrdersList/components/Cells/OrderViewCell'
import { ProscanMrnCell } from 'pages/orders/OrdersList/components/Cells/ProscanMrnCell'
import { RISOrderStatusCell } from 'pages/orders/OrdersList/components/Cells/RISOrderStatusCell'
import { SourceMrnCell } from 'pages/orders/OrdersList/components/Cells/SourceMrnCell'
import { SystemTagCell } from 'pages/orders/OrdersList/components/Cells/TagsCell/SystemTagCell'
import { TagsCell } from 'pages/orders/OrdersList/components/Cells/TagsCell/TagsCell'
import { configureAllRisOrdersTableColumns } from 'store/filters/orders/ordersSlice'
import { ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS } from 'store/filters/orders/types'
import { getAllOrderTableConfiguration } from 'store/filters/selectors'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { generateTableColumnSortConfig } from 'utils/helpers/tables/generateTableColumnSortConfig'
import { useRoles } from 'utils/hooks/useRoles'

import { useSortTableColumns } from './useSortTableColumns'

export const useAllRisOrdersTabTableConfigForSuperUserAndSysAdminRoles =
  (): IColumnConfigItem[] => {
    const dispatch = useAppDispatch()
    const { checkUserRoles } = useRoles()
    const { columnsConfiguration } = useAppSelector(
      getAllOrderTableConfiguration
    )

    const [sort, handleSortColumn] = useSortTableColumns()

    return [
      {
        title: 'domains.RISOrders.proScanAccessionColumnTitle',
        key: 'accessionNumber',
        sort: generateTableColumnSortConfig(
          'accessionNumber',
          handleSortColumn,
          sort
        ),
      },
      ...(columnsConfiguration[
        ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.PRO_SCAN_MRN
      ]
        ? [
            {
              title: 'domains.RISOrders.proScanMRNColumnTitle',
              key: 'proscanMrn',
              RenderCell: ProscanMrnCell,
            },
          ]
        : []),
      {
        title: 'domains.RISOrders.urgencyTitle',
        key: 'urgency',
        RenderCell: UrgencyCell,
      },
      {
        title: 'domains.RISOrders.dateAndTimeOfServiceTitle',
        key: 'dateOfService',
        RenderCell: DateTimeLocaleCell,
        sort: generateTableColumnSortConfig(
          'dateOfService',
          handleSortColumn,
          sort
        ),
      },
      ...(columnsConfiguration[
        ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.CREATE_DATE
      ]
        ? [
            {
              title: 'domains.RISOrders.creationDateColumnTitle',
              key: 'creationDateTime',
              RenderCell: DateLocaleCell,
            },
          ]
        : []),
      {
        title: 'domains.RISOrders.patientColumnTitle',
        key: 'patientCell',
        RenderCell: RisOrderPatientCell,
      },
      {
        title: 'domains.RISOrders.procedureTitle',
        key: 'procedureCell',
        RenderCell: RisOrderProcedureCell,
      },
      ...(columnsConfiguration[
        ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.SOURCE_ACCESSION
      ]
        ? [
            {
              title: 'domains.RISOrders.sourceAccessionNumberColumnTitle',
              key: 'sourceAccessionNumber',
            },
          ]
        : []),
      ...(columnsConfiguration[ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.SOURCE_MRN]
        ? [
            {
              title: 'domains.RISOrders.sourceMRN',
              key: 'sourceMrn',
              RenderCell: SourceMrnCell,
            },
          ]
        : []),
      {
        title: 'domains.RISOrders.siteColumnTitle',
        key: 'siteName',
      },
      ...(columnsConfiguration[ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.COMPANY]
        ? [
            {
              title: 'domains.RISOrders.companyTitle',
              key: 'companyName',
            },
          ]
        : []),
      {
        title: 'domains.RISOrders.refMdTitle',
        key: 'physicianName',
        RenderCell: PhysicianCell,
      },
      {
        title: 'domains.RISOrders.assignedReaderTitle',
        key: 'assignedRadiologistName',
        RenderCell: RadiologistCell,
      },
      {
        title: 'domains.RISOrders.risOrderStatusColumnTitle',
        key: 'risOrderStatus',
        RenderCell: RISOrderStatusCell,
      },
      ...(columnsConfiguration[
        ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.BILLING_TYPE
      ]
        ? [
            {
              title: 'domains.RISOrders.billingTypeTitle',
              key: 'billingType',
              RenderCell: BillingTypeCell,
            },
          ]
        : []),
      ...(columnsConfiguration[
        ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.BILLING_STATUS
      ]
        ? [
            {
              title: 'domains.RISOrders.billingStatusTitle',
              key: 'billingStatus',
              RenderCell: BillingStatusCell,
            },
          ]
        : []),
      {
        title: 'domains.RISOrders.tagsColumnTitle',
        key: 'tags',
        RenderCell: TagsCell,
      },
      {
        title: 'domains.RISOrders.systemTags',
        key: 'systemTags',
        RenderCell: SystemTagCell,
      },
      {
        key: 'accessionNumber_view',
        RenderCell: OrderViewCell,
        cellStyles: { width: '87px' },
        RenderHeaderCell: ConfigureGearHeaderCell,
        externalHeaderCellProps: {
          options: getGearOptionsConfig(
            true,
            checkUserRoles([
              USER_ROLES.SITE_USER_ADMIN,
              USER_ROLES.SITE_USER,
              USER_ROLES.SUPER_USER,
              USER_ROLES.SYS_ADMIN,
              USER_ROLES.PROCTOR,
            ])
          ),
          columnsConfig: columnsConfiguration,
          onOptionsChange: (
            columnName: ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS
          ) => {
            dispatch(configureAllRisOrdersTableColumns({ columnName }))
          },
        },
      },
    ]
  }

import {
  IDatePeriod,
  PERIODS_ENUM,
} from 'components/filters/DateRangeFilter/types'

export const DATE_PERIODS: IDatePeriod[] = [
  {
    key: PERIODS_ENUM.TODAY,
    label: 'domains.RISOrders.today',
  },
  {
    key: PERIODS_ENUM.LAST_30_DAYS,
    label: 'domains.RISOrders.last30Days',
  },
  {
    key: PERIODS_ENUM.LAST_90_DAYS,
    label: 'domains.RISOrders.last90Days',
  },
  {
    key: PERIODS_ENUM.THIS_WEEK,
    label: 'domains.RISOrders.thisWeek',
  },
]

export const customPeriodOption: IDatePeriod = {
  key: PERIODS_ENUM.CUSTOM,
  label: 'domains.RISOrders.custom',
}

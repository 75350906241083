import { ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS } from 'store/filters/orders/types'

export const getGearOptionsConfig = (
  isSuperUserOrSysAdmin: boolean,
  shouldShowBillingColumns: boolean
) =>
  isSuperUserOrSysAdmin
    ? {
        [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.PRO_SCAN_MRN]:
          'domains.RISOrders.proScanMRNColumnTitle',
        [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.CREATE_DATE]:
          'domains.RISOrders.creationDateColumnTitle',
        [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.SOURCE_ACCESSION]:
          'domains.RISOrders.sourceAccessionNumberColumnTitle',
        [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.SOURCE_MRN]:
          'domains.RISOrders.sourceMRN',
        [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.COMPANY]:
          'domains.RISOrders.companyTitle',
        ...(shouldShowBillingColumns && {
          [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.BILLING_TYPE]:
            'domains.RISOrders.billingTypeTitle',
          [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.BILLING_STATUS]:
            'domains.RISOrders.billingStatusTitle',
        }),
      }
    : {
        [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.SOURCE_ACCESSION]:
          'domains.RISOrders.sourceAccessionNumberColumnTitle',
        [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.PRO_SCAN_MRN]:
          'domains.RISOrders.proScanMRNColumnTitle',
        [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.SOURCE_MRN]:
          'domains.RISOrders.sourceMRN',
        [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.CREATE_DATE]:
          'domains.RISOrders.creationDateColumnTitle',
        [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.SITE_NAME]:
          'domains.RISOrders.siteNameTitle',
        ...(shouldShowBillingColumns && {
          [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.BILLING_TYPE]:
            'domains.RISOrders.billingTypeTitle',
          [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.BILLING_STATUS]:
            'domains.RISOrders.billingStatusTitle',
        }),
      }

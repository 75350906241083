import { ORDER_STATUS_ENUM, TCaptureEditMetadata } from 'store/order/types'
import { LOADING_STATE } from 'types/general'
import { components } from 'types/schemas'

export type TOrderReport = components['schemas']['ReportRs']

export type TOrderReportInformation = Omit<
  components['schemas']['ReportOrderRs'],
  'orderStatus' | 'reportEditCaptures'
> & {
  orderStatus: ORDER_STATUS_ENUM
  reportEditCaptures?: Array<TCaptureEditMetadata>
}
export type TReportTextsRs = components['schemas']['ReportTextsRs']

export type TUpdateReportRq = components['schemas']['UpdateReportRq']

export type TReportToggleUpdateRq =
  components['schemas']['ReportToggleUpdateRq']

export type TReportLegalCopyRs = components['schemas']['ReportLegalCopyRs']

export type TLegalCopyExistenceRs = Required<
  components['schemas']['LegalCopyExistenceRs']
>

export type TReportCreationRq = components['schemas']['ReportCreationRq']

export type TBffAuditTriggerRq = Required<
  components['schemas']['BffAuditTriggerRq']
>

export interface IState {
  reportInformation?: TOrderReportInformation
  loadingState: LOADING_STATE
  compareInformation?: TReportTextsRs
  openedReportModal: REPORT_MODALS | null
  legalCopies: {
    data: TReportLegalCopyRs[]
    loadingState: LOADING_STATE
  }
  orderReportVersionModalData: {
    isFinalLegalCopyReleased: boolean
    isFinalLegalCopyReleasedLoading: boolean
    isOrderReportVersionCreating: boolean
  }
}

export enum DOWNLOAD_REPORT_TYPES {
  PDF = 'pdf',
  HTML = 'html',
}

export enum MANUAL_REPORT_STATUS_ENUM {
  APPROVED = 'APPROVED',
  ADDENDUM = 'ADDENDUM',
  DRAFT = 'DRAFT',
}

export enum REPORT_TYPE_ENUM {
  FINAL = 'FINAL',
  PRELIMINARY = 'PRELIMINARY',
  FINAL_ADDENDUM = 'FINAL_ADDENDUM',
  PRELIMINARY_ADDENDUM = 'PRELIMINARY_ADDENDUM',
}

export enum REPORT_MODALS {
  VERSIONS = 'VERSIONS',
  LEGAL_COPIES = 'LEGAL_COPIES',
  PRELIMINARY_REPORT = 'PRELIMINARY_REPORT',
  CREATE_VERSION = 'CREATE_VERSION',
  SEND_FAX = 'SEND_FAX',
}

export enum AUDIT_TRIGGER_ACTION {
  DOWNLOAD_LEGAL_COPY = 'DOWNLOAD_LEGAL_COPY',
  COPY_LEGAL_COPY_HL7_MESSAGE = 'COPY_LEGAL_COPY_HL7_MESSAGE',
}

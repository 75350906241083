import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ERROR_CODE } from 'constants/errorCode'

export const useBEErrorTranslation = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'common.BEErrors' })

  const translateError = useCallback(
    (errorCode: ERROR_CODE = ERROR_CODE.NETWORK_ERROR) => {
      return t(errorCode)
    },
    [t]
  )

  return { translateError }
}

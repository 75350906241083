import { Alert, Snackbar, styled } from '@mui/material'

export const StyledAlert = styled(Alert)(({ theme: { spacing } }) => ({
  minWidth: '320px',
  maxWidth: '350px',
  display: 'flex',
  '& .MuiAlert-icon': {
    paddingTop: spacing(1.25),
    alignSelf: 'start',
  },
}))

export const StyledSnackbarContainer = styled(Snackbar)(() => ({
  position: 'static',
}))

import { createAsyncThunk } from '@reduxjs/toolkit'
import { TBillingType } from 'services/dictionary/types'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'
import { TPageable } from 'types/general'

import { ORDER_BILLING_INFO_SLICE_NAME } from './constants'
import {
  BILLING_STATUS,
  INSURANCE_TYPES_ENUM,
  TBillingStatusRq,
  TDraftOrderBillingInfoUpdateRq,
  TInsuranceBillingPageRs,
  TInsuranceFilter,
  TInsurancePageRs,
  TInsuranceRs,
  TInsuranceType,
  TOrderBillingInformationRs,
  TOrderBillingInfoUpdateRq,
  TRevertReasonAndDateRs,
} from './types'

export const fetchOrderBillingInformationTypes = createAsyncThunk<
  TBillingType[],
  { insuranceType: INSURANCE_TYPES_ENUM; accessionNumber: string },
  {
    rejectValue: TServiceError
  }
>(
  `${ORDER_BILLING_INFO_SLICE_NAME}/fetchBillingTypes`,
  async ({ accessionNumber, insuranceType }, { rejectWithValue }) => {
    const result = await request<TBillingType[]>({
      url: `bff/orders/${accessionNumber}/billing-types`,
      method: 'GET',
      params: { type: insuranceType },
      headers: {
        AccessionNumber: accessionNumber,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const fetchOrderBillingInformation = createAsyncThunk<
  TOrderBillingInformationRs,
  string,
  {
    rejectValue: TServiceError
  }
>(
  `${ORDER_BILLING_INFO_SLICE_NAME}/fetchBillingInformation`,
  async (accessionNumber, { rejectWithValue }) => {
    const result = await request<TOrderBillingInformationRs>({
      url: `/bff/orders/${accessionNumber}/billing`,
      method: 'GET',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const updateBillingInformation = createAsyncThunk<
  unknown,
  { fields: TOrderBillingInfoUpdateRq; accessionNumber: string },
  {
    rejectValue: TServiceError
  }
>(
  `${ORDER_BILLING_INFO_SLICE_NAME}/updateBillingInformation`,
  async ({ accessionNumber, fields }, { rejectWithValue }) => {
    const result = await request<number>({
      url: `/orders/${accessionNumber}/billing`,
      data: fields,
      method: 'PUT',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return result.data
  }
)

export const draftUpdateBillingInformation = createAsyncThunk<
  unknown,
  { fields: TDraftOrderBillingInfoUpdateRq; accessionNumber: string },
  {
    rejectValue: TServiceError
  }
>(
  `${ORDER_BILLING_INFO_SLICE_NAME}/draftUpdateBillingInformation`,
  async ({ accessionNumber, fields }, { rejectWithValue }) => {
    const result = await request<number>({
      url: `/orders/${accessionNumber}/billing/draft`,
      data: fields,
      method: 'PUT',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return result.data
  }
)

export const searchInsurance = createAsyncThunk<
  TInsurancePageRs,
  {
    pageable?: TPageable
    filter: TInsuranceFilter | null
    billingTypeId: number
    isDefaultInsurancePlan?: boolean
  },
  {
    rejectValue: TServiceError
  }
>(
  `${ORDER_BILLING_INFO_SLICE_NAME}/searchInsurance`,
  async (
    { pageable, billingTypeId, filter, isDefaultInsurancePlan = false },
    { rejectWithValue }
  ) => {
    const result = await request<TInsurancePageRs>({
      url: '/orders/insurances',
      method: 'GET',
      params: {
        ...pageable,
        ...(filter ?? {}),
        billingTypeId,
        isDefaultInsurancePlan,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const setBillingStatusRq = createAsyncThunk<
  unknown,
  TBillingStatusRq & { accessionNumber: string },
  {
    rejectValue: TServiceError
  }
>(
  `${ORDER_BILLING_INFO_SLICE_NAME}/setBillingStatusRq`,
  async ({ accessionNumber, ...changeStatusData }, { rejectWithValue }) => {
    const result = await request<unknown>({
      url: `/orders/${accessionNumber}/billing-status`,
      method: 'POST',
      data: changeStatusData,
      headers: {
        AccessionNumber: accessionNumber,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const fetchInsuranceHistory = createAsyncThunk<
  TInsuranceBillingPageRs,
  {
    pageable: TPageable
    proscanMrn: string
    type: TInsuranceType
    billingTypeId: number
  },
  {
    rejectValue: TServiceError
  }
>(
  `${ORDER_BILLING_INFO_SLICE_NAME}/getInsuranceHistory`,
  async ({ pageable, ...restQuery }, { rejectWithValue }) => {
    const result = await request<TInsuranceBillingPageRs>({
      url: '/orders/billings/insurances',
      method: 'GET',
      params: {
        ...pageable,
        ...restQuery,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const fetchUnlistedInsurance = createAsyncThunk<
  TInsuranceRs,
  {
    billingType: BILLING_STATUS
  },
  {
    rejectValue: TServiceError
  }
>(
  `${ORDER_BILLING_INFO_SLICE_NAME}/fetchUnlistedInsurance`,
  async ({ billingType }, { rejectWithValue }) => {
    const result = await request<TInsuranceRs>({
      url: '/orders/insurances/system',
      method: 'GET',
      params: {
        billingType,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

export const fetchBillingRevertReasonsAndDate = createAsyncThunk<
  TRevertReasonAndDateRs,
  string,
  { rejectValue: TServiceError }
>(
  `${ORDER_BILLING_INFO_SLICE_NAME}/fetchBillingRevertReasonsAndDate`,
  async (accessionNumber, { rejectWithValue }) => {
    const result = await request<TRevertReasonAndDateRs>({
      url: `/orders/${accessionNumber}/billing/revert/reason`,
      method: 'GET',
      headers: {
        AccessionNumber: accessionNumber,
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)

import { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import { useNavigate } from 'react-router-dom'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import {
  Alert,
  Avatar,
  Box,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import { PROSCAN_KNOWLEDGE_BASE_URL } from 'constants/contacts'
import { routes } from 'routes/routes'
import { getUserInfo } from 'store/auth/selectors'
import { useAppSelector } from 'store/hooks'
import { persistor } from 'store/store'
import { useModal } from 'utils/hooks/useModal'

import { PSConfirmationDialog } from '../ui-components/PSConfirmationDialog'
import {
  StyledArrowDownIcon,
  StyledEyeBrowContainer,
  StyledProfileButton,
} from './Header.styled'

export const Header = () => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const auth = useAuth()
  const navigate = useNavigate()
  const open = Boolean(anchorEl)
  const { isModalOpened, toggleModalVisibility } = useModal()

  const user = useAppSelector(getUserInfo)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleProfileClick = () => {
    navigate(routes.profile.root.path)
    handleClose()
  }

  const handleLogoutConfirm = async () => {
    persistor.purge()
    await auth.signoutRedirect()
    handleClose()
    toggleModalVisibility()
  }

  const handleLogoutClick = () => {
    toggleModalVisibility()
    handleClose()
  }

  const shouldDisplayUATBanner = ['proscan-uat.com'].some(
    (url) => window.location.hostname === url
  )

  return (
    <>
      <PSConfirmationDialog
        data-cy='logout-confirmation-dialog'
        isDialogOpen={isModalOpened}
        headerText={t('components.modals.logoutLabel')}
        dialogText={t('components.modals.logoutMessage')}
        agreeButtonText={t('components.modals.logoutAcceptButton')}
        onCancel={toggleModalVisibility}
        onAgree={handleLogoutConfirm}
      />
      <StyledEyeBrowContainer data-cy='styled-eye-brow' container>
        <Box data-cy='header-box' display='flex' alignItems='center' gap={2}>
          {shouldDisplayUATBanner && (
            <Alert data-cy='uat-banner' severity='error'>
              {'Attention: You are currently logged in to UAT.'}
            </Alert>
          )}
          <Avatar data-cy='avatar'>{`${user.firstName?.slice(
            0,
            1
          )}${user.lastName?.slice(0, 1)}`}</Avatar>
          <StyledProfileButton gap={1} onClick={handleClick}>
            <Typography variant='body2' align='center'>
              {`${user.firstName} ${user.lastName}`}
            </Typography>
            <StyledArrowDownIcon />
          </StyledProfileButton>
        </Box>
        <Menu
          id='person-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem onClick={handleProfileClick}>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText>{t('common.myProfile')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleLogoutClick}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>{t('common.logOut')}</ListItemText>
          </MenuItem>
          <MenuItem
            component={Link}
            href={PROSCAN_KNOWLEDGE_BASE_URL}
            target='_blank'
          >
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText>{t('common.knowledgeBase')}</ListItemText>
          </MenuItem>
        </Menu>
      </StyledEyeBrowContainer>
    </>
  )
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import {
  TUrgencyColorMapKeys,
  URGENCY_COLOR_MAP,
} from 'constants/colors/urgencyColors'
import { PSChip } from 'components/ui-components/PSChip'

interface IUrgencyCellProps {
  data: {
    id: TUrgencyColorMapKeys
    name: string
  }
}

export const UrgencyCell: FC<IUrgencyCellProps> = ({ data }) => {
  const { t } = useTranslation()

  if (data) {
    const title = URGENCY_COLOR_MAP[data.id].transformTitle

    return (
      <PSChip
        textColor={URGENCY_COLOR_MAP[data.id].textColor}
        bgColor={URGENCY_COLOR_MAP[data.id].backgroundColor}
        label={title ? t(title) : data.name}
      />
    )
  }

  return <Box>{t('components.table.notApplicable')}</Box>
}

import { IState } from 'store/support/types'
import { LOADING_STATE } from 'types/general'

export const SLICE_NAME = 'support'

export const initialState: IState = {
  table: {
    content: [],
    isFirstPage: true,
    isLastPage: false,
    totalElements: 0,
    isFilterPanelOpen: false,
    loadingState: LOADING_STATE.idle,
  },
  entity: {
    data: null,
    loadingState: LOADING_STATE.idle,
    isTargetGroupUpdating: false,
    isStatusUpdating: false,
    isTypeUpdating: false,
  },
  comments: {
    content: [],
    isFirstPage: true,
    isLastPage: false,
    totalElements: 0,
    queryData: {
      page: undefined,
      size: 10,
      search: '',
    },
    loadingState: LOADING_STATE.idle,
    error: null,
  },
}

import type { IState } from 'store/company/types'
import { LOADING_STATE } from 'types/general'

export const COMPANY_BILLING_TYPE_SCOPE = 'COMPANY'

export const initialState: IState = {
  list: {
    content: [],
    totalElements: 0,
    totalPages: 0,
    first: true,
    last: true,
  },
  availableCompaniesFilters: {
    countries: [],
    states: [],
  },
  company: {
    data: null,
    loadingState: LOADING_STATE.idle,
  },
  reportConfiguration: undefined,
  loadingState: LOADING_STATE.idle,
  error: undefined,
}

import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATE } from 'types/general'

import { initialState, sliceName } from './constants'
import {
  getDestinationGroups,
  getDestinations,
  getDicomDestinationGroupRq,
  getDicomDestinationRq,
} from './controllers'

const destinationsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDicomDestinationRq.pending, (state) => {
        state.destinationLoadingState = LOADING_STATE.pending
      })
      .addCase(getDestinations.pending, (state) => {
        state.destinationLoadingState = LOADING_STATE.pending
      })
      .addCase(getDestinations.rejected, (state) => {
        state.destinationLoadingState = LOADING_STATE.rejected
      })
      .addCase(getDestinations.fulfilled, (state, { payload }) => {
        state.destinationsTableData = {
          ...state.destinationsTableData,
          content: payload.content,
          isFirstPage: payload.first,
          isLastPage: payload.last,
          totalElements: payload.totalElements,
        }
        state.destinationLoadingState = LOADING_STATE.fulfilled
      })
      .addCase(getDestinationGroups.pending, (state) => {
        state.destinationLoadingState = LOADING_STATE.pending
      })
      .addCase(getDestinationGroups.rejected, (state) => {
        state.destinationLoadingState = LOADING_STATE.rejected
      })
      .addCase(getDestinationGroups.fulfilled, (state, { payload }) => {
        state.destinationGroupsTableData = {
          ...state.destinationGroupsTableData,
          content: payload.content,
          isFirstPage: payload.first,
          isLastPage: payload.last,
          totalElements: payload.totalElements,
        }
        state.destinationLoadingState = LOADING_STATE.fulfilled
      })
      .addCase(getDicomDestinationGroupRq.pending, (state) => {
        state.destinationGroupLoadingState = LOADING_STATE.pending
      })
      .addCase(getDicomDestinationRq.fulfilled, (state) => {
        state.destinationLoadingState = LOADING_STATE.fulfilled
      })
      .addCase(getDicomDestinationRq.rejected, (state) => {
        state.destinationLoadingState = LOADING_STATE.rejected
      })
      .addCase(getDicomDestinationGroupRq.fulfilled, (state) => {
        state.destinationGroupLoadingState = LOADING_STATE.fulfilled
      })
      .addCase(getDicomDestinationGroupRq.rejected, (state) => {
        state.destinationGroupLoadingState = LOADING_STATE.rejected
      })
  },
})

export default destinationsSlice

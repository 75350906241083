import { FC } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'

import { StyledPercentageContainer } from './PSPercentageProgress.styled'

interface IPSPercentageProgressProps {
  percentage: number
  color: string
}

export const PSPercentageProgress: FC<IPSPercentageProgressProps> = ({
  percentage,
  color,
}) => {
  return (
    <Box position='relative' display='inline-flex'>
      <CircularProgress
        style={{ color }}
        variant='determinate'
        value={percentage}
        size={64}
      />
      <StyledPercentageContainer>
        <Typography fontWeight='bold' color={color}>{`${Math.round(
          percentage
        )}%`}</Typography>
      </StyledPercentageContainer>
    </Box>
  )
}

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'store/hooks'
import { setPushStudiesToPACSProgressToFinish } from 'store/order/slice'
import {
  FIREBASE_NOTIFICATION_TYPE_ENUM,
  TFirebaseMessage,
  TMessageHandler,
} from 'utils/FireBaseNotifications/types'
import { usePSSnackbar } from 'utils/hooks/snackbar/usePSSnackbar'

export const useHandleManualPushStudiesToPACSMessage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { openSuccessSnackbar, openErrorSnackbar } = usePSSnackbar()

  const finishProgressHandler = useCallback((accessionNumber: string) => {
    dispatch(
      setPushStudiesToPACSProgressToFinish({
        accessionNumber,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleManualPushStudiesToPACSMessage: TMessageHandler = useCallback(
    (event) => {
      try {
        const notificationBody: TFirebaseMessage = JSON.parse(
          event.data.notification.body
        )

        if (
          notificationBody.type ===
          FIREBASE_NOTIFICATION_TYPE_ENUM.SUCCESS_MANUAL_PUSH_DOCUMENTS
        ) {
          finishProgressHandler(notificationBody.metaInfo.accessionNumber)
          openSuccessSnackbar(
            t('domains.RISOrders.pushStudiesInPACSSuccessful')
          )
        }

        if (
          notificationBody.type ===
          FIREBASE_NOTIFICATION_TYPE_ENUM.FAILURE_MANUAL_PUSH_DOCUMENTS
        ) {
          finishProgressHandler(notificationBody.metaInfo.accessionNumber)
          openErrorSnackbar(
            t('common.notifications.generalErrorNotificationMessage')
          )
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('JSON Parse error: ', error)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return { handleManualPushStudiesToPACSMessage }
}

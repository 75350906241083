import { GENDER_AT_BIRTH } from 'constants/genders'
import { TSerieDto } from 'components/ui-components/PSDICOMFileUploader/types'
import { TCountryKey } from 'i18n/dictionaries/countryKeys'
import { TStateKey } from 'i18n/dictionaries/stateKeys'
import { TSystemTagColumnData } from 'store/filters/orders/types'
import { TPatientResponse } from 'store/patient/types'
import { COMMENT_TYPE, TBffCommentGridRs } from 'store/support/types'
import { TTagRs } from 'store/tags/types'
import { NonUndefined } from 'utils/types/nonUndefined'
import { Nullable } from 'utils/types/nullable'
import { LOADING_STATE, TPageable } from 'types/general'
import { components } from 'types/schemas'

// Studies
export type TStudyRq = Omit<
  components['schemas']['StudyRq'],
  | 'studyFolder'
  | 'studyDate'
  | 'studyTime'
  | 'patientName'
  | 'modality'
  | 'studyDescription'
  | 'sourceMrn'
  | 'seriesData'
> & {
  studyDate: string | null
  studyTime: string | null
  patientName: string | null
  modality: string | null
  studyDescription: string | null
  sourceMrn: string | null
  seriesData: TSerieDto[]
}

export type TStudyRs = Omit<
  components['schemas']['StudyRs'],
  'studyFolder' | 'gender' | 'taProcessDescription' | 'seriesData'
> & {
  seriesData?: TSerieDto[]
  id: number
  sopInstanceUID: string
  gender: GENDER_AT_BIRTH
  taProcessDescription: TA_PROCESS_DESCRIPTION_ENUM
}

export type TGroupedStudy = TStudyRs & {
  isChecked?: boolean
}

export interface IEnhancedViewerLinkParams {
  patientId: components['parameters']['patientId']
  accessionNumber: components['parameters']['accessionNumber']
  pacsUser?: components['parameters']['pacsUser']
}

// Studies search
export interface ICompanyItem {
  companyName: string
  companyId: number
  totalStudiesInCompany: number | null
  studies: Array<TGroupedStudy>

  isIndeterminate?: boolean
  isChecked: boolean
}

export interface IGroupedByCompanyStudiesResponse {
  totalPages: number
  totalElements: number
  first: boolean
  last: boolean
  totalStudies: number | null
  content: Array<ICompanyItem>
}

export interface IStudiesResponse {
  totalPages: number
  totalElements: number
  first: boolean
  last: boolean
  totalStudies: number | null
  content: Array<TStudyRs>
}

export type TStudiesQueryParams = Omit<
  components['schemas']['BffStudyFilterRq'],
  | 'companyIds'
  | 'isPaged'
  | 'creationDateFrom'
  | 'creationDateTo'
  | 'studyDateFrom'
  | 'studyDateTo'
> & {
  creationDateFrom?: string | null
  creationDateTo?: string | null
  studyDateFrom?: string | null
  studyDateTo?: string | null
} & Omit<TPageable, 'sort'>

export interface IStudiesSearch {
  queryData: TStudiesQueryParams
  totalElements: number
  totalPages: number
  isFirstPage: boolean
  isLastPage: boolean
  groupedContent: Array<ICompanyItem>
  nonGroupedContent: Array<TStudyRs>
  totalStudies: number | null
  isTableShown?: boolean
  isLoading: boolean
  isLoadingCompanyStudies?: boolean
}

export type TSearchStudiesTabPrefix = keyof IState['studiesSearch']

// Order Preview
export type TOrderResponse = Omit<
  components['schemas']['BffOrderRs'],
  | 'examDetails'
  | 'tags'
  | 'primaryStudies'
  | 'comparisonInformation'
  | 'systemTags'
  | 'capturedEditInfo'
  | 'status'
  | 'creationType'
  | 'patient'
> & {
  creationType?: ORDER_CREATION_TYPE
  status: ORDER_STATUS_ENUM
  examDetails: TBffExamDetails
  tags?: Array<TTagRs>
  systemTags?: Array<TSystemTagColumnData>
  primaryStudies: Omit<
    components['schemas']['BffOrderRs']['primaryStudies'],
    'dicomStudies'
  > & {
    dicomStudies: TStudyRs[]
  }
  comparisonInformation: Omit<
    components['schemas']['BffOrderRs']['comparisonInformation'],
    'studies'
  > & {
    studies: TStudyRs[]
  }
  capturedEditInfo: TBffCapturedEditInfo
  patient: TPatientResponse
}

// Order Form
export type TBffOrderPhysician = components['schemas']['BffOrderPhysician']

export type TNDCCodeWithLat = components['schemas']['NdcCode']

export type TOrderBatchItem = {
  accessionNumber: string
  orderInfo: TOrderRequest
}

// Order Form - Submit order
export type TOrderRequest = Omit<
  components['schemas']['OrderInfoRq'],
  | 'examDetails'
  | 'primaryStudies'
  | 'comparisonInformation'
  | 'sourceAccessionNumber'
> & {
  sourceAccessionNumber: string | null
  comparisonInformation: {
    studyToAdd: Array<TStudyRq>
    studyToRemove: number[]
  }
  primaryStudies: {
    studyToRemove: number[]
    studyToAdd: Array<TStudyRq>
  }
  examDetails: {
    examInformation: Omit<
      components['schemas']['ExamInformationRq'],
      | 'diagnosis'
      | 'pointOfService'
      | 'faxNumber'
      | 'followUpDate'
      | 'followUpTime'
    > & {
      diagnosis?: string
      faxNumber?: string | null
      followUpDate?: string | null
      followUpTime?: string | null
    }
    examPaperwork: components['schemas']['PaperworkUpdateRq']
  }
}

export type TOrderCreateResponse = components['schemas']['OrderCreationRs']

// Order Form - Draft order
export type TOrderDraftRequest = Omit<
  components['schemas']['OrderDraftRq'],
  | 'examDetails'
  | 'primaryStudies'
  | 'comparisonInformation'
  | 'sourceAccessionNumber'
> & {
  sourceAccessionNumber: string | null
  comparisonInformation: {
    studyToAdd: Array<TStudyRq>
    studyToRemove: number[]
  }
  primaryStudies: {
    studyToRemove: number[]
    studyToAdd: Array<TStudyRq>
  }
  examDetails: {
    examInformation: Omit<
      components['schemas']['ExamInformationRq'],
      | 'diagnosis'
      | 'pointOfService'
      | 'faxNumber'
      | 'followUpDate'
      | 'followUpTime'
    > & {
      diagnosis?: string
      faxNumber?: string | null
      followUpDate?: string | null
      followUpTime?: string | null
    }
    examPaperwork: components['schemas']['PaperworkUpdateRq']
  }
}

// Exam Paperwork
export type TFileInfo = Required<components['schemas']['BffFileInfo']>

export type TBffExamPaperwork = Record<
  keyof components['schemas']['BffExamPaperwork'],
  TFileInfo[]
>

export type TFaxNumbersConfig = components['schemas']['FaxNumbersConfig']

export type TBffExamDetails = {
  examPaperwork: TBffExamPaperwork
  examInformation: Omit<
    components['schemas']['BffExamInformation'],
    'country' | 'state' | 'modifier3D'
  > & {
    country: TCountryKey
    state: TStateKey
    modifier3D?: IMAGE_POST_PROCESSING
  }
}

// Capture
export type TCaptureEditMetadata = Omit<
  Required<components['schemas']['CaptureEditMetadataRs']>,
  'capturedAt'
> & {
  capturedAt: string
}

export type TBffCapturedEditInfo = Omit<
  components['schemas']['BffCapturedEditInfoRs'],
  'orderEditCaptures' | 'reportEditCaptures'
> & {
  orderEditCaptures: Array<TCaptureEditMetadata>
  reportEditCaptures: Array<TCaptureEditMetadata>
}

// Change Order Status
export type TManualOnHoldStatusRequestData = Omit<
  components['schemas']['OrderStatusRq'],
  'manualHoldDetails'
> & { manualHoldDetails?: string | null }

export type TCancelAddendumRq = components['schemas']['CancelAddendumRq']

export type TTriggerOrderStatusRq = components['schemas']['OrderStatusRq']

// Order Sharing
export type TIndividualSharingTriggerRq =
  components['schemas']['IndividualSharingTriggerRq']

type TTaSharingTriggerRequest = components['schemas']['TaSharingTriggerRq']

export type TTaSharingTriggerRq = Omit<
  TTaSharingTriggerRequest,
  | 'patientName'
  | 'gender'
  | 'birthDate'
  | 'mrn'
  | 'accessionNumberTagValue'
  | 'studyDescription'
> & {
  patientName: NonUndefined<Nullable<TTaSharingTriggerRequest['patientName']>>
  gender: NonUndefined<Nullable<TTaSharingTriggerRequest['gender']>>
  birthDate: NonUndefined<Nullable<TTaSharingTriggerRequest['birthDate']>>
  mrn: NonUndefined<Nullable<TTaSharingTriggerRequest['mrn']>>
  accessionNumberTagValue: NonUndefined<
    Nullable<TTaSharingTriggerRequest['accessionNumberTagValue']>
  >
  studyDescription: NonUndefined<
    Nullable<TTaSharingTriggerRequest['studyDescription']>
  >
}

// Patient Inconsistent Data
export interface IInconsistentStudyData {
  sourceMrn?: boolean
  firstname: boolean
  lastname: boolean
  dateOfBirth: boolean
  genderAtBirth: boolean
}

export interface IInconsistentData {
  dicom: IInconsistentStudyData
  hlSeven: IInconsistentStudyData
}

// Support Requests
export type TBffOrderSupportRequestGridRs = Required<
  components['schemas']['BffOrderSupportRequestGridRs']
>

export type TBffOrderSupportRequestPageRs = Omit<
  components['schemas']['BffOrderSupportRequestPageRs'],
  'content'
> & { content: TBffOrderSupportRequestGridRs[] }

export type TOrderSupportRequestPageRs = Omit<
  TBffOrderSupportRequestPageRs,
  'first' | 'last'
> & { isFirstPage: boolean; isLastPage: boolean }

export type TSupportRequestStatus =
  components['schemas']['SupportRequestStatus']

export type TBffCommentRs = components['schemas']['BffCommentRs']

// Order Files Action Block
interface IProcessProgress {
  [accessionNumber: string]: number
}

// Order attachments
export type TOrderAttachmentRq = Omit<
  components['schemas']['OrderAttachmentRq'],
  'primaryStudies' | 'comparisonInformation'
> & {
  primaryStudies: {
    studyToAdd: TStudyRq[]
    studyToRemove: number[]
  }
  comparisonInformation: {
    studyToAdd: TStudyRq[]
    studyToRemove: number[]
  }
}

export type TOrdersCopyCandidatesPageRs =
  components['schemas']['OrdersCopyCandidatesPageRs']

export type TOrdersCopyCandidatesFilterRq = Omit<
  components['schemas']['OrdersCopyCandidatesFilterRq'],
  'siteId' | 'sourceAccessionNumber'
>

// Redux State
export interface IState {
  orderInfo: {
    loadingState: LOADING_STATE
    enhanceLinkLoadingState: LOADING_STATE
    data: TOrderResponse | null
  }
  studiesSearch: {
    primaryStudiesTab: IStudiesSearch
    comparisonInfoTab: IStudiesSearch
  }
  inconsistentPatient: {
    data: IInconsistentData
    source: INCONSISTENT_INFO_DISPATCH_SOURCE | null
    isInconsistencyAcknowledgmentChecked: boolean
    isModalShown: boolean
  }
  supportRequests: {
    count: number
    loadingState: LOADING_STATE
    list: TOrderSupportRequestPageRs
    commentsData: {
      [supportRequestId: number]: {
        [COMMENT_TYPE.PUBLIC]?: {
          loadingState: LOADING_STATE
          list: TBffCommentGridRs[]
        }
        [COMMENT_TYPE.INTERNAL]?: {
          loadingState: LOADING_STATE
          list: TBffCommentGridRs[]
        }
      }
    }
    page: number
  }
  reportDeliveryResults: {
    content: TReportDeliveryDataRs[]
    loadingState: LOADING_STATE
  }
  pushStudiesToPACSProgress: IProcessProgress
  resendHL7ToDownstreamProgress: IProcessProgress
  copyData: {
    loadingState: LOADING_STATE
    list?: TOrdersCopyCandidatesPageRs
    pageable: TPageable
    filter?: TOrdersCopyCandidatesFilterRq
    selectedOrders: Record<string, boolean>
    selectedOrdersEditInfo: Record<string, TCaptureRs[]>
    selectedOrdersInfo: Record<string, TOrderResponse>
  }
  manualReportDelivery: {
    loadingState: LOADING_STATE
    data?: TReportReDeliveryData
  }
}

// List of ProScan Statuses (https://wiki.andersenlab.com/display/PSI/Status+model)
// Link to the status list in the schema.d.ts file components['schemas']['OrderStatus']
export enum ORDER_STATUS_ENUM {
  SCHEDULED = 'SCHEDULED',
  PENDING_SUBMISSION = 'PENDING_SUBMISSION',
  ON_HOLD = 'ON_HOLD',
  WAITING_FOR_ASSIGNMENT = 'WAITING_FOR_ASSIGNMENT',
  ASSIGNED_WAITING = 'ASSIGNED_WAITING',
  PENDING_REVIEW = 'PENDING_REVIEW',
  PENDING_RE_REVIEW = 'PENDING_RE_REVIEW',
  PENDING_ADDENDUM = 'PENDING_ADDENDUM',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PENDING_TRANSCRIPTION = 'PENDING_TRANSCRIPTION',
  REPORT_QA = 'REPORT_QA',
  FINAL_REVIEW = 'FINAL_REVIEW',
  COMPLETED = 'COMPLETED',
  NO_REPORT = 'NO_REPORT',
  CANCELED = 'CANCELED',
  REPORT_DELIVERED = 'REPORT_DELIVERED',
  IN_REVIEW = 'IN_REVIEW',
  FINAL_REPORT = 'FINAL_REPORT',
  FINAL_REPORT_ADDENDUM = 'FINAL_REPORT_ADDENDUM',
  MIGRATED = 'MIGRATED',
  IMPORTED_REVIEW = 'IMPORTED_REVIEW',
  IMPORTED_FINAL = 'IMPORTED_FINAL',
  IMPORTED_CANCELED = 'IMPORTED_CANCELED',
}

export enum ARCHIVE_DOWNLOAD_TYPE_ENUM {
  ARCHIVE = 'ARCHIVE',
  ISO = 'ISO',
}

export enum IMAGE_POST_PROCESSING {
  NOT_PERFORMED = 'NOT_PERFORMED',
  MODALITY = 'MODALITY',
  INDEPENDENT = 'INDEPENDENT',
}

export enum SEARCH_STUDIES_TAB_PREFIX {
  PrimaryStudiesTab = 'primaryStudiesTab',
  ComparisonInfoTab = 'comparisonInfoTab',
}

export enum INCONSISTENT_INFO_DISPATCH_SOURCE {
  SUBMIT = 'SUBMIT',
  PATIENT_CARD = 'PATIENT_CARD',
}

export enum ORDER_CREATION_TYPE {
  HL7 = 'HL7',
  MANUAL = 'MANUAL',
  // the order which was created by transfer agent
  DICOM = 'DICOM',
}

export enum TA_PROCESS_DESCRIPTION_ENUM {
  CREATE = 'CREATE',
  ATTACH_USING_SITE_DATA = 'ATTACH_USING_SITE_DATA',
  ATTACH_USING_PROSCAN_DATA = 'ATTACH_USING_PROSCAN_DATA',
  PASS_THROUGH = 'PASS_THROUGH',
  AUTO_SHARE = 'AUTO_SHARE',
}

export enum URGENCY_ID_ENUM {
  'MedicalClearance' = 1,
  'Stroke' = 2,
  'ERVisit' = 3,
  'WETPrelim' = 4,
  'WETCall' = 5,
  'STAT' = 6,
  'Normal' = 7,
}

export type TReportDeliveryDataRs =
  components['schemas']['ReportDeliveryDataRs']

export type TAddressOfDelivery = components['schemas']['AddressOfDelivery']

export type TReportReDeliveryData = Required<
  components['schemas']['ReportReDeliveryData']
>
export type TRedeliveryPayload = components['schemas']['RedeliveryPayload']

export enum DELIVERY_STATUS {
  FAILED_ATTEMPT = 'FAILED_ATTEMPT',
  PENDING_DELIVERY = 'PENDING_DELIVERY',
  SUCCESSFULLY_SENT = 'SUCCESSFULLY_SENT',
  ACKNOWLEDGED = 'ACKNOWLEDGED',
}

export enum CAPTURE_TYPE_ENUM {
  ORDER_EDIT = 'ORDER_EDIT',
  BILLING_EDIT = 'BILLING_EDIT',
  REPORT_EDIT = 'REPORT_EDIT',
  REPORT_VIEW = 'REPORT_VIEW',
}

export type TUpdateOrderTagsRq =
  components['schemas']['UpdateOrderTagRelationsRq']

export type TUpdateOrderTagsResponse = Omit<
  components['schemas']['OrderTagRelationsUpdatedRs'],
  'orderTags'
> & {
  orderTags: TTagRs[]
}

export type TOrderSystemDeliveryTagRq = {
  tagsToApply: number[]
  tagsToRemove: number[]
}

export type TOrderPhysician = components['schemas']['OrderPhysician']

export type TOrderCopyCandidateRs =
  components['schemas']['OrderCopyCandidateRs']

export type TCaptureRs = components['schemas']['CaptureRs']

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip, Typography } from '@mui/material'
import { PatientCell } from 'components/cells/PatientCell/PatientCell'
import { TOrder } from 'store/ordersList/types'

import { getPatientNameData } from './helpers/getPatientNameData'

interface IRisOrderPatientCellProps {
  data: TOrder
}

export const RisOrderPatientCell: FC<IRisOrderPatientCellProps> = ({
  data,
}) => {
  const { t } = useTranslation()

  const { isDicomFullName, ...orderData } = getPatientNameData(data)

  return (
    <Tooltip
      title={t('domains.RISOrders.patientExtractedFromDICOM')}
      disableHoverListener={!isDicomFullName}
    >
      <Typography
        variant='inherit'
        {...(isDicomFullName && { color: 'text.disabled' })}
      >
        <PatientCell data={orderData} />
      </Typography>
    </Tooltip>
  )
}

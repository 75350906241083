import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState, SLICE_NAME } from 'store/support/constants'
import {
  getComments,
  getSupportRequestById,
  getSupportRequestList,
  updateSupportRequestStatusById,
  updateSupportRequestTargetGroupById,
  updateSupportRequestTypeById,
} from 'store/support/controllers'
import { LOADING_STATE, TQueryData } from 'types/general'

const supportSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    toggleOpenTableFilter: (state) => {
      state.table.isFilterPanelOpen = !state.table.isFilterPanelOpen
    },
    resetCommentsData: (state) => {
      state.comments = initialState.comments
    },
    resetEntityData: (state) => {
      state.entity = initialState.entity
    },
    changeCommentsQueryData: (
      state,
      { payload }: PayloadAction<Partial<TQueryData>>
    ) => {
      state.comments.queryData = {
        ...state.comments.queryData,
        ...payload,
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getSupportRequestList.pending, (state) => {
        state.table.loadingState = LOADING_STATE.pending
      })
      .addCase(getSupportRequestList.rejected, (state) => {
        state.table.loadingState = LOADING_STATE.rejected
      })
      .addCase(getSupportRequestList.fulfilled, (state, { payload }) => {
        state.table = {
          ...state.table,
          content: payload.content,
          isFirstPage: payload.first,
          isLastPage: payload.last,
          totalElements: payload.totalElements,
          loadingState: LOADING_STATE.fulfilled,
        }
      })
      .addCase(getComments.pending, (state) => {
        state.comments.loadingState = LOADING_STATE.pending
        state.comments.error = null
      })
      .addCase(getComments.rejected, (state, { payload }) => {
        state.comments.loadingState = LOADING_STATE.rejected
        state.comments.error = payload
      })
      .addCase(getComments.fulfilled, (state, { payload }) => {
        state.comments = {
          ...state.comments,
          content: [...state.comments.content, ...payload.content],
          isFirstPage: Boolean(payload.first),
          isLastPage: Boolean(payload.last),
          totalElements: payload.totalElements ?? 0,
          loadingState: LOADING_STATE.fulfilled,
          error: null,
        }
      })
      .addCase(getSupportRequestById.pending, (state) => {
        state.entity.loadingState = LOADING_STATE.pending
      })
      .addCase(getSupportRequestById.rejected, (state) => {
        state.entity.loadingState = LOADING_STATE.rejected
      })
      .addCase(getSupportRequestById.fulfilled, (state, { payload }) => {
        state.entity.loadingState = LOADING_STATE.fulfilled
        state.entity.data = payload
      })
      .addCase(updateSupportRequestTargetGroupById.pending, ({ entity }) => {
        entity.isTargetGroupUpdating = true
      })
      .addCase(updateSupportRequestTargetGroupById.rejected, ({ entity }) => {
        entity.isTargetGroupUpdating = false
      })
      .addCase(
        updateSupportRequestTargetGroupById.fulfilled,
        ({ entity }, { meta: { arg } }) => {
          entity.data = {
            ...entity.data,
            targetGroup: arg.targetGroup,
          }
          entity.isTargetGroupUpdating = false
        }
      )
      .addCase(updateSupportRequestTypeById.pending, ({ entity }) => {
        entity.isTypeUpdating = true
      })
      .addCase(updateSupportRequestTypeById.rejected, ({ entity }) => {
        entity.isTypeUpdating = false
      })
      .addCase(
        updateSupportRequestTypeById.fulfilled,
        ({ entity }, { meta: { arg } }) => {
          entity.data = {
            ...entity.data,
            type: arg.type,
          }
          entity.isTypeUpdating = false
        }
      )
      .addCase(updateSupportRequestStatusById.pending, ({ entity }) => {
        entity.isStatusUpdating = true
      })
      .addCase(updateSupportRequestStatusById.rejected, ({ entity }) => {
        entity.isStatusUpdating = false
      })
      .addCase(
        updateSupportRequestStatusById.fulfilled,
        ({ entity }, { meta: { arg } }) => {
          entity.data = {
            ...entity.data,
            status: arg.status,
          }
          entity.isStatusUpdating = false
        }
      ),
})

export const {
  toggleOpenTableFilter,
  resetCommentsData,
  resetEntityData,
  changeCommentsQueryData,
} = supportSlice.actions
export default supportSlice

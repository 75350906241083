import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from 'services/axiosBaseQuery'
import { TBillingStatus, TOrderStatusRs } from 'services/dictionary/types'

export const ordersApi = createApi({
  reducerPath: 'ordersApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/orders' }),
  endpoints: (builder) => ({
    getOrderStatus: builder.query<TOrderStatusRs, string>({
      query: (accessionNumber) => ({ url: `${accessionNumber}/status` }),
    }),
    getBillingStatus: builder.query<TBillingStatus, string>({
      query: (accessionNumber) => ({
        url: `${accessionNumber}/billing/status`,
      }),
    }),
  }),
})

export const { useGetOrderStatusQuery, useGetBillingStatusQuery } = ordersApi

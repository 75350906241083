import { createAsyncThunk } from '@reduxjs/toolkit'
import { TFlagTableFilter } from 'store/filters/flags/types'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'
import { prepareArrayForSending } from 'utils/helpers/arrays/prepareArrayForSending'
import { TPageable } from 'types/general'

import {
  FLAG_ARCHIVE_STATUS,
  TFlag,
  TFlagId,
  TFlagInfo,
  TFlagsPageResponse,
} from './types'

export const postFlag = createAsyncThunk<
  TFlagId,
  TFlag,
  {
    rejectValue: TServiceError
  }
>('flags/createFlag', async (data, { rejectWithValue }) => {
  const result = await request<TFlagId>({
    url: '/orders/flags',
    data,
    method: 'POST',
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const fetchFlags = createAsyncThunk<
  TFlagsPageResponse,
  { pageable: TPageable; filter?: TFlagTableFilter },
  {
    rejectValue: TServiceError
  }
>('flags/fetchFlags', async ({ pageable, filter }, { rejectWithValue }) => {
  const params = {
    ...pageable,
    search: filter?.search ?? undefined,
    physicianIds: prepareArrayForSending(
      filter?.physicianIds?.map(({ providerId }) => providerId) ?? []
    ),
    siteIds: prepareArrayForSending(
      filter?.siteIds?.map(({ siteId }) => siteId) ?? []
    ),
    procedureIds: prepareArrayForSending(
      filter?.procedureIds?.map(({ id }) => id) ?? []
    ),
    ...(filter?.transitionStatuses && {
      transitionStatuses: prepareArrayForSending(
        filter.transitionStatuses.map(({ value }) => value)
      ),
    }),
    types: prepareArrayForSending(filter?.types ?? []),
    billingTypeIds: prepareArrayForSending(filter?.billingTypeIds ?? []),
    statuses: prepareArrayForSending(filter?.statuses ?? []),
  }
  const result = await request<TFlagsPageResponse>({
    url: '/bff/orders/flags',
    params,
    method: 'GET',
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const fetchFlagArchive = createAsyncThunk<
  unknown,
  { status: FLAG_ARCHIVE_STATUS; id: string | number },
  {
    rejectValue: TServiceError
  }
>('flags/archiveFlag', async (payload, { rejectWithValue }) => {
  const result = await request<unknown>({
    url: `/orders/flags/${payload.id}`,
    data: { status: payload.status },
    method: 'PATCH',
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const fetchFlagInfo = createAsyncThunk<
  TFlagInfo,
  TFlagId,
  { rejectValue: TServiceError }
>('flags/fetchFlagInfo', async (payload, { rejectWithValue }) => {
  const result = await request<TFlagInfo>({
    url: `/bff/orders/flags/${payload.id}`,
    method: 'GET',
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const editFlag = createAsyncThunk<
  unknown,
  { fields: TFlag; id: number },
  {
    rejectValue: TServiceError
  }
>('flags/editFlag', async (payload, { rejectWithValue }) => {
  const result = await request<TFlagId>({
    url: `/orders/flags/${payload.id}`,
    data: payload.fields,
    method: 'PUT',
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

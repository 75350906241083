import { DialogActions, styled } from '@mui/material'
import { getIsDarkMode } from 'utils/helpers/general'

export const PSDialogActions = styled(DialogActions)(
  ({ theme: { spacing, palette } }) => ({
    margin: spacing(3),
    padding: spacing(2),
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderRadius: spacing(0.5),
    background: getIsDarkMode(palette.mode)
      ? // @ts-expect-error backgroundChannel property exists
        `rgba(${palette.common.backgroundChannel} / 0.08)`
      : // @ts-expect-error mainChannel property exists
        `rgba(${palette.info.mainChannel} / 0.04)`,
  })
)

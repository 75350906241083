import { FC, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Snackbar, Typography } from '@mui/material'
import { topRightSnackbarAnchor } from 'constants/uiConstants'
import { SnackbarKey, useSnackbar } from 'notistack'

import { PSButton } from '../PSButton'
import { StyledAlert } from './PSIdleSnackbar.styled'

interface IPSIdleSnackbarProps {
  id: SnackbarKey
  messageText?: string
  buttonText?: string
  isOpen?: boolean
  onAgreeClick: () => void
}

export const PSIdleSnackbar: FC<IPSIdleSnackbarProps> = forwardRef<
  HTMLDivElement,
  IPSIdleSnackbarProps
>(({ id, isOpen = true, messageText, buttonText, onAgreeClick }, ref) => {
  const { t } = useTranslation()
  const { closeSnackbar } = useSnackbar()

  const handleAgreeClick = () => {
    onAgreeClick()
    closeSnackbar(id)
  }

  return (
    <Snackbar open={isOpen} anchorOrigin={topRightSnackbarAnchor} ref={ref}>
      <StyledAlert
        severity='warning'
        variant='filled'
        action={
          <PSButton
            color='inherit'
            variant='text'
            size='small'
            onClick={handleAgreeClick}
          >
            {buttonText ?? t('components.buttons.yes')}
          </PSButton>
        }
      >
        <Typography variant='body2'>
          {messageText ?? t('components.titles.sessionExpirationTitle')}
        </Typography>
      </StyledAlert>
    </Snackbar>
  )
})

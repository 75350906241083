import { enCountries } from './enCountries'
import { enFlagTypes } from './enFlagTypes'
import { enGenders } from './enGenders'
import { enLateralityParts } from './enLaterality'
import { enOrderBillingStatuses } from './enOrderBillingStatuses'
import { enProScanStatuses } from './enProScanStatuses'
import { enRelationship } from './enRelationship'
import { enReportTypes } from './enReportType'
import { enStates } from './enStates'
import { enSupportRequestStatuses } from './enSupportRequestStatuses'
import { enUrgencies } from './enUrgencies'

export const dictionaries = {
  ...enCountries,
  states: enStates,
  genders: enGenders,
  relationships: enRelationship,
  lateralityPart: enLateralityParts,
  flagTypes: enFlagTypes,
  proScanStatuses: enProScanStatuses,
  orderBillingStatuses: enOrderBillingStatuses,
  supportRequestStatuses: enSupportRequestStatuses,
  urgencies: enUrgencies,
  reportTypes: enReportTypes,
}

import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATE } from 'types/general'

import * as controllers from './controllers'
import { IState } from './types'

const initialState: IState = {
  list: {
    content: [],
    totalElements: 0,
    totalPages: 0,
    first: true,
    last: false,
  },
  loadingState: LOADING_STATE.idle,
  error: null,
  flag: undefined,
}

export const name = 'flags'

const flagsSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearFlagInfo: (state) => {
      state.flag = initialState.flag
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(controllers.fetchFlagInfo.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.fetchFlagInfo.fulfilled, (state, action) => {
        state.flag = action.payload
        state.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(controllers.fetchFlagInfo.rejected, (state, action) => {
        state.loadingState = LOADING_STATE.rejected
        state.error = action.payload
      })
    builder
      .addCase(controllers.fetchFlagArchive.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.fetchFlagArchive.fulfilled, (state) => {
        state.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(controllers.fetchFlagArchive.rejected, (state, action) => {
        state.loadingState = LOADING_STATE.rejected
        state.error = action.payload
      })
      .addCase(controllers.fetchFlags.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.fetchFlags.fulfilled, (state, action) => {
        state.list = action.payload
        state.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(controllers.fetchFlags.rejected, (state, action) => {
        state.loadingState = LOADING_STATE.rejected
        state.error = action.payload
      })
  },
})

export const { clearFlagInfo } = flagsSlice.actions

export default flagsSlice

import { TMultiAssignmentRadiologist } from '../types'

export const getRadiologistForTable = (
  radiologist: TMultiAssignmentRadiologist
) => ({
  providerId: radiologist?.providerDisplayNameRs?.providerId ?? '',
  radiologistName: {
    providerId: radiologist?.providerDisplayNameRs?.providerId ?? '',
    firstName: radiologist?.providerDisplayNameRs?.firstName ?? '',
    lastName: radiologist?.providerDisplayNameRs?.lastName ?? '',
    degrees: radiologist?.providerDisplayNameRs?.degrees ?? [],
  },
})

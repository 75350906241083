import { useEffect } from 'react'

import { TMessageHandler } from '../types'

export const useNotificationListener = (handleMessage: TMessageHandler) => {
  useEffect(() => {
    navigator.serviceWorker.addEventListener('message', handleMessage)

    return () => {
      navigator.serviceWorker.removeEventListener('message', handleMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATE } from 'types/general'

import { initialState } from './constants'
import * as controllers from './controllers'

export const name = 'company'

const companySlice = createSlice({
  name,
  initialState,
  reducers: {
    clearCompanyInfo: (state) => {
      state.company = initialState.company
    },
    clearReportConfigurationInfo: (state) => {
      state.reportConfiguration = initialState.reportConfiguration
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(controllers.fetchCompanies.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.fetchCompanies.fulfilled, (state, action) => {
        state.list = action.payload
        state.loadingState = LOADING_STATE.fulfilled
        state.error = undefined
      })
      .addCase(controllers.fetchCompanies.rejected, (state, action) => {
        state.list = initialState.list
        state.loadingState = LOADING_STATE.rejected
        state.error = action.payload
      })
      .addCase(
        controllers.fetchCompaniesAvailableFilters.fulfilled,
        (state, { payload }) => {
          state.availableCompaniesFilters = payload
        }
      )
      .addCase(controllers.fetchCompanyInfo.pending, (state) => {
        state.company.loadingState = LOADING_STATE.pending
        state.company.data = null
        state.error = undefined
      })
      .addCase(
        controllers.fetchSiteReportConfigurationInfo.pending,
        (state) => {
          state.loadingState = LOADING_STATE.pending
          state.error = undefined
        }
      )
      .addCase(
        controllers.fetchSiteReportConfigurationInfo.fulfilled,
        (state, action) => {
          state.reportConfiguration = action.payload
          state.error = undefined
          state.loadingState = LOADING_STATE.fulfilled
        }
      )
      .addCase(
        controllers.fetchSiteReportConfigurationInfo.rejected,
        (state, action) => {
          state.loadingState = LOADING_STATE.rejected
          state.error = action.payload
        }
      )
      .addCase(controllers.fetchCompanyInfo.fulfilled, (state, action) => {
        state.company.data = action.payload
        state.company.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(controllers.fetchCompanyInfo.rejected, (state) => {
        state.company.data = initialState.company.data
        state.company.loadingState = LOADING_STATE.rejected
      })
      .addCase(controllers.addCompany.fulfilled, (state) => {
        state.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(controllers.addCompany.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.addCompany.rejected, (state, action) => {
        state.loadingState = LOADING_STATE.rejected
        state.error = action.payload
      })
      .addCase(controllers.updateSiteReportConfiguration.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
        state.error = undefined
      })
      .addCase(controllers.updateSiteReportConfiguration.fulfilled, (state) => {
        state.loadingState = LOADING_STATE.fulfilled
        state.error = undefined
      })
      .addCase(
        controllers.updateSiteReportConfiguration.rejected,
        (state, action) => {
          state.loadingState = LOADING_STATE.rejected
          state.error = action.payload
        }
      )
      .addCase(controllers.editCompany.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
        state.error = undefined
      })
      .addCase(controllers.editCompany.fulfilled, (state) => {
        state.loadingState = LOADING_STATE.fulfilled
        state.error = undefined
      })
      .addCase(controllers.editCompany.rejected, (state, action) => {
        state.loadingState = LOADING_STATE.rejected
        state.error = action.payload
      })
  },
})

export const { clearCompanyInfo, clearReportConfigurationInfo } =
  companySlice.actions

export default companySlice

import { ForwardedRef, forwardRef } from 'react'
import { ChipProps } from '@mui/material'

import { StyledChip } from './PSChip.styled'

interface IPSChipProps extends ChipProps {
  textColor?: string
  bgColor?: string
}

export const PSChip = forwardRef(
  (
    { textColor, bgColor, ...props }: IPSChipProps,
    ref: ForwardedRef<HTMLDivElement> | null
  ) => (
    <StyledChip {...props} textColor={textColor} bgColor={bgColor} ref={ref}>
      {props.children}
    </StyledChip>
  )
)

import { createAsyncThunk } from '@reduxjs/toolkit'
import { TOrdersTableParams } from 'store/filters/orders/types'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'

import { sliceName } from './constants'
import { prepareTableQueryParamsForSend } from './helpers/prepareTableQueryParamsForSend'
import { ORDERS_TABLE_TAB, TOrdersResponse } from './types'

export const getOrders = createAsyncThunk<
  {
    data: TOrdersResponse
    tableName: ORDERS_TABLE_TAB
  },
  {
    tableName: ORDERS_TABLE_TAB
    params: TOrdersTableParams
    abortSignal?: AbortSignal
  },
  { rejectValue: TServiceError }
>(
  `${sliceName}/getOrdersByStatus`,
  async (
    {
      tableName,
      params: { search, page, size, isPaged, ...searchParams },
      abortSignal,
    },
    { rejectWithValue }
  ) => {
    const result = await request<TOrdersResponse>({
      url: '/bff/orders/bystatuses',
      method: 'GET',
      params: {
        ...prepareTableQueryParamsForSend(searchParams),
        page,
        size,
        isPaged,
        tabName: tableName,
        ...(search && { search: search.replaceAll(',', '') }),
      },
      signal: abortSignal,
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return {
      data: result.data,
      tableName,
    }
  }
)

export const removeAssignment = createAsyncThunk<
  unknown,
  { accessionNumbers: string[] },
  {
    rejectValue: TServiceError
  }
>(
  `${sliceName}/removeAssignment`,
  async ({ accessionNumbers }, { rejectWithValue }) => {
    const result = await request({
      url: '/orders/assignments',
      method: 'DELETE',
      params: {
        accessionNumbers: accessionNumbers.join(','),
      },
    })

    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result
  }
)

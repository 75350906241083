import { FC } from 'react'
import FlagIcon from '@mui/icons-material/Flag'
import { SvgIconProps } from '@mui/material'
import {
  DeliveredToRefMDIcon,
  DeliveredToSiteIcon,
  FailedDicomTransferIcon,
  FailedFaxIcon,
  MultipleStudiesIcon,
  PendingDicomTransferIcon,
  PendingFaxDeliveryIcon,
  PreliminaryAvailableIcon,
  ReportVersionAvailableIcon,
  SuccessfulDicomTransferIcon,
} from 'assets/icons'

export enum SYSTEM_TAGS_TYPES {
  REPORT_VER_AVAILABLE = 'REPORT_VER_AVAILABLE',
  PRELIMINARY_AVAILABLE = 'PRELIMINARY_AVAILABLE',
  PENDING_DICOM_TRANSFER = 'PENDING_DICOM_TRANSFER',
  SUCCESSFUL_DICOM_TRANSFER = 'SUCCESSFUL_DICOM_TRANSFER',
  FAILED_DICOM_TRANSFER = 'FAILED_DICOM_TRANSFER',
  FAILED_FAX = 'FAILED_FAX',
  IMPORTED = 'IMPORTED',
  PENDING_FAX_DELIVERY = 'PENDING_FAX_DELIVERY',
  MULTIPLE_STUDY = 'MULTIPLE_STUDY',
  DELIVERED_TO_SITE = 'DELIVERED_TO_SITE',
  DELIVERED_TO_REF_MD = 'DELIVERED_TO_REF_MD',
}

export const mapSystemTagToIcon: Record<SYSTEM_TAGS_TYPES, FC<SvgIconProps>> = {
  [SYSTEM_TAGS_TYPES.REPORT_VER_AVAILABLE]: ReportVersionAvailableIcon,
  [SYSTEM_TAGS_TYPES.PRELIMINARY_AVAILABLE]: PreliminaryAvailableIcon,
  [SYSTEM_TAGS_TYPES.PENDING_DICOM_TRANSFER]: PendingDicomTransferIcon,
  [SYSTEM_TAGS_TYPES.SUCCESSFUL_DICOM_TRANSFER]: SuccessfulDicomTransferIcon,
  [SYSTEM_TAGS_TYPES.FAILED_DICOM_TRANSFER]: FailedDicomTransferIcon,
  [SYSTEM_TAGS_TYPES.FAILED_FAX]: FailedFaxIcon,
  [SYSTEM_TAGS_TYPES.IMPORTED]: FlagIcon,
  [SYSTEM_TAGS_TYPES.PENDING_FAX_DELIVERY]: PendingFaxDeliveryIcon,
  [SYSTEM_TAGS_TYPES.MULTIPLE_STUDY]: MultipleStudiesIcon,
  [SYSTEM_TAGS_TYPES.DELIVERED_TO_SITE]: DeliveredToSiteIcon,
  [SYSTEM_TAGS_TYPES.DELIVERED_TO_REF_MD]: DeliveredToRefMDIcon,
}

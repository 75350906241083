import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { AlertColor } from '@mui/material'
import { PayloadAction } from '@reduxjs/toolkit'
import { ERROR_CODE } from 'constants/errorCode'
import {
  MILLISECONDS_IN_MINUTE,
  MILLISECONDS_IN_SECOND,
} from 'constants/timings'
import {
  bottomLeftSnackbarAnchor,
  topRightSnackbarAnchor,
} from 'constants/uiConstants'
import { OptionsObject, useSnackbar } from 'notistack'
import { PSIdleSnackbar } from 'components/ui-components/PSIdleSnackbar'
import { PSProgressSnackbar } from 'components/ui-components/PSProgressSnackbar'
import { PSSnackbar } from 'components/ui-components/PSSnackbar'
import { RootState } from 'store/store'

import { useBEErrorTranslation } from '../useBEErrorTranslation'
import { DEFAULT_SNACKBAR_CONFIGS, DEFAULT_SNACKBAR_DELAY } from './constants'

type TSnackbarConfigs = Omit<OptionsObject, 'content' | 'anchorOrigin'>

export const usePSSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { translateError } = useBEErrorTranslation()
  const { closeSnackbar } = useSnackbar()

  const openSnackbar = useCallback(
    (
      status: AlertColor,
      message: string,
      header?: string,
      configs?: TSnackbarConfigs
    ) => {
      enqueueSnackbar(message, {
        content: (key) => (
          <PSSnackbar
            id={key}
            message={message}
            status={status}
            header={header}
          />
        ),
        anchorOrigin: topRightSnackbarAnchor,
        ...DEFAULT_SNACKBAR_CONFIGS,
        ...configs,
      })
    },
    [enqueueSnackbar]
  )

  const openIdleSnackbar = useCallback(
    (
      onAgreeClick: () => void,
      snackbarConfig?: {
        messageText?: string
        buttonText?: string
        delay?: number
      }
    ) => {
      const messageText =
        snackbarConfig?.messageText ??
        t('components.titles.sessionExpirationTitle')

      return enqueueSnackbar(messageText, {
        content: (key) => (
          <PSIdleSnackbar
            id={key}
            messageText={snackbarConfig?.messageText}
            buttonText={snackbarConfig?.buttonText}
            onAgreeClick={onAgreeClick}
          />
        ),
        autoHideDuration: snackbarConfig?.delay ?? MILLISECONDS_IN_MINUTE,
        anchorOrigin: topRightSnackbarAnchor,
      })
    },
    [enqueueSnackbar, t]
  )

  const openInfoSnackbar = useCallback(
    (message: string, configs?: TSnackbarConfigs) => {
      openSnackbar('info', message, undefined, configs)
    },
    [openSnackbar]
  )

  const openSuccessSnackbar = useCallback(
    (message?: string, header?: string) => {
      openSnackbar(
        'success',
        message ?? t('common.PSSnackbar.changesSuccessfullySaved'),
        header ?? t('common.PSSnackbar.success'),
        { autoHideDuration: MILLISECONDS_IN_SECOND * 5 }
      )
    },
    [openSnackbar, t]
  )

  const openErrorSnackbar = useCallback(
    (message?: string, header?: string) => {
      openSnackbar(
        'error',
        message ?? translateError(ERROR_CODE.NETWORK_ERROR),
        header ?? t('common.PSSnackbar.error'),
        { autoHideDuration: DEFAULT_SNACKBAR_DELAY }
      )
    },
    [openSnackbar, translateError, t]
  )

  const openProgressSnackbar = useCallback(
    (
      message: string,
      selector: (state: RootState) => number | undefined,
      configs: { hasCloseButton?: boolean; onProgressFinish?: () => void } = {}
    ) => {
      return enqueueSnackbar(message, {
        autoHideDuration: null,
        anchorOrigin: bottomLeftSnackbarAnchor,
        content: (key) => (
          <PSProgressSnackbar
            id={key}
            hasCloseButton={configs?.hasCloseButton}
            onProgressFinish={configs?.onProgressFinish}
            selector={selector}
            messageText={message}
          />
        ),
      })
    },
    [enqueueSnackbar]
  )

  const handleRequestResultAction = useCallback(
    (action: PayloadAction<any>, successMessage = '', errorMessage = '') => {
      if (action.type.includes('/rejected')) {
        openErrorSnackbar(
          errorMessage || translateError(action.payload?.errorCodes?.[0])
        )
      } else {
        openSuccessSnackbar(successMessage)
      }
    },
    [translateError, openSuccessSnackbar, openErrorSnackbar]
  )

  const handleErrorRequestAction = useCallback(
    (
      action: PayloadAction<any, string, any>,
      _successMessage = '',
      errorMessage = ''
    ) => {
      const isManuallyAborted = action.meta?.arg.abortSignal?.aborted

      if (action.type.includes('/rejected') && !isManuallyAborted) {
        openErrorSnackbar(
          errorMessage || translateError(action.payload.errorCodes?.[0])
        )
      }
    },
    [translateError, openErrorSnackbar]
  )

  const showErrorSnackbarByErrorCode = useCallback(
    (errorCode?: ERROR_CODE) => {
      openErrorSnackbar(translateError(errorCode), '')
    },
    [openErrorSnackbar, translateError]
  )

  return {
    handleErrorRequestAction,
    handleRequestResultAction,
    openSuccessSnackbar,
    openErrorSnackbar,
    openIdleSnackbar,
    openInfoSnackbar,
    closeSnackbar,
    openProgressSnackbar,
    showErrorSnackbarByErrorCode,
  }
}

export const SITE_NAME_COMPANY_NAME_REGEX = /^[a-zA-Z0-9&/ -]+$/
export const PRACTICE_NAME_REGEX = /^[a-zA-Z0-9/ -]+$/
export const STREET_REGEX = /^[0-9a-zA-Z ]*$/
export const POSTAL_CODE_REGEX = /^[0-9-]*$/
export const CITY_REGEX = /^[a-zA-Z ]*$/
export const NAME_LAST_NAME_MIDDLE_NAME = /^[a-zA-Z-' ]*[a-zA-Z-']$/
export const ONLY_CAPITAL_ALPHABET = /^[A-Z]*$/
export const ONLY_NUMERIC = /^\d*$/
export const NON_DIGIT_CHARACTERS = /\D/g

export const ONLY_NUMBERS_AND_ALPHABET = /^[a-zA-Z0-9]+$/
export const NUMBERS_ALPHABET_EMPTY_SPACES = /^\w+(\s\w+)*$/
export const NUMBERS_ALPHABET_EMPTY_COMAS = /^[a-zA-Z0-9,]*$/
export const PASSWORD_REGEX =
  /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9])(?=.{8,})/

export const FILE_EXTENSION_REGEX = /[^.]+$/

export const DECIMAL_REGEX = /^\d+(\.\d*)?$/

export const SOURCE_ACCESSION_NUMBER_REGEX = /^[^|^~\\&]+$/

export const WHITE_SPACE_REGEXP = /\s+/g

export const NINE_DIGITS_REGEX = /\b\d{9}\b/

export const ORG_CODE_REGEX = /^[0-9a-zA-Z]{4,10}$/

export const UNDERSCORE_AND_HYPHEN_REGEX = /[-_]/g

export const COMMA_REGEX = /,/g

export const TAG_NAME_REGEX = /^\w{3,30}$/

export const IPV4_IPV6_REGEX = /^[a-zA-Z0-9.:]+$/

export const LOCALHOST_REGEXP =
  /\b127(?:\.(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})){3}\b/

export const FROM_ONE_TO_TWENTY_FIVE_CHARACTERS = /^.{1,25}$/

export const COMMA_SEPARATED_REGEXP = /^\w+(, \w+)*$/

export const DELIVERY_FILE_NAME_PATTERN = /^[a-zA-Z0-9, \-_.{}]+$/

export const ALPHABETICAL_SPACES_APOSTROPHES_DASHES = /^[a-zA-Z\s'-]+$/

export const ALPHABETICAL_NUMERIC_SPACES_APOSTROPHES_DASHES =
  /^[a-zA-Z0-9\s'-]+$/

export const INSURANCE_AUTHORIZATION_REGEXP = /^[a-zA-Z0-9\s'‘\-.,]+$/u

export const EMAIL_REGEXP =
  /^([\w.-]+)@((?:\[\d{1,3}\.\d{1,3}\.\d{1,3}\.)|(?:([\w-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(]?)$/

export const SYMBOLS_REGEXP = /[-\\[\]{}/!@#$%^&*(),']/g

export const ORDER_ISO_NAME_REGEXP = /Ris_Order-(.*?)\.iso/

export const NON_CYRILLIC_SYMBOLS = /^[^\u0400-\u04FF]*$/

import { useState } from 'react'
import { EventsType, useIdleTimer } from 'react-idle-timer'
import { SnackbarKey } from 'notistack'
import {
  getIsFilesDownloading,
  getIsFilesUploading,
} from 'store/filesDownloading/selectors'
import { useAppSelector } from 'store/hooks'

import { usePSSnackbar } from './usePSSnackbar'

interface IIdleSnackbarConfigs {
  timeout: number
  promptBeforeIdle?: number
  events?: EventsType[]
  onTimeoutOver: () => void | Promise<void>
  onContinue?: () => void
}

export const usePSIdleSnackbar = ({
  timeout,
  promptBeforeIdle,
  events,
  onTimeoutOver,
  onContinue,
}: IIdleSnackbarConfigs) => {
  const [snackbarId, setSnackbarId] = useState<SnackbarKey>()
  const { openIdleSnackbar, closeSnackbar } = usePSSnackbar()

  const isFilesUploading = useAppSelector(getIsFilesUploading)
  const isFilesDownloading = useAppSelector(getIsFilesDownloading)

  const onIdle = () => {
    onTimeoutOver()
    closeSnackbar(snackbarId)
    pause()
  }

  const onPrompt = () => {
    setSnackbarId(
      openIdleSnackbar(() => {
        onContinue && onContinue()
        activate()
      })
    )
  }

  const { activate, pause } = useIdleTimer({
    onIdle,
    onPrompt,
    timeout,
    events,
    disabled: isFilesUploading || isFilesDownloading,
    startOnMount: true,
    stopOnIdle: true,
    promptBeforeIdle,
    throttle: 500,
    crossTab: true,
    syncTimers: 1,
  })
}

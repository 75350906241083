import { LOADING_STATE } from 'types/general'

import { IState, ORDERS_TABLE_TAB } from './types'

export const sliceName = 'ordersList'

const emptyTableData = {
  content: [],
  totalElements: 0,
  totalPages: 0,
  isFirstPage: true,
  isLastPage: false,
}

export const initialState: IState = {
  [ORDERS_TABLE_TAB.ALL]: emptyTableData,
  [ORDERS_TABLE_TAB.PREPARATION]: emptyTableData,
  [ORDERS_TABLE_TAB.ASSIGNMENT]: {
    selectedRows: {},
    ...emptyTableData,
  },
  [ORDERS_TABLE_TAB.REPORT_QA]: emptyTableData,
  [ORDERS_TABLE_TAB.BILLING]: emptyTableData,
  loadingState: LOADING_STATE.idle,
  isFilterPanelOpen: false,
}
